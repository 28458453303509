import { useMutation, useQueryClient } from 'react-query';

// Our Axios Instance
import analyticsAxiosInstance from 'services/API/AnalyticsAPI';

// Our Endpoints
import { analyzeEndpoint } from 'shared/analyticsEndpoints';

// Our Query keys
import { ERROR_MESSAGE_DATA } from 'shared/query-keys';

function useMutateGetAnalysis() {
	const queryClient = useQueryClient();

	return useMutation(
		async (assumptionsPayload) => {
			const response = await analyticsAxiosInstance.post(
				analyzeEndpoint,
				assumptionsPayload
			);

			return response.data.data;
		},
		{
			onError: () => {
				queryClient.setQueryData(
					ERROR_MESSAGE_DATA,
					'Please fill out assumptions'
				);
			}
		}
	);
}

export default useMutateGetAnalysis;
