import { dollarFormatter } from 'shared/utils';
/**
 *
 * @param {Array} valuesToFormat
 */
function formatCollection(valuesToFormat) {
	return valuesToFormat
		.map(({ type, value, label }) => {
			const isCurrency = type === 'currency';
			const isRate = type === 'rate' || type === 'percent';

			if (isCurrency)
				return `${label ?? ''} ${dollarFormatter.format(value)}`;

			if (isRate) return `${label ?? ''} ${value}%`;

			return `${label ?? ''} ${value}`;
		})
		.join('\n');
}

export default formatCollection;
