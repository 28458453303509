import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

// Our Axios Instance
import axiosInstance from 'services/API/API';

// Our Endpoints
import { walletOverviewUrl } from 'shared/api-urls';

// Our Query Keys
import { WALLET_OVERVIEW_DATA } from 'shared/query-keys';

const FIVE_MINUTES = 1000 * 60 * 5;

function useGetWalletOverview(select) {
	const { clientId } = useParams();

	const endpointBasedOnClientId = clientId
		? `${walletOverviewUrl}/${clientId}`
		: walletOverviewUrl;

	const DYNAMIC_WALLET_OVERVIEW_QUERY_KEY = clientId
		? [WALLET_OVERVIEW_DATA, clientId]
		: WALLET_OVERVIEW_DATA;

	return useQuery(
		DYNAMIC_WALLET_OVERVIEW_QUERY_KEY,
		async () => {
			const response = await axiosInstance.get(endpointBasedOnClientId);
			const clientWalletOverviewData = response.data.data;
			return clientWalletOverviewData;
		},
		{
			staleTime: FIVE_MINUTES,
			select: (walletOverviewData) => {
				if (select) return select(walletOverviewData);
				return walletOverviewData;
			}
		}
	);
}

export default useGetWalletOverview;
