import { useState, useMemo, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Box, Grid } from '@mui/material';

// Our Components
import Dropdown from 'components/Dropdown/Dropdown';
import LiabilityFormHeading from 'components/Client/Onboarding/LiabilityFormHeading';
import LiabilityDynamicButton from 'components/Client/Onboarding/LiabilityDynamicButton';
import Loader from 'components/Loader/index';
import MonthlyPaymentInput from 'components/Client/Onboarding/MonthlyPaymentInput';
import OutstandingBalanceInput from 'components/Client/Onboarding/OutstandingBalanceInput';
import RateInput from 'components/Input/RateInput';
import TextInput from 'components/Input/TextInput';

// Input Types
import { TEXT } from 'components/Input/Types';

// Utils
import { isSubmissionReady, roundToDecimal } from 'shared/utils';

// Our Hooks
import useMutateAddCommercialLiability from 'hooks/liability/commercial/useMutateAddCommercialLiability';
import useQueryGetCommercialLiability from 'hooks/liability/commercial/useQueryGetCommercialLiability';

// Our Constants
import { COMMERCIAL_LOAN_PURPOSE_OPTIONS_MAPPING } from 'shared/constants';

const COMMERCIAL_LOAN_TYPES = [
	'Term Loan',
	'Line of Credit',
	'Commercial Real Estate Loan',
	'Other'
];

const COMMERCIAL_LOAN_PURPOSE_OPTIONS = [
	'Acquiring, refinancing or improving real estate and buildings',
	'Short- and long-term working capital',
	'Refinancing current business debt',
	'Purchasing and installation of machinery and equipment, including AI-related expenses',
	'Purchasing furniture, fixtures, and supplies',
	'Changes of ownership (complete or partial)',
	'Multiple purpose loans, including any of the above'
];

const SBA_LOAN_CLASSIFICATION = [
	'SBA 7(a)',
	'SBA 504',
	'SBA Express',
	'Non-SBA'
];

const COMMERCIAL_ENTITY_TYPES = [
	'Sole proprietorship',
	'Partnership',
	'C-Corporation',
	'S-Corporation',
	'Limited Liability Company (LLC)',
	'Cooperative',
	'Non-profit'
];

const LOAN_PAYMENT_TYPES = ['Fixed', 'Variable'];

const COMMERCIAL_PROPERTIES_TO_SKIP = new Set([
	'id',
	'clientId',
	'lastUpdated',
	'createdDate'
]);

function CommercialLiability({ enablePrePopulation }) {
	const navigate = useNavigate();
	const params = useParams();
	const clientId = params?.clientId ?? 0;

	// Commercial Liability Related
	const [entityType, setEntityType] = useState('');
	const [entityName, setEntityName] = useState('');
	const [employeeIdentificationNumber, setEmployeeIdentificationNumber] =
		useState('');
	const [loanType, setLoanType] = useState('');
	const [loanTypeOtherText, setLoanTypeOtherText] = useState('');
	const [loanPurpose, setLoanPurpose] = useState('');
	const [loanPayment, setLoanPayment] = useState('');
	const [loanClassification, setLoanClassification] = useState('');
	const [lender, setLender] = useState('');
	const [monthlyPayment, setMonthlyPayment] = useState('');
	const [outstandingBalance, setOutstandingBalance] = useState('');
	const [rate, setRate] = useState('');
	const [remainingYears, setRemainingYears] = useState('');

	const saveCommercialLiability = useMutateAddCommercialLiability();

	const { isLoading, data, isSuccess } =
		useQueryGetCommercialLiability(enablePrePopulation);

	const requiredFormValues = [
		entityName,
		entityType,
		lender,
		loanType,
		loanPayment,
		loanPurpose,
		monthlyPayment,
		outstandingBalance,
		rate,
		remainingYears
	];

	const isFormReady = useMemo(() => {
		const isOtherLoanType = loanType === 'Other';

		return isOtherLoanType
			? loanTypeOtherText.length > 0
			: isSubmissionReady(requiredFormValues);
	}, [
		...requiredFormValues,
		loanTypeOtherText,
		employeeIdentificationNumber
	]);

	// if loan type changes clear the form data
	useEffect(() => {
		if (loanType !== 'Other') setLoanTypeOtherText('');
	}, [loanType]);

	// Pre population logic
	useEffect(() => {
		if (isSuccess) {
			// This maps the fieldName in the data response to the corresponding state fnc that updates that state
			const WANTED_KEY_SET_MAPPING = {
				entityName: setEntityName,
				entityType: setEntityType,
				lenderName: setLender,
				loanPayment: setLoanPayment,
				loanType: setLoanType,
				monthlyPayment: setMonthlyPayment,
				outstandingBalance: setOutstandingBalance,
				otherLoanTypeName: setLoanTypeOtherText,
				purposeOfLoan: setLoanPurpose,
				rate: setRate,
				remainingMonth: setRemainingYears,
				sbaLoanClassification: setLoanClassification,
				uniqueEntityId: setEmployeeIdentificationNumber
			};

			const commercialLiabilityFields = Object.keys(data);

			try {
				commercialLiabilityFields.forEach(
					(commercialLiabilityField) => {
						if (
							COMMERCIAL_PROPERTIES_TO_SKIP.has(
								commercialLiabilityField
							)
						)
							return;

						const currentData = data[commercialLiabilityField];

						const setUpdater =
							WANTED_KEY_SET_MAPPING[commercialLiabilityField];

						if (commercialLiabilityField === 'otherLoanTypeName') {
							const isOtherLoanType = data?.loanType === 'Other';

							if (isOtherLoanType) {
								setUpdater(currentData);
								return;
							}
						}

						if (commercialLiabilityField === 'purposeOfLoan') {
							const mappedPurpose =
								COMMERCIAL_LOAN_PURPOSE_OPTIONS_MAPPING[
									currentData
								];

							const checkIfPurposeIsMissing =
								!COMMERCIAL_LOAN_PURPOSE_OPTIONS.includes(
									mappedPurpose
								);

							// if missing do nothing
							if (checkIfPurposeIsMissing) return;

							// Here we are not missing so we set the mapped purpose
							setUpdater(mappedPurpose);
							return;
						}
						if (
							commercialLiabilityField === 'rate' ||
							commercialLiabilityField === 'monthlyPay' ||
							commercialLiabilityField === 'outstandingBalance'
						) {
							// Here the values are ints we need to convert them to strings
							setUpdater(`${currentData}`);
							return;
						}

						if (commercialLiabilityField === 'remainingMonth') {
							const adjustedRemainingTerm = Math.floor(
								currentData / 12
							);
							setUpdater(adjustedRemainingTerm);
							return;
						}
						setUpdater(currentData);
					}
				);
			} catch (e) {
				// eslint-disable-next-line no-console
				console.error(e);
			}
		}
	}, [data]);

	const submitLiabilityData = (route) => {
		const formattedRate = roundToDecimal(+rate);

		const formattedRemainingTerm = Math.floor(remainingYears * 12);

		const mappedPurpose =
			COMMERCIAL_LOAN_PURPOSE_OPTIONS_MAPPING[loanPurpose];

		// loanPayment is loan type Fixed | Variable
		// Form field says Interest Type
		const commercialLiabilityPayloadData = {
			clientId,
			entityName,
			entityType,
			lenderName: lender,
			loanPayment,
			loanType,
			monthlyPayment,
			outstandingBalance: +outstandingBalance,
			otherLoanTypeName: loanTypeOtherText,
			purposeOfLoan: mappedPurpose,
			rate: formattedRate,
			remainingMonth: formattedRemainingTerm,
			sbaLoanClassification: loanClassification,
			uniqueEntityId: employeeIdentificationNumber
		};

		saveCommercialLiability.mutate(commercialLiabilityPayloadData, {
			onSuccess: () => {
				navigate(route);
			}
		});
	};

	if (isLoading) {
		return (
			<>
				<Helmet>
					<title>Commercial Loan</title>
				</Helmet>
				<LiabilityFormHeading headingText="Commercial Loan" />
				<Loader
					size={60}
					boxSX={{ alignItems: 'center', marginTop: 15 }}
				/>
			</>
		);
	}

	return (
		<>
			<Helmet>
				<title>Commercial Loan</title>
			</Helmet>

			<LiabilityFormHeading headingText="Commercial Loan" />

			<Box component="form" noValidate autoComplete="off">
				<Grid container columnSpacing={2} marginTop={4}>
					<Grid item xs={6}>
						<TextInput
							type={TEXT}
							label="Lender *"
							subLabel="Enter the name of your lender"
							value={lender}
							onChange={setLender}
						/>
					</Grid>
					<Grid item xs={6} sx={{ minHeight: 120, marginBottom: 2 }}>
						<Dropdown
							items={COMMERCIAL_LOAN_TYPES}
							label="Loan Type *"
							selected={loanType}
							onChange={setLoanType}
						/>
						{loanType === 'Other' && (
							<TextInput
								type={TEXT}
								label="Please specify *"
								value={loanTypeOtherText}
								onChange={setLoanTypeOtherText}
								sx={{ marginTop: 2, marginBottom: 4 }}
							/>
						)}
					</Grid>

					<Grid item xs={6} sx={{ height: 120 }}>
						<Dropdown
							items={SBA_LOAN_CLASSIFICATION}
							label="SBA Loan Classification"
							selected={loanClassification}
							onChange={setLoanClassification}
						/>
					</Grid>

					<Grid item xs={6} sx={{ height: 120, marginBottom: 2 }}>
						<Dropdown
							items={COMMERCIAL_LOAN_PURPOSE_OPTIONS}
							label="Purpose of Loan *"
							selected={loanPurpose}
							onChange={setLoanPurpose}
						/>
					</Grid>

					<Grid item xs={6} sx={{ height: 120 }}>
						<OutstandingBalanceInput
							outstandingBalance={outstandingBalance}
							setOutstandingBalance={setOutstandingBalance}
							required
						/>
					</Grid>

					<Grid item xs={6}>
						<MonthlyPaymentInput
							label="Monthly Payment *"
							monthlyPayment={monthlyPayment}
							outstandingBalance={outstandingBalance}
							setMonthlyPayment={setMonthlyPayment}
						/>
					</Grid>

					<Grid item xs={6} sx={{ height: 120, marginBottom: 2 }}>
						<TextInput
							type={TEXT}
							label="Remaining Term *"
							subLabel="Years left in loan"
							value={remainingYears}
							onChange={setRemainingYears}
						/>
					</Grid>

					<Grid item xs={6} sx={{ height: 120 }}>
						<RateInput
							rate={rate}
							setRate={setRate}
							label="Annual Interest Rate *"
						/>
					</Grid>

					<Grid item xs={6} sx={{ height: 120, marginBottom: 2 }}>
						<Dropdown
							items={LOAN_PAYMENT_TYPES}
							label="Interest Type *"
							selected={loanPayment}
							onChange={setLoanPayment}
						/>
					</Grid>

					<Grid item xs={6} sx={{ height: 120, marginBottom: 2 }}>
						<TextInput
							type={TEXT}
							label="Entity Name *"
							subLabel="Company or organization's name"
							value={entityName}
							onChange={setEntityName}
						/>
					</Grid>

					<Grid item xs={6} sx={{ height: 120 }}>
						<Dropdown
							items={COMMERCIAL_ENTITY_TYPES}
							label="Entity Type *"
							selected={entityType}
							onChange={setEntityType}
						/>
					</Grid>

					<Grid item xs={6} sx={{ height: 120, marginBottom: 2 }}>
						<TextInput
							type={TEXT}
							label="Employer Identification Number"
							value={employeeIdentificationNumber}
							error={employeeIdentificationNumber?.length !== 12}
							subLabel="Should be 12 digits"
							onChange={setEmployeeIdentificationNumber}
						/>
					</Grid>

					<Grid item xs={12} sx={{ marginTop: 4 }}>
						<LiabilityDynamicButton
							disabled={!isFormReady}
							isMutationLoading={
								saveCommercialLiability.isLoading
							}
							onClick={submitLiabilityData}
						/>
					</Grid>
				</Grid>
			</Box>
		</>
	);
}

export default CommercialLiability;
