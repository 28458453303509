import { Helmet } from 'react-helmet-async';

// Our Components
import BasicFrame from 'components/Frame/Basic';
import Loader from 'components/Loader/index';

// Our Hooks
import useQueryConsumeMagicToken from 'hooks/passwordless/useQueryConsumeMagicToken';

function MagicTokenVerification() {
	const { isLoading } = useQueryConsumeMagicToken();

	return (
		<>
			<Helmet>
				<title>Magic Token Verification</title>
			</Helmet>

			<BasicFrame sx={{ height: '100vh', width: '100%' }}>
				{isLoading && <Loader size={60} />}
			</BasicFrame>
		</>
	);
}

export default MagicTokenVerification;
