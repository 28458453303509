import { Box } from '@mui/material';
import PropTypes from 'prop-types';

// Our Components
import ExportableReportHeadingText from 'components/ExportableReport/Heading/ExportableReportHeadingText';

// Our Common Prop types
import CommonProps from 'shared/prop-types';

function ExportableReportChartContainer({ heading, variant, sx, children }) {
	const noChildComponent = !children;

	return (
		<Box sx={{ width: 400, height: 300, ...sx }}>
			<ExportableReportHeadingText
				headingText={heading}
				sx={{ marginBottom: 2 }}
			/>
			{noChildComponent && (
				<Box sx={{ width: '100%', height: '85%', bgcolor: variant }} />
			)}
			{!noChildComponent && children}
		</Box>
	);
}

ExportableReportChartContainer.propTypes = {
	heading: PropTypes.string.isRequired,
	variant: PropTypes.string,
	sx: CommonProps.sx
};

ExportableReportChartContainer.defaultProps = {
	variant: 'red',
	sx: {}
};

export default ExportableReportChartContainer;
