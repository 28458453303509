import axios from 'axios';

// Our Onboarding Service URL
const onboardingServiceUrl = process.env.REACT_APP_ONBOARDING_SERVICE_URL;
const onboardingServiceApiKey = process.env.REACT_APP_ONBOARDING_API_KEY;

if (!onboardingServiceUrl) {
	throw new Error('Onboarding Service URL is not set');
}

if (!onboardingServiceApiKey) {
	throw new Error('Onboarding Service API Key is not set');
}

// Our Onboarding Service API
const onboardingServiceApi = axios.create({
	baseURL: onboardingServiceUrl,
	headers: {
		'Content-Type': 'application/json',
		'Access-Control-Allow-Credentials': true,
		Accept: 'application/json',
		'X-API-Key': onboardingServiceApiKey
	}
});

export default onboardingServiceApi;
