import { v4 as uuidv4 } from 'uuid';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@emotion/react';

function LoanOrderedListDetails({ details }) {
	const SoraTheme = useTheme();

	const { primary } = SoraTheme.palette;

	const { subtitle, listHeadings, listDetails } = details;

	const renderList = () =>
		listHeadings.map((heading, index) => (
			<li key={uuidv4()}>
				{heading}
				<ol type="a">
					<li>{listDetails[index]}</li>
				</ol>
			</li>
		));
	return (
		<Box>
			<Typography
				variant="subtitle"
				gutterBottom
				color={primary.white}
				sx={{ fontWeight: 'bold' }}
			>
				{subtitle}
			</Typography>
			<ol style={{ color: primary.white }}>{renderList()}</ol>
		</Box>
	);
}

export default LoanOrderedListDetails;
