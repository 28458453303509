// Our Components
import PageHeading from 'components/PageHeading/PageHeading';
import OnboardingTasks from 'pages/Tasks/OnboardingTasks';

function AdvisorIntegrationsPage() {
	return (
		<>
			<PageHeading
				title="Integrations"
				subtitleText="Clients pending onboarding from integrated applications will be visible below. Select a client to complete onboarding."
			/>
			<OnboardingTasks />
		</>
	);
}

export default AdvisorIntegrationsPage;
