import { Grid, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from 'react-query';

// our component
import DtaQuestionnaire from 'components/Frame/DtaQuestionnaire';
import { PrimaryButton } from 'components/Button/Button';
import Loader from 'components/Loader/index';

// our Hooks
import useMutateSaveDts from 'hooks/dts/useMutateSaveDts';
import useGetProfile from 'hooks/useGetProfile';

// Our Query Keys
import { DTS_STATUS } from 'shared/query-keys';

// Our Routes
import { CLIENT_SORAWALLET_ROUTE } from 'routes';

// Our constants
import { DTS_IN_PROGRESS } from 'shared/constants';

/**
 * dev - development
 * test - stage
 * prod - production
 */
const APP_ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT;

function DebtToleranceAssessment() {
	const navigate = useNavigate();
	const queryClient = useQueryClient();

	// API Calls
	const updateDts = useMutateSaveDts();
	const { isLoading, isSuccess, data: userData } = useGetProfile();

	const handleDtsSubmit = () => {
		// Call mutation to update dts data
		const dtsStatus = {
			clientId: userData.id,
			dtsStatus: DTS_IN_PROGRESS
		};
		updateDts.mutate(dtsStatus, {
			onSuccess: () => {
				queryClient.invalidateQueries(DTS_STATUS);
				navigate(CLIENT_SORAWALLET_ROUTE);
			}
		});
	};

	if (isLoading) return <Loader />;

	if (isSuccess) {
		const { hash, firstName, lastName } = userData;
		const hashWithEnv = `${
			APP_ENVIRONMENT === 'production' ? '' : `${APP_ENVIRONMENT}_`
		}${hash}`;
		const iframeUrl = `https://interview.getreveal.ai/debt-risk-assessment?iframe=true&colorMode=light&rtid=${hashWithEnv}`; // passing hashed userId for security
		const clientFullName = `${firstName} ${lastName}`;
		return (
			<>
				<Grid
					container
					item
					xs={12}
					sx={{
						paddingTop: { xs: 0, md: 4 },
						paddingLeft: { xs: 2, md: 10 },
						flexDirection: 'column',
						gap: 4,
						marginBottom: 4
					}}
				>
					<Typography variant="h1Gascogne">
						Debt Tolerance Assessment
					</Typography>
					<Typography variant="body1">
						The debt tolerance score will allow your financial
						advisor to understand your preferences and tolerance for
						debt to know the best way to support you in your growing
						net worth.
					</Typography>
				</Grid>
				<Grid
					container
					item
					xs={12}
					sx={{
						paddingTop: { xs: 0, md: 4 },
						paddingLeft: { xs: 2, md: 10 },
						flexDirection: 'column',
						gap: 4,
						marginBottom: 4
					}}
				>
					<DtaQuestionnaire
						iframeUrl={iframeUrl}
						clientFullName={clientFullName}
					/>
					<Grid
						container
						item
						xs={12}
						md={2}
						xl={1}
						sx={{
							justifyContent: 'center'
						}}
					>
						<PrimaryButton
							sx={{
								marginBottom: 2,
								width: '90%',
								padding: {
									xs: 2,
									md: 2
								}
							}}
							onClick={handleDtsSubmit}
						>
							Submit
						</PrimaryButton>
					</Grid>
				</Grid>
			</>
		);
	}
}

export default DebtToleranceAssessment;
