// Our Components
import Modal from 'components/Modal/Modal';
import UploadLoanDocs from 'pages/Client/Onboarding/UploadLoanDocs';

function UploadLoanDocumentsModal({
	isOpen,
	handleClose,
	masterDocumentId,
	newLoanId,
	clientId
}) {
	return (
		<Modal isOpen={isOpen} handleClose={handleClose} sx={{}}>
			<UploadLoanDocs
				clientId={clientId}
				masterDocumentId={masterDocumentId}
				newLoanId={newLoanId}
				handleClose={handleClose}
			/>
		</Modal>
	);
}

export default UploadLoanDocumentsModal;
