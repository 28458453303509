import { useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import {
	Typography,
	Grid,
	Box,
	Accordion,
	AccordionDetails,
	AccordionSummary
} from '@mui/material';
import { isEmail } from 'validator';
import { INVITE_THANKS_ROUTE } from 'routes/index';

// Our Components
import Alert from 'components/Alert';
import { PrimaryButton } from 'components/Button/Button';
import TextInput from 'components/Input/TextInput';
import { EMAIL, TEXT } from 'components/Input/Types';
import Checkbox from 'components/Checkbox/Checkbox';
import Modal from 'components/Modal/Modal';
import Loader from 'components/Loader';

// our Hooks
import useMutateAddClientInvitation from 'hooks/useMutateAddClientInvitation';

// Our Utils
import { isSubmissionReady } from 'shared/utils';
import sentenceCapitalize from 'shared/utils/formatting/sentenceCapitalize';

export function ClientInvite() {
	const navigate = useNavigate();
	const sentClientInvite = useMutateAddClientInvitation();
	const { isError: inviteClientError, isLoading } = sentClientInvite;
	const [isOpen, setIsOpen] = useState(false);
	const [firstName, setFirstName] = useState('');
	const [lastName, setLastName] = useState('');
	const [enteredInviteeEmail, setEnteredInviteeEmail] = useState('');
	const [coborrowerFirstName, setCoborrowerFirstName] = useState('');
	const [coborrowerLastName, setCoborrowerLastName] = useState('');
	const [coborrowerEmail, setCoborrowerEmail] = useState(' ');
	const [coborrowerAddition, setCoborrowerAddition] = useState(false);

	const handleFirstNameChange = (value) => {
		setFirstName(value);
	};

	const handleLastNameChange = (value) => {
		setLastName(value);
	};

	const handleEnteredInviteeEmailChange = (value) => {
		setEnteredInviteeEmail(value);
	};

	const handleCoborrowerFirstNameChange = (value) => {
		setCoborrowerFirstName(value);
	};

	const handleCoborrowerLastNameChange = (value) => {
		setCoborrowerLastName(value);
	};

	const handleCoborrowerEmailChange = (value) => {
		setCoborrowerEmail(value);
	};

	const handleCoborrowerAddition = (value) => {
		setCoborrowerAddition(value);
	};

	const handleOpen = () => {
		setIsOpen(true);
	};

	const handleClose = () => {
		setIsOpen(false);
	};

	const formValues = [firstName, lastName, enteredInviteeEmail];

	const isInviteeEmailValid = useMemo(
		() => isEmail(enteredInviteeEmail),
		[enteredInviteeEmail]
	);
	const isCoBorrowerEmailValid = useMemo(
		() => isEmail(coborrowerEmail),
		[coborrowerEmail]
	);

	const isFormReady = useMemo(
		() =>
			coborrowerAddition
				? isSubmissionReady([
						...formValues,
						coborrowerFirstName,
						coborrowerLastName
				  ]) &&
				  isEmail(enteredInviteeEmail) &&
				  isEmail(coborrowerEmail)
				: isSubmissionReady(formValues) && isEmail(enteredInviteeEmail),
		[
			...formValues,
			coborrowerFirstName,
			coborrowerLastName,
			coborrowerEmail,
			coborrowerAddition
		]
	);

	const submitInviteeInfo = (event) => {
		event.preventDefault();

		// The /user/login endpoint won't correctly associate an invited
		// user's account if there are capitalized letters in the email address
		// To get around this issue for now, I'm lower casing all invitedEmails
		// before setting data in react query
		const invitedClientEmail = enteredInviteeEmail.toLowerCase();

		const formattedInvitedClientFirstName = sentenceCapitalize(firstName);
		const formattedInvitedClientLastName = sentenceCapitalize(lastName);

		const invitedClientName = `${formattedInvitedClientFirstName} ${formattedInvitedClientLastName}`;

		const invitedCoborrowerEmail = coborrowerEmail.toLowerCase();

		const formattedCoborrowerFirstName = sentenceCapitalize(firstName);
		const formattedCoborrowerLastName = sentenceCapitalize(lastName);

		const invitedCoborrowerName = `${formattedCoborrowerFirstName} ${formattedCoborrowerLastName}`;

		// Create the data object passed to the invite endpoint based on
		// if the client has a co-borrower or not

		let inviteDataObject = [
			{
				invitedClientEmail,
				invitedClientName
			}
		];

		if (coborrowerAddition && invitedCoborrowerEmail !== '') {
			inviteDataObject = [
				{
					invitedClientEmail,
					invitedClientName
				},
				{
					invitedClientEmail: invitedCoborrowerEmail,
					invitedClientName: invitedCoborrowerName
				}
			];
		}

		sentClientInvite.mutate(inviteDataObject, {
			onSuccess: () => {
				navigate(INVITE_THANKS_ROUTE);
			},
			onError: () => {
				// Clearing all form fields
				handleFirstNameChange('');
				handleLastNameChange('');
				handleEnteredInviteeEmailChange('');
				handleCoborrowerFirstNameChange('');
				handleCoborrowerLastNameChange('');
				handleCoborrowerEmailChange('');
				handleCoborrowerAddition(false);
				// Closing the modal
				handleClose();
			}
		});
	};

	return (
		<>
			{inviteClientError && <Alert variant="error" />}
			<Typography variant="h1Gascogne" component="h1">
				Invite Client
			</Typography>

			<Typography variant="body2" marginTop={2}>
				Enter your client&apos;s information below in order to send them
				an email invitation to join Sora.
			</Typography>

			<Box component="form" noValidate autoComplete="off">
				<Grid container columnSpacing={2} marginTop={4}>
					<Grid item xs={5} marginBottom={4}>
						<TextInput
							inputProps={{
								'data-test': 'firstName'
							}}
							type={TEXT}
							label="First Name"
							subLabel="Use legal name"
							value={firstName}
							onChange={handleFirstNameChange}
						/>
					</Grid>
					<Grid item xs={5}>
						<TextInput
							inputProps={{
								'data-test': 'lastName'
							}}
							type={TEXT}
							label="Last Name"
							subLabel="Use legal name"
							value={lastName}
							onChange={handleLastNameChange}
						/>
					</Grid>
					<Grid item xs={2} />

					<Grid item xs={5} marginBottom={4}>
						<TextInput
							inputProps={{
								'data-test': 'email'
							}}
							type={EMAIL}
							label="Email Address"
							value={enteredInviteeEmail}
							onChange={handleEnteredInviteeEmailChange}
							error={!isInviteeEmailValid}
							helperText="Please enter a valid email address"
						/>
					</Grid>
					<Grid item xs={5} />
					<Grid item xs={2} />

					<Grid item xs={10} marginBottom={4}>
						<Accordion
							sx={{
								boxShadow: 'none',
								'& .MuiAccordionSummary-expandIconWrapper.Mui-expanded':
									{
										transform: 'rotate(0deg)'
									}
							}}
						>
							<AccordionSummary
								expandIcon={
									<Checkbox
										label="Add spouse/co-borrower"
										checked={coborrowerAddition}
										onChange={handleCoborrowerAddition}
										sx={{
											marginTop: 2
										}}
									/>
								}
								sx={{
									flexDirection: 'row-reverse',
									paddingLeft: '0px !important'
								}}
							/>
							<AccordionDetails>
								<Grid container spacing={2}>
									<Grid
										item
										xs={6}
										marginBottom={4}
										sx={{
											paddingLeft: '0px !important'
										}}
									>
										<TextInput
											type={TEXT}
											label="Spouse/Co-Borrower's First Name"
											subLabel="Use legal name"
											onChange={
												handleCoborrowerFirstNameChange
											}
											value={coborrowerFirstName}
										/>
									</Grid>
									<Grid item xs={6}>
										<TextInput
											type={TEXT}
											label="Spouse/Co-Borrower's Last Name"
											subLabel="Use legal name"
											value={coborrowerLastName}
											onChange={
												handleCoborrowerLastNameChange
											}
										/>
									</Grid>

									<Grid
										item
										xs={6}
										marginBottom={4}
										sx={{
											paddingLeft: '0px !important'
										}}
									>
										<TextInput
											type={EMAIL}
											label="Spouses/Co-Borrower's Email Address"
											value={coborrowerEmail}
											onChange={
												handleCoborrowerEmailChange
											}
											error={!isCoBorrowerEmailValid}
											helperText="Please enter a valid email address"
										/>
									</Grid>

									<Grid item xs={6} />
								</Grid>
							</AccordionDetails>
						</Accordion>
					</Grid>
					<Grid item xs={2} />

					<Box
						sx={{
							marginTop: 2,
							marginLeft: 2,
							marginBottom: 6
						}}
					>
						<PrimaryButton
							data-test="inviteButton"
							onClick={handleOpen}
							disabled={!isFormReady}
						>
							Invite
						</PrimaryButton>

						<Modal
							isOpen={isOpen}
							handleClose={handleClose}
							title={
								coborrowerAddition
									? 'Can we reach out to your clients?'
									: 'Can we reach out to your client?'
							}
							subtitle="In order to proceed, Sora needs your consent to contact your clients to collect and validate liability information."
						>
							{!isLoading && (
								<PrimaryButton
									data-test="acceptInvite"
									onClick={submitInviteeInfo}
								>
									Yes
								</PrimaryButton>
							)}
							{isLoading && <Loader />}
						</Modal>
					</Box>
				</Grid>
			</Box>
		</>
	);
}

export default ClientInvite;
