import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

// Our Components
import InsightsCard from 'components/SoraWallet/Cards/insights/InsightsCard';
import InsightsModal from 'components/SoraWallet/Cards/insights/InsightsModal';
import SoraInsightsCarouselHeader from 'components/Carousel/SoraInsightCarouselHeader/SoraInsightsCarouselHeader';
import SoraInsightsLoadingCarousel from 'components/Carousel/SoraInsightsLoadingCarousel';
import StandardCarousel from 'components/Carousel/StandardCarousel';

// Our Constants
import {
	BE_NO_CHATGPT,
	BE_WITH_CHATGPT,
	FE_CHATGPT_ONLY
} from 'shared/constants';

// Our Prop Types
import CommonPropTypes from 'shared/prop-types';

/*
 if insights_mode is 0 -> FE rules with chatGpt
 if insights_mode is 1 -> BE rules no chatGpt
 if insights_mode is 2 -> BE rules with chatGpt
*/
const INSIGHTS_MODE = process.env.REACT_APP_INSIGHTS_MODE;

const IS_BE_INSIGHTS_MODE =
	INSIGHTS_MODE === BE_NO_CHATGPT || INSIGHTS_MODE === BE_WITH_CHATGPT;

function SoraInsightsCarousel({
	backEndRuleEngine,
	isClient,
	insightCardSX,
	insightQueries
}) {
	// Local state
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [selectedInsight, setSelectedInsight] = useState(null);

	const openModal = () => setIsModalOpen(true);

	const closeModal = () => {
		setIsModalOpen(false);
		setSelectedInsight(null);
	};

	const onClickCallBack = (currentlySelectedInsight) => {
		setSelectedInsight(currentlySelectedInsight);
		openModal();
	};

	const frontendInsightsCards = useMemo(() => {
		if (INSIGHTS_MODE === FE_CHATGPT_ONLY)
			return insightQueries.map((currentInsightQuery) => (
				<InsightsCard
					key={uuidv4()}
					sx={insightCardSX}
					isClient={isClient}
					currentInsightQuery={currentInsightQuery}
					onClick={onClickCallBack}
				/>
			));
		return [];
	}, [insightCardSX, insightQueries, isClient]);

	const backendInsightsCards = useMemo(() => {
		if (IS_BE_INSIGHTS_MODE) {
			const {
				isFetching,
				isSuccess,
				data: insightData
			} = backEndRuleEngine;
			if (isFetching) return [];

			if (isSuccess) {
				const finalCards = insightData.map((currentInsightData) => {
					const mimickQuery = { data: currentInsightData, isSuccess };
					return (
						<InsightsCard
							key={uuidv4()}
							sx={insightCardSX}
							isClient={isClient}
							currentInsightQuery={mimickQuery}
							onClick={onClickCallBack}
						/>
					);
				});

				return finalCards;
			}
		}

		return [];
	}, [backEndRuleEngine, insightCardSX, isClient]);

	return (
		<>
			<SoraInsightsCarouselHeader />

			{IS_BE_INSIGHTS_MODE && backEndRuleEngine.isFetching && (
				<SoraInsightsLoadingCarousel />
			)}

			{IS_BE_INSIGHTS_MODE && backEndRuleEngine.isSuccess && (
				<StandardCarousel carouselItems={backendInsightsCards} />
			)}

			{INSIGHTS_MODE === FE_CHATGPT_ONLY && (
				<StandardCarousel carouselItems={frontendInsightsCards} />
			)}

			{selectedInsight && (
				<InsightsModal
					isModalOpen={isModalOpen}
					closeModal={closeModal}
					selectedInsight={selectedInsight}
				/>
			)}
		</>
	);
}

SoraInsightsCarousel.propTypes = {
	clientData: PropTypes.shape({
		annualIncome: PropTypes.number.isRequired,
		assetsAmount: PropTypes.number.isRequired,
		creditScore: PropTypes.number.isRequired,
		email: PropTypes.string,
		firstName: PropTypes.string.isRequired,
		lastName: PropTypes.string.isRequired
	}).isRequired,
	isClient: PropTypes.bool.isRequired,
	insightCardSX: CommonPropTypes.sx
};

SoraInsightsCarousel.defaultProps = {
	insightCardSX: {}
};

export default SoraInsightsCarousel;
