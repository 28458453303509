import { useMutation } from 'react-query';

// Our Axios Instance
import axiosInstance from 'services/API/API';

// Our Endpoints
import { getMagicToken } from 'shared/api-urls';

const DEFAULT_SEND_EMAIL = false;

function useMutateGetMagicToken() {
	return useMutation(async ({ email, sendEmail, route }) => {
		const withSendEmailFallback = sendEmail ?? DEFAULT_SEND_EMAIL;

		const response = await axiosInstance.get(`${getMagicToken}/${email}`, {
			params: { send: withSendEmailFallback, route }
		});

		return response.data;
	});
}

export default useMutateGetMagicToken;
