import { useQuery } from 'react-query';
import { useLocation, useParams } from 'react-router-dom';

// Our Axios Instance
import axiosInstance from 'services/API/API';

// Api URLS
import { getUserLiabilitiesUrl } from 'shared/api-urls';

// QUery keys
import { USER_PERSONALS } from 'shared/query-keys';

// our routes
import { LIABILITY_ADDITIONAL_ROUTE } from 'routes';

// Our utils
import getNonNullValues from 'shared/utils/clientOnboarding/getNonNullValues';

function useGetUserPersonalLiability() {
	const location = useLocation();
	const { pathname } = location;
	const { tradeLineId } = useParams();

	const isManualRoute = pathname.includes(LIABILITY_ADDITIONAL_ROUTE);

	// if this is a isManualRoute do not do the data binding

	const urlWithTradeLineId = `${getUserLiabilitiesUrl}${tradeLineId}`;

	return useQuery(
		[USER_PERSONALS, tradeLineId],
		async () => {
			const response = await axiosInstance.get(urlWithTradeLineId);

			// This is the data from Sora Api which includes meta
			const Liability = response.data;

			// This is the actual mortgage Data
			const personalLiabilityData = Liability.data;
			return personalLiabilityData;
		},
		{
			enabled: !isManualRoute,
			refetchOnWindowFocus: false,
			select: ({
				expectedPayOffDate,
				financialGoal,
				interestRate,
				lender,
				loanType,
				loanUserFor,
				monthlyPay,
				outstandingBalance,
				personalLoanUse,
				rateManuallyUpdated,
				tenureMonth
			}) => {
				// get rid of garbage data before giving it to AutoLiability Component
				const studentDataWithoutNullValues = getNonNullValues({
					expectedPayOffDate,
					financialGoal,
					interestRate,
					lender,
					loanType,
					loanUserFor,
					monthlyPay,
					outstandingBalance,
					personalLoanUse,
					rateManuallyUpdated,
					tenureMonth
				});
				return studentDataWithoutNullValues;
			}
		}
	);
}

export default useGetUserPersonalLiability;
