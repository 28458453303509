import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Grid, Typography } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';

// Our Components
import OtherLoanRowItem from 'components/Client/Onboarding/Table/OtherLoanRowItem';
import LiabilityFormHeading from 'components/Client/Onboarding/LiabilityFormHeading';
import Loader from 'components/Loader';
import OnboardTable from 'components/Client/Onboarding/Table/OnboardTable';
import { PrimaryButton } from 'components/Button/Button';

// Our Routes
import { LIABILITY_ADDITIONAL_ROUTE } from 'routes';

// Our Hooks
import useGetAllUserOtherLoans from 'hooks/clientOnboarding/useGetAllUserOtherLoans';

// Constants
const OTHER_LIABILITY_HEADING_OPTIONS = [
	'Line of credit',
	'Balance',
	'Rate',
	'Paid fully each month'
];

function OtherLiabilities() {
	// Defining useNavigate from react router dom

	const { isLoading, isSuccess, data } = useGetAllUserOtherLoans();

	if (isLoading)
		return (
			<>
				<Helmet>
					<title>Other liabilities</title>
				</Helmet>

				{/* Once data binding begins we will add Mortgage specific information to this header */}
				<LiabilityFormHeading headingText="Other Liabilities" />

				<Loader
					size={60}
					boxSX={{ alignItems: 'center', marginTop: 15 }}
				/>
			</>
		);
	if (isSuccess) {
		const otherLoans = data.map(
			({ otherLoan, balance, rate, isFullyPaid, tradeLineType }) => (
				<OtherLoanRowItem
					key={uuidv4()}
					balance={balance}
					otherLoanName={otherLoan}
					rate={rate}
					isFullyPaid={isFullyPaid}
					tradeLineType={tradeLineType}
				/>
			)
		);

		return (
			<>
				<Helmet>
					<title>Other Liabilities</title>
				</Helmet>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						{/* Once data binding begins we will add Mortgage specific information to this header */}
						<LiabilityFormHeading headingText="Other Liabilities" />
						<Typography variant="body1" sx={{ marginTop: 2 }}>
							Liabilities listed here includes Credit Cards and
							Heloc home loans
						</Typography>
					</Grid>

					<Grid
						item
						xs={12}
						md={10}
						sx={{ marginTop: 4, marginBottom: 4 }}
					>
						<OnboardTable
							tableHeadings={OTHER_LIABILITY_HEADING_OPTIONS}
							tableItems={otherLoans}
							tableHeadingItemSX={{ minWidth: 40 }}
						/>
					</Grid>
					<Grid item xs={12}>
						<Link
							to={LIABILITY_ADDITIONAL_ROUTE}
							style={{ textDecoration: 'none' }}
						>
							<PrimaryButton data-test="next">Next</PrimaryButton>
						</Link>
					</Grid>
				</Grid>
			</>
		);
	}
}

export default OtherLiabilities;
