import { useMutation, useQueryClient } from 'react-query';

// Our Axios
import axiosInstance from 'services/API/API';

// Our Endpoints
import { taskIsDoneUrl } from 'shared/api-urls';

// Our Query Keys
import { WEALTH_BOX_CLIENTS, WEALTH_BOX_DONE_CLIENTS } from 'shared/query-keys';

function useMutateMarkTaskAsDone() {
	const queryClient = useQueryClient();
	return useMutation(
		async (taskToComplete) => {
			// Trigger the change
			await axiosInstance.patch(taskIsDoneUrl, taskToComplete);

			return taskToComplete;
		},
		{
			onSuccess: async () => {
				await queryClient.invalidateQueries(WEALTH_BOX_CLIENTS);
				await queryClient.invalidateQueries(WEALTH_BOX_DONE_CLIENTS);
			}
		}
	);
}
export default useMutateMarkTaskAsDone;
