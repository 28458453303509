import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { Paper } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';

// Our Prop types
import CommonPropTypes from 'shared/prop-types';

// Our Components
import FileDefaultMessage from 'components/FileUpload/FileDefaultMessage';

// Local Constants
const MAX_FILE_SIZE_5_MB = 5e6; // 5 x 10^6 => 5000000
// IMPORTANT - maxFileSize should be provided in bytes.

function FileUpload({
	acceptedFileTypes,
	handleAcceptedFileCallBack,
	handleRejectFileCallBack,
	files,
	filesRejected,
	maxFileSize,
	maxNumberOfFiles,
	OnDragComponent,
	sx,
	children
}) {
	const onDrop = useCallback(
		(acceptedFiles) => {
			handleAcceptedFileCallBack([
				...files,
				...acceptedFiles.map((currentAcceptedFile) => ({
					fileData: currentAcceptedFile,
					key: uuidv4() // IMPORTANT DO NOT CHANGE. WITHOUT THIS KEY IT BREAKS THE QUERY KEY TO FILE ITEM MAPPING
				}))
			]);
		},
		[files]
	);

	const onDropRejected = useCallback(
		(rejectedFiles) => {
			handleRejectFileCallBack([...filesRejected, ...rejectedFiles]);
		},
		[filesRejected]
	);

	const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
		onDrop,
		onDropRejected,
		noClick: false,
		maxSize: maxFileSize ?? MAX_FILE_SIZE_5_MB, // use the maxFileSize if passed in otherwise default to 5 MB
		maxFiles: maxNumberOfFiles,
		...acceptedFileTypes
	});

	return (
		<Paper
			variant="outlined"
			sx={{
				borderStyle: 'dashed',
				borderWidth: 2,
				marginTop: 4,
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				justifyContent: 'center',
				padding: '30px',
				...sx
			}}
			{...getRootProps()}
		>
			<input {...getInputProps()} />
			{!children && (
				<FileDefaultMessage isDragActive={isDragActive} open={open} />
			)}
			{children}

			{children && OnDragComponent && (
				<OnDragComponent
					isDragActive={isDragActive}
					open={open}
					files={files}
				/>
			)}
		</Paper>
	);
}

FileUpload.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	acceptedFileTypes: PropTypes.shape({ accept: PropTypes.any }),
	maxFileSize: PropTypes.number,
	maxNumberOfFiles: PropTypes.number,
	sx: CommonPropTypes.sx
};

FileUpload.defaultProps = {
	acceptedFileTypes: {},
	maxFileSize: MAX_FILE_SIZE_5_MB,
	maxNumberOfFiles: 10,
	sx: {}
};

export default FileUpload;
