/* eslint-disable react/jsx-no-useless-fragment */
import { useParams, useNavigate } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import { Helmet } from 'react-helmet-async';

// Our Components
import Loader from 'components/Loader/index';

// Our Query KEys
import { CLIENTINVITECODE, ROLE, INVITEE_EMAIL } from 'shared/query-keys';

// Our Hooks
import useAddVerifyClientInvite from 'hooks/useAddVerifyClientInvite';

function VerifyClient() {
	const queryClient = useQueryClient();
	const navigate = useNavigate();
	const { inviteCode } = useParams();

	// Saving user invite code based on URL visited
	queryClient.setQueryData(CLIENTINVITECODE, inviteCode);

	// An invited user is a client, set role to reflect that
	queryClient.setQueryData(ROLE, 'CLIENT');

	const { isLoading, error, data, isSuccess } = useAddVerifyClientInvite();

	if (isLoading) {
		return (
			<>
				<Helmet>
					<title>Verify Client Invite Page</title>
				</Helmet>
				<Loader size={60} boxSX={{ justifyContent: 'center' }} />
			</>
		);
	}

	if (error) return `An error has occurred: ${error.message}`;

	if (isSuccess) {
		// Store the invitee's email in react query so we can pre-populate the input on the /signup page
		queryClient.setQueryData(INVITEE_EMAIL, data?.invitedEmail);
		navigate('/signup');
	}

	return (
		<>
			<Helmet>
				<title>Verify Client Invite Page</title>
			</Helmet>
			<Loader size={60} boxSX={{ justifyContent: 'center' }} />
		</>
	);
}

export default VerifyClient;
