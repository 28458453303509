import { Paper, Typography } from '@mui/material';

function ChartToolTip(props) {
	const { payload } = props;

	if (payload.length < 1) return <div />;

	const { lender } = payload[0].payload;

	if (lender === '') return <div />;

	return (
		<Paper elevation={8} sx={{ padding: 2, borderRadius: 6 }}>
			<Typography variant="body1">{lender}</Typography>
		</Paper>
	);
}

export default ChartToolTip;
