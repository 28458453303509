import { useMutation, useQueryClient } from 'react-query';
import { useLocation, useParams } from 'react-router-dom';

// our axios
import axiosInstance from 'services/API/API';

// Our Endpoints
import { getUserLiabilitiesUrl as saveAutoLiabilityURL } from 'shared/api-urls';

// Our Routes
import {
	ADVISOR_BASE_NEW_LOAN_SELECTION_ROUTE,
	CLIENT_NEW_LOAN_SELECTION_ROUTE,
	LIABILITY_ADDITIONAL_ROUTE
} from 'routes';

// Our Query keys
import {
	CLIENT_OVERVIEW_DATA,
	LIABILITY,
	WALLET_OVERVIEW_DATA,
	USER_LIABILITIES
} from 'shared/query-keys';

function useMutateSaveAutoLiability() {
	const location = useLocation();
	const { pathname } = location;
	const { tradeLineId } = useParams();

	const queryClient = useQueryClient();

	// Booleans
	const isManualEntry = pathname.includes(LIABILITY_ADDITIONAL_ROUTE);
	const clientEnteredThroughAddNewLiabilityFlow = pathname.includes(
		CLIENT_NEW_LOAN_SELECTION_ROUTE
	);
	const advisorEnteredThroughAddNewLiabilityFlow = pathname.includes(
		ADVISOR_BASE_NEW_LOAN_SELECTION_ROUTE
	);
	const onClientAutoPage = pathname.includes('/add-new/auto');

	const urlForRequest =
		isManualEntry ||
		clientEnteredThroughAddNewLiabilityFlow ||
		advisorEnteredThroughAddNewLiabilityFlow ||
		onClientAutoPage
			? saveAutoLiabilityURL
			: `${saveAutoLiabilityURL}${tradeLineId}`;

	return useMutation(async (autoLiabilityData) => {
		if (
			isManualEntry ||
			clientEnteredThroughAddNewLiabilityFlow ||
			advisorEnteredThroughAddNewLiabilityFlow ||
			onClientAutoPage
		) {
			// overwriting data confidence here.
			const autoPayloadWithRateManuallyUpdated = {
				...autoLiabilityData,
				rateManuallyUpdated: true
			};

			// manual entry is a post call
			const response = await axiosInstance.post(
				urlForRequest,
				autoPayloadWithRateManuallyUpdated
			);

			await queryClient.invalidateQueries(USER_LIABILITIES);
			await queryClient.invalidateQueries(WALLET_OVERVIEW_DATA, {
				refetchInactive: true
			});
			await queryClient.invalidateQueries(CLIENT_OVERVIEW_DATA, {
				refetchInactive: true
			});

			return response.data;
		}

		// dynamic entries will be patch calls
		const response = await axiosInstance.patch(
			urlForRequest,
			autoLiabilityData
		);

		await queryClient.invalidateQueries(WALLET_OVERVIEW_DATA, {
			refetchInactive: true
		});

		await queryClient.invalidateQueries(LIABILITY, {
			refetchInactive: true
		});

		return response.data;
	});
}

export default useMutateSaveAutoLiability;
