import { Box, Grid, Typography } from '@mui/material';
import { useTheme } from '@emotion/react';

// Our Components
import NewLoanOptionsTable from 'components/LoanInquiry/LoanOffersTable/NewLoanOptionsTable';
import { TertiaryButton } from 'components/Button/Button';

function NewLoanOptionsSection({
	newLoanOffers,
	clientData,
	loanRequestId,
	newLoanDetails,
	faStatus,
	handleChange
}) {
	const SoraTheme = useTheme();

	const { primary } = SoraTheme.palette;

	return (
		<Grid
			item
			xs={12}
			sx={{
				marginBottom: 2,
				paddingRight: 4,
				alignItems: 'center'
			}}
		>
			<Typography
				variant="h2Gascogne"
				component="h2"
				gutterBottom
				sx={{ color: primary.indigo }}
			>
				New Loan Options
			</Typography>
			<Typography variant="caption" component="p" gutterBottom>
				Please note offers are dynamic and may change at a daily basis.
			</Typography>

			{/* Loan Offers */}
			<NewLoanOptionsTable
				loanOffers={newLoanOffers}
				clientData={clientData}
				loanRequestId={loanRequestId}
				newLoanDetails={newLoanDetails}
			/>
			{faStatus === 'PENDING_INFO' && (
				<Box
					sx={{
						width: '100%',
						display: 'flex',
						marginTop: 4
					}}
				>
					<TertiaryButton
						sx={{ margin: '0 auto' }}
						onClick={handleChange}
					>
						Add missing information
					</TertiaryButton>
				</Box>
			)}
		</Grid>
	);
}

export default NewLoanOptionsSection;
