import { useMutation, useQueryClient } from 'react-query';

// our axios
import axiosInstance from 'services/API/API';

// Our Endpoints
import { addNewLoanInquiryUrl } from 'shared/api-urls';

// our query keys
import { ADVISOR_LOAN_REQUESTS, ERROR_MESSAGE_DATA } from 'shared/query-keys';

function useMutateSaveNewLoanInquiry() {
	const queryClient = useQueryClient();
	return useMutation(
		async (newLoanInquiryData) => {
			const response = await axiosInstance.post(
				addNewLoanInquiryUrl,
				newLoanInquiryData
			);

			return response.data;
		},
		{
			onSuccess: () => {
				// user is shown success lightbox
				queryClient.invalidateQueries(ADVISOR_LOAN_REQUESTS);
			},

			onError: (error) => {
				queryClient.setQueryData(ERROR_MESSAGE_DATA, error);
			}
		}
	);
}

export default useMutateSaveNewLoanInquiry;
