import { Typography } from '@mui/material';

// Our Components
import IntegrationCards from 'components/Integrations/IntegrationCards';

function IntegrationsPage() {
	return (
		<>
			<Typography
				variant="h1Gascogne"
				component="h1"
				sx={{ marginBottom: 4 }}
			>
				Integrations
			</Typography>

			<IntegrationCards />
		</>
	);
}

export default IntegrationsPage;
