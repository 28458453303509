import { Box, Divider, Typography } from '@mui/material';

// Our Assets
import SoraLogoBlack from 'assets/images/sora_logos/SoraLogoBlack.svg';

const SORA_LOGO_DIMENSIONS = 50;

function ExportableReportFooter() {
	return (
		<>
			<Divider
				variant="middle"
				sx={{
					backgroundColor: '#000',
					width: '100%',
					margin: 0
				}}
			/>
			<Box
				sx={{
					width: '100%',
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center',
					marginTop: 1
				}}
			>
				<Box
					component="img"
					src={SoraLogoBlack}
					sx={{
						width: SORA_LOGO_DIMENSIONS,
						height: SORA_LOGO_DIMENSIONS
					}}
				/>
				<Typography variant="body2" marginTop={0.5}>
					<a href="https://www.sorafinance.com">sorafinance.com</a>
				</Typography>
			</Box>
		</>
	);
}

export default ExportableReportFooter;
