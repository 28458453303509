import { useMutation } from 'react-query';

// Our Axios
import axiosInstance from 'services/API/API';

// Our Endpoint
import { verifyEmployeeUrl } from 'shared/api-urls';

function useMutateAddEmployee() {
	return useMutation(async (employeeData) => {
		const response = await axiosInstance.post(
			verifyEmployeeUrl,
			employeeData
		);

		const { data: employeeDataResponse } = response.data;

		return employeeDataResponse;
	});
}

export default useMutateAddEmployee;
