import { useQuery, useQueryClient } from 'react-query';

// Our axios Instance
import axiosInstance from 'services/API/API';

// Sora API endpoint
import { userProfile } from 'shared/api-urls';

// React Query Keys
import { CLIENT_PROFILE_DATA } from 'shared/query-keys';

// if select is passed. PLEASE pass a fn
function useGetClientsProfile(clientId, enabled, select) {
	const queryClient = useQueryClient();
	const advisorUrl = `${userProfile}/${clientId}`;

	return useQuery(
		[CLIENT_PROFILE_DATA, clientId],
		async () => {
			const res = await axiosInstance.get(advisorUrl);
			const clientProfileData = res.data.data;
			return clientProfileData;
		},
		{
			staleTime: 60000,
			onSuccess: (clientProfileData) => {
				queryClient.setQueryData(
					CLIENT_PROFILE_DATA,
					clientProfileData
				);
			},
			select,
			enabled
		}
	);
}

export default useGetClientsProfile;
