import { useQuery, useQueryClient } from 'react-query';
import { useSearchParams, useNavigate } from 'react-router-dom';

// Our Axios Instance
import axiosInstance from 'services/API/API';

import useGetProfile from 'hooks/useGetProfile';

// Our Query Keys
import {
	USERPROFILEDATA,
	WEALTH_BOX_CLIENTS_CONNECT,
	ERROR_MESSAGE_DATA
} from 'shared/query-keys';

// Our Endpoints
import { wealthBoxSoraConnect } from 'shared/api-urls';

// Our Routes
import { INVITE_ROUTE, ADVISOR_INTEGRATIONS_ROUTE } from 'routes';

// Our Utils
import { dataIsValid } from 'shared/utils';

function useGetConnectWealthBox(isWealthBox) {
	const queryClient = useQueryClient();
	const { data: userProfileData } = useGetProfile();
	const [searchParams] = useSearchParams();
	const navigate = useNavigate();

	const doesWealthBoxCodeExist = dataIsValid(searchParams?.get('code'));

	const notWhiteListedEmail =
		userProfileData?.email !== 'daniel+wealthboxconsume@sorafinance.com';

	return useQuery(
		WEALTH_BOX_CLIENTS_CONNECT,
		async () => {
			const wealthboxCode = searchParams.get('code');

			const response = await axiosInstance.get(
				`${wealthBoxSoraConnect}${wealthboxCode}`
			);

			return response.data;
		},
		{
			enabled:
				isWealthBox && doesWealthBoxCodeExist && notWhiteListedEmail,
			onSuccess: async () => {
				await queryClient.invalidateQueries(USERPROFILEDATA);
				navigate(ADVISOR_INTEGRATIONS_ROUTE);
			},
			onError: () => {
				navigate(INVITE_ROUTE, { state: { startingIndex: 4 } });
				queryClient.setQueryData(
					ERROR_MESSAGE_DATA,
					'We ran into an issue while connecting. Please wait a few seconds while we try again.'
				);
			}
		}
	);
}

export default useGetConnectWealthBox;
