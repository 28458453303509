import { AppBar, Box, IconButton, Toolbar } from '@mui/material';

// Icons
import CloseIcon from '@mui/icons-material/Close';

// Our Assets
import SoraLogoBlack from 'assets/images/sora_logos/SoraLogoBlack.svg';

// Intended to show only in mobile views.
function SimpleMobileAppBar({ toggleMenuOpen }) {
	return (
		<AppBar position="static">
			<Toolbar
				disableGutters
				sx={{
					justifyContent: 'space-between',
					alignItems: 'center',
					bgcolor: 'white',
					paddingLeft: 2,
					paddingRight: 2
				}}
			>
				<Box component="img" src={SoraLogoBlack} sx={{ height: 25 }} />

				<IconButton
					size="large"
					edge="end"
					aria-label="close"
					onClick={() => toggleMenuOpen()}
					sx={{ color: 'black' }}
				>
					<CloseIcon />
				</IconButton>
			</Toolbar>
		</AppBar>
	);
}

export default SimpleMobileAppBar;
