// This maps the dropdown options with what the BE expects
// lefthand side is what the UI shows and the right are the values expected by the BE
const FINANCIAL_GOAL_TO_ENUM_MAPPING = (goal) => {
	const GOALS_TO_ENUM_MAPPING = {
		'Reduce my monthly payments': 'LOW_EMI',
		'Reduce total cost of debt': 'LOW_COD',
		'Pay off my loans faster': 'EARLY_PAYOFF',
		'Cash out': 'CASH_OUT'
	};

	return GOALS_TO_ENUM_MAPPING[goal];
};

export default FINANCIAL_GOAL_TO_ENUM_MAPPING;
