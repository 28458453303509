import { Box, Typography } from '@mui/material';
import { useLocation, useNavigate, generatePath } from 'react-router-dom';

// Our Components
import { TertiaryButton } from 'components/Button/Button';

// Our Routes
import { ADVISOR_NEW_LOAN_SELECTION_ROUTE } from 'routes';

function MarginalDollarTableNoLiabilitiesFallback() {
	const { state } = useLocation();
	const navigate = useNavigate();

	const { clientSelected } = state;
	return (
		<Box
			sx={{
				width: '100%',
				height: 300,
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				justifyContent: 'center',
				border: '0.25px #1C1C1C solid'
			}}
		>
			<Typography variant="body1" sx={{ marginBottom: 2 }}>
				There are no liabilities to show
			</Typography>

			<TertiaryButton
				onClick={() =>
					navigate(
						generatePath(ADVISOR_NEW_LOAN_SELECTION_ROUTE, {
							clientId: clientSelected.id
						})
					)
				}
				sx={{ paddingTop: 2, paddingBottom: 2, textTransform: 'none' }}
			>
				Add liability
			</TertiaryButton>
		</Box>
	);
}

export default MarginalDollarTableNoLiabilitiesFallback;
