import { useState, useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import { Box, Grid } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useQueryClient } from 'react-query';

// Our Components
import Dropdown from 'components/Dropdown/Dropdown';
import FinancialGoalDropdown from 'components/Client/Onboarding/FinancialGoalDropdown';
import GetFullAddressPartialForm from 'components/Forms/addressRelated/GetFullAddressPartialForm';
import HomeLoanType from 'pages/Client/Onboarding/Liabilities/HomeLoanType';
import LiabilityFormHeading from 'components/Client/Onboarding/LiabilityFormHeading';
import LiabilityDynamicButton from 'components/Client/Onboarding/LiabilityDynamicButton';
import OutstandingBalanceInput from 'components/Client/Onboarding/OutstandingBalanceInput';
import MonthlyPaymentInput from 'components/Client/Onboarding/MonthlyPaymentInput';
import CurrencyTextInput from 'components/Input/CurrencyTextInput';
import RateInput from 'components/Input/RateInput';
import StandardDatePicker from 'components/DatePicker/StandardDatePicker';
import TextInput from 'components/Input/TextInput';
import { TEXT } from 'components/Input/Types';

// Utils
import { isSubmissionReady, roundToDecimal } from 'shared/utils';
import dateToIsoFormat from 'shared/utils/clientOnboarding/dateToIsoFormat';
import FINANCIAL_GOAL_TO_ENUM_MAPPING from 'shared/utils/clientOnboarding/financialGoalMapping';
import getDefaultApproxPayoffDate from 'shared/utils/clientOnboarding/getDefaultApproxPayoffDate';
import PROPERTY_TYPES_TO_ENUM_MAPPING from 'shared/utils/clientOnboarding/propertyTypeToEnumMapping';

// Hooks
import useMutateSaveMortgageLiability from 'hooks/clientOnboarding/mutations/useMutateSaveMortgageLiability';

// Our Query Keys
import { USERPROFILEDATA } from 'shared/query-keys';

// Constants
import { MORTGAGE, TYPES_OF_HOME_PROPERTIES } from 'shared/constants';

const PROPERTY_OWNERSHIP_TYPES = [
	'0-4 years',
	'5-7 years',
	'8-14 years',
	'15-29 years',
	'30+ years'
];

const DEFAULT_LOAN_INFO = {
	loanTerm: '30-year',
	loanType: 'Fixed'
};

function NewMortgageLiability() {
	// these defaults are based on Methodfi Response
	const navigate = useNavigate();
	const saveMortgage = useMutateSaveMortgageLiability();
	const queryClient = useQueryClient();

	const { isLoading: isMutationLoading } = saveMortgage;

	// Mortgage Liability Related
	const [approxPayoffDate, setApproxPayoffDate] = useState(
		getDefaultApproxPayoffDate(MORTGAGE)
	);

	const [lender, setLender] = useState('');
	const [loanInfo, setLoanInfo] = useState(DEFAULT_LOAN_INFO);
	const [monthlyPayment, setMonthlyPayment] = useState('');
	const [escrow, setEscrow] = useState('0');
	const [outstandingBalance, setOutstandingBalance] = useState(''); // balance / 100 is bc methodfi provides balance in cents so we divide by 100 to convert into dollars
	const [rate, setRate] = useState('');

	// Address Related
	const [addressLine1, setAddressLine1] = useState('');
	const [addressLine2, setAddressLine2] = useState('');
	const [city, setCity] = useState('');
	const [propertyType, setPropertyType] = useState('Primary'); // this refers to Loan Type field
	const [propertyOwnershipTenure, setPropertyOwnershipTenure] =
		useState('15-29 years'); // how long do you intend to own this home ?!
	const [state, setState] = useState('');
	const [zipCode, setZipCode] = useState('');

	// Financial Goal
	const [financialGoal, setFinancialGoal] = useState(
		'Reduce total cost of debt'
	);

	// separate
	const [disableAutoComplete, setDisableAutoComplete] = useState(false);

	const formValues = [
		addressLine1,
		approxPayoffDate,
		city,
		financialGoal,
		lender,
		loanInfo.loanTerm,
		loanInfo.loanType,
		monthlyPayment,
		outstandingBalance,
		propertyType,
		propertyOwnershipTenure,
		rate,
		escrow,
		state,
		zipCode
	];

	const isFormReady = useMemo(
		() => isSubmissionReady(formValues) && approxPayoffDate !== null,
		formValues
	);

	const handleApproxPayoffDateChange = (value) => {
		const isValueEmpty = value === '';
		if (isValueEmpty) {
			setApproxPayoffDate('');
			return;
		}
		setApproxPayoffDate(value);
	};

	const paramsFromURL = useParams();
	const clientId = paramsFromURL?.clientId;

	const profileData = queryClient.getQueryData(USERPROFILEDATA);
	const isClient = profileData?.role === 'CLIENT';

	const submitLiabilityData = (route) => {
		const formattedApproxPayOffDate = dateToIsoFormat(approxPayoffDate);

		const formattedFinancialGoal =
			FINANCIAL_GOAL_TO_ENUM_MAPPING(financialGoal);

		const { loanTerm, loanType } = loanInfo;

		const formattedLoanType = loanType.toUpperCase();

		const formattedPropertyType =
			PROPERTY_TYPES_TO_ENUM_MAPPING(propertyType);

		const formattedRate = roundToDecimal(+rate);

		const strippedOwnershipTenure = loanTerm?.split('-')[0];

		const homeData = {
			clientId,
			addressLine1,
			addressLine2,
			approxPayoffDate: formattedApproxPayOffDate,
			city,
			escrow,
			financialGoal: formattedFinancialGoal,
			lender,
			loanType: formattedLoanType,
			monthlyPayment: +monthlyPayment,
			outstandingBalance: +outstandingBalance,
			propertyType: formattedPropertyType,
			propertyOwnershipTenure,
			rate: formattedRate,
			state,
			tenure: strippedOwnershipTenure,
			tradelineType: 'MORTGAGE',
			zipcode: zipCode
		};

		saveMortgage.mutate(homeData, {
			onSuccess: () => {
				navigate(route);
			}
		});
	};

	return (
		<>
			<Helmet>
				<title>Home Loan</title>
			</Helmet>

			{/* Once data binding begins we will add Mortgage specific information to this header */}
			<LiabilityFormHeading headingText="Home Loan" />

			<Box component="form" noValidate autoComplete="off">
				<Grid container columnSpacing={2} marginTop={4}>
					<Grid item xs={6}>
						<OutstandingBalanceInput
							outstandingBalance={outstandingBalance}
							setOutstandingBalance={setOutstandingBalance}
						/>
					</Grid>
					<Grid item xs={6} marginBottom={4}>
						<RateInput rate={rate} setRate={setRate} />
					</Grid>

					{/* Uses 12 Grid Columns and has marginBottom 4 */}
					<HomeLoanType
						loanInfo={loanInfo}
						setLoanInfo={setLoanInfo}
					/>

					<Grid item xs={6}>
						<TextInput
							type={TEXT}
							label="Lender"
							subLabel="Enter the name of your lender"
							value={lender}
							onChange={setLender}
						/>
					</Grid>
					<Grid item xs={6} marginBottom={6}>
						<MonthlyPaymentInput
							monthlyPayment={monthlyPayment}
							outstandingBalance={outstandingBalance}
							setMonthlyPayment={setMonthlyPayment}
						/>
					</Grid>

					<Grid item xs={6} marginBottom={6}>
						<CurrencyTextInput
							label="Is any part of the monthly payment for escrow, insurance or property tax? How much?"
							onChange={setEscrow}
							value={escrow}
							subLabel="Optional"
						/>
					</Grid>
					<Grid item xs={6} />

					<Grid item xs={6}>
						<StandardDatePicker
							label="Expected Payoff Date"
							helperText="Enter the approximate payoff date"
							onChange={handleApproxPayoffDateChange}
							value={approxPayoffDate}
							error={approxPayoffDate === ''}
						/>
					</Grid>
					<Grid item xs={6} marginBottom={4}>
						<Dropdown
							items={TYPES_OF_HOME_PROPERTIES}
							selected={propertyType}
							onChange={setPropertyType}
							variant="outlined"
							label="Type of property"
						/>
					</Grid>

					<FinancialGoalDropdown
						financialGoal={financialGoal}
						setFinancialGoal={setFinancialGoal}
						gridColumns={6}
					/>
					<Grid item xs={6}>
						<Dropdown
							items={PROPERTY_OWNERSHIP_TYPES}
							selected={propertyOwnershipTenure}
							onChange={setPropertyOwnershipTenure}
							variant="outlined"
							label="Intended ownership tenure (years)"
							labelSx={{
								whiteSpace: 'nowrap'
							}}
						/>
					</Grid>

					<GetFullAddressPartialForm
						addressLine1={addressLine1}
						addressLine2={addressLine2}
						city={city}
						state={state}
						zipCode={zipCode}
						setAddressLine1={setAddressLine1}
						setAddressLine2={setAddressLine2}
						setCity={setCity}
						setState={setState}
						setZipCode={setZipCode}
						disableAutoComplete={disableAutoComplete}
						setDisableAutoComplete={setDisableAutoComplete}
					/>
					<Grid item xs={12} sx={{ marginTop: 4 }}>
						<LiabilityDynamicButton
							disabled={!isFormReady}
							isMutationLoading={isMutationLoading}
							onClick={submitLiabilityData}
						/>
					</Grid>
				</Grid>
			</Box>
		</>
	);
}

export default NewMortgageLiability;
