import { useMutation, useQueryClient } from 'react-query';
import { useLocation } from 'react-router-dom';

// Our axios instance
import axiosInstance from 'services/API/API';

// Our endpoint
import { userProfile } from 'shared/api-urls';

// Our Query keys
import {
	USER_DATA,
	USERPROFILEDATA,
	WALLET_OVERVIEW_DATA
} from 'shared/query-keys';

// Additional useMutation
import useMutateUpdateOnboardingStatus from 'hooks/clientOnboarding/mutations/useMutateUpdateOnboardingStatus';

// Our Constants
import { REVIEW_COMPLETED } from 'shared/constants';

// Our Routes
import { CLIENT_REVIEW_ROUTE } from 'routes';

// Our Utils
import capitalizeUserProfile from 'shared/utils/formatting/capitalizeUserProfile';

function useMutateAddProfileData() {
	const queryClient = useQueryClient();
	const updateOnboardingStatus = useMutateUpdateOnboardingStatus();
	const location = useLocation();

	const { pathname } = location;

	return useMutation(
		USER_DATA,
		async (userData) => {
			// Guarantee the name is formatted :)
			const formattedUserData = capitalizeUserProfile(userData);

			const response = await axiosInstance.post(
				userProfile,
				formattedUserData
			);

			const userProfileData = response.data.data;

			const isOnReviewPage = pathname.includes(CLIENT_REVIEW_ROUTE);

			if (isOnReviewPage) {
				// Update Onboarding Status
				await updateOnboardingStatus.mutate(REVIEW_COMPLETED);
			}

			await queryClient.invalidateQueries(WALLET_OVERVIEW_DATA);

			return userProfileData;
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries(USERPROFILEDATA);
			}
		}
	);
}

export default useMutateAddProfileData;
