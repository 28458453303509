import { useQuery, useQueryClient } from 'react-query';
import axiosInstance from 'services/API/API';
import { useParams } from 'react-router-dom/dist/index';

// Our Query Keys
import {
	CLIENT_ONBOARDING_INCOME,
	ERROR_MESSAGE_DATA
} from 'shared/query-keys';

// Our End Points
import { userIncome } from 'shared/api-urls';

// Utils
import { dataIsValid } from 'shared/utils';

function useGetIncome() {
	const queryClient = useQueryClient();

	const urlParams = useParams();

	const params = {
		clientId: urlParams?.clientId
	};

	const DYNAMIC_QUERY_KEY = dataIsValid(params?.clientId)
		? [CLIENT_ONBOARDING_INCOME, params?.clientId]
		: CLIENT_ONBOARDING_INCOME;

	return useQuery(
		DYNAMIC_QUERY_KEY,
		() =>
			axiosInstance
				.get(userIncome, { params })
				.then((res) => res.data.data),
		{
			onError: () => {
				queryClient.setQueryData(
					ERROR_MESSAGE_DATA,
					'We had an issue updating retrieving your income information.'
				);
			},
			refetchOnWindowFocus: false
		}
	);
}

export default useGetIncome;
