import { useMutation, useQueryClient } from 'react-query';
import {
	useNavigate,
	useLocation,
	useParams,
	generatePath
} from 'react-router-dom';

// Our axios instance
import axiosInstance from 'services/API/API';

// Endpoints
import { userIncome } from 'shared/api-urls';

// Our Query keys
import {
	CLIENT_ONBOARDING_INCOME,
	ERROR_MESSAGE_DATA,
	SORA_INSIGHTS,
	WALLET_OVERVIEW_DATA
} from 'shared/query-keys';

// Our Routes
import { ADVISOR_WALLET_ROUTE, CLIENT_SORAWALLET_ROUTE } from 'routes';

// Additional useMutation
import useMutateUpdateOnboardingStatus from 'hooks/clientOnboarding/mutations/useMutateUpdateOnboardingStatus';
import useWasFromWallet from 'hooks/utilityHook/useWasFromWallet';

// Our Constants
import { INCOME_UPDATED } from 'shared/constants';

function useMutateSetIncome() {
	const navigate = useNavigate();
	const isFromWallet = useWasFromWallet();
	const updateOnboardingStatus = useMutateUpdateOnboardingStatus();
	const queryClient = useQueryClient();
	const location = useLocation();

	const { state } = location;

	const params = useParams();

	const { clientId } = params;

	return useMutation(
		async ({ totalOtherAnnualIncome, otherIncomeId }) => {
			const payload = {
				compensationAmount: totalOtherAnnualIncome,
				compensationUnit: 'annually',
				compensationCurrency: 'USD'
			};

			if (clientId || state?.clientId) {
				payload.clientId = clientId;
			}

			const apiMethodUsed =
				otherIncomeId === 0 || otherIncomeId === undefined
					? 'post'
					: 'patch';

			const urlBasedOnIncomeId =
				otherIncomeId === 0 || otherIncomeId === undefined
					? userIncome
					: `${userIncome}/${otherIncomeId}`;

			const response = await axiosInstance[apiMethodUsed](
				urlBasedOnIncomeId,
				payload
			);

			const userIncomeData = response.data.data;

			if (!isFromWallet) {
				// Updates onboarding status
				await updateOnboardingStatus.mutate(INCOME_UPDATED);
			} else {
				await queryClient.invalidateQueries(WALLET_OVERVIEW_DATA, {
					refetchInactive: true
				});
			}

			return userIncomeData;
		},
		{
			onError: () => {
				queryClient.setQueryData(
					ERROR_MESSAGE_DATA,
					'We had an issue updating your asset information.'
				);
			},
			onSuccess: async () => {
				await queryClient.invalidateQueries(CLIENT_ONBOARDING_INCOME);
				await queryClient.invalidateQueries(WALLET_OVERVIEW_DATA);

				queryClient.invalidateQueries(SORA_INSIGHTS);

				if (isFromWallet) {
					if (clientId) {
						navigate(
							generatePath(`${ADVISOR_WALLET_ROUTE}:clientId`, {
								clientId
							}),
							{ replace: true }
						);
					} else {
						navigate(CLIENT_SORAWALLET_ROUTE, { replace: true });
					}
				}
			}
		}
	);
}

export default useMutateSetIncome;
