import { IconButton, Checkbox } from '@mui/material';

// Our Components
import Loader from 'components/Loader/index';

// Our Hooks
import useMutateMarkLoanTaskDone from 'hooks/tasks/useMutateMarkLoanTaskDone';

function LoanTasksLoadingCheckbox({ rowData }) {
	const markLoanTaskAsDone = useMutateMarkLoanTaskDone();

	const { isLoading } = markLoanTaskAsDone;

	const { done, loanRequestId } = rowData;

	const onClick = (e) => {
		e.stopPropagation();
		markLoanTaskAsDone.mutate([
			{ newLoanId: loanRequestId, taskDone: !done }
		]);
	};

	if (isLoading) return <Loader />;

	return (
		<IconButton onClick={onClick} sx={{ padding: 0 }}>
			<Checkbox
				defaultChecked={done}
				sx={{
					padding: 0,
					marginTop1: 1,
					color: 'black',
					'&.Mui-checked': {
						color: 'black'
					}
				}}
			/>
		</IconButton>
	);
}

export default LoanTasksLoadingCheckbox;
