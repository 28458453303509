import { useMutation, useQueryClient } from 'react-query';
import { getAuth, sendPasswordResetEmail } from 'firebase/auth';

import { ERROR_MESSAGE_DATA, SUCCESS_MESSAGE_DATA } from 'shared/query-keys';

const useMutateResetPassword = () => {
	const auth = getAuth();

	const queryClient = useQueryClient();

	return useMutation((email) => sendPasswordResetEmail(auth, email), {
		onSuccess: () => {
			queryClient.setQueryData(
				SUCCESS_MESSAGE_DATA,
				'Email has been sent'
			);
		},
		onError: (error) => {
			queryClient.setQueryData(ERROR_MESSAGE_DATA, error.message);
		}
	});
};

export default useMutateResetPassword;
