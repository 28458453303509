// This maps the dropdown options with what the BE expects
// lefthand side is what the UI shows and the right are the values expected by the BE
const EMUM_TO_FINANCIAL_GOAL_MAPPING = (incomingEnum) => {
	const ENUM_TO_GOALS_MAPPING = {
		LOW_EMI: 'Reduce my monthly payments',
		LOW_COD: 'Reduce total cost of debt',
		EARLY_PAYOFF: 'Pay off my loans faster',
		CASH_OUT: 'Cash out',
		'OTHER PRIORITY': ''
	};

	return ENUM_TO_GOALS_MAPPING[incomingEnum];
};

export default EMUM_TO_FINANCIAL_GOAL_MAPPING;
