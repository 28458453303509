import { useQuery, useQueryClient } from 'react-query';
import { useParams, useNavigate } from 'react-router-dom';
import { getAuth, signInWithCustomToken } from 'firebase/auth';

// Our Axios Instance
import axiosInstance from 'services/API/API';

// Our Endpoints
import { getCustomToken } from 'shared/api-urls';

// Our Query Keys
import { FIREBASEUSERCREDENTIALS, DTS_ROUTE } from 'shared/query-keys';

function useQueryConsumeMagicToken() {
	const auth = getAuth();
	const queryClient = useQueryClient();
	const navigate = useNavigate();
	const { code } = useParams();

	return useQuery(
		FIREBASEUSERCREDENTIALS,
		async () => {
			const response = await axiosInstance.get(
				`${getCustomToken}/${code}`
			);

			const { customToken: magicToken, route } = response.data.data;

			const loginResponse = await signInWithCustomToken(auth, magicToken);

			if (route) {
				queryClient.setQueryData(DTS_ROUTE, route);
			}

			const { accessToken } = loginResponse.user;

			return accessToken;
		},
		{
			onSuccess: () => {
				const route = queryClient.getQueryData(DTS_ROUTE) ?? false;
				if (route) {
					navigate('/dts-routing-user', { replace: true });
				} else {
					navigate('/routing-user', { replace: true });
				}
			}
		}
	);
}

export default useQueryConsumeMagicToken;
