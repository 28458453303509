import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';

// Our Components
import TableHeading from 'components/Client/Onboarding/Table/TableHeading';
import TableRowItem from 'components/Client/Onboarding/Table/TableRowitem';

// Our PropTypes
import CommonPropTypes from 'shared/prop-types';

// It is crucial that tableItems is an ARRAY of React Elements.
function OnboardTable({
	sx,
	tableHeadings,
	tableHeadingSX,
	tableHeadingItemSX,
	tableItems,
	tableRowSX
}) {
	return (
		<>
			<TableHeading
				tableHeadings={tableHeadings}
				tableHeadingSX={tableHeadingSX}
				tableHeadingItemSX={tableHeadingItemSX}
			/>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					height: '100%',
					width: '100%',
					...sx
				}}
			>
				{tableItems.map((tableItem) => (
					<TableRowItem key={uuidv4()} sx={tableRowSX}>
						{tableItem}
					</TableRowItem>
				))}
			</Box>
		</>
	);
}

OnboardTable.propTypes = {
	sx: CommonPropTypes.sx,
	tableHeadings: PropTypes.arrayOf(PropTypes.string).isRequired,
	tableHeadingSX: CommonPropTypes.sx,
	tableHeadingItemSX: CommonPropTypes.sx,
	tableItems: PropTypes.arrayOf(PropTypes.element).isRequired,
	tableRowSX: CommonPropTypes.sx
};

OnboardTable.defaultProps = {
	sx: {},
	tableHeadingSX: {},
	tableHeadingItemSX: {},
	tableRowSX: {}
};

export default OnboardTable;
