import { Grid, Typography } from '@mui/material';

// Our Components
import Dropdown from 'components/Dropdown/Dropdown';
import RateInput from 'components/Input/RateInput';

// Our constants
import {
	APPLY_CAPITAL_GAINS_TAX_SIMPLE,
	APPLY_CAPITAL_GAINS_TAX_DETAILED
} from 'shared/constants';

function TaxesAndDividendsSection({
	isSimpleMode,
	incomeTaxRate,
	setIncomeTaxRate,
	capitalGainsTaxRate,
	setCapitalGainsTaxRate,
	averageAnnualDividendReturnRate,
	setAverageAnnualDividendReturnRate,
	dividendTaxRate,
	setDividendTaxRate,
	isPropertyInterestTaxDeductible,
	setIsPropertyInterestTaxDeductible,
	applyCapitalGainsTax,
	setApplyCapitalGainsTax
}) {
	return (
		<>
			<Typography
				sx={{ marginTop: 3, marginBottom: 2, display: 'block' }}
				variant="subtitleGascongne"
			>
				Taxes & Dividends
			</Typography>

			<Grid container spacing={2}>
				<Grid item xs={4}>
					<RateInput
						label="Income tax rate"
						subLabel=""
						rate={incomeTaxRate}
						setRate={setIncomeTaxRate}
						variant="body2"
					/>
				</Grid>
				<Grid item xs={4}>
					<RateInput
						label="Capital gains tax rate"
						subLabel=""
						rate={capitalGainsTaxRate}
						setRate={setCapitalGainsTaxRate}
						variant="body2"
					/>
				</Grid>
				<Grid item xs={4}>
					<RateInput
						label="Average annual dividend return rate"
						subLabel=""
						labelSx={{
							whiteSpace: 'nowrap'
						}}
						rate={averageAnnualDividendReturnRate}
						setRate={setAverageAnnualDividendReturnRate}
						variant="body2"
					/>
				</Grid>

				<Grid item xs={4}>
					<RateInput
						label="Dividend tax rate"
						subLabel=""
						rate={dividendTaxRate}
						setRate={setDividendTaxRate}
						variant="body2"
					/>
				</Grid>
				<Grid item xs={4}>
					<Dropdown
						label="Is property interest tax-deductible?"
						labelSx={{ whiteSpace: 'nowrap' }}
						labelVariant="body2"
						items={['Yes', 'No']}
						selected={isPropertyInterestTaxDeductible}
						onChange={setIsPropertyInterestTaxDeductible}
					/>
				</Grid>
				<Grid item xs={4}>
					<Dropdown
						label="How to apply capital gains tax"
						labelSx={{ whiteSpace: 'nowrap' }}
						labelVariant="body2"
						items={
							isSimpleMode
								? APPLY_CAPITAL_GAINS_TAX_SIMPLE
								: APPLY_CAPITAL_GAINS_TAX_DETAILED
						}
						selected={applyCapitalGainsTax}
						onChange={setApplyCapitalGainsTax}
					/>
				</Grid>
			</Grid>
		</>
	);
}

export default TaxesAndDividendsSection;
