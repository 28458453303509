import { useMutation } from 'react-query';

// Our Axios Instance
import axiosInstance from 'services/API/API';
import onboardingServiceApi from 'services/API/OnboardingServiceAPI';

// Our Endpoints
import { userGetLoginTokenUrl } from 'shared/api-urls';

function useMutateStartOnboardingProcess() {
	return useMutation(async (onboardClientDetails) => {
		const userTokenResponse = await axiosInstance.get(userGetLoginTokenUrl);

		const userTokenData = userTokenResponse.data.data;

		const onboardingRequestPayload = {
			...onboardClientDetails,
			...userTokenData
		};

		const response = await onboardingServiceApi.post(
			'/onboard/single/client',
			onboardingRequestPayload
		);

		return response.data;
	});
}

export default useMutateStartOnboardingProcess;
