import { useState, useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import { Box, Grid } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useQueryClient } from 'react-query';

// Our Components
import Dropdown from 'components/Dropdown/Dropdown';
import FinancialGoalDropdown from 'components/Client/Onboarding/FinancialGoalDropdown';
import LiabilityFormHeading from 'components/Client/Onboarding/LiabilityFormHeading';
import LiabilityDynamicButton from 'components/Client/Onboarding/LiabilityDynamicButton';
import LoanTypeDropdown from 'components/Client/Onboarding/LoanTypeDropdown';
import MileageTextInput from 'components/Input/MileageTextInput';
import MonthlyPaymentInput from 'components/Client/Onboarding/MonthlyPaymentInput';
import OutstandingBalanceInput from 'components/Client/Onboarding/OutstandingBalanceInput';
import RateInput from 'components/Input/RateInput';
import StandardDatePicker from 'components/DatePicker/StandardDatePicker';
import TextInput from 'components/Input/TextInput';
import TenureDropdown from 'components/Client/Onboarding/TenureDropdown';
import USStateDropdown from 'components/Dropdown/USStateDropdown';

// Input Types
import { TEXT } from 'components/Input/Types';

// Our Hooks
import useSaveAutoLiability from 'hooks/clientOnboarding/mutations/useSaveAutoLiability';

// Our Query Keys
import { USERPROFILEDATA } from 'shared/query-keys';

// Utils
import { isSubmissionReady, roundToDecimal } from 'shared/utils';
import dateToIsoFormat from 'shared/utils/clientOnboarding/dateToIsoFormat';
import FINANCIAL_GOAL_TO_ENUM_MAPPING from 'shared/utils/clientOnboarding/financialGoalMapping';
import getDefaultApproxPayoffDate from 'shared/utils/clientOnboarding/getDefaultApproxPayoffDate';

// CONSTANTS
import { AUTO } from 'shared/constants';

const VEHICLE_CONDITION_OPTIONS = ['Excellent', 'Good', 'Fair', 'Poor']; // This is for the DatePicker of Graduation Year

function NewAutoLiability() {
	const navigate = useNavigate();
	const queryClient = useQueryClient();
	const saveUserAutoLiability = useSaveAutoLiability();

	const { isLoading: isMutationLoading } = saveUserAutoLiability;

	// Auto Liability Related
	const [approxPayoffDate, setApproxPayoffDate] = useState(
		getDefaultApproxPayoffDate(AUTO)
	);
	const [lender, setLender] = useState('');
	const [loanType, setLoanType] = useState('Fixed');
	const [monthlyPayment, setMonthlyPayment] = useState('');
	const [ownershipTenure, setOwnershipTenure] = useState('');
	const [outstandingBalance, setOutstandingBalance] = useState(''); // balance / 100 is bc methodfi provides balance in cents so we divide by 100 to convert into dollars
	const [rate, setRate] = useState('');

	// Vehicle Related Data
	const [licensePlate, setLicensePlate] = useState('');
	const [usState, setUSState] = useState('');
	const [vehicleCondition, setVehicleCondition] = useState('');
	const [vehicleMileage, setVehicleMileage] = useState('');
	const [vehicleVINNumber, setVehicleVINNumber] = useState('');

	// Financial Goal
	const [financialGoal, setFinancialGoal] = useState(
		'Reduce total cost of debt'
	);

	const formValues = [
		approxPayoffDate,
		financialGoal,
		lender,
		monthlyPayment,
		outstandingBalance,
		ownershipTenure,
		loanType,
		rate,
		vehicleCondition,
		vehicleMileage
	];

	const isFormReady = useMemo(
		() => isSubmissionReady(formValues) && approxPayoffDate !== null,
		formValues
	);

	const handleApproxPayoffDateChange = (value) => {
		const isValueEmpty = value === '';
		if (isValueEmpty) {
			setApproxPayoffDate('');
			return;
		}
		setApproxPayoffDate(value);
	};

	const paramsFromURL = useParams();
	const clientId = paramsFromURL?.clientId;

	const profileData = queryClient.getQueryData(USERPROFILEDATA);
	const isClient = profileData?.role === 'CLIENT';

	const submitLiabilityData = (route) => {
		const formattedApproxPayOffDate = dateToIsoFormat(approxPayoffDate);
		const formattedFinancialGoal =
			FINANCIAL_GOAL_TO_ENUM_MAPPING(financialGoal);
		const formattedLoanType = loanType.toUpperCase();
		const formattedRate = roundToDecimal(+rate);

		const autoData = {
			clientId,
			approxPayoffDate: formattedApproxPayOffDate,
			condition: vehicleCondition,
			financialGoal: formattedFinancialGoal,
			lender,
			licensePlate,
			loanType: formattedLoanType,
			mileage: vehicleMileage,
			monthlyPayment: +monthlyPayment,
			outstandingBalance: +outstandingBalance,
			rate: formattedRate,
			tradelineType: AUTO, // can be one of AUTO | MORTGAGE | PERSONAL | STUDENT
			tenure: +ownershipTenure,
			state: usState,
			vin: vehicleVINNumber
		};

		saveUserAutoLiability.mutate(autoData, {
			onSuccess: () => {
				navigate(route);
			}
		});
	};

	return (
		<>
			<Helmet>
				<title>Auto Loan</title>
			</Helmet>

			{/* Once data binding begins we will add Mortgage specific information to this header */}
			<LiabilityFormHeading headingText="Auto Loan" />

			<Box component="form" noValidate autoComplete="off">
				<Grid container columnSpacing={2} marginTop={4}>
					<Grid item xs={6}>
						<OutstandingBalanceInput
							outstandingBalance={outstandingBalance}
							setOutstandingBalance={setOutstandingBalance}
						/>
					</Grid>
					<Grid item xs={6} marginBottom={4}>
						<MonthlyPaymentInput
							monthlyPayment={monthlyPayment}
							outstandingBalance={outstandingBalance}
							setMonthlyPayment={setMonthlyPayment}
						/>
					</Grid>
					<Grid item xs={6}>
						<RateInput rate={rate} setRate={setRate} />
					</Grid>
					<Grid item xs={6} marginBottom={4}>
						<StandardDatePicker
							label="Expected Payoff Date"
							helperText="Enter the approximate payoff date"
							onChange={handleApproxPayoffDateChange}
							value={approxPayoffDate}
							error={approxPayoffDate === ''}
						/>
					</Grid>

					<TenureDropdown
						gridColumns={6}
						tenure={ownershipTenure}
						setTenure={setOwnershipTenure}
						dropDownSX={{ width: '100%' }}
						sx={{ marginBottom: 0 }}
					/>

					{/* Takes 5 Grid Columns */}
					{/* has a default of marginBottom 4 */}
					<LoanTypeDropdown
						gridColumns={6}
						loanType={loanType}
						setLoanType={setLoanType}
					/>

					<Grid item xs={6}>
						<TextInput
							type={TEXT}
							label="Lender"
							subLabel="Enter the name of your lender"
							value={lender}
							onChange={setLender}
						/>
					</Grid>

					<Grid item xs={6} marginBottom={4}>
						<TextInput
							type={TEXT}
							label="Vehicle VIN number"
							subLabel="Optional"
							value={vehicleVINNumber}
							onChange={setVehicleVINNumber}
						/>
					</Grid>

					<Grid item xs={6}>
						<TextInput
							type={TEXT}
							label="License Plate number"
							subLabel="Optional"
							value={licensePlate}
							onChange={setLicensePlate}
						/>
					</Grid>

					<USStateDropdown
						gridColumns={6}
						state={usState}
						setState={setUSState}
						sx={{ marginBottom: 4 }}
						helperText="Optional"
					/>

					<Grid item xs={6}>
						<MileageTextInput
							vehicleMileage={vehicleMileage}
							setVehicleMileage={setVehicleMileage}
						/>
					</Grid>
					<Grid item xs={6} marginBottom={4}>
						<Dropdown
							dataTestTag="condition"
							items={VEHICLE_CONDITION_OPTIONS}
							selected={vehicleCondition}
							onChange={setVehicleCondition}
							variant="outlined"
							label="What condition is your vehicle in?"
						/>
					</Grid>

					<FinancialGoalDropdown
						gridColumns={6}
						withoutCashout
						financialGoal={financialGoal}
						setFinancialGoal={setFinancialGoal}
						dropDownLabelSX={{ whiteSpace: 'nowrap' }}
					/>
					<Grid item xs={12} sx={{ marginTop: 4 }}>
						<LiabilityDynamicButton
							disabled={!isFormReady}
							isMutationLoading={isMutationLoading}
							onClick={submitLiabilityData}
						/>
					</Grid>
				</Grid>
			</Box>
		</>
	);
}

export default NewAutoLiability;
