import { Box } from '@mui/material';

// Our Components
import LoanComparisonTopSection from 'components/Analytics/LoanComparison/LoanComparisonTopSection';
import LoanComparisonGraphSection from 'components/Analytics/LoanComparison/LoanComparisonGraphSection';

// Our Hooks
import useMutateGetAmortizationTable from 'hooks/analytics/useMutateGetAmortizationTable';
import useMutateGetAnalyticsSavings from 'hooks/analytics/useMutateGetAnalyticsInterestSavings';
import useMutateGetAnalyticsNPV from 'hooks/analytics/useMutateGetAnalyticsNPV';
import useMutateGetAnalyticsUpsidePotential from 'hooks/analytics/useMutateGetAnalyticsUpsidePotential';

function LoanComparison() {
	const analyticsAmortization = useMutateGetAmortizationTable();
	const analyticsSavings = useMutateGetAnalyticsSavings();
	const analyticsNPV = useMutateGetAnalyticsNPV();
	const analyticsUpsidePotential = useMutateGetAnalyticsUpsidePotential();

	const { data: analyticsAmortizationData } = analyticsAmortization;

	const { data: analyticsSavingData } = analyticsSavings;

	const { data: analyticsNPVData } = analyticsNPV;

	const { data: analyticsUpsidePotentialData } = analyticsUpsidePotential;

	return (
		<Box
			sx={{
				minHeight: 450,
				backgroundColor: 'white',
				padding: 7,
				borderRadius: 3
			}}
		>
			<LoanComparisonTopSection
				analyticsSavings={analyticsSavings}
				analyticsNPV={analyticsNPV}
				analyticsAmortization={analyticsAmortization}
				analyticsUpsidePotential={analyticsUpsidePotential}
			/>

			{analyticsSavingData &&
				analyticsAmortizationData &&
				analyticsNPVData &&
				analyticsUpsidePotentialData && (
					<LoanComparisonGraphSection
						analyticsSavingData={analyticsSavingData}
						analyticsAmortizationData={analyticsAmortizationData}
						analyticsNPVData={analyticsNPVData}
						analyticsUpsidePotentialData={
							analyticsUpsidePotentialData
						}
					/>
				)}
		</Box>
	);
}

export default LoanComparison;
