import { useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';

// Our Axios Instance
import axiosInstance from 'services/API/API';

// Our Endpoints
import { refinanceOfferUrl } from 'shared/api-urls';

// Our Query key
import { REFINANCE_OFFERS } from 'shared/query-keys';

function useGetNewRefinanceOffers() {
	const location = useLocation();

	const { state } = location;

	const { tradelineId } = state;

	return useQuery(
		REFINANCE_OFFERS,
		async () => {
			const res = await axiosInstance.get(
				`${refinanceOfferUrl}/${tradelineId}`
			);

			return res.data.data;
		},
		{
			refetchOnWindowFocus: false,
			select: (offerData) => {
				const isDataPresent = Array.isArray(offerData);
				const isTooManyOffers = isDataPresent && offerData.length > 3;

				if (isTooManyOffers) {
					return offerData.slice(0, 3);
				}
				return offerData;
			}
		}
	);
}

export default useGetNewRefinanceOffers;
