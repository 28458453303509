import { parseISO, max } from 'date-fns';

const getMostRecentTime = (timeStampStrings) => {
	const isInputInvalid = !timeStampStrings || timeStampStrings.length === 0;

	if (isInputInvalid) {
		return 'Click the refresh data button';
	}

	const convertedTimeStamps = timeStampStrings.map((timeString) =>
		new Date(`${timeString} UTC`).toISOString()
	);

	const parsedObjects = convertedTimeStamps.map((ts) => parseISO(ts));

	const mostRecentDate = max(parsedObjects);

	return mostRecentDate;
};

export default getMostRecentTime;
