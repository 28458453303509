import { ListSubheader } from '@mui/material';

// Our Constants
import { HELOC } from 'shared/constants';

const borderBottom = '1px solid black';
const backgroundColor = '#ebf3f8';
const fontColor = '#000000';

const subHeaderStyle = {
	borderBottom,
	color: fontColor,
	background: backgroundColor
};

const LOAN_INQUIRY_TYPES = [
	<ListSubheader key="mortgage-loans" sx={subHeaderStyle}>
		Mortgage Loans
	</ListSubheader>,
	'Mortgage Loan (New)',
	'Mortgage Loan (Refinance)',
	'Cash Out Refinance',
	// Next section
	<ListSubheader key="securities-based-line" sx={subHeaderStyle}>
		Securities Based Line
	</ListSubheader>,
	'Securities Based Line',
	<ListSubheader key="commercial-loan" sx={subHeaderStyle}>
		Commercial Loan
	</ListSubheader>,
	//  Next Section
	'Commercial Loan',
	<ListSubheader key="other-loans" sx={subHeaderStyle}>
		Other Loans
	</ListSubheader>,
	// Rest Alphabetically
	'Automotive Loan (New)',
	'Automotive Loan (Refinance)',
	'Construction Loan',
	'Farm Loan',
	HELOC,
	'Lot Loan',
	'Personal Loan (New)',
	'Personal Loan (Refinance)',
	'Reverse Mortgage',
	'Student Loan (Refinance)',
	'Other'
];

export const OTHER_OFFER_TYPE = 'OTHER';
export const OTHER_TRADELINE_TYPE = 'OTHERS';
export const SBLOC_TYPE = 'SBLINE';

export default LOAN_INQUIRY_TYPES;
