import { useEffect, useState, useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import { Box, Grid } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';

// Our Components
import CreditLimitInput from 'components/Input/CreditLimitInput';
import LiabilityFormHeading from 'components/Client/Onboarding/LiabilityFormHeading';
import LiabilityDynamicButton from 'components/Client/Onboarding/LiabilityDynamicButton';
import Loader from 'components/Loader';
import OutstandingBalanceInput from 'components/Client/Onboarding/OutstandingBalanceInput';
import RateInput from 'components/Input/RateInput';
import NextPaymentMinAmountInput from 'components/Input/NextPaymentMinAmountInput';
import TextInput from 'components/Input/TextInput';

// Input Types
import { TEXT } from 'components/Input/Types';

// Utils
import { dataIsValid, isSubmissionReady, roundToDecimal } from 'shared/utils';
import shouldUpdateRateManuallyUpdated from 'shared/utils/events/shouldUpdateRateManuallyUpdated';

// Our Hooks
import useGetUserCreditCardLiability from 'hooks/clientOnboarding/useGetUserCreditCardLiability';
import useMutateSaveCreditCardLiability from 'hooks/clientOnboarding/mutations/useSaveCreditCardLiability';

// Constants
import { CREDITCARD } from 'shared/constants';

function CreditCardLiability() {
	const navigate = useNavigate();
	const saveCreditCardLiability = useMutateSaveCreditCardLiability();
	const { isLoading, isSuccess, data } = useGetUserCreditCardLiability();
	const { isLoading: isMutationLoading } = saveCreditCardLiability;

	// Credit Card Liability Related
	const [lender, setLender] = useState('');
	const [outstandingBalance, setOutstandingBalance] = useState('');
	const [rate, setRate] = useState('');
	const [nextPaymentMinimumAmount, setNextPaymentMinimumAmount] =
		useState('');
	const [creditLimit, setCreditLimit] = useState('');

	const didRateChange = useMemo(() => {
		// if data is empty false
		if (!dataIsValid(data)) return false;
		// if interestRate is empty false.
		if (!dataIsValid(data?.interestRate)) return false;
		// if rate is empty false.
		if (rate === '') return false;
		// compare NOW
		return +rate !== data.interestRate;
	}, [rate, data]);

	useEffect(() => {
		if (isSuccess) {
			// This maps the fieldName in the data response to the corresponding state fnc that updates that state
			const WANTED_KEY_SET_MAPPING = {
				creditLimit: setCreditLimit,
				interestRate: setRate,
				lender: setLender,
				monthlyPay: setNextPaymentMinimumAmount,
				outstandingBalance: setOutstandingBalance
			};

			const creditCardLiabilityFields = Object.keys(data);

			try {
				creditCardLiabilityFields.forEach(
					(creditCardLiabilityField) => {
						const currentData = data[creditCardLiabilityField];
						const setUpdater =
							WANTED_KEY_SET_MAPPING[creditCardLiabilityField];
						if (
							creditCardLiabilityField === 'creditLimit' ||
							creditCardLiabilityField === 'interestRate' ||
							creditCardLiabilityField === 'monthlyPay' ||
							creditCardLiabilityField === 'outstandingBalance'
						) {
							// Here the values are ints we need to convert them to strings
							setUpdater(`${currentData}`);
							return;
						}
						setUpdater(currentData);
					}
				);
			} catch (e) {
				// eslint-disable-next-line no-console
				console.error(e);
			}
		}
	}, [data]);

	const requiredFormValues = [
		lender,
		outstandingBalance,
		rate,
		nextPaymentMinimumAmount
	];

	const isFormReady = useMemo(
		() => isSubmissionReady(requiredFormValues),
		requiredFormValues
	);

	const paramsFromURL = useParams();
	const clientId = paramsFromURL?.clientId;

	const submitLiabilityData = (route) => {
		const formattedRate = roundToDecimal(+rate);

		const CreditCardLiabilityData = {
			clientId,
			lender,
			outstandingBalance: +outstandingBalance,
			rate: formattedRate,
			monthlyPayment: nextPaymentMinimumAmount,
			creditLimit,
			tradelineType: CREDITCARD // can be one of AUTO | MORTGAGE | PERSONAL | STUDENT | CREDITCARD
		};

		CreditCardLiabilityData.rateManuallyUpdated =
			shouldUpdateRateManuallyUpdated(
				data?.rateManuallyUpdated,
				didRateChange
			);

		saveCreditCardLiability.mutate(CreditCardLiabilityData, {
			onSuccess: () => {
				navigate(route);
			}
		});
	};

	if (isLoading) {
		return (
			<>
				<Helmet>
					<title>Credit Card</title>
				</Helmet>
				<LiabilityFormHeading headingText="Credit Card" />
				<Loader
					size={60}
					boxSX={{ alignItems: 'center', marginTop: 15 }}
				/>
			</>
		);
	}
	return (
		<>
			<Helmet>
				<title>Credit Card</title>
			</Helmet>

			<LiabilityFormHeading headingText="Credit Card" />

			<Box component="form" noValidate autoComplete="off">
				<Grid container columnSpacing={2} marginTop={4}>
					<Grid item xs={6} sx={{ height: 120 }}>
						<TextInput
							type={TEXT}
							label="Lender"
							subLabel="Enter the name of your lender"
							value={lender}
							onChange={setLender}
						/>
					</Grid>

					<Grid item xs={6} sx={{ height: 120 }}>
						<OutstandingBalanceInput
							outstandingBalance={outstandingBalance}
							setOutstandingBalance={setOutstandingBalance}
						/>
					</Grid>

					<Grid item xs={6} sx={{ height: 120 }}>
						<RateInput rate={rate} setRate={setRate} />
					</Grid>

					<Grid item xs={6} sx={{ height: 120, marginBottom: 2 }}>
						<NextPaymentMinAmountInput
							nextPaymentMinAmount={nextPaymentMinimumAmount}
							setNextPaymentMinAmount={
								setNextPaymentMinimumAmount
							}
						/>
					</Grid>

					<Grid item xs={6} sx={{ height: 120 }}>
						<CreditLimitInput
							creditLimit={creditLimit}
							setCreditLimit={setCreditLimit}
						/>
					</Grid>

					<Grid item xs={12} sx={{ marginTop: 4 }}>
						<LiabilityDynamicButton
							disabled={!isFormReady}
							isMutationLoading={isMutationLoading}
							onClick={submitLiabilityData}
						/>
					</Grid>
				</Grid>
			</Box>
		</>
	);
}

export default CreditCardLiability;
