// helper utils
import sentenceCapitalize from 'shared/utils/formatting/sentenceCapitalize';
import { dataIsValid } from 'shared/utils';

function capitalizeUserProfile(userProfile) {
	const hasFirstNameAndLastName =
		dataIsValid(userProfile?.firstName) &&
		dataIsValid(userProfile?.lastName);

	if (!hasFirstNameAndLastName) return userProfile;

	// we have names to capitalize here!!!!
	const { firstName, lastName } = userProfile;

	const capitalizedFirstName = sentenceCapitalize(firstName?.trim());
	const capitalizedLastName = sentenceCapitalize(lastName?.trim());

	return {
		...userProfile,
		firstName: capitalizedFirstName,
		lastName: capitalizedLastName
	};
}

export default capitalizeUserProfile;
