import { useQuery } from 'react-query';

// Our Axios Instance
import axiosInstance from 'services/API/API';

// Our Query Keys
import { ANALYTICS_ASSUMPTIONS_SELECTED_CLIENT } from 'shared/query-keys';

// Our Endpoints
import { assumptionsAnalyticsGetUrl } from 'shared/api-urls';

// Our Utils
import { dataIsValid } from 'shared/utils';

// Our Constants
import { DEFAULT_STALE_TIME_FIVE_MINUTES } from 'shared/constants';

function useQueryGetAssumptions(clientId) {
	const isClientIdPresent = dataIsValid(clientId);

	return useQuery(
		[ANALYTICS_ASSUMPTIONS_SELECTED_CLIENT, clientId],
		async () => {
			const response = await axiosInstance.get(
				`${assumptionsAnalyticsGetUrl}${clientId}`
			);

			return response.data.data;
		},
		{
			enabled: isClientIdPresent,
			staleTime: DEFAULT_STALE_TIME_FIVE_MINUTES
		}
	);
}

export default useQueryGetAssumptions;
