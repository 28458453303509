import { Typography } from '@mui/material';

// Our Components
import ExportableReportSection from 'components/ExportableReport/Sections/ExportableReportSection';

// Our Utils
import convertToCreditScoreWordFromValue from 'shared/utils/formatting/convertToCreditScoreWordFromValue';
import { dollarFormatter } from 'shared/utils';
import formatAddressTitleCase from 'shared/utils/formatting/formatAddressTitleCase';

const defaultSX = { fontSize: '0.75rem', lineHeight: '1.3rem' };

function ExportableReportClientDetails({
	annualIncome,
	address,
	creditScore,
	sx
}) {
	const formattedAnnualIncome = dollarFormatter.format(annualIncome);

	const formattedCreditScoreWord =
		convertToCreditScoreWordFromValue(creditScore);

	const formattedClientAddress = formatAddressTitleCase(address);

	return (
		<ExportableReportSection
			sx={{
				display: 'flex',
				flexDirection: 'column',
				gap: 1,
				...sx
			}}
		>
			<Typography variant="body2" sx={defaultSX}>
				<strong>Income:</strong> {formattedAnnualIncome}
			</Typography>
			<Typography variant="body2" sx={defaultSX}>
				<strong>Home Address:</strong> {formattedClientAddress}
			</Typography>

			<Typography variant="body2" sx={defaultSX}>
				<strong>Credit Score:</strong> {creditScore} -{' '}
				{formattedCreditScoreWord}
			</Typography>
		</ExportableReportSection>
	);
}

export default ExportableReportClientDetails;
