import { Box, Typography } from '@mui/material';
import { useTheme } from '@emotion/react';
import PropTypes from 'prop-types';

// Our PropTypes
import CommonPropTypes from 'shared/prop-types';

function DropdownLabel({ sx, labelText, labelVariant }) {
	const SoraTheme = useTheme();
	const { primary } = SoraTheme.palette;
	if (labelText)
		return (
			<Typography variant={labelVariant} sx={{ marginBottom: 1, ...sx }}>
				{labelText}
			</Typography>
		);

	// intentionally did not add marginBottom due to changes required in other places currently using Dropdown
	return <Box sx={{ backgroundColor: primary.white, ...sx }} />;
}

DropdownLabel.propTypes = {
	labelText: PropTypes.oneOfType([PropTypes.string]),
	labelVariant: PropTypes.string,
	sx: CommonPropTypes.sx
};

DropdownLabel.defaultProps = {
	labelText: null,
	labelVariant: 'subtitle2',
	sx: {}
};

export default DropdownLabel;
