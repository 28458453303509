import { useTheme } from '@emotion/react';
import { useMemo } from 'react';
import { Link, useLocation, useParams, generatePath } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';

// Icons
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import ModeOutlinedIcon from '@mui/icons-material/ModeOutlined';

// Our Constants
import { COLLECTION } from 'shared/constants';

// Routes
import {
	ADVISOR_DYNAMIC_UPDATE_LIABILITY_ROUTE,
	DYNAMIC_UPDATE_LIABILITY_ROUTE
} from 'routes';

function TotalLiabilitiesActionButtons({
	liabilitySelectedData,
	handleDeleteClick
}) {
	const SoraTheme = useTheme();
	const { primary } = SoraTheme.palette;

	const { pathname } = useLocation();
	const { clientId } = useParams();

	const isAdvisor = clientId !== undefined;

	const path = isAdvisor
		? ADVISOR_DYNAMIC_UPDATE_LIABILITY_ROUTE
		: DYNAMIC_UPDATE_LIABILITY_ROUTE;

	const { tradeLineType, tradelineId } = liabilitySelectedData;

	const payload = isAdvisor
		? {
				clientId,
				tradelineId,
				tradelineType: tradeLineType?.toLowerCase()
		  }
		: {
				tradelineId,
				tradelineType: tradeLineType?.toLowerCase()
		  };

	const updateRoute = useMemo(
		() => generatePath(path, payload),
		[tradeLineType]
	);

	const isLiabilityUpdateable = useMemo(() => {
		if (tradeLineType === COLLECTION) return false;

		return true;
	}, [tradeLineType, pathname]);

	return (
		<>
			{isLiabilityUpdateable && (
				<Link
					to={updateRoute}
					state={{ fromWallet: true }}
					onClick={(e) => e.stopPropagation()}
				>
					<IconButton sx={{ color: primary.black }}>
						<ModeOutlinedIcon />
					</IconButton>
				</Link>
			)}

			<IconButton
				sx={{ color: primary.black }}
				onClick={(e) => {
					e.stopPropagation();
					handleDeleteClick();
				}}
			>
				<DeleteOutlineOutlinedIcon />
			</IconButton>
		</>
	);
}

export default TotalLiabilitiesActionButtons;
