import { Autocomplete, TextField, Typography } from '@mui/material';
import { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import TagIcon from '@mui/icons-material/Tag';

// Our Prop types
import CommonPropTypes from 'shared/prop-types';

// for prop definitions such as freeSolo please refer to this doc
// https://mui.com/material-ui/react-autocomplete/#free-solo

const determineDefaultState = (multiple, value) => {
	if (multiple) return [];
	if (value) return value;

	return null;
};

function AutocompleteTextinput({
	autoComplete,
	autoSelect,
	autoCompleteSelectedValue,
	autoCompleteSX,
	disabled,
	disableCloseOnSelect,
	disablePortal,
	dataTestTag,
	freeSolo,
	forcePopupIcon,
	getOptionLabel,
	handleChange,
	handleSelect,
	helperText,
	label,
	multiple,
	optionList,
	placeholder,
	renderOption,
	sx,
	tabIndex,
	value,
	withoutFilter,
	withTag,
	required
}) {
	const [autoCompleteValue, setAutoCompleteValue] = useState(
		determineDefaultState(multiple, autoCompleteSelectedValue)
	);

	const determineFilter = withoutFilter ? { filterOptions: (x) => x } : {};

	// this label is required aware
	const requiredLabel = useMemo(
		() => (required ? `${label} *` : label),
		[required, label]
	);

	return (
		<Autocomplete
			disabled={disabled}
			slotProps={{
				popper: {
					modifiers: [
						{
							name: 'flip',
							enabled: false,
							options: {
								enabled: false,
								rootBoundary: 'document',
								allowedAutoPlacements: ['bottom']
							}
						}
					]
				}
			}}
			required={required}
			{...determineFilter}
			sx={autoCompleteSX}
			autoSelect={autoSelect}
			autoHighlight
			autoComplete={autoComplete}
			disableCloseOnSelect={disableCloseOnSelect}
			disablePortal={disablePortal}
			getOptionLabel={getOptionLabel}
			freeSolo={freeSolo}
			inputValue={value}
			multiple={multiple}
			onInputChange={(e, newInputValue) => handleChange(newInputValue)}
			options={optionList}
			onChange={(e, newValue) => {
				if (handleSelect) {
					handleSelect(newValue, setAutoCompleteValue);
				} else {
					setAutoCompleteValue(newValue);
				}
			}}
			renderInput={(params) => (
				<>
					{label && (
						<Typography
							variant="subtitle2"
							sx={{ marginBottom: 1 }}
						>
							{requiredLabel}
						</Typography>
					)}
					<TextField
						{...params}
						data-test={dataTestTag}
						type="text"
						helperText={helperText}
						variant="outlined"
						sx={{ ...sx }}
						placeholder={placeholder}
						InputProps={{
							...params.InputProps,
							startAdornment: withTag ? <TagIcon /> : null,
							tabIndex
						}}
					/>
				</>
			)}
			forcePopupIcon={forcePopupIcon}
			renderOption={renderOption}
			value={autoCompleteValue}
		/>
	);
}

AutocompleteTextinput.propTypes = {
	autoComplete: PropTypes.bool,
	autoSelect: PropTypes.bool,
	autoCompleteSX: CommonPropTypes.sx,
	disabled: PropTypes.bool,
	disableCloseOnSelect: PropTypes.bool,
	disablePortal: PropTypes.bool,
	dataTestTag: PropTypes.string,
	freeSolo: PropTypes.bool,
	forcePopupIcon: PropTypes.bool,
	helperText: PropTypes.string,
	multiple: PropTypes.bool,
	tabIndex: PropTypes.string,
	withoutFilter: PropTypes.bool
};

AutocompleteTextinput.defaultProps = {
	autoComplete: false,
	autoSelect: false,
	autoCompleteSX: {},
	disabled: false,
	disableCloseOnSelect: false,
	disablePortal: false,
	dataTestTag: 'addressLine1',
	freeSolo: true,
	forcePopupIcon: false,
	helperText: 'Address line 1',
	multiple: false,
	withoutFilter: true,
	tabIndex: '1'
};

export default AutocompleteTextinput;
