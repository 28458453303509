import { v4 as uuidv4 } from 'uuid';
import { Typography } from '@mui/material';

// Our Hooks
import useQueriesInsightsToChatGpt from 'hooks/insights/useQueriesInsightsToChatGptResult';
import useGetAllRuleEngineInsights from 'hooks/insights/useGetAllRuleEngineInsights';

// Our Components
import ExportableReportHeadingText from 'components/ExportableReport/Heading/ExportableReportHeadingText';
import ExportableReportSection from 'components/ExportableReport/Sections/ExportableReportSection';

// Our Constants
import {
	FE_CHATGPT_ONLY,
	BE_NO_CHATGPT,
	BE_WITH_CHATGPT
} from 'shared/constants';

/*
 if insights_mode is 0 -> FE rules with chatGpt
 if insights_mode is 1 -> BE rules no chatGpt
 if insights_mode is 2 -> BE rules with chatGpt
*/
const INSIGHTS_MODE = process.env.REACT_APP_INSIGHTS_MODE;

const IS_FE_INSIGHTS_MODE = INSIGHTS_MODE === FE_CHATGPT_ONLY;

const IS_BE_INSIGHTS_MODE =
	INSIGHTS_MODE === BE_NO_CHATGPT || INSIGHTS_MODE === BE_WITH_CHATGPT;

function ExportableReportInsights({ headingText, walletOverviewData }) {
	const results = useQueriesInsightsToChatGpt(true, walletOverviewData);

	const {
		isLoading: isBackendLoading,
		isSuccess: isBackendSuccess,
		data: isBackendInsight
	} = useGetAllRuleEngineInsights();

	const backendInsights =
		isBackendSuccess &&
		isBackendInsight.map(({ insightTextOutput }) => (
			<li key={uuidv4()}>
				<Typography
					variant="body1"
					sx={{
						marginTop: 1,
						marginBottom: 1,
						fontSize: '0.75rem',
						lineHeight: '1rem'
					}}
				>
					{insightTextOutput}
				</Typography>
			</li>
		));

	return (
		<ExportableReportSection>
			<ExportableReportHeadingText headingText={headingText} />
			<ul>
				{IS_FE_INSIGHTS_MODE &&
					results.map((currentChatGPTQuery) => {
						const { isLoading, isSuccess, data, isError } =
							currentChatGPTQuery;

						if (isLoading) {
							return (
								<li
									key={uuidv4()}
									sx={{ marginTop: 1, marginBottom: 1 }}
								>
									<Typography
										variant="body1"
										sx={{
											marginTop: 1,
											marginBottom: 1,
											fontSize: '0.75rem',
											lineHeight: '1rem'
										}}
									>
										Insight is loading, please close and
										reopen this report.
									</Typography>
								</li>
							);
						}

						if (isSuccess) {
							const { insightTextOutput } = data;
							return (
								<li key={uuidv4()}>
									<Typography
										variant="body1"
										sx={{
											marginTop: 1,
											marginBottom: 1,
											fontSize: '0.75rem',
											lineHeight: '1rem'
										}}
									>
										{insightTextOutput}
									</Typography>
								</li>
							);
						}

						if (isError) {
							return (
								<li
									key={uuidv4()}
									variant="body1"
									sx={{ marginTop: 1, marginBottom: 1 }}
								>
									Error loading client insight
								</li>
							);
						}

						return null;
					})}
				{IS_BE_INSIGHTS_MODE && backendInsights}
				{IS_BE_INSIGHTS_MODE && isBackendLoading && (
					<li key={uuidv4()} sx={{ marginTop: 1, marginBottom: 1 }}>
						<Typography
							variant="body1"
							sx={{
								marginTop: 1,
								marginBottom: 1,
								fontSize: '0.75rem',
								lineHeight: '1rem'
							}}
						>
							Insight is loading, please close and reopen this
							report.
						</Typography>
					</li>
				)}
			</ul>
		</ExportableReportSection>
	);
}

export default ExportableReportInsights;
