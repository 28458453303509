import { Typography } from '@mui/material';

// Our Components
import Loader from 'components/Loader';
import OfferTable from 'components/Offers/OfferTable/OfferTable';
import OfferColumns from 'components/Offers/OfferColumns/OfferColumns';

// Our Hooks
import useGetNewRefinanceOffers from 'hooks/useGetRefinanceOffers';

// Our Constants
import { REFINANCE_OFFER } from 'shared/constants';

function RefinanceOffer({ setIsModalOpen }) {
	const {
		data: refinanceOffers,
		error,
		isSuccess: getRefinanceOffersIsSuccess,
		isLoading: getRefinanceOfferIsLoading
	} = useGetNewRefinanceOffers(REFINANCE_OFFER);

	if (error) {
		return (
			<Typography variant="h2Gascogne" component="h2">
				Whoops. Something went wrong with the offers.
			</Typography>
		);
	}

	if (getRefinanceOfferIsLoading) return <Loader />;

	if (getRefinanceOffersIsSuccess) {
		const clientHasNoOffers = refinanceOffers.length < 1;
		if (clientHasNoOffers) {
			return (
				<Typography variant="h4">
					Seems like no offers were found.
				</Typography>
			);
		}
		return (
			<>
				<OfferTable
					offerType={REFINANCE_OFFER}
					offers={refinanceOffers}
				/>
				<OfferColumns
					offerType={REFINANCE_OFFER}
					offers={refinanceOffers}
					setIsModalOpen={setIsModalOpen}
				/>
			</>
		);
	}
}

export default RefinanceOffer;
