import { useMemo } from 'react';
import { useTheme } from '@emotion/react';
import { Box, Typography } from '@mui/material';

// Our Components
import CardItem from 'components/SoraWallet/Cards/CardItem';
import CardHeading from 'components/SoraWallet/Cards/CardHeading';

// Our Utils
import { roundToDecimal } from 'shared/utils';

// local constant for image on the card
const CARD_IMAGE =
	'https://prod-public-asset.s3.amazonaws.com/SoraAssets/piggyBank.png';

// helper fn
function getDebtToIncomeText(debtToIncomeRatio) {
	if (debtToIncomeRatio >= 0.5) return 'Action Needed';
	if (debtToIncomeRatio >= 0.36 && debtToIncomeRatio <= 0.4999)
		return 'Limiting';
	if (debtToIncomeRatio > 0 && debtToIncomeRatio <= 0.3599) return 'Ideal';
	if (debtToIncomeRatio === 0) return 'NA';
	return 'NA';
}

function getCreditScoreText(creditScore) {
	// 300-579 Poor
	// 580-669 Fair
	// 670-739 Good
	// 740-799 Very Good
	// 800+ Excellent
	if (creditScore <= 579) return 'Poor';
	if (creditScore > 579 && creditScore < 670) return 'Fair';
	if (creditScore >= 670 && creditScore < 740) return 'Good';
	if (creditScore >= 740 && creditScore < 800) return 'Very Good';
	if (creditScore >= 800) return 'Excellent';
	return 'Good'; // if we aren't given a number for some reason Good is rendered
}

function CreditAndDebtToIncome({ creditScore, debtToIncomeRatio }) {
	const SoraTheme = useTheme();
	const { primary } = SoraTheme.palette;

	const creditScoreText = useMemo(
		() => getCreditScoreText(creditScore),
		[creditScore]
	);

	const debtToIncomeRatioText = useMemo(
		() => getDebtToIncomeText(debtToIncomeRatio),
		[debtToIncomeRatio]
	);

	const formattedDebtToIncomeRatio = useMemo(
		() =>
			debtToIncomeRatio === 0
				? 'NA'
				: `${roundToDecimal(debtToIncomeRatio * 100, 0)}%`,
		[debtToIncomeRatio]
	);

	return (
		<CardItem
			withoutButton
			buttonSx={{ alignSelf: 'center' }}
			sx={{
				background: 'linear-gradient(180deg, #B0CDE4 0%, #F5F9FC 100%)',
				gap: '20px',
				padding: '20px 28px 16px 28px'
			}}
		>
			<Box>
				<CardHeading title="Credit Score" />
				<Typography
					variant="h4"
					sx={{ color: primary.indigo, marginBottom: 2 }}
				>
					{creditScore}
					<Typography
						variant="caption"
						sx={{ marginLeft: 2, color: primary.black }}
					>
						{creditScoreText}
					</Typography>
				</Typography>
			</Box>
			<Box>
				<CardHeading title="Debt to Income" />

				<Typography
					variant="h4"
					sx={{ color: primary.indigo, marginBottom: 2 }}
				>
					{formattedDebtToIncomeRatio}
					<Typography
						variant="caption"
						sx={{ marginLeft: 2, color: primary.black }}
					>
						{debtToIncomeRatioText}
					</Typography>
				</Typography>
			</Box>
			<Box
				component="img"
				src={CARD_IMAGE}
				sx={{
					display: { xs: 'none', md: 'block' },
					position: 'absolute',
					right: '1rem',
					bottom: 0,
					width: '5rem !important',
					height: '7rem !important',
					marginBottom: '0.375rem !important'
				}}
			/>
		</CardItem>
	);
}

export default CreditAndDebtToIncome;
