import { useCallback } from 'react';
import PropTypes from 'prop-types';

// Our Components
import CurrencyTextInput from 'components/Input/CurrencyTextInput';

// Our PropTypes
import CommonPropTypes from 'shared/prop-types';

// Constants
const MAX_NUMBER_ALLOWED = 10000000; // 100,000,000
const MIN_NUMBER_ALLOW = 0;

function NextPaymentMinAmountInput({
	nextPaymentMinAmount,
	setNextPaymentMinAmount,
	sx
}) {
	const handleNextPaymentMinAmountChange = useCallback((value) => {
		const isValueEmpty = value === '';
		if (isValueEmpty) {
			setNextPaymentMinAmount('');
			return;
		}
		const isAtMaxiumumValueAccepted = value === `${MAX_NUMBER_ALLOWED}.`; // This simply enforces no periods to be allowed when at max value.
		if (isAtMaxiumumValueAccepted) return;
		const startsWithZero = value && value.length > 1 && value[0] === '0';
		if (startsWithZero) {
			const isOnlyZeroes = value.split('').every((char) => char === '0');
			if (isOnlyZeroes) return;
		}

		const number = +value;
		const isWithinRange =
			number >= MIN_NUMBER_ALLOW && number <= MAX_NUMBER_ALLOWED;
		if (isWithinRange) {
			setNextPaymentMinAmount(value);
		}
	}, []);

	return (
		<CurrencyTextInput
			label="Next Payment Minimum Amount"
			subLabel="Enter the minimum allowable next payment"
			value={nextPaymentMinAmount}
			onChange={handleNextPaymentMinAmountChange}
			sx={sx}
		/>
	);
}

NextPaymentMinAmountInput.propTypes = {
	nextPaymentMinAmount: PropTypes.string.isRequired,
	setNextPaymentMinAmount: PropTypes.func.isRequired,
	sx: CommonPropTypes.sx
};

NextPaymentMinAmountInput.defaultProps = {
	sx: {}
};

export default NextPaymentMinAmountInput;
