import { useState } from 'react';
import PropTypes from 'prop-types';

// Our Components
import ClientInvitation from 'pages/Client/AddingClient/InviteSingleClient/SendClientInvite';
import DynamicTabs from 'components/Tabs/DynamicTabs';
import IntegrationsPage from 'pages/Integrations/IntegrationsPage';
import OnboardClientsBySpreadsheet from 'pages/Client/AddingClient/AddClientBySpreadSheet/AddClientsBySpreadsheet';
import OnboardSingleClientPage from 'pages/Client/AddingClient/AddSingleClientAutomated/OnboardSingleClientPage';
import ProspectiveClientPage from 'pages/ProspectiveClientPage/ProspectiveClientPage';

// TABS
const TAB_ITEMS = [
	{ type: 'Add Individual Client' },
	{ type: 'Upload Multiple Clients' },
	{ type: 'Invite a Client' },
	{ type: 'Prospective Client' },
	{ type: 'Integrations' }
];
const TAB_PANEL_ITEMS = [
	{ children: <OnboardSingleClientPage />, value: 0 },
	{ children: <OnboardClientsBySpreadsheet />, value: 1 },
	{ children: <ClientInvitation />, value: 2 },
	{ children: <ProspectiveClientPage />, value: 3 },
	{ children: <IntegrationsPage />, value: 4 }
];

function AddingClientsTabs({ defaultIndex }) {
	const [value, setValue] = useState(defaultIndex);

	const handleClick = (e, indexClicked) => {
		setValue(indexClicked);
	};

	return (
		<DynamicTabs
			dataTestTag="inviteClientTabs"
			sx={{ marginBottom: 4, marginTop: 4, width: '100%' }}
			tabItems={TAB_ITEMS}
			handleChange={handleClick}
			tabPanelItems={TAB_PANEL_ITEMS}
			tabsSX={{ marginBottom: 6 }}
			tabItemSX={{ cursor: 'pointer', textTransform: 'none' }}
			variant="standard"
			value={value}
		/>
	);
}

AddingClientsTabs.propTypes = {
	defaultIndex: PropTypes.number
};

AddingClientsTabs.defaultProps = {
	defaultIndex: 0
};

export default AddingClientsTabs;
