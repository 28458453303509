import { Grid, IconButton } from '@mui/material';
import { useState, useCallback } from 'react';
import { useTheme } from '@emotion/react';

// Icons
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

// Our Components
import Loader from 'components/Loader/index';
import Modal from 'components/Modal/Modal';
import { PrimaryButton, SecondaryButton } from 'components/Button/Button';

// Our Hooks
import useMutateRemoveClient from 'hooks/useMutateRemoveClient';

// This component is assumed to be used in the DataGrid
function DeleteButton({ rowData }) {
	const SoraTheme = useTheme();
	const { primary } = SoraTheme.palette;
	const clientId = rowData?.clientId;
	const clientName = rowData?.name;

	// Local state
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [selectedClientId, setSelectedClientId] = useState(clientId || 0);
	const [selectedClientName, setSelectedClientName] = useState(
		clientName || ''
	);
	const closeModal = useCallback(() => setIsModalOpen(false), []);

	const handleModalToggle = (event) => {
		// Preventing the row click from firing
		event.stopPropagation();
		setIsModalOpen(true);
	};

	// API Calls
	const removeClient = useMutateRemoveClient({ selectedClientId });

	const { isLoading } = removeClient;

	// fire mutation
	const submitClientRemoval = () => {
		removeClient.mutate(selectedClientId, {
			onSuccess: () => {
				setIsModalOpen(false);
			}
		});
	};
	return (
		<>
			<IconButton onClick={handleModalToggle}>
				<DeleteOutlineOutlinedIcon
					sx={{
						color: primary.black,
						'&:hover': {
							color: primary.grey
						},
						'&:active': {
							color: primary.soraBlue
						}
					}}
				/>
			</IconButton>

			<Modal
				title="Are you sure you'd like to delete this client?"
				isOpen={isModalOpen}
				handleClose={closeModal}
				subtitle={`This will remove ${selectedClientName} from your client list.`}
			>
				<Grid container>
					<Grid item xs={12}>
						{!isLoading && (
							<>
								<PrimaryButton
									sx={{ marginTop: 4, marginRight: 4 }}
									onClick={submitClientRemoval}
								>
									Yes
								</PrimaryButton>
								<SecondaryButton
									sx={{ marginTop: 4 }}
									onClick={closeModal}
								>
									No
								</SecondaryButton>
							</>
						)}
						{isLoading && <Loader />}
					</Grid>
				</Grid>
			</Modal>
		</>
	);
}

export default DeleteButton;
