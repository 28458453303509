import { useState, useMemo } from 'react';
import { Box, Typography, Grid, TextareaAutosize } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import emailjs from '@emailjs/browser';
import { useMutation } from 'react-query';

// Our components
import Dropdown from 'components/Dropdown/Dropdown';
import { PrimaryButton } from 'components/Button/Button';
import Input from 'components/Input/TextInput';
import Loader from 'components/Loader';
import Modal from 'components/Modal/Modal';
import { EMAIL, TEXT } from 'components/Input/Types';

// utils
import { clearFormValues, isSubmissionReady } from 'shared/utils';

const EMAIL_SERVICE_ID = process.env.REACT_APP_EMAILJS_SERVICE_ID;
const EMAIL_TEMPLATE_ID =
	process.env.REACT_APP_EMAILJS_CONTACT_SORA_TEMPLATE_ID;
const EMAIL_PUBLIC_KEY = process.env.REACT_APP_EMAILJS_PUBLIC_KEY;

export function FullContactUsForm() {
	const SoraTheme = useTheme();

	const [firstName, setFirstName] = useState('');
	const [lastName, setLastName] = useState('');
	const [emailAddress, setEmailAddress] = useState('');
	const [subject, setSubject] = useState('');
	const [message, setMessage] = useState('');
	const [isOpen, setIsOpen] = useState(false);

	const formData = [
		firstName,
		lastName,
		emailAddress,
		subject,
		message,
		isOpen
	];
	const isFormReady = useMemo(() => isSubmissionReady(formData), formData);

	const sendFormData = useMutation(async () => {
		const emailJsParams = {
			name: `${firstName} ${lastName}`,
			emailAddress,
			subject,
			message
		};
		// This will send the email
		await emailjs.send(
			EMAIL_SERVICE_ID,
			EMAIL_TEMPLATE_ID,
			emailJsParams,
			EMAIL_PUBLIC_KEY
		);

		clearFormValues([
			setFirstName,
			setLastName,
			setEmailAddress,
			setSubject,
			setMessage
		]);
		setIsOpen(true);
	});

	const { isLoading } = sendFormData;

	// Handle field change
	const handleFirstNameChange = (value) => {
		setFirstName(value);
	};

	const handleLastNameChange = (value) => {
		setLastName(value);
	};

	const handleEmailChange = (value) => {
		setEmailAddress(value);
	};

	const handleSubjectChange = (value) => {
		setSubject(value);
	};

	const handleMessageChange = (event) => {
		const { value } = event.target;
		setMessage(value);
	};

	const handleClose = () => {
		setIsOpen(false);
	};

	// Handle Email Send
	const submitContactUsForm = (event) => {
		event.preventDefault();
		sendFormData.mutate({});
	};

	return (
		<Box component="form" noValidate autoComplete="off">
			<Modal
				isOpen={isOpen}
				handleClose={handleClose}
				title="Your email has been sent"
				subtitle="Thank you for contacting Sora. We will get back to you as we can."
			>
				<PrimaryButton onClick={handleClose}>Thanks</PrimaryButton>
			</Modal>

			<Grid item xs={8}>
				<Typography variant="h1Gascogne" component="h1" gutterBottom>
					Contact Us
				</Typography>

				<Typography
					variant="body1"
					gutterBottom
					component="div"
					sx={{
						marginBottom: 4
					}}
				>
					Fill in the information below to send an email message to
					Sora. We aim to respond to all inquiries within 24 hours.
				</Typography>
			</Grid>

			{isLoading && <Loader />}
			{!isLoading && (
				<>
					<Grid
						container
						// gap={2}
						xs={12}
						sx={{
							display: 'flex',
							marginBottom: '0'
						}}
					>
						<Grid item xs={12} md={6}>
							{/* First Name */}
							<Input
								id="firstName"
								label="First Name"
								type={TEXT}
								name="first name"
								onChange={handleFirstNameChange}
								value={firstName}
								endAdornment="hidden"
								sx={{
									width: '100%',
									marginBottom: 2,
									paddingRight: 2
								}}
							/>
						</Grid>
						<Grid item xs={12} md={6}>
							{/* Last Name */}
							<Input
								id="lastName"
								label="Last Name"
								type={TEXT}
								name="last name"
								value={lastName}
								onChange={handleLastNameChange}
								sx={{
									width: '100%',
									marginBottom: 2
								}}
							/>
						</Grid>
					</Grid>
					<Grid
						item
						xs={12}
						sx={{
							padding: '0'
						}}
					>
						{/* Email */}
						<Input
							id="Email"
							label="Email"
							type={EMAIL}
							name="email"
							value={emailAddress}
							onChange={handleEmailChange}
							sx={{
								width: '100%',
								marginBottom: 2
							}}
						/>

						{/* Subject */}
						<Dropdown
							id="subject"
							label="Subject"
							placeholder="Select appropriate email subject"
							items={[
								'Billing/payments',
								'Connecting liabilities',
								'Lender support',
								'Other'
							]}
							selected={subject}
							type="visible"
							onChange={handleSubjectChange}
							sx={{
								marginBottom: 2,
								width: '100%'
							}}
						/>

						<Typography
							variant="subtitle1"
							gutterBottom
							component="div"
							sx={{
								marginBottom: 1
							}}
						>
							Message
						</Typography>

						{/* Message */}
						<TextareaAutosize
							aria-label="email message"
							minRows={10}
							maxRows={100}
							onChange={handleMessageChange}
							value={message}
							style={{
								width: '100%',
								fontFamily: SoraTheme.typography.fontFamily,
								fontWeight:
									SoraTheme.typography.body1.fontWeight,
								fontSize: SoraTheme.typography.body1.fontSize,
								lineHeight:
									SoraTheme.typography.body1.lineHeight,
								letterSpacing:
									SoraTheme.typography.body1.letterSpacing,
								padding: SoraTheme.spacing(2)
							}}
							sx={{
								width: '100%'
							}}
						/>
					</Grid>
				</>
			)}

			<Grid item xs={8} marginTop={4} marginBottom={10}>
				<PrimaryButton
					disabled={!isFormReady}
					onClick={submitContactUsForm}
				>
					Submit
				</PrimaryButton>
			</Grid>
		</Box>
	);
}

export default FullContactUsForm;
