import { Helmet } from 'react-helmet-async';
import BasicFrame from 'components/Frame/Basic';
import SignupForm from 'components/Signup/SignupForm';

export default function SignupPage() {
	return (
		<>
			<Helmet>
				<title>Sign up</title>
			</Helmet>
			<BasicFrame sx={{ height: '100vh', width: '100%' }}>
				<SignupForm />
			</BasicFrame>
		</>
	);
}
