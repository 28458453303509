import { Box, Divider, Typography } from '@mui/material';
import { toDate, format } from 'date-fns';
import PropTypes from 'prop-types';

// Our Components
import ExportableReportHeadingText from 'components/ExportableReport/Heading/ExportableReportHeadingText';
import ExportableReportSection from 'components/ExportableReport/Sections/ExportableReportSection';

// Our Utils
import { dataIsValid } from 'shared/utils';

// Our Assets
import SoraLogoBlack from 'assets/images/sora_logos/SoraLogoBlack.svg';

function ExportableReportHeading({ advisor, clientFullName }) {
	const today = toDate(new Date());
	const formattedDate = format(today, 'MMMM dd, yyyy');

	const { name: advisorName, companyLogoUrl } = advisor;

	const companyLogo =
		dataIsValid(companyLogoUrl) && companyLogoUrl !== ''
			? companyLogoUrl
			: SoraLogoBlack;

	return (
		<ExportableReportSection>
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'flex-start',
					paddingBottom: 2
				}}
			>
				<Box sx={{ display: 'flex', flexDirection: 'column' }}>
					<ExportableReportHeadingText
						headingVariant="h2Gascogne"
						headingText={clientFullName}
					/>
					<Typography variant="body2">{formattedDate}</Typography>
				</Box>

				<Box
					component="img"
					src={companyLogo}
					sx={{
						height: 60,
						objectFit: 'contain'
					}}
				/>
			</Box>
			<Divider
				variant="middle"
				sx={{
					backgroundColor: '#000',
					width: '100%',
					margin: 0
				}}
			/>
		</ExportableReportSection>
	);
}

ExportableReportHeading.propTypes = {
	advisor: PropTypes.shape({
		company: PropTypes.string,
		companyLogoUrl: PropTypes.string,
		email: PropTypes.string,
		firstName: PropTypes.string,
		imageUrl: PropTypes.string,
		lastName: PropTypes.string,
		name: PropTypes.string
	}).isRequired,
	clientFullName: PropTypes.string.isRequired
};

export default ExportableReportHeading;
