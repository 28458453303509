import {
	Box,
	SwipeableDrawer,
	Grid,
	AppBar,
	IconButton,
	Toolbar
} from '@mui/material';
import { Outlet, useLocation } from 'react-router-dom';

// Our Components
import DynamicOnboardingProgress from 'components/NavMenu/DynamicOnboardingProgress';
import Footer from 'components/Footer/Footer';

import { useState, useEffect } from 'react';

// Icons
import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';

// Our Components
import ClosedMobileNav from 'components/Frame/ClosedMobileNav';

// Our Hooks
import useOnRouteChange from 'hooks/utilityHook/useOnRouteChange';
import {
	CLIENT_ONBOARDING_ASSETS_ROUTE,
	CLIENT_ONBOARDING_INCOME_ROUTE,
	CLIENT_ONBOARDING_LIABILITIES_IDENTIFIED_ROUTE,
	CLIENT_ONBOARDING_REVIEW_ROUTE,
	FA_ONBOARDING_ADDITIONAL_DETAILS_ROUTE,
	FA_ONBOARDING_THANKS_ROUTE
} from 'routes/index';

function ContainerFrameOnboarding({ sx }) {
	const [progress, setProgress] = useState(0);
	const [userType, setUserType] = useState('');
	const [isMenuOpen, setIsMenuOpen] = useState(false);

	const toggleMenuOpen = () => setIsMenuOpen(!isMenuOpen);
	const { pathname } = useLocation();

	// Codsworth
	useEffect(() => {
		setIsMenuOpen(false);
	}, [pathname]);

	useOnRouteChange(() => {
		if (pathname.includes(CLIENT_ONBOARDING_LIABILITIES_IDENTIFIED_ROUTE)) {
			setProgress(0);
			setUserType('client');
		}
		if (pathname.includes(CLIENT_ONBOARDING_INCOME_ROUTE)) {
			setProgress(25);
			setUserType('client');
		}
		if (pathname.includes(CLIENT_ONBOARDING_ASSETS_ROUTE)) {
			setProgress(50);
			setUserType('client');
		}
		if (pathname.includes(CLIENT_ONBOARDING_REVIEW_ROUTE)) {
			setProgress(75);
			setUserType('client');
		}
		if (pathname.includes(FA_ONBOARDING_ADDITIONAL_DETAILS_ROUTE)) {
			setProgress(50);
			setUserType('advisor');
		}
		if (pathname.includes(FA_ONBOARDING_THANKS_ROUTE)) {
			setProgress(100);
			setUserType('advisor');
		}
	});

	return (
		<Grid
			container
			spacing={2}
			sx={{
				height: '100%',
				width: '100%',
				...sx
			}}
		>
			<Grid
				item
				sx={{
					display: { xs: 'block', lg: 'none' },
					maxHeight: 60,
					marginBottom: 2
				}}
			>
				<AppBar
					position="static"
					sx={{ width: '100vw', bgcolor: 'white' }}
				>
					<Toolbar
						disableGutters
						sx={{
							justifyContent: 'space-between',
							alignItems: 'center',
							paddingLeft: 2,
							paddingRight: 2,
							height: '100%'
						}}
					>
						<Box
							component="img"
							src="https://prod-public-asset.s3.amazonaws.com/SoraLogos/SoraLogoBlack.svg"
							sx={{ height: 25 }}
						/>

						<IconButton
							size="large"
							edge="start"
							color="inherit"
							aria-label="menu"
							onClick={() => toggleMenuOpen()}
						>
							{!isMenuOpen ? (
								<MenuIcon sx={{ color: 'black' }} />
							) : (
								<CloseIcon sx={{ color: 'black' }} />
							)}
						</IconButton>
					</Toolbar>

					<SwipeableDrawer
						open={isMenuOpen}
						onOpen={toggleMenuOpen}
						transitionDuration={350}
						onClose={toggleMenuOpen}
						PaperProps={{
							sx: {
								background:
									'linear-gradient(180deg, #B0CDE4 0%, #FFF 100%)',
								display: 'flex',
								flexDirection: 'column',
								justifyContent: 'space-between',
								width: '100%',
								paddingLeft: 0
							}
						}}
					>
						<Box>
							<ClosedMobileNav toggleMenuOpen={toggleMenuOpen} />
						</Box>

						<Box>
							<DynamicOnboardingProgress
								userType={userType}
								progress={progress}
							/>
						</Box>
					</SwipeableDrawer>
				</AppBar>
			</Grid>

			<Grid
				item
				xs={0}
				lg={2}
				sx={{ display: { xs: 'none', lg: 'block' } }}
			>
				<DynamicOnboardingProgress
					userType={userType}
					progress={progress}
				/>
			</Grid>

			<Grid item xs={12} lg={10}>
				<Grid
					container
					sx={{
						marginLeft: 4,
						marginRight: 4,
						width: 'inherit',
						height: 'inherit'
					}}
				>
					<Grid
						item
						xs={12}
						sx={{
							minHeight: '100vh',
							marginTop: 6,
							marginLeft: 2,
							marginRight: 2
						}}
					>
						<Outlet />
					</Grid>
					<Grid
						item
						xs={12}
						sx={{
							justifyContent: 'center',
							alignItems: 'center',
							gap: 5,
							marginLeft: 2
						}}
					>
						<Footer />
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
}

export default ContainerFrameOnboarding;
