// User
export const addNewLoanInquiryUrl = '/save-new-loan-inquiry';
export const getAdvisorLoanRequests = '/get-new-loan-inquiry';
export const getLoanRequestRelatedDocuments = '/new-loan-details/documents';
export const getLoanRequestDocDownloadUrl =
	'/new-loan-inquiry/download-document';
export const checkForCompany = '/user/check-domain?domain=';
export const getUserLiabilitiesUrl = '/v1/user/liability/';
export const getUserEmploymentDataUrl = '/user/employment';
export const userAssets = '/user/asset';
export const userExecuteUrl = '/user/execute';
export const userGetLoginTokenUrl = '/user/fbtoken';
export const userIncome = '/user/income';
export const userLogin = '/user/login';
export const userOnboardingStatus = '/v1/user/onboarding-status/';
export const userProfile = '/user/profile';
export const uploadProfilePicture = '/user/upload-profile-pic';
export const uploadCompanyLogo = '/user/upload-company-logo';
export const cashoutOfferUrl = '/user/offer/v1/cashout';
export const newOfferUrl = '/user/offer/new';
export const refinanceOfferUrl = '/user/offer/v1/refinance';
export const removeClientUrl = '/v1/advisor/removeclient/';

// Passwordless
export const getMagicToken = '/user/magictoken';
export const getCustomToken = '/user/customtoken';

// Alerts
export const alertSettingsUrl = '/alerts/settings';
export const alertSettingsSubscribeUrl = '/alerts/subscribe';
export const alertSettingsUnsubscribeUrl = '/alerts/unsubscribe';

// FA -> Financial Advisor Related
export const faClients = '/fa/v1/clients';
export const faContactClient = '/fa/send-message';
export const faLiabilitySummary = '/fa/summary';

// Client
export const clientInvitation = '/client/invite';
export const reInviteClientUrl = '/client/re-invite';
export const verifyClientInvitation = '/client/verify?inviteCode=';
export const generateReport = '/client/get-query-report';

// Liability
export const commercialLoanEndpoint = '/biz/tradeline';

// Wallet page
export const walletLiabilityURL = '/v1/wallet/liability/';
export const walletOverviewUrl = '/wallet/overview';
export const getWalletBurnDown = '/wallet/reports/burndown';
// These are the "rules engines from the BE"
export const getSoraInsightsUrl = '/rule/execute';

// Data Refresh
export const dataRefreshUrl = '/v1/data/refresh';
export const advisorRefreshAllClientDataUrl = '/v1/fa/client-data/refresh';

// Mask mode, impersonating another user account
export const maskAsAnotherUser = '/v1/info/super';

// Analytics
export const analyticsOfferUrl = '/analytics/offer';
export const analyticsLiabilityUrl = '/analytics/liability';

// Assumptions Analytics
export const assumptionsAnalyticsSaveUrl = '/analytics/save-assumptions';
export const assumptionsAnalyticsGetUrl = '/analytics/assumptions/';

// Admin
export const getAdvisorsEndpoint = '/admin/v1/advisors';

// Miscellaneous
export const onboardUrl = '/onboard';
export const fileUploadUrl = '/file';
export const loanDocumentUploadUrl = '/new-loan-inquiry/upload-document';
export const deleteDocumentUrl = '/new-loan-inquiry/delete-document';
export const updateNewLoanInquiryStatusUrl = '/update-new-loan-inquiry-status';
export const setChosenLoanOfferUrl = '/new-loan-inquiry/accept-offer';
export const newHomeUrl = '/newhome';
export const loanAppliedUrl = '/loanapplied';
export const estimatedValueUrl = '/estimatedvalue';
export const chatGptInsightsUrl = '/v1/gpt/rewrite-advice';
export const verifyEmployeeUrl = '/verifyemployee';

// Tasks
export const taskIsDoneUrl = '/external/contact/task/done';
export const loanTaskIsDoneUrl = '/new-loan-inquiry-task-done';

// Loan Requests
export const loanRequestUrl = '/new-loan-details';

// 3rd Party Below

// Methodfi
export const getMethodfiToken = '/mfi-connect';
export const getMethodfiLiabilities = '/mfi-liabilities/';

// PinWheel
export const pinwheelTokenUrl = '/token';
export const pinwheelAccountConnectUrl = '/pinwheel';
export const clientIncomeUrl = '/income/manualupload';

// TalkJs
export const postCreateTalkJsConversation = '/talkjs/create-conversations';

// WealthBox
export const wealthBoxAuthApi = 'https://app.crmworkspace.com/oauth/authorize';
export const wealthBoxSoraConnect = '/integration/wealthbox/connect/';
export const wealthboxGetContacts = '/v1/external/contactlist';
export const refreshWealthboxClientEndpoint = '/external/contact/refresh/';

// Emails
export const sendGenericEmailUrl = '/send-generic-mail';

// DTS
export const getDtsStatusUrl = '/dts/status/';
export const updateDtsStatusUrl = '/dts/status';
