import { Alert } from '@mui/material';

function LatencyAlertWarning() {
	return (
		<Alert severity="warning" variant="filled" sx={{ color: 'black' }}>
			Our system is facing some unusual latency and the engineers are
			working on it
		</Alert>
	);
}

export default LatencyAlertWarning;
