import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { TextField, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import CommonPropTypes from 'shared/prop-types';

export default function YearDatePicker({
	sx,
	label,
	value,
	onChange,
	tabIndex
}) {
	return (
		<>
			{label && (
				<Typography variant="subtitle2" sx={{ marginBottom: 1 }}>
					{label}
				</Typography>
			)}
			<LocalizationProvider dateAdapter={AdapterDateFns}>
				<DatePicker
					views={['year']}
					minDate={new Date('1950-01-01')}
					maxDate={new Date('2050-01-01')}
					value={value}
					onChange={onChange}
					renderInput={(params) => (
						<TextField
							{...params}
							tabIndex={tabIndex}
							helperText={null}
							sx={{ width: '100%' }}
						/>
					)}
					sx={{ ...sx }}
					inputFormat="yyyy"
				/>
			</LocalizationProvider>
		</>
	);
}

YearDatePicker.defaultProps = {
	sx: {},
	label: 'Year'
};

YearDatePicker.propTypes = {
	sx: CommonPropTypes.sx,
	label: PropTypes.string
};
