import { useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';

// Our Components
import CurrencyTextInput from 'components/Input/CurrencyTextInput';
import Dropdown from 'components/Dropdown/Dropdown';
import TextInput from 'components/Input/TextInput';
import RateInput from 'components/Input/RateInput';
import YearInput from 'components/Input/YearInput';
import ResponsiveTable from 'components/Table/ResponsiveTable';
import MarginalDollarTableNoLiabilitiesFallback from 'components/Analytics/MarginalDollar/MarginalDollarTableNoLiabilitiesFallback';

// Input Types
import { PERCENT } from 'components/Input/Types';

// Local Constants
const DEFAULT_TABLE_CELL_HEIGHT = 40;
const DEFAULT_TABLE_CELL_WIDTH = 100;

const DROPDOWN_WIDTH = 80;

// Table Columns
const TABLE_COLUMNS = [
	'Liability type',
	'Lender Name',
	'Present Value',
	'1st period\nannual interest',
	'Years in \n1st period',
	'Two periods?',
	'Interest Only?',
	'2nd period\nannual interest',
	'Years in \n2nd period'
];

function MarginalDollarTable({ rowsState, setRowsState }) {
	const rows = useMemo(
		() =>
			rowsState.map((rowState, index) => {
				const rowsWithState = [
					{
						props: {
							sx: {
								minWidth: 103,
								backgroundColor: '#A8C5DA',
								position: 'sticky',
								left: 0,
								zIndex: 2,
								border: '0.25px #1C1C1C solid'
							}
						},
						value: rowState.liabilityType
					},
					{
						props: {
							sx: {
								background: '#A8C5DA',
								position: 'sticky',
								left: 103,
								zIndex: 2,
								border: '0.25px #1C1C1C solid'
							}
						},
						value: rowState.lender
					},
					{
						props: {},
						value: (
							// presentValue
							<CurrencyTextInput
								sx={{
									height: DEFAULT_TABLE_CELL_HEIGHT,
									minWidth: DEFAULT_TABLE_CELL_WIDTH + 75
								}}
								inputSx={{ height: DEFAULT_TABLE_CELL_HEIGHT }}
								value={rowState.presentValue}
								onChange={(value) => {
									const newRowsState = [...rowsState];
									newRowsState[index].presentValue = value;
									setRowsState(newRowsState);
								}}
							/>
						),
						key: uuidv4()
					},
					{
						props: {},
						value: (
							// firstPeriodAnnualGrowth
							<RateInput
								label=""
								subLabel=""
								sx={{
									height: DEFAULT_TABLE_CELL_HEIGHT,
									minWidth: DEFAULT_TABLE_CELL_WIDTH
								}}
								inputSx={{ height: DEFAULT_TABLE_CELL_HEIGHT }}
								rate={rowState.firstPeriodAnnualGrowth}
								setRate={(value) => {
									const newRowsState = [...rowsState];
									newRowsState[
										index
									].firstPeriodAnnualGrowth = value;
									setRowsState(newRowsState);
								}}
							/>
						),
						key: uuidv4()
					},
					{
						props: {},
						value: (
							// yearsInFirstPeriod
							<YearInput
								sx={{
									height: DEFAULT_TABLE_CELL_HEIGHT,
									minWidth: DEFAULT_TABLE_CELL_WIDTH
								}}
								value={rowState.yearsInFirstPeriod}
								onChange={(value) => {
									if (value === '0') return;

									const newRowsState = [...rowsState];
									newRowsState[index].yearsInFirstPeriod =
										value;

									if (value !== '0') {
										setRowsState(newRowsState);
									}
								}}
							/>
						),
						key: uuidv4()
					},
					{
						props: {},
						value: (
							// twoPeriods
							<Dropdown
								sx={{
									height: DEFAULT_TABLE_CELL_HEIGHT,
									minWidth: DROPDOWN_WIDTH
								}}
								selectSX={{
									height: DEFAULT_TABLE_CELL_HEIGHT,
									minWidth: DROPDOWN_WIDTH
								}}
								menuProps={{
									PaperProps: {
										sx: { minWidth: DROPDOWN_WIDTH }
									}
								}}
								items={['Yes', 'No']}
								selected={rowState.twoPeriods}
								onChange={(value) => {
									const newRowsState = [...rowsState];
									const isYes = value === 'Yes';
									newRowsState[index].twoPeriods = value;

									newRowsState[
										index
									].secondPeriodAnnualGrowth = isYes
										? ''
										: '0';

									newRowsState[index].yearsInSecondPeriod =
										isYes ? '' : '0';

									setRowsState(newRowsState);
								}}
							/>
						)
					},
					{
						props: {},
						value: (
							// interest Only
							<Dropdown
								sx={{
									height: DEFAULT_TABLE_CELL_HEIGHT
								}}
								selectSX={{
									height: DEFAULT_TABLE_CELL_HEIGHT
								}}
								menuProps={{
									PaperProps: {}
								}}
								items={['Yes', 'No']}
								selected={rowState.interestOnly}
								onChange={(value) => {
									const newRowsState = [...rowsState];
									newRowsState[index].interestOnly = value;
									setRowsState(newRowsState);
								}}
							/>
						)
					},
					{
						props: {},
						value: (
							// secondPeriodAnnualGrowth
							<TextInput
								disabled={rowState.twoPeriods === 'No'}
								type={PERCENT}
								inputSx={{
									height: DEFAULT_TABLE_CELL_HEIGHT,
									minWidth: DEFAULT_TABLE_CELL_WIDTH
								}}
								value={rowState.secondPeriodAnnualGrowth}
								onChange={(value) => {
									const newRowsState = [...rowsState];
									newRowsState[
										index
									].secondPeriodAnnualGrowth = value;
									setRowsState(newRowsState);
								}}
							/>
						)
					},
					{
						props: {},
						value: (
							// yearsInSecondPeriod
							<YearInput
								disabled={rowState.twoPeriods === 'No'}
								sx={{
									height: DEFAULT_TABLE_CELL_HEIGHT,
									minWidth: DEFAULT_TABLE_CELL_WIDTH
								}}
								value={rowState.yearsInSecondPeriod}
								onChange={(value) => {
									const newRowsState = [...rowsState];
									newRowsState[index].yearsInSecondPeriod =
										value;
									setRowsState(newRowsState);
								}}
							/>
						)
					}
				];

				// If the row has an asset category, add it to the beginning of the row
				return rowsWithState;
			}),
		[rowsState]
	);

	const key = useMemo(() => uuidv4(), [rows.length]);

	const showEmptyTableMessage = rows.length === 0;

	return (
		<ResponsiveTable
			emptyTableMessage={
				showEmptyTableMessage ? (
					<MarginalDollarTableNoLiabilitiesFallback />
				) : null
			}
			key={key}
			columnCellSx={{
				border: '0.25px #1C1C1C solid',
				background: 'rgba(38, 131, 194, 0.10)'
			}}
			firstColumnSx={{
				position: 'sticky',
				left: 0,
				background: '#A8C5DA',
				border: '0.25px #1C1C1C solid'
			}}
			secondColumnSx={{
				position: 'sticky',
				left: 103,
				background: '#A8C5DA',
				border: '0.25px #1C1C1C solid'
			}}
			cellSx={{
				border: '0.25px #1C1C1C solid'
			}}
			ContainerComponent={null}
			columns={TABLE_COLUMNS}
			rows={rows}
			makeLastBold={false}
		/>
	);
}

export default MarginalDollarTable;
