// Our axios Instance
import axiosInstance from 'services/API/API';
import { useMutation, useQueryClient } from 'react-query';

// Our stuff
import {
	alertSettingsSubscribeUrl,
	alertSettingsUnsubscribeUrl
} from 'shared/api-urls';
import { SUCCESS_MESSAGE_DATA, ERROR_MESSAGE_DATA } from 'shared/query-keys';

function useMutateAlertSettings() {
	const queryClient = useQueryClient();
	return useMutation(
		({ value, alertId }) => {
			let payload = {};

			// alertId 1 & 8 need to be interacted with together
			if (alertId === 1) {
				// if alertId 1 is triggered we create a payload for the both setting 1 and 8
				const groupedAlerts = [1, 8];
				payload = { alerts: groupedAlerts };
			} else {
				// Otherwise we just pass a single alert
				payload = {
					alerts: [alertId]
				};
			}

			const determinedUrl = !value
				? alertSettingsSubscribeUrl
				: alertSettingsUnsubscribeUrl;

			axiosInstance
				.put(determinedUrl, payload)
				.then((res) => res.data.data);
		},
		{
			onSuccess: () => {
				queryClient.setQueryData(
					SUCCESS_MESSAGE_DATA,
					'Alert setting saved.'
				);
			},
			onError: () =>
				queryClient.setQueryData(
					ERROR_MESSAGE_DATA,
					'Error saving alert setting.'
				)
		}
	);
}

export default useMutateAlertSettings;
