import PropTypes from 'prop-types';
import { useTheme } from '@emotion/react';
import {
	Typography,
	IconButton,
	DialogTitle,
	Dialog,
	DialogContent
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

// Our Prop Types
import CommonPropTypes from 'shared/prop-types';

function JumboModal({
	children,
	isOpen,
	handleClose,
	title,
	subtitle,
	sx,
	maxWidth,
	errorText
}) {
	const SoraTheme = useTheme();

	// If this doesn't cover all options lets pass an optional Prop.
	const overflow = 'auto';

	return (
		<Dialog
			component="div"
			open={isOpen}
			onClose={handleClose}
			fullWidth
			maxWidth={maxWidth}
			sx={{
				paddingLeft: 0,
				'& .MuiPaper-rounded ': {
					paddingLeft: 4,
					paddingTop: 4,
					paddingBottom: 4,
					borderRadius: '40px',
					overflow,
					...sx
				}
			}}
		>
			<IconButton
				aria-label="close"
				fontSize="64px"
				onClick={handleClose}
				sx={{
					position: 'absolute',
					right: '0',
					top: '0',
					marginTop: 2,
					marginRight: 2,
					marginBottom: 2
				}}
			>
				<CloseIcon />
			</IconButton>

			<DialogTitle sx={{ paddingBottom: 0, marginBottom: 2 }}>
				<Typography
					variant="h2Gascogne"
					sx={{
						display: 'block',
						color: SoraTheme.palette.primary.indigo,
						marginBottom: 1
					}}
				>
					{title}
				</Typography>
				<Typography
					variant="body1"
					sx={{
						lineHeight: '1.5rem'
					}}
				>
					{subtitle}
				</Typography>
				{errorText && (
					<Typography
						variant="body2"
						sx={{
							lineHeight: '1.5rem',
							color: '#d32f2f',
							marginTop: 1
						}}
					>
						{errorText}
					</Typography>
				)}
			</DialogTitle>
			<DialogContent sx={{ overflow: 'auto' }}>{children}</DialogContent>
		</Dialog>
	);
}

JumboModal.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	handleClose: PropTypes.func.isRequired,
	title: PropTypes.string,
	subtitle: PropTypes.string,
	sx: CommonPropTypes.sx,
	maxWidth: PropTypes.string
};

JumboModal.defaultProps = {
	title: '',
	subtitle: '',
	sx: {},
	maxWidth: 'md'
};

export default JumboModal;
