import { useEffect } from 'react';
import { useQueryClient } from 'react-query';

// This hook will run once the component gets destroyed
// queryKeys is expected to be an array of queryKeys
function useCleanUpQueries(queryKeys) {
	const queryClient = useQueryClient();

	useEffect(
		() => () => {
			queryKeys.forEach((queryKey) =>
				queryClient.removeQueries(queryKey)
			);
		},
		[]
	);
}

export default useCleanUpQueries;
