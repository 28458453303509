// Our Utils
import isAutomatedRun from 'shared/utils/events/isAutomatedRun';

/**
 *
 * @param {boolean} originalDataValue
 * @param {boolean} didRateChange
 */

function shouldUpdateRateManuallyUpdated(originalDataValue, didRateChange) {
	// if liability was manually updated to begin with return that value
	if (originalDataValue) return originalDataValue;

	const isAutomaticOnboarding = isAutomatedRun();

	return isAutomaticOnboarding ? false : didRateChange;
}

export default shouldUpdateRateManuallyUpdated;
