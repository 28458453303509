import { useNavigate, useParams } from 'react-router-dom';
import { Box, Grid, Typography } from '@mui/material';
import { useQueryClient } from 'react-query';
import PropTypes from 'prop-types';
import { useTheme } from '@emotion/react';

// Our Components
import CardItem from 'components/SoraWallet/Cards/CardItem';
import Chart from 'components/SoraWallet/Chart';
import { TertiaryButton } from 'components/Button/Button';

// Wallet Prop Types
import {
	bestRefiPropShape,
	currentLoanPropShape
} from 'components/SoraWallet/WalletPropTypes';

// Our Routes
import { REFINANCE_OFFER_ROUTE } from 'routes';

// Our Query Keys
import { FA_CURRENT_CLIENT_ID, WALLET_OVERVIEW_DATA } from 'shared/query-keys';

function RefinanceOptionsCard({
	bestRefi,
	currentLoan,
	isClient,
	shouldRenderChart
}) {
	const SoraTheme = useTheme();
	const { primary } = SoraTheme.palette;

	const queryClient = useQueryClient();
	const navigate = useNavigate();

	const { tradelineId } = useParams();

	const clientId = queryClient.getQueryData(FA_CURRENT_CLIENT_ID);

	const dynamicKey = clientId
		? [WALLET_OVERVIEW_DATA, clientId]
		: WALLET_OVERVIEW_DATA;

	const walletOverviewData = queryClient.getQueryData(dynamicKey);

	const clientDataForOfferRoute = {
		firstName: walletOverviewData.firstName,
		lastName: walletOverviewData.lastName,
		clientId
	};

	const buttonText = isClient ? 'Refinance' : 'See refinance options';

	return (
		<Grid
			item
			sm={12}
			md={12}
			lg={12}
			xl={12}
			sx={{ height: 'fit-content' }}
		>
			<Box
				sx={{
					display: 'flex',
					alignItems: 'flex-end',
					gap: 2,
					marginBottom: 3
				}}
			>
				<Typography
					variant="h2Gascogne"
					sx={{ display: 'block', color: primary.indigo }}
				>
					Refinance Options
				</Typography>
				<TertiaryButton
					sx={{ minHeight: 32, padding: '0 16px' }}
					onClick={() =>
						navigate(REFINANCE_OFFER_ROUTE, {
							state: {
								clientData: clientDataForOfferRoute,
								tradelineId
							}
						})
					}
				>
					{buttonText}
				</TertiaryButton>
			</Box>

			<CardItem
				withoutButton
				sx={{
					paddingBottom: 2,
					paddingRight: 0,
					height: 'fit-content'
				}}
			>
				{shouldRenderChart && (
					<Chart
						bestOfferLoan={bestRefi}
						currentLoan={currentLoan}
						variant="new"
					/>
				)}
			</CardItem>
		</Grid>
	);
}

RefinanceOptionsCard.propTypes = {
	bestRefi: PropTypes.shape(bestRefiPropShape).isRequired,
	currentLoan: PropTypes.shape(currentLoanPropShape).isRequired,
	isClient: PropTypes.bool.isRequired,
	shouldRenderChart: PropTypes.bool.isRequired
};

export default RefinanceOptionsCard;
