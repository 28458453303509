// Our Routes
import { OTHER_LOAN_ROUTE, LIABILITY_ADDITIONAL_ROUTE } from 'routes';

// Our Constants
const BASE_ROUTE = '/client-onboarding/liabilities/';

// utilty function that returns the nextRoute in Dynamic Liability Forms
// userTradeLines should be ordered as the same as tab menu
function getNextLiabilityRoute(pathname, currentTradeLineId, userTradeLines) {
	const isManualRoute = pathname.includes(LIABILITY_ADDITIONAL_ROUTE);
	const isOtherLoanRoute = pathname === OTHER_LOAN_ROUTE;

	// if its a manual route the next button takes them back to select the next manual liability.
	if (isManualRoute || isOtherLoanRoute) return LIABILITY_ADDITIONAL_ROUTE;

	// If we are here we have determined we are not a manual liability. nor are we on the credit card page
	// Time to do dynamic work.
	const lastPossibleIndex = userTradeLines.length - 1;
	const indexOfCurrentTradeLine = userTradeLines.findIndex(
		(currentTradeLine) =>
			currentTradeLine.tradelineId === +currentTradeLineId
	);
	const indexOfNextTradeLine = indexOfCurrentTradeLine + 1;
	if (indexOfNextTradeLine > lastPossibleIndex)
		return LIABILITY_ADDITIONAL_ROUTE; // logic safety measure. Shouldn't happen bc credit cards are filtered to 1 entry but still.
	const nextTradeLine = userTradeLines[indexOfNextTradeLine];

	if (nextTradeLine.tradeLineType === 'creditcard') return OTHER_LOAN_ROUTE;

	// If we reached this point lets created the dynamic next route
	// '/client-onboarding/liabilities/' + mortage + 1
	const nextDynamicRoute = `${BASE_ROUTE}${nextTradeLine.tradeLineType}/${nextTradeLine.tradelineId}`;
	return nextDynamicRoute;
}

export default getNextLiabilityRoute;
