import axios from 'axios';

const baseURL = 'https://api.talkjs.com';

const talkJSAxiosInstance = axios.create({
	baseURL: `${baseURL}/v1/${process.env.REACT_APP_TALKJS_APP_ID}`,
	headers: {
		Authorization: `Bearer ${process.env.REACT_APP_TALKJS_SECRET}`
	}
});

export default talkJSAxiosInstance;
