import { AUTO, STUDENT, PERSONAL } from 'shared/constants';

const DEFAULT_YEAR_TO_ADD = 20; // This is the default for Mortgage
const DEFAULT_AUTO_YEAR = 3;
const DEFAULT_PERSONAL_YEAR = 1;
const DEFAULT_STUDENT_YEAR = 5;
function getDefaultApproxPayoffDate(liability) {
	// gets the current date
	const today = new Date(Date.now());

	// gets the current year
	const currentYear = today.getFullYear();

	// variable that tracks the value to add to year
	let additionToCurrentYear = DEFAULT_YEAR_TO_ADD;

	// based on the liability it will get ITS default year
	// i.e. for Auto if today is Dec 2, 2022 it will add 3 years to it. Making it Dec, 2 2025
	if (liability === AUTO) additionToCurrentYear = DEFAULT_AUTO_YEAR;
	if (liability === STUDENT) additionToCurrentYear = DEFAULT_STUDENT_YEAR;
	if (liability === PERSONAL) additionToCurrentYear = DEFAULT_PERSONAL_YEAR;

	today.setFullYear(currentYear + additionToCurrentYear);

	return today;
}

export default getDefaultApproxPayoffDate;
