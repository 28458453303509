import { Helmet } from 'react-helmet-async';
import { Grid } from '@mui/material';
import { useTheme } from '@emotion/react';

// Our components
import BasicFrame from 'components/Frame/Basic';
import ForgotPasswordForm from 'components/ForgotPassword/ForgotPasswordForm';
import LoginArtAndSoraLogo from 'components/LoginForm/LoginArtAndSoraLogo';

export default function ForgotPasswordPage() {
	const SoraTheme = useTheme();

	return (
		<>
			<Helmet>
				<title>Forgot Password</title>
			</Helmet>
			<BasicFrame sx={{ height: '100vh', width: '100%' }}>
				<Grid
					item
					xs={6}
					sx={{
						height: '100%',
						width: '100%'
					}}
				>
					<LoginArtAndSoraLogo />
				</Grid>
				{/* MUI has no way of offsetting a <Grid item>,
					best solution is to put a placeholder grid item in there.
					Not a fan of this solution, the framework should handle it better
					https://github.com/mui/material-ui/issues/11251 */}
				<Grid
					item
					xs={1}
					sx={{
						backgroundColor: SoraTheme.palette.primary.white
					}}
				/>
				<Grid
					item
					xs={4}
					sx={{
						backgroundColor: SoraTheme.palette.primary.white
					}}
				>
					<ForgotPasswordForm
						sx={{
							marginBottom: 6
						}}
					/>
				</Grid>
			</BasicFrame>
		</>
	);
}
