import { useQuery } from 'react-query';

// Our Hooks
import useGetCurrentTalkjsUserId from 'hooks/talkjs/queries/useGetCurrentTalkJsUserId';

// Our Axios Instance
import talkJSAxiosInstance from 'services/API/TalkJsAxiosInstance';

// Our Query Keys
import { TALKJS_ALL_UNREAD_MESSAGES } from 'shared/query-keys';

// Our Utils
import countUnreadMessages from 'shared/utils/talkjs/countUnreadMessages';

function useGetAllUnreadMessagesForCurrentUser() {
	const { isSuccess, data: currentTalkJsUserId } =
		useGetCurrentTalkjsUserId();

	return useQuery(
		TALKJS_ALL_UNREAD_MESSAGES,
		async () => {
			const response = await talkJSAxiosInstance.get(
				`/users/${currentTalkJsUserId}/conversations`,
				{
					params: {
						limit: 30,
						unreadsOnly: true
					}
				}
			);

			const conversations = response.data.data;

			const unreadMessageCount = countUnreadMessages(conversations);

			return unreadMessageCount;
		},
		{
			enabled: isSuccess
		}
	);
}

export default useGetAllUnreadMessagesForCurrentUser;
