import { Grid, Typography } from '@mui/material';
import { useMemo } from 'react';
import {
	LineChart,
	Line,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	Legend,
	ResponsiveContainer
} from 'recharts';
import { useTheme } from '@emotion/react';

// Our components
import AnalyticsPresentationTable from 'components/Table/AnalyticsPresentationTable';
import AnalyticsPresentationRow from 'components/Table/AnalyticsPrsentationRow';

// Our utils
import createFormattedRowData from 'shared/utils/analytics/createFormattedRowData';
import { dollarFormatter } from 'shared/utils';

// Local Constants
const LINE_STROKE_WIDTH = 5;

function UpsidePotentialTab({ analyticsUpsidePotentialData }) {
	const SoraTheme = useTheme();

	const { analytics } = SoraTheme.palette;

	const { compoundings, currentLoan, bestOffer } =
		analyticsUpsidePotentialData;

	const {
		monthlyMortgagePayment: currentLoanMonthlyMortgagePayment,
		monthlyPaymentSavings,
		remainingMonth,
		upsidePotential
	} = currentLoan;

	const { monthlyMortgagePayment: bestOfferMonthlyMortgagePayment } =
		bestOffer;

	const savingsInvestmentPotentialTableRows = useMemo(
		() =>
			[
				createFormattedRowData(
					'Current Monthly Payment',
					currentLoanMonthlyMortgagePayment,
					bestOfferMonthlyMortgagePayment
				),
				createFormattedRowData(
					'Monthly Payment Savings',
					monthlyPaymentSavings,
					''
				),
				['Current Loan Term', remainingMonth, ''],
				createFormattedRowData('Upside Potential', upsidePotential, '')
			].map((rowData, i) => {
				const isLast = i === 3;
				const borderBottom = isLast ? 'none' : '0.25px black solid';

				return (
					<AnalyticsPresentationRow
						currentRowData={rowData}
						sx={{ borderBottom }}
						firstColumnSX={{ width: 170 }}
					/>
				);
			}),
		[analyticsUpsidePotentialData]
	);

	const formattedGraphData = useMemo(() => {
		if (analyticsUpsidePotentialData)
			return compoundings.map(
				({
					year,
					contribution,
					upsidePotential: compoundingUpsidePotential
				}) => ({
					year,
					contribution,
					FV: compoundingUpsidePotential
				})
			);

		return [];
	}, [analyticsUpsidePotentialData]);

	return (
		<>
			<Typography
				variant="h2Gascogne"
				sx={{
					display: 'block',
					marginBottom: 4
				}}
			>
				Savings Investment Potential
			</Typography>

			<Grid container spacing={2}>
				<Grid item xs={12} xl={6}>
					<ResponsiveContainer width="90%" height={500}>
						<LineChart data={formattedGraphData ?? []}>
							<CartesianGrid strokeDasharray="3 3" />
							<XAxis
								dataKey="year"
								label={{
									value: 'Year',
									position: 'left',
									offset: 10
								}}
							/>

							<YAxis
								width={120}
								tickFormatter={(yAxisTickValue) => {
									const formattedValue =
										dollarFormatter.format(yAxisTickValue);
									return formattedValue;
								}}
							/>
							<Tooltip
								formatter={(value) =>
									dollarFormatter.format(value)
								}
							/>
							<Legend />
							<Line
								strokeWidth={LINE_STROKE_WIDTH}
								dot={false}
								type="monotoneY"
								dataKey="FV"
								stroke={analytics.upsidePotentialFV}
							/>
							<Line
								strokeWidth={LINE_STROKE_WIDTH}
								dot={false}
								type="monotone"
								dataKey="contribution"
								name="Contribution"
								stroke={analytics.upsidePotentialContribution}
							/>
						</LineChart>
					</ResponsiveContainer>
				</Grid>

				<Grid
					container
					item
					xs={12}
					xl={6}
					sx={{ marginBottom: 4, maxHeight: 312 }}
				>
					<AnalyticsPresentationTable
						tableData={savingsInvestmentPotentialTableRows}
						firstColumnSX={{ width: 170 }}
					/>
				</Grid>
			</Grid>
		</>
	);
}

export default UpsidePotentialTab;
