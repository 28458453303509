import { Box } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import { useMemo } from 'react';

// Our Components
import OffersTableRowItem from 'components/Offers/OfferTable/OffersTableRowItem';

// Our Utils
import getOffersRowMapping from 'components/Offers/OfferTable/getOffersRowMapping';

function OfferTable({ offerType, offers }) {
	const soraChoiceOffer = offers[0];

	const hasExistingClientOffer =
		offers.length && 'existingClientOffer' in soraChoiceOffer;

	const distanceFromTop = offerType === 'cash_out' ? 240 : 230;

	const offerRowMapping = getOffersRowMapping(offerType, soraChoiceOffer);

	const renderedOfferTable = useMemo(
		() =>
			offerRowMapping.map((rowKey, index) => {
				// offerRowData will always be an array
				// each item in that array will be
				// number | string | null
				const offerRowData = offers.map((offer) => offer[rowKey]);

				const last = index === offerRowMapping.length - 1;

				const existingClientOffer =
					hasExistingClientOffer &&
					soraChoiceOffer.existingClientOffer;

				return (
					<OffersTableRowItem
						key={uuidv4()}
						currentOfferKey={rowKey}
						currentOfferData={offerRowData}
						isLast={last}
						existingClientOffer={existingClientOffer}
						offerType={offerType}
					/>
				);
			}),
		[offers]
	);

	return (
		<Box
			sx={{
				position: 'relative',
				width: '100%',
				zIndex: 1,
				top: distanceFromTop
			}}
		>
			{renderedOfferTable}
		</Box>
	);
}

export default OfferTable;
