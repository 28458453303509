import { Grid } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';

// Our Assets
import eMoneyLogo from 'assets/images/integration_logos/e.svg';
import redTailLogo from 'assets/images/integration_logos/red.svg';
import wealthBoxLogo from 'assets/images/integration_logos/wealth.svg';

// Our Components
import IntegrationCard from 'components/Integrations/IntegrationCard';

const INTEGRATION_OPTIONS = [
	{
		logo: wealthBoxLogo,
		bodyText:
			'Initiate seamless client onboarding via Sora’s integration with Wealthbox',
		isDisabled: false
	},
	{
		logo: redTailLogo,
		bodyText: 'Coming Soon',
		isDisabled: true
	},
	{
		logo: eMoneyLogo,
		bodyText: 'Coming Soon',
		isDisabled: true
	}
];

function IntegrationCards() {
	return (
		<Grid container sx={{ gap: 2 }}>
			{INTEGRATION_OPTIONS.map((componentProps) => (
				<Grid item xs={12} md={5} lg={3} xl={2} key={uuidv4()}>
					<IntegrationCard {...componentProps} />
				</Grid>
			))}
		</Grid>
	);
}

export default IntegrationCards;
