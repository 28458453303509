import { useQueryClient, useMutation } from 'react-query';

// Our Axios
import axiosInstance from 'services/API/API';

// Our Query Keys
import { ERROR_MESSAGE_DATA } from 'shared/query-keys';

// Our Endpoint
import { analyticsOfferUrl } from 'shared/api-urls';

const useMutateGetAmortizationTable = () => {
	const queryClient = useQueryClient();

	return useMutation(
		async (offerRequestDetails) => {
			const response = await axiosInstance.post(
				`${analyticsOfferUrl}/amortization`,
				offerRequestDetails
			);

			const amortizationData = response.data.data;

			return amortizationData;
		},

		{
			onError: (error) => {
				queryClient.setQueryData(ERROR_MESSAGE_DATA, error.message);
			}
		}
	);
};

export default useMutateGetAmortizationTable;
