import { parseISO } from 'date-fns';

// Utils
import { dataIsValid } from 'shared/utils';

function safeConvertDateToUTC(dateToConvert) {
	// if input is undefined or null just exit.
	if (!dataIsValid(dateToConvert) || dateToConvert === '')
		return dateToConvert;

	// should have a string or date object here.
	try {
		const finalizedDate = parseISO(dateToConvert);
		return finalizedDate;
	} catch (e) {
		console.error(e);

		return dateToConvert;
	}
}

export default safeConvertDateToUTC;
