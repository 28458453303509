import { Box } from '@mui/material';

// Our Prop Types
import CommonProps from 'shared/prop-types';

function ExportableReportSection({ children, sx }) {
	return (
		<Box
			sx={{
				width: '100%',
				marginBottom: 2,
				...sx
			}}
		>
			{children}
		</Box>
	);
}

ExportableReportSection.propTypes = {
	sx: CommonProps.sx
};

ExportableReportSection.defaultProps = {
	sx: {}
};

export default ExportableReportSection;
