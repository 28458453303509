import { useQuery } from 'react-query';

// Our Hooks
import useGetProfile from 'hooks/useGetProfile';

// Our Query Keys
import { TALKJS_CURRENT_USER_ID } from 'shared/query-keys';

// Constants
import { ADMIN } from 'shared/constants';

function useGetCurrentTalkjsUserId() {
	const { isSuccess, data: profileData } = useGetProfile();

	return useQuery(
		TALKJS_CURRENT_USER_ID,
		async () => {
			// this query becomes enabled once isSuccess is true -> we should have the profileData by this point.
			const { role, talkJsId, adminTalkJsId } = profileData;

			if (role === ADMIN) return adminTalkJsId;

			return talkJsId;
		},
		{
			enabled: isSuccess
		}
	);
}

export default useGetCurrentTalkjsUserId;
