import { useQuery } from 'react-query';

// Our Hooks
import useGetProfile from 'hooks/useGetProfile';
import useGetCurrentTalkjsUserId from 'hooks/talkjs/queries/useGetCurrentTalkJsUserId';
import useMutateCreateGeneralChannel from 'hooks/talkjs/mutations/useMutateCreateGeneralChannel';

// Our Axios Instance
import talkJSAxiosInstance from 'services/API/TalkJsAxiosInstance';

// Our Query Keys
import { TALKJS_ALL_MESSAGES } from 'shared/query-keys';

// Our Constants
import { ADMIN } from 'shared/constants';

function useGetAllMessagesForCurrentUser(setCurrentConversation) {
	const { data: userProfile } = useGetProfile();

	const { isSuccess, data: currentTalkJsUserId } =
		useGetCurrentTalkjsUserId();

	const createGeneralChannel = useMutateCreateGeneralChannel();

	return useQuery(
		TALKJS_ALL_MESSAGES,
		async () => {
			const response = await talkJSAxiosInstance.get(
				`/users/${currentTalkJsUserId}/conversations`
			);

			const conversations = response.data.data;

			const { role } = userProfile;

			const isAdmin = role === ADMIN;

			if (isAdmin) return conversations;

			if (conversations.length < 1) {
				const userProfileId = userProfile.id;
				await createGeneralChannel.mutateAsync(userProfileId);

				setCurrentConversation(currentTalkJsUserId);
			}

			return conversations;
		},
		{
			enabled: isSuccess
		}
	);
}

export default useGetAllMessagesForCurrentUser;
