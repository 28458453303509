import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { useMemo } from 'react';

import {
	Box,
	List,
	ListItemButton,
	ListItem,
	ListItemIcon,
	ListItemText,
	Paper,
	Typography
} from '@mui/material';

import OnboardingNavImage from 'assets/images/OnboardingNavImage.png';

import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';

// Our Components
import SoraLogo from 'components/NavMenu/NavBarComponents/SoraLogoComponent';
import ProgressMeter from 'components/ProgressMeter/ProgressMeter';

import {
	OnboardingLiabilityIcon,
	OnboardingAssetsIcon,
	OnboardingIncomeIcon
} from 'components/NavMenu/OnboardingProgress';

const LIST_ITEMS = [
	{
		Icon: OnboardingLiabilityIcon,
		text: 'Liabilities'
	},
	{
		Icon: OnboardingIncomeIcon,
		text: 'Income'
	},
	{
		Icon: OnboardingAssetsIcon,
		text: 'Assets'
	},
	{
		Icon: RadioButtonUncheckedIcon,
		text: 'Review'
	}
];

function SoraList({ color, progress }) {
	const ListItems = useMemo(
		() =>
			LIST_ITEMS.map(({ Icon, text }) => (
				<ListItem key={uuidv4()} disablePadding>
					<ListItemButton
						disableRipple
						sx={{
							cursor: 'auto',
							'&:hover': { backgroundColor: 'transparent' }
						}}
					>
						<ListItemIcon>
							<Icon progress={progress} />
						</ListItemIcon>
						<ListItemText>
							<Typography
								variant="subtitle1"
								sx={{
									color
								}}
							>
								{text}
							</Typography>
						</ListItemText>
					</ListItemButton>
				</ListItem>
			)),
		[progress]
	);

	return ListItems;
}

function OnboardingNavigation({ progress, userType }) {
	const SoraTheme = useTheme();
	const navigate = useNavigate();

	const { indigo } = SoraTheme.palette.primary;
	const { onboardingNavLinearGradient } = SoraTheme.palette.gradient;

	return (
		<Paper
			sx={{
				width: '100%',
				height: '100%',
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'space-between',
				background: onboardingNavLinearGradient,
				boxSizing: 'border-box'
			}}
		>
			<SoraLogo />
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					alignItems: 'center'
				}}
			>
				{userType === 'client' && (
					<List
						sx={{
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'center',
							alignItems: 'center',
							boxSizing: 'border-box'
						}}
					>
						<SoraList
							color={indigo}
							navigate={navigate}
							progress={progress}
						/>
					</List>
				)}

				{userType === 'advisor' && (
					<Typography variant="h5" sx={{ color: indigo }}>
						Onboarding
					</Typography>
				)}

				<Box sx={{ width: '100%' }}>
					<ProgressMeter
						value={progress}
						containerStyles={{
							width: '100%',
							display: 'flex',
							marginTop: 4,
							justifyContent: 'center'
						}}
						sx={{
							borderRadius: '10px',
							width: '90%'
						}}
					/>
					<Typography
						variants="subtitle1"
						sx={{
							color: SoraTheme.palette.primary.indigo,
							fontWeight: '700',
							textAlign: 'center',
							marginTop: '15px'
						}}
					>
						{progress}% complete
					</Typography>
				</Box>
			</Box>

			<Box sx={{ flexGrow: 2 }}>
				<img
					src={OnboardingNavImage}
					alt="Sidebar"
					style={{
						width: '100%',
						height: '100%',
						zIndex: 5
					}}
				/>
			</Box>
		</Paper>
	);
}

export default OnboardingNavigation;
