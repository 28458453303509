import { useQuery } from 'react-query';

// Our Axios Instance
import axiosInstance from 'services/API/API';

// Our Endpoints
import { wealthboxGetContacts } from 'shared/api-urls';

// our constants
import { DEFAULT_STALE_TIME_FIVE_MINUTES } from 'shared/constants';

const calculateLastPage = (totalCount, pageSize) =>
	Math.ceil(totalCount / pageSize);

const getNextPage = (currentPage, lastPageOnServer) => {
	const nextPage = currentPage + 1;

	return nextPage > lastPageOnServer ? null : nextPage;
};

const DEFAULT_PAGE_SIZE = 50;

const DEFAULT_PAGE_PARAMS = {
	pageSize: DEFAULT_PAGE_SIZE,
	requiredRefresh: false,
	done: false
};

export const fetchWealthboxContactList = async (pageNumber, optionalParams) => {
	const smartParams = { ...DEFAULT_PAGE_PARAMS, ...optionalParams };

	const response = await axiosInstance.get(wealthboxGetContacts, {
		params: {
			pageNo: pageNumber + 1,
			...smartParams
		}
	});

	const { pageSize } = smartParams;

	const { data } = response.data;

	const { contactList, page, totalCount } = data; // assuming totalCount is available in data

	const lastPageOnServer = calculateLastPage(totalCount, pageSize);
	const nextPageOnServer = getNextPage(page, lastPageOnServer);

	return {
		contactList,
		page,
		totalCount,
		nextPageOnServer,
		lastPageOnServer,
		pageSize
	};
};

function useGetWealthboxContactList(
	QUERY_KEY,
	pageNumber,
	optionalParams = DEFAULT_PAGE_PARAMS
) {
	return useQuery(
		[QUERY_KEY, pageNumber],
		async () => {
			const smartParams = { ...DEFAULT_PAGE_PARAMS, ...optionalParams };
			const response = await axiosInstance.get(wealthboxGetContacts, {
				params: {
					pageNo: pageNumber + 1,
					...smartParams
				}
			});

			const { pageSize } = smartParams;

			const { data } = response.data;

			const { contactList, page, totalCount } = data; // assuming totalCount is available in data

			const lastPageOnServer = calculateLastPage(totalCount, pageSize);
			const nextPageOnServer = getNextPage(page, lastPageOnServer);

			return {
				contactList,
				page,
				totalCount,
				nextPageOnServer,
				lastPageOnServer,
				pageSize
			};
		},
		{
			staleTime: DEFAULT_STALE_TIME_FIVE_MINUTES,
			refetchOnWindowFocus: false,
			refetchOnMount: false,
			keepPreviousData: true // Add this option to fix the caching problem
		}
	);
}

export default useGetWealthboxContactList;
