import { Box, Tab, Tooltip, Typography } from '@mui/material';
import { useState } from 'react';

// Our Components
import a11yProps from 'components/Tabs/AccessibilityProps';

function DefaultTab({ disabled, label, index, onChange }) {
	const [open, setOpen] = useState(false);

	return (
		<Tooltip
			title="Coming soon..."
			open={open}
			onMouseOver={(e) => {
				if (disabled) {
					setOpen(true);
				}
			}}
			onMouseLeave={() => {
				setOpen(false);
			}}
		>
			<Box>
				<Tab
					disabled={disabled}
					onClick={() => onChange(index)}
					label={
						<Typography
							disabled
							variant="body1"
							sx={{
								textTransform: 'none',
								color: 'black'
							}}
						>
							{label}
						</Typography>
					}
					{...a11yProps(index)}
				/>
			</Box>
		</Tooltip>
	);
}

export default DefaultTab;
