// Our Components
import TextInput from 'components/Input/TextInput';

function EmailInput({ email, handleEmailChange }) {
	const isEmailInvalid =
		email === '' || !email.includes('.') || !email.includes('@');

	const helperText = isEmailInvalid
		? 'Please enter a valid email address'
		: '';

	return (
		<TextInput
			id="email"
			label="Email"
			type="email"
			onChange={handleEmailChange}
			inputProps={{
				'data-test': 'email-input'
			}}
			value={email}
			error={isEmailInvalid}
			helperText={helperText}
			sx={{
				marginTop: 4,
				marginBottom: 2
			}}
		/>
	);
}

export default EmailInput;
