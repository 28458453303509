import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// Our Components
import Loader from 'components/Loader';
import NavMenuAdvisor from 'components/NavMenu/NavMenuAdvisor';
import MobileNavMenuClient from 'components/NavMenu/MobileNavMenuClient';
import TopNavMenuAdvisor from 'components/NavMenu/TopNavMenuAdvisor';
import TopNavMenuClient from 'components/NavMenu/TopNavMenuClient';
import NavSubMenu from 'components/NavMenu/NavBarComponents/NavSubMenu';

// Our Hooks
import useGetProfile from 'hooks/useGetProfile';

function MobileNavMenu() {
	const SoraTheme = useTheme();

	const { isLoading, error, data, isSuccess } = useGetProfile();

	if (isLoading) return <Loader boxSX={{ justifyContent: 'center' }} />;

	if (error) return `An error has occurred: ${error.message}`;

	if (isSuccess) {
		const roleFromApi = data?.role;
		const isClient = roleFromApi === 'CLIENT';

		const {
			profilePicUrl: advisorProfilePic,
			companyName,
			firstName,
			lastName
		} = data;

		return (
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					height: '100vh',
					minHeight: '100vh',
					marginTop: 10,
					alignItems: 'center'
				}}
			>
				{/* TOP LIST */}
				<Box>
					{isClient ? (
						<MobileNavMenuClient />
					) : (
						<NavMenuAdvisor variant="mobile" />
					)}
				</Box>

				{/* BOTTOM LIST */}
				<Box>
					<NavSubMenu isClient={isClient} variant="mobile" />
					<Box
						sx={{
							display: 'flex',
							height: '100%'
						}}
					/>

					<Box sx={{ textAlign: 'center', marginBottom: 2 }}>
						{isClient ? (
							<TopNavMenuClient
								sx={{ color: SoraTheme.palette.primary.black }}
							/>
						) : (
							<TopNavMenuAdvisor
								firstName={firstName}
								lastName={lastName}
								companyName={companyName}
								profilePicUrl={advisorProfilePic}
								sx={{ color: SoraTheme.palette.primary.black }}
							/>
						)}
					</Box>
				</Box>
			</Box>
		);
	}
}

export default MobileNavMenu;
