import { useMemo } from 'react';

// constants
import { CREDITCARD, COLLECTION, LOAN, HELOC } from 'shared/constants';

// We have not created pages for these two Liabilities LOAN was confirmed to NOT mean personal.
const CURRENTLY_UNSUPPORTED_LIABILITIES = new Set([COLLECTION, LOAN, HELOC]);

// expects an an array of liabilities and the success state from a React Query Call
// This hook is used to populate the dynamic tabs
function useGetSupportedLiabilities(liabilities, isSuccess) {
	return useMemo(() => {
		// if no liabilities are found return an empty array.
		if (!liabilities) return [];

		let hasFoundCreditCard = false; // since creditCards are displayed on a single page we do not need a break down of each credit card
		// therefore once we find ONE instance of it. It is enough for us to know that credit cards were found so the option for credit card should be rendered in the nav.

		return liabilities.filter((liability) => {
			const { tradeLineType } = liability;
			const isTradeLineTypeCreditCard = tradeLineType === CREDITCARD;
			if (isTradeLineTypeCreditCard) {
				if (!hasFoundCreditCard) {
					hasFoundCreditCard = true; // mark that we found a credit card
					return true; // this enables that at least 1 credit card tradeline type goes through.
				}
				// if we hit this case it means that we hit a credit card already so we wont allow the next credit card items to go through
				return false;
			}

			const isTradeLineSupported =
				!CURRENTLY_UNSUPPORTED_LIABILITIES.has(tradeLineType);
			if (isTradeLineSupported) return true;
			return false;
		});
	}, [liabilities, isSuccess]);
}

export default useGetSupportedLiabilities;
