import { Grid, IconButton } from '@mui/material';
import { useState, useCallback, useEffect } from 'react';
import { useTheme } from '@emotion/react';

// MUI Icons
import LinkIcon from '@mui/icons-material/Link';

// Our Components
import Loader from 'components/Loader/index';
import Modal from 'components/Modal/Modal';
import { TertiaryButton } from 'components/Button/Button';

// Our Hooks
import useMutateGetMagicToken from 'hooks/passwordless/useMutateGetMagicToken';

// Our Utils
import copyToClipboard from 'shared/utils/events/copyToClipBoard';

// This component is assumed to be used in the DataGrid
function InviteButton({ rowData }) {
	const SoraTheme = useTheme();
	const { primary } = SoraTheme.palette;

	// Mutations
	const getMagicToken = useMutateGetMagicToken();

	const { isLoading } = getMagicToken;

	// Local state
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [uniqueUrl, setUniqueUrl] = useState(null);

	const selectedClientName = rowData?.name ?? '';
	const selectedClientEmail = rowData?.clientEmail ?? '';

	const closeModal = useCallback(() => {
		setIsModalOpen(false);
		setUniqueUrl(null);
	}, []);

	const handleModalToggle = (event) => {
		// Preventing the row click from firing
		event.stopPropagation();
		setIsModalOpen(true);
	};

	useEffect(() => {
		if (isModalOpen) {
			getMagicToken.mutate(
				{
					email: selectedClientEmail
				},
				{
					onSuccess: (response) => {
						const { data } = response;
						setUniqueUrl(data);
					}
				}
			);
		}
	}, [isModalOpen]);

	return (
		<>
			<IconButton onClick={handleModalToggle}>
				<LinkIcon
					sx={{
						color: primary.black,
						'&:hover': {
							color: primary.grey
						},
						'&:active': {
							color: primary.soraBlue
						}
					}}
				/>
			</IconButton>

			<Modal
				title={`Login link for ${selectedClientName}`}
				subtitle={`Share the link below with ${selectedClientName} for them to access their Sora account. Keep in mind this link can only be used once and will expire in 15 minutes.`}
				isOpen={isModalOpen}
				handleClose={closeModal}
			>
				<Grid container>
					<Grid item xs={12}>
						{!isLoading && (
							<TertiaryButton
								sx={{ marginTop: 4, marginRight: 4 }}
								onClick={() => {
									copyToClipboard(uniqueUrl);
									closeModal();
								}}
							>
								Copy to clipboard
							</TertiaryButton>
						)}

						{isLoading && <Loader />}
					</Grid>
				</Grid>
			</Modal>
		</>
	);
}

export default InviteButton;
