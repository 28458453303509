import { useMemo } from 'react';
import { Grid, Skeleton } from '@mui/material';
import { useNavigate } from 'react-router-dom';

// Our Components
import Alert from 'components/Alert/index';
import LoansTaskTable from 'components/Tasks/LoanTasksTable';
import TasksAccordion from 'components/Tasks/TasksAccordion';
import PageHeading from 'components/PageHeading/PageHeading';

// Our Hooks
import useGetAdvisorLoanRequests from 'hooks/newLoanInquiries/useGetAdvisorLoanRequests';
import useMutateAddClientInvitation from 'hooks/useMutateAddClientInvitation';
import useMutateReinviteClient from 'hooks/tasks/useMutateReinviteClient';
import useMutateStartOnboardingProcess from 'hooks/Onboarding/AutomaticOnboarding/useMutateStartOnboardingProcess';

// Our Routes
import { LOAN_INQUIRY_DETAILS_ROUTE } from 'routes/index';

function LoanPipeline() {
	const navigate = useNavigate();

	// Mutations
	const sendClientInvitation = useMutateAddClientInvitation();
	const sendClientReInvitation = useMutateReinviteClient();
	const startOnboardingProcess = useMutateStartOnboardingProcess();

	const { isError: isOnboardError, isSuccess: isOnboardSuccess } =
		startOnboardingProcess;

	// GET call on page load
	// Pulling in the Advisor's in progress loans to populate tables
	const {
		isLoading,
		isSuccess,
		data: advisorsLoanRequests
	} = useGetAdvisorLoanRequests();

	const handleSelected = (selectedClientData) => {
		const loanRequestId = selectedClientData?.loanRequestId;
		if (loanRequestId) {
			navigate(
				`${LOAN_INQUIRY_DETAILS_ROUTE}?loanRequestId=${loanRequestId}`
			);
		}
	};

	const completedLoanRequestList = useMemo(() => {
		if (isSuccess)
			return advisorsLoanRequests.filter(({ taskDone }) => taskDone);
		return [];
	}, [advisorsLoanRequests, isSuccess]);

	const activeLoanRequestList = useMemo(() => {
		if (isSuccess)
			return advisorsLoanRequests.filter(({ taskDone }) => !taskDone);
		return [];
	}, [advisorsLoanRequests, isSuccess]);

	const isSuccessAlert =
		sendClientInvitation.isSuccess ||
		sendClientReInvitation.isSuccess ||
		isOnboardSuccess;

	const isErrorAlert =
		sendClientInvitation.isError ||
		sendClientReInvitation.isError ||
		isOnboardError;

	if (isLoading) {
		return (
			<>
				<PageHeading title="Loan Pipeline" />
				<Skeleton
					sx={{ width: '100%', height: 400 }}
					variant="rectangular"
				/>
			</>
		);
	}

	if (isSuccess) {
		return (
			<>
				{isSuccessAlert && <Alert variant="success" />}
				{isErrorAlert && <Alert variant="error" />}

				<PageHeading title="Loan Pipeline" sx={{ marginBottom: 0 }} />

				<Grid item xs={12}>
					<TasksAccordion defaultExpandState taskHeading="Loans">
						{isSuccess && (
							<LoansTaskTable
								handleRowClick={handleSelected}
								taskList={activeLoanRequestList}
								noLoanTasks={
									advisorsLoanRequests?.length < 1 ?? true
								}
							/>
						)}
					</TasksAccordion>
				</Grid>

				<Grid item xs={12}>
					<TasksAccordion defaultExpandState taskHeading="Completed">
						{isLoading && (
							<Skeleton
								sx={{ width: '100%', height: 400 }}
								variant="rectangular"
							/>
						)}
						{isSuccess && (
							<LoansTaskTable
								handleRowClick={handleSelected}
								taskList={completedLoanRequestList}
							/>
						)}
					</TasksAccordion>
				</Grid>
			</>
		);
	}
}

export default LoanPipeline;
