import { useQuery } from 'react-query';

// Our Axios Instance
import axiosInstance from 'services/API/API';

// Our Query Keys
import { ADVISOR_LOAN_REQUESTS } from 'shared/query-keys';

// Our Endpoints
import { getAdvisorLoanRequests } from 'shared/api-urls';

// our constants
import { DEFAULT_STALE_TIME_FIVE_MINUTES } from 'shared/constants';

function useGetAdvisorLoanRequests() {
	return useQuery(
		ADVISOR_LOAN_REQUESTS,
		async () => {
			const response = await axiosInstance.get(getAdvisorLoanRequests);
			return response.data?.data;
		},
		{
			staleTime: DEFAULT_STALE_TIME_FIVE_MINUTES,
			refetchOnWindowFocus: false
		}
	);
}

export default useGetAdvisorLoanRequests;
