import axiosInstance from 'services/API/API';
import { useQuery } from 'react-query';
import { loanRequestUrl } from 'shared/api-urls';

// Query Key
import { LOAN_REQUEST } from 'shared/query-keys';

function useGetLoanRequestDetails(loanRequestId) {
	return useQuery(
		[LOAN_REQUEST, loanRequestId],
		async () => {
			const res = await axiosInstance.get(
				`${loanRequestUrl}/${loanRequestId}`
			);
			return res.data.data;
		},
		{
			refetchOnWindowFocus: false
		}
	);
}

export default useGetLoanRequestDetails;
