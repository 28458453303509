import { useEffect, useState, useMemo } from 'react';
import { Box, Typography, Grid } from '@mui/material';
import { useQueryClient } from 'react-query';

// Our components
import Alert from 'components/Alert';
import { PrimaryButton } from 'components/Button/Button';
import GetFullAddressPartialForm from 'components/Forms/addressRelated/GetFullAddressPartialForm';
import Input from 'components/Input/TextInput';
import CurrencyTextInput from 'components/Input/CurrencyTextInput';
import Loader from 'components/Loader';
import LoanInquirySuccessModal from 'components/Modal/LoanInquiryConfirmationModal';
import SoraTextField from 'components/Input/SoraTextField';
import RequiredFieldsTooltip from 'components/Forms/NewLoanForms/RequiredFieldsTooltip';
import { NUMBER, TEXT } from 'components/Input/Types';

// Our hooks 🪝
import useMutateCreateDeal from 'hooks/hubspot/useMutateCreateDeal';
import useMutateSaveNewLoanInquiry from 'hooks/newLoanInquiries/useMutateSaveNewLoanInquiry';
import useMutateUpdateLoanInquiry from 'hooks/newLoanInquiries/useMutateUpdatedLoanInquiry';
import useMutateSendGenericEmail from 'hooks/emails/useMutateSendGenericEmail';

// Our Query keys
import { ERROR_MESSAGE_DATA } from 'shared/query-keys';

// Utils
import { clearFormValues } from 'shared/utils';
import createDefaultCloseDate from 'shared/utils/hubspot/createDefaultCloseDate';
import formatCollection from 'shared/utils/formatting/currency/formatCollection';

// Constants
import { FARM, NEW_LOAN_OFFER_TYPE } from 'shared/constants';

function FarmLoanForm({
	isClientNameProvided,
	advisorEmail,
	advisorName,
	advisorCompany,
	advisorWebsite,
	acresValue,
	additionalNotesValue,
	addressLine1Value,
	addressLine2Value,
	cityValue,
	clientId,
	creditScoreValue,
	disableAutoComplete,
	emailAddressValue,
	firstName,
	landUseValue,
	lastName,
	loanAmountValue,
	loanRequestId,
	postSuccessEvent,
	setDisableAutoComplete,
	stateValue,
	zipCodeValue,
	loanType
}) {
	const queryClient = useQueryClient();
	const updateLoanInquiry = useMutateUpdateLoanInquiry();

	// API Calls
	const saveLoanInquiry = useMutateSaveNewLoanInquiry();
	const { isLoading: savingLoanInquiry } = saveLoanInquiry;

	// Mutations
	const sendGenericEmail = useMutateSendGenericEmail();
	const createDeal = useMutateCreateDeal();

	// Build full name
	const fullNameValue = `${firstName} ${lastName}`;

	const [creditScore, setCreditScore] = useState(creditScoreValue || '');
	const [loanAmount, setLoanAmount] = useState(loanAmountValue || '');
	const [acres, setAcres] = useState(acresValue || '');
	const [landUse, setLandUse] = useState(landUseValue || '');

	// Address related fields
	const [addressLine1, setAddressLine1] = useState(addressLine1Value || '');
	const [addressLine2, setAddressLine2] = useState(addressLine2Value || '');
	const [city, setCity] = useState(cityValue || '');
	const [state, setState] = useState(stateValue || '');
	const [zipCode, setZipCode] = useState(zipCodeValue || '');

	const [emailAddress, setEmailAddress] = useState(emailAddressValue || '');
	const [message, setMessage] = useState('');
	const [additionalNotes, setAdditionalNotes] = useState(
		additionalNotesValue || ''
	);
	const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);

	const isFormReady = useMemo(
		() => isClientNameProvided,
		[isClientNameProvided]
	);

	useEffect(
		() =>
			setMessage(`<pre>
			The advisor ${advisorName} has request a Farm Loan for one of their clients. 
			
			Advisor making request:
			Advisor's name: ${advisorName}
			Advisor's email: ${advisorEmail}
			
			They made a request for Farm Loan for the following CLIENT:

			Client's name: ${fullNameValue}
			Client's Email address: ${emailAddress}
			Client's credit score: ${creditScore}
			Requested loan amount: ${loanAmount}
			Address of property: ${addressLine1} ${addressLine2} ${city}, ${state} ${zipCode}
			# of acres: ${acres}
			Land use: ${landUse}

			Additional Notes:

			${additionalNotes}</pre>
		`),
		[
			emailAddress,
			creditScore,
			loanAmount,
			addressLine1,
			addressLine2,
			city,
			state,
			zipCode,
			acres,
			landUse,
			additionalNotes
		]
	);

	const sendFormData = () => {
		const sendToArray = ['lending@sorafinance.com'];
		const subject = `Farm Loan Request - ${firstName} ${lastName}`;

		sendGenericEmail.mutate(
			{ firstName, lastName, subject, message, sendToArray },
			{
				onSuccess: () => {
					clearFormValues([
						setCreditScore,
						setLoanAmount,
						setAcres,
						setLandUse,
						setAddressLine1,
						setAddressLine2,
						setCity,
						setState,
						setZipCode,
						setEmailAddress,
						setMessage,
						setAdditionalNotes
					]);
				}
			}
		);
	};

	const submitNewLoanInquiry = () => {
		const newLoanInquiryData = {
			acres,
			additionalNotes,
			addressLine1,
			addressLine2,
			city,
			clientId,
			creditScore,
			emailAddress,
			firstName,
			landUse,
			lastName,
			loanAmount,
			offerType: NEW_LOAN_OFFER_TYPE,
			state,
			tradelineType: FARM,
			zipCode
		};

		// Call mutation to update user's data
		saveLoanInquiry.mutate(newLoanInquiryData, {
			onSuccess: () => {
				setIsSuccessModalOpen(true);
			},
			onError: () => {
				// Set error message
				queryClient.setQueryData(
					ERROR_MESSAGE_DATA,
					'There was an error making your loan request.'
				);
			}
		});
	};

	const updateLoanRequest = () => {
		const updatedLoanInquiryData = {
			acres,
			additionalNotes,
			addressLine1,
			addressLine2,
			city,
			clientId,
			creditScore,
			emailAddress,
			firstName,
			landUse,
			lastName,
			loanAmount,
			state,
			tradelineType: FARM,
			offerType: NEW_LOAN_OFFER_TYPE,
			zipCode
		};

		// Call mutation to update user's data
		updateLoanInquiry.mutate(
			{ loanRequestId, updatedLoanInquiryData },
			{
				onSuccess: () => {
					clearFormValues([
						setCreditScore,
						setLoanAmount,
						setAcres,
						setLandUse,
						setAddressLine1,
						setAddressLine2,
						setCity,
						setState,
						setZipCode,
						setEmailAddress,
						setMessage,
						setAdditionalNotes
					]);

					if (postSuccessEvent) {
						postSuccessEvent();
					}
				},
				onError: () => {
					// Set error message
					queryClient.setQueryData(
						ERROR_MESSAGE_DATA,
						'There was an error making your loan request.'
					);
				}
			}
		);
	};

	const { isLoading } = sendFormData;

	const currentURL = window.location.href;
	const atLoanRequestDetailsUrl = currentURL.includes('loan-request-details');

	const submitForm = (event) => {
		event.preventDefault();

		const closeDate = createDefaultCloseDate();
		const valuesToFormat = [
			{
				type: 'currency',
				value: loanAmount,
				label: 'Desired loan amount:'
			}
		];

		const formattedCollection = formatCollection(valuesToFormat);
		const advisorNotes = `Advisor's name: ${advisorName}
				Advisor's email: ${advisorEmail}
				Advisor's company: ${advisorCompany}
				Credit Score: ${creditScore}
				${formattedCollection}
				State: ${state}
				Number of acres: ${acres}
				What will the land be used for?: ${landUse}
				Address: ${addressLine1} ${addressLine2} ${city}, ${state} ${zipCode}
				------------------------------------------------------------------------------------------------
				Advisor's notes: \n${additionalNotes}`;

		createDeal.mutate({
			advisorInfo: {
				email: advisorEmail,
				companyName: advisorCompany,
				companyDomain: advisorWebsite
			},
			properties: {
				amount: loanAmount,
				dealname: `${advisorName} / ${fullNameValue}`,
				dealtype: loanType,
				borrower: fullNameValue,
				closedate: closeDate
			},
			noteInfo: {
				hs_note_body: advisorNotes
			}
		});

		// If we're at /loan-inquiry, perform the following
		if (!atLoanRequestDetailsUrl) {
			submitNewLoanInquiry();
			sendFormData();
		}
		// Else if we're at /loan-inquiry-details, perform the following
		else if (atLoanRequestDetailsUrl) {
			// Call new mutations here patching the new loan inquiry
			updateLoanRequest(loanRequestId);
		}
	};

	// Handlers for mutation
	const { isError: sendFormDataError } = sendFormData;

	return (
		<Box
			component="form"
			noValidate
			autoComplete="off"
			sx={{ height: '100%', width: '100%' }}
		>
			{!atLoanRequestDetailsUrl && (
				<Grid item xs={12}>
					<Typography
						variant="h2Gascogne"
						gutterBottom
						component="div"
						sx={{
							marginTop: 6
						}}
					>
						Farm Loan
					</Typography>

					<Typography
						variant="body2"
						gutterBottom
						component="div"
						sx={{
							marginBottom: 4
						}}
					>
						Fill in the information below to submit a loan request
						to Sora. You will be able to complete and review your
						loan request in the dashboard task bar.
					</Typography>
				</Grid>
			)}

			{isLoading || (savingLoanInquiry && <Loader />)}
			{!isLoading && !savingLoanInquiry && (
				<>
					{sendFormDataError && <Alert variant="error" />}
					{/* Modal shown on successful submission of  */}
					<LoanInquirySuccessModal isOpen={isSuccessModalOpen} />
					<Grid container spacing={2}>
						<Grid item xs={12} md={6}>
							{/* Credit Score */}
							<Input
								label="Credit score"
								value={creditScore}
								onChange={setCreditScore}
								type={NUMBER}
								inputProps={{
									'data-test': 'creditScore'
								}}
							/>
						</Grid>

						<Grid item xs={12} md={6}>
							{/* Desired Loan Amount */}
							<CurrencyTextInput
								label="Desired loan amount"
								value={loanAmount}
								onChange={setLoanAmount}
								type={NUMBER}
								inputProps={{
									'data-test': 'loanAmount'
								}}
							/>
						</Grid>
						<Grid item xs={12} md={6}>
							{/* Acres */}
							<Input
								label="Number of acres"
								subLabel="Approximate number of acres of property"
								value={acres}
								onChange={setAcres}
								type={NUMBER}
								inputProps={{
									'data-test': 'numAcres'
								}}
							/>
						</Grid>

						<Grid item xs={12} md={6}>
							{/* Land use input */}
							<Input
								label="What will the land be used for?"
								value={landUse}
								onChange={setLandUse}
								type={TEXT}
								inputProps={{
									'data-test': 'landUse'
								}}
							/>
						</Grid>

						<GetFullAddressPartialForm
							addressLine1={addressLine1}
							addressLine2={addressLine2}
							city={city}
							state={state}
							zipCode={zipCode}
							setAddressLine1={setAddressLine1}
							setAddressLine2={setAddressLine2}
							setCity={setCity}
							setState={setState}
							setZipCode={setZipCode}
							disableAutoComplete={disableAutoComplete}
							setDisableAutoComplete={setDisableAutoComplete}
						/>
						<Grid item xs={12}>
							<SoraTextField
								label="Additional Notes"
								value={additionalNotes}
								onChange={setAdditionalNotes}
							/>
						</Grid>
					</Grid>
				</>
			)}

			<Grid item xs={12} marginTop={4} marginBottom={6}>
				{!isFormReady && (
					<RequiredFieldsTooltip>
						<span>
							<PrimaryButton
								disabled={!isFormReady}
								onClick={submitForm}
							>
								Submit
							</PrimaryButton>
						</span>
					</RequiredFieldsTooltip>
				)}
				{isFormReady && (
					<PrimaryButton onClick={submitForm}>Submit</PrimaryButton>
				)}
			</Grid>
		</Box>
	);
}

export default FarmLoanForm;
