import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import {
	Typography,
	List,
	ListItem,
	ListItemButton,
	ListItemText
} from '@mui/material';

// Our routes
import { CLIENT_SORAWALLET_ROUTE, SELECT_NEW_LOAN_ROUTE } from 'routes';

function MobileNavMenuClient() {
	const SoraTheme = useTheme();
	const navigate = useNavigate();

	return (
		<List>
			{/* Dashboard link */}
			<ListItem
				disablePadding
				onClick={() => navigate(CLIENT_SORAWALLET_ROUTE)}
			>
				<ListItemButton sx={{ textAlign: 'center' }}>
					<ListItemText>
						<Typography
							variant="h2Gascogne"
							component="span"
							sx={{ color: SoraTheme.palette.primary.indigo }}
						>
							Wallet
						</Typography>
					</ListItemText>
				</ListItemButton>
			</ListItem>

			<ListItem
				disablePadding
				onClick={() => navigate(SELECT_NEW_LOAN_ROUTE)}
			>
				<ListItemButton sx={{ textAlign: 'center' }}>
					<ListItemText>
						<Typography
							variant="h2Gascogne"
							component="span"
							sx={{ color: SoraTheme.palette.primary.indigo }}
						>
							New Loan
						</Typography>
					</ListItemText>
				</ListItemButton>
			</ListItem>
		</List>
	);
}

export default MobileNavMenuClient;
