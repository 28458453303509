import { Box, Typography } from '@mui/material';
import { useTheme } from '@emotion/react';
import { v4 as uuidv4 } from 'uuid';

// Local Constants
const LIQUIDITY_PILL_OPTIONS = [
	'Sell Assets',
	'Interest Only Loan',
	'Amortized Loan'
];

const LIQUIDITY_PILL_OPTIONS_DEFAULT_STATE = {
	'Interest Only Loan': {
		interestRate: '4.25',
		loanTerm: '5'
	},
	'Amortized Loan': {
		interestRate: '7.5',
		loanTerm: '5'
	}
};

function LiquidityOptions({
	optionTextSx,
	selectedLiquidityOptions,
	setSelectedLiquidityOptions,
	isSellAssetsSelected,
	setIsSellAssetsSelected
}) {
	const SoraTheme = useTheme();

	const { primary } = SoraTheme.palette;

	return (
		<Box sx={{ display: 'flex', gap: 2, marginTop: 1, marginBottom: 4 }}>
			{LIQUIDITY_PILL_OPTIONS.map((option) => {
				const isSellAssets = option === 'Sell Assets';

				const isSelected = isSellAssets
					? isSellAssetsSelected
					: selectedLiquidityOptions
							.map(({ type }) => type)
							.includes(option);

				return (
					<Box
						key={uuidv4()}
						sx={{
							border: '1px solid black',
							padding: 2,
							paddingTop: 1,
							paddingBottom: 1,
							borderRadius: 2,
							'&:hover': {
								cursor: 'pointer'
							},
							backgroundColor: isSelected
								? primary.indigo
								: 'white',
							color: isSelected ? 'white' : 'black',
							userSelect: 'none'
						}}
						onClick={() => {
							if (isSellAssets) {
								setIsSellAssetsSelected(!isSellAssetsSelected);
								return;
							}
							if (isSelected) {
								const filteredSelectedLiquidityOptions =
									selectedLiquidityOptions.filter(
										({ type }) => type !== option
									);

								setSelectedLiquidityOptions(
									filteredSelectedLiquidityOptions
								);
								return;
							}

							const defaultState =
								LIQUIDITY_PILL_OPTIONS_DEFAULT_STATE[option];

							setSelectedLiquidityOptions([
								...selectedLiquidityOptions,
								{
									type: option,
									...defaultState
								}
							]);
						}}
					>
						<Typography variant="body2" sx={optionTextSx ?? {}}>
							{option}
						</Typography>
					</Box>
				);
			})}
		</Box>
	);
}

export default LiquidityOptions;
