import { Box, Typography } from '@mui/material';

// Our Components
import AnalyticsPresentationTable from 'components/Table/AnalyticsPresentationTable';
import CurrencyTextInput from 'components/Input/CurrencyTextInput';
import RateInput from 'components/Input/RateInput';
import TextInput from 'components/Input/TextInput';

// our Utils
import { dollarFormatter, roundToDecimal } from 'shared/utils';
import sentenceCapitalize from 'shared/utils/formatting/sentenceCapitalize';

// Input Types
import { TEXT } from 'components/Input/Types';

// Constants
const defaultTableStyles = {
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
	borderBottom: '0.25px black solid',
	padding: 2
};

function formatValue(value, valueKey) {
	if (valueKey === 'balance') {
		return dollarFormatter.format(value);
	}
	if (valueKey === 'rate') {
		return `${roundToDecimal(value, 2)}%`;
	}
	if (valueKey === 'remainingYears') {
		return roundToDecimal(value / 12, 2);
	}
	return value;
}

const defaultProps = {
	label: '',
	subLabel: ''
};

function AnalyticsTable({
	analyticsOffer,
	bestOfferBalance,
	setBestOfferBalance,
	bestOfferInterestRate,
	setBestOfferInterestRate,
	bestOfferTenure,
	setBestOfferTenure
}) {
	const { currentLoan } = analyticsOffer;

	const { balance, interestRate, remainingMonth } = currentLoan;

	const currentLoanValues = {
		balance,
		rate: interestRate,
		remainingYears: remainingMonth
	};

	const tableData = ['balance', 'rate', 'remainingYears'].map(
		(valueKey, i) => {
			const isLast = i === 2;
			const borderBottom = isLast ? 'none' : '0.25px black solid';
			const currentLoanValue = currentLoanValues[valueKey];

			const formattedKeyName =
				valueKey === 'remainingYears'
					? 'Remaining Years'
					: sentenceCapitalize(valueKey);

			const formattedCurrentLoanValue = formatValue(
				currentLoanValue,
				valueKey
			);

			const ComponentKeyMapping = {
				balance: {
					Component: CurrencyTextInput,
					props: {
						...defaultProps,
						value: bestOfferBalance,
						onChange: setBestOfferBalance
					}
				},
				rate: {
					Component: RateInput,
					props: {
						...defaultProps,
						rate: bestOfferInterestRate,
						setRate: setBestOfferInterestRate
					}
				},
				remainingYears: {
					Component: TextInput,
					props: {
						...defaultProps,
						value: bestOfferTenure,
						onChange: setBestOfferTenure,
						type: TEXT
					}
				}
			};

			const { Component: BestOfferComponent, props } =
				ComponentKeyMapping[valueKey];

			return (
				<Box
					key={valueKey}
					sx={{ ...defaultTableStyles, borderBottom }}
				>
					<Box sx={{ width: 110 }}>
						<Typography variant="body2">
							{formattedKeyName}
						</Typography>
					</Box>
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'flex-start',
							minWidth: 86
						}}
					>
						<Typography variant="body2">
							{formattedCurrentLoanValue}
						</Typography>
					</Box>
					<Box sx={{ width: 150 }}>
						<BestOfferComponent {...props} />
					</Box>
				</Box>
			);
		}
	);

	return <AnalyticsPresentationTable>{tableData}</AnalyticsPresentationTable>;
}

export default AnalyticsTable;
