import axiosInstance from 'services/API/API';
import { useQuery, useQueryClient } from 'react-query';

import { walletOverviewUrl } from 'shared/api-urls';
import { CLIENT_OVERVIEW_DATA } from 'shared/query-keys';

const FIVE_MINUTES = 1000 * 60 * 5;

function useGetWalletOverviewAsAdvisor(clientId, enabled, select) {
	const queryClient = useQueryClient();

	return useQuery(
		[CLIENT_OVERVIEW_DATA, clientId],
		async () => {
			const response = await axiosInstance.get(
				`${walletOverviewUrl}/${clientId}`
			);
			const clientWalletOverviewData = response.data.data;
			return clientWalletOverviewData;
		},
		{
			staleTime: FIVE_MINUTES,
			onSuccess: (clientOverviewData) => {
				// keeping to not break existing code
				queryClient.setQueryData(
					CLIENT_OVERVIEW_DATA,
					clientOverviewData
				);

				return clientOverviewData;
			},
			select: (clientOverviewData) => {
				if (select) return select(clientOverviewData);

				return clientOverviewData;
			},

			enabled
		}
	);
}

export default useGetWalletOverviewAsAdvisor;
