import { Typography } from '@mui/material';
import PropTypes from 'prop-types';

// Our Components
import { SecondaryButton } from 'components/Button/Button';

function DragFileMessage({ isDragActive, open, files }) {
	if (isDragActive)
		return <Typography variant="body1">Yay I see a file!</Typography>;

	if (!files.length)
		return (
			<SecondaryButton onClick={() => open()}>
				Choose file
			</SecondaryButton>
		);

	return '';
}

DragFileMessage.propTypes = {
	isDragActive: PropTypes.bool.isRequired,
	open: PropTypes.func.isRequired
};

export default DragFileMessage;
