/**
 * @param {number} index
 * @returns {object}
 * Accessibility Prop Documentation -> https://mui.com/material-ui/react-tabs/#basic-tabs
 * Under Basic Tabs
 */

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`
	};
}

export default a11yProps;
