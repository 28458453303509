import { Helmet } from 'react-helmet-async';
import { Grid, Typography, Zoom } from '@mui/material';
import { useState, useEffect, useMemo, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// Our Components
import AnalyticsClientList from 'components/Analytics/AnalyticsClientList';
import AssumptionsModal from 'components/Modal/analytics/AssumptionsModal';
import AnalyticsTabs from 'components/Analytics/AnalyticsTabs';
import { TextButton } from 'components/Button/Button';

// Our Hooks
import useGetClients from 'hooks/useGetClients';
import useSmartAnalyticsTabNavigation from 'hooks/analytics/smartTabNavigation/useSmartAnalyticsNavigation';

// Context
import AnalyticsContext from 'context/AnalyticsContext';

// Our Routes
import { ADVISOR_ANALYTICS_INTRO } from 'routes';

// Our Utils
import { dataIsValid } from 'shared/utils';

function Analytics() {
	const smartNavigate = useSmartAnalyticsTabNavigation();
	const navigate = useNavigate();

	const { state } = useLocation();

	const prePopulatedClientData = state?.clientSelected;
	const preSelectedTab = state?.tabIndex;

	// Currently selected Tab
	const [tabPanelSelected, setTabPanelSelected] = useState(
		preSelectedTab ?? 0
	);

	// Client Search
	const [selectedClient, setSelectedClient] = useState(
		prePopulatedClientData ?? null
	);

	const [clientSearchName, setClientSearchName] = useState(
		prePopulatedClientData?.name ?? ''
	);

	// modal error
	const [modalError, setModalError] = useState('');

	const { isLoading, isSuccess } = useGetClients();

	// Modal
	const [isModalOpen, setIsModalOpen] = useState(
		!!prePopulatedClientData ?? false
	);

	const handleClientSelect = useCallback(
		(currentSelectedClient, updateSelectedClient) => {
			updateSelectedClient(currentSelectedClient);
			setSelectedClient(currentSelectedClient);
			setClientSearchName(currentSelectedClient?.name ?? '');

			smartNavigate(
				{
					tabIndex: tabPanelSelected,
					clientSelected: currentSelectedClient
				},
				true
			);

			if (currentSelectedClient === null) {
				navigate(ADVISOR_ANALYTICS_INTRO);
			}

			if (currentSelectedClient !== null) {
				setIsModalOpen(true);
			}
		},
		[]
	);

	const handleClientSearchName = (incomingClientName) => {
		if (incomingClientName === '') {
			setSelectedClient(null);
			navigate(ADVISOR_ANALYTICS_INTRO);
		}
		setClientSearchName(incomingClientName);
	};

	const isClientSelected = useMemo(
		() => dataIsValid(selectedClient),
		[selectedClient]
	);

	const handleTabSelection = useCallback(
		(value) => {
			smartNavigate({ tabIndex: value, clientSelected: selectedClient });
			setTabPanelSelected(value);
		},
		[selectedClient]
	);

	useEffect(() => {
		const preSelectedClientIsMissing = !dataIsValid(prePopulatedClientData);
		if (preSelectedClientIsMissing) {
			navigate(ADVISOR_ANALYTICS_INTRO);
		}
	}, []);

	// Inside your component
	const contextValue = useMemo(
		() => ({
			setIsModalOpen,
			setModalError,
			modalError
		}),
		[setIsModalOpen, setModalError, modalError]
	);

	if (isLoading)
		return (
			<>
				<Helmet>
					<title>Analytics</title>
				</Helmet>
				<Grid container item>
					<Typography variant="h1Gascogne" component="h1">
						Analytics
					</Typography>
				</Grid>
			</>
		);

	if (isSuccess) {
		return (
			<>
				<Helmet>
					<title>Analytics</title>
				</Helmet>

				<Grid container item sx={{ alignItems: 'center' }}>
					<Typography
						variant="h1Gascogne"
						component="h1"
						sx={{ marginRight: 2 }}
					>
						Analytics
					</Typography>

					<Grid
						item
						xs={4}
						sm={6}
						md={4}
						lg={3}
						sx={{ marginRight: 1 }}
					>
						<AnalyticsClientList
							selectedClient={selectedClient}
							handleClientSelect={handleClientSelect}
							clientSearchName={clientSearchName}
							handleClientSearchName={handleClientSearchName}
						/>
						<AnalyticsContext.Provider value={contextValue}>
							<AssumptionsModal
								key={selectedClient?.id}
								selectedClient={selectedClient}
								isModalOpen={isModalOpen}
								setIsModalOpen={setIsModalOpen}
							/>
						</AnalyticsContext.Provider>
					</Grid>

					{isClientSelected && (
						<Zoom in style={{ transitionDelay: '100ms' }}>
							<Grid item xs={4} sm={6} md={4} lg={2}>
								<TextButton
									onClick={() => {
										setIsModalOpen(true);
									}}
								>
									Update assumptions
								</TextButton>
							</Grid>
						</Zoom>
					)}

					<Grid item xs={12} sx={{ marginTop: 2 }}>
						<AnalyticsContext.Provider value={contextValue}>
							<AnalyticsTabs
								tabPanelSelected={tabPanelSelected}
								setTabPanelSelected={handleTabSelection}
							/>
						</AnalyticsContext.Provider>
					</Grid>
				</Grid>
			</>
		);
	}
}

export default Analytics;
