import { Grid, Box } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { useTheme } from '@emotion/react';

// Our Components
import LoginForm from 'components/LoginForm/LoginForm';
import LoginArtAndSoraLogo from 'components/LoginForm/LoginArtAndSoraLogo';
import MobileLoginFallback from 'components/LoginForm/MobileLoginFallback';
import Footer from 'components/Footer/Footer';

function LoginPage() {
	const SoraTheme = useTheme();
	return (
		<>
			<Helmet>
				<title>Login</title>
			</Helmet>
			<Box
				sx={{
					display: {
						xs: 'flex',
						sm: 'none'
					},
					flexDirection: 'column',
					alignItems: 'center',
					justifyContent: 'center',
					height: '90vh',
					background: SoraTheme.palette.gradient.gradient1
				}}
			>
				<MobileLoginFallback />
			</Box>
			<Box
				sx={{
					display: {
						xs: 'flex',
						sm: 'none'
					},
					height: '8vh'
				}}
			>
				<Footer />
			</Box>
			<Grid
				container
				sx={{
					justifyContent: 'space-between',
					height: '100vh',
					display: { xs: 'none', sm: 'flex' }
				}}
			>
				<Grid
					item
					xs={0}
					md={6}
					sx={{
						display: {
							xs: 'none',
							md: 'block'
						},
						height: '100vh',
						overflow: 'hidden'
					}}
				>
					<LoginArtAndSoraLogo />
				</Grid>

				<Grid
					container
					item
					xs={12}
					md={5}
					sx={{
						justifyContent: 'center',
						alignItems: 'center',
						height: '100vh',
						display: {
							xs: 'none',
							sm: 'flex'
						}
					}}
				>
					<Grid item xs={9} md={10}>
						<LoginForm />
					</Grid>
				</Grid>
			</Grid>
		</>
	);
}

export default LoginPage;
