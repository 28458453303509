function getNonNullValues(objectToCleanse) {
	const objectWithoutNullValues = {};
	// works only with Objects that are not nested. i.e. must be Flat
	// eslint-disable-next-line array-callback-return
	Object.keys(objectToCleanse).map((currentKey) => {
		const key = currentKey;

		const currentValue = objectToCleanse[key];
		const isValid = currentValue !== null && currentValue !== undefined;

		if (isValid) objectWithoutNullValues[key] = currentValue;
	});

	// objectWithoutNullValues is the same as the objectToCleanse as in key value pairs
	// except it is filtered. No values of null will be present
	return objectWithoutNullValues;
}

export default getNonNullValues;
