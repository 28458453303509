import { CSVLink } from 'react-csv';
import SaveAltSharpIcon from '@mui/icons-material/SaveAltSharp';
import { Typography } from '@mui/material';

// Our Components
import { TextButton } from 'components/Button/Button';

function ExportCsvButton({ data, fileName }) {
	return (
		<CSVLink data={data} filename={fileName}>
			<TextButton
				sx={{ color: 'black', marginRight: 2 }}
				startIcon={<SaveAltSharpIcon />}
			>
				<Typography
					variant="body2"
					sx={{ fontSize: '0.8rem', fontWeight: 400 }}
				>
					Export
				</Typography>
			</TextButton>
		</CSVLink>
	);
}

export default ExportCsvButton;
