import { useLocation, useParams } from 'react-router-dom';
import { useMutation, useQueryClient } from 'react-query';

// Our Axios Instance
import axiosInstance from 'services/API/API';

// Our Endpoints
import { commercialLoanEndpoint } from 'shared/api-urls';

// Our Query keys
import {
	CLIENT_OVERVIEW_DATA,
	WALLET_OVERVIEW_DATA,
	USER_LIABILITIES
} from 'shared/query-keys';

function useMutateAddCommercialLiability(handleClose) {
	const queryClient = useQueryClient();
	const { pathname } = useLocation();
	const { tradeLineId } = useParams();

	const IS_ADD_NEW = pathname.includes('add-new');

	return useMutation(
		async (commercialLiabilityPayload) => {
			if (IS_ADD_NEW) {
				const response = await axiosInstance.post(
					commercialLoanEndpoint,
					commercialLiabilityPayload
				);

				const { data } = response;

				await queryClient.invalidateQueries(USER_LIABILITIES);
				await queryClient.invalidateQueries(WALLET_OVERVIEW_DATA, {
					refetchInactive: true
				});
				await queryClient.invalidateQueries(CLIENT_OVERVIEW_DATA, {
					refetchInactive: true
				});

				return data;
			}

			// IS NOT NEW soooooo update it :D
			const response = await axiosInstance.patch(commercialLoanEndpoint, {
				...commercialLiabilityPayload,
				id: tradeLineId
			});

			await queryClient.invalidateQueries(USER_LIABILITIES);
			await queryClient.invalidateQueries(WALLET_OVERVIEW_DATA, {
				refetchInactive: true
			});

			const { data } = response;

			return data;
		},
		{
			onError: () => {
				handleClose(false);
			}
		}
	);
}

export default useMutateAddCommercialLiability;
