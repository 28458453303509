import { Box } from '@mui/material';

// Our PropTypes
import CommonPropTypes from 'shared/prop-types';

function TableRowItem({ children, sx }) {
	return (
		<Box
			sx={{
				width: '100%',
				display: 'flex',
				justifyContent: 'space-between',
				borderBottom: '2px solid #C7C7C7',
				paddingTop: 2,
				paddingBottom: 2,
				...sx
			}}
		>
			{children}
		</Box>
	);
}

TableRowItem.propTypes = {
	sx: CommonPropTypes.sx
};
TableRowItem.defaultProps = {
	sx: {}
};

export default TableRowItem;
