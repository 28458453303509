import { useTheme } from '@emotion/react';
import { Typography } from '@mui/material';

// Our Icons
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import BusinessIcon from '@mui/icons-material/Business';

function ProfilePicturePlaceHolder({ withDraggable, isCorporate }) {
	const SoraTheme = useTheme();

	const { primary } = SoraTheme.palette;

	const dragMessage = isCorporate
		? 'Drag company logo here'
		: 'Drag profile photo here';
	return (
		<>
			{!isCorporate && (
				<AccountCircleIcon
					sx={{
						width: 200,
						height: 200,
						color: primary.lightGrey
					}}
				/>
			)}
			{isCorporate && (
				<BusinessIcon
					sx={{
						width: 200,
						height: 200,
						color: primary.lightGrey
					}}
				/>
			)}
			{withDraggable && (
				<Typography variant="subtitle1">{dragMessage}</Typography>
			)}
		</>
	);
}

export default ProfilePicturePlaceHolder;
