// Our axios Instance
import axiosInstance from 'services/API/API';

import { useQuery, useQueryClient } from 'react-query';
import { verifyClientInvitation } from 'shared/api-urls';
import { CLIENTINVITECODE, VERIFIEDCLIENTINVITEDATA } from 'shared/query-keys';

function ClientInvitationVerification() {
	const queryClient = useQueryClient();
	// Get client invite code out of react query
	const clientInviteCode = queryClient.getQueryData(CLIENTINVITECODE);

	// Construct Url for axios get call below
	const urlWithInviteCode = `${verifyClientInvitation + clientInviteCode}`;

	return useQuery(VERIFIEDCLIENTINVITEDATA, () =>
		axiosInstance
			.get(urlWithInviteCode, { withCredentials: true })
			.then((res) => res.data.data)
	);
}

export default ClientInvitationVerification;
