import { Grid } from '@mui/material';
import { useMemo } from 'react';

// Our Components
import Dropdown from 'components/Dropdown/Dropdown';

// Our Constants
import { LOAN_TENURE_OPTIONS } from 'shared/constants';

const DEFAULT_FIXED_LOAN_30_YEAR_TERM = LOAN_TENURE_OPTIONS.Fixed[3];
const DEFAULT_ARM_LOAN_5_YEAR_TERM = LOAN_TENURE_OPTIONS.ARM[1];
const SUPPORTED_LOAN_TYPES = ['Fixed', 'ARM'];

function HomeLoanType({ loanInfo, setLoanInfo }) {
	const loanTerm = useMemo(() => loanInfo.loanTerm, [loanInfo.loanTerm]);
	const { loanType } = loanInfo;

	const handleLoanTermChange = (incomingValue) => {
		setLoanInfo({ ...loanInfo, loanTerm: incomingValue });
	};

	const handleLoanTypeChange = (incomingValue) => {
		const isChangingToArmLoan = incomingValue === 'ARM';
		const changeCurrentlySelectedLoan = {};
		if (isChangingToArmLoan) {
			changeCurrentlySelectedLoan.loanTerm = DEFAULT_ARM_LOAN_5_YEAR_TERM;
		} else {
			changeCurrentlySelectedLoan.loanTerm =
				DEFAULT_FIXED_LOAN_30_YEAR_TERM;
		}
		setLoanInfo({
			...loanInfo,
			...changeCurrentlySelectedLoan,
			loanType: incomingValue
		});
	};

	const supportedLoanTerms = useMemo(
		() => LOAN_TENURE_OPTIONS[loanType],
		[loanInfo]
	);

	return (
		<>
			<Grid item xs={6}>
				<Dropdown
					items={supportedLoanTerms}
					selected={loanTerm}
					onChange={handleLoanTermChange}
					variant="outlined"
					label="Type of Loan"
				/>
			</Grid>
			<Grid item xs={6} marginBottom={4}>
				<Dropdown
					items={SUPPORTED_LOAN_TYPES}
					selected={loanType}
					onChange={handleLoanTypeChange}
					variant="outlined"
					labelSx={{ marginBottom: 1, height: 24 }}
				/>
			</Grid>
		</>
	);
}

export default HomeLoanType;
