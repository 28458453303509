import {
	BarChart,
	Bar,
	Cell,
	LabelList,
	YAxis,
	XAxis,
	ResponsiveContainer,
	Rectangle
} from 'recharts';
import { v4 as uuidv4 } from 'uuid';
import { Typography } from '@mui/material';
// Our Util
import { dollarFormatter } from 'shared/utils';

// Custom shape component for the bar
function CustomBar(props) {
	const { fill, x, y, width, height } = props;

	if (fill.startsWith('linear-gradient')) {
		return (
			<defs>
				<linearGradient id="barGradient" x1="0" y1="0" x2="0" y2="1">
					<stop offset="0%" stopColor="#2685C1" />
					<stop offset="139.01%" stopColor="rgba(38, 133, 193, 0)" />
				</linearGradient>
				<Rectangle
					x={x}
					y={y}
					width={width}
					height={height}
					fill="url(#barGradient)"
				/>
			</defs>
		);
	}

	return <Rectangle {...props} />;
}

function renderCustomLabel({
	x,
	y,
	width,
	height,
	value,
	position,
	formatter
}) {
	const xOffset = position === 'right' ? width + 10 : -105; // Adjust based on position
	const formattedValue = formatter ? formatter(value) : value;
	return (
		<foreignObject
			x={x + xOffset}
			y={y + height / 2 - 10}
			width={100}
			height={30}
		>
			<Typography variant="body2">{formattedValue}</Typography>
		</foreignObject>
	);
}

function SimpleDynamicBarchart({ data, valueFormatter }) {
	return (
		<ResponsiveContainer width="100%" height="90%">
			<BarChart
				barSize={40}
				barGap={20}
				layout="vertical"
				width="100%"
				height="100%"
				data={data}
				margin={{ right: 150, left: 50 }}
			>
				<defs>
					<linearGradient
						id="barGradient"
						x1="1"
						y1="0"
						x2="0"
						y2="0"
					>
						<stop offset="0%" stopColor="#2685C1" />
						<stop
							offset="100%"
							stopColor="rgba(38, 133, 193, 0.2)"
						/>
					</linearGradient>
				</defs>
				<YAxis
					type="category"
					dataKey="name"
					tick={false}
					axisLine={false}
				/>
				<XAxis
					type="number"
					tick={false}
					tickLine={false}
					axisLine={false}
				/>

				<Bar dataKey="value" shape={<CustomBar />}>
					{data.map((entry, index) => (
						<Cell
							key={uuidv4()}
							fill={
								index === 1 ? 'url(#barGradient)' : entry.fill
							}
						/>
					))}
					<LabelList
						dataKey="name"
						position="left"
						fill="black"
						content={(props) =>
							renderCustomLabel({ ...props, position: 'left' })
						}
					/>
					<LabelList
						dataKey="value"
						position="right"
						fill="black"
						formatter={(value) => {
							if (valueFormatter) return valueFormatter(value);
							return dollarFormatter.format(value);
						}}
						content={(props) =>
							renderCustomLabel({ ...props, position: 'right' })
						}
					/>
				</Bar>
			</BarChart>
		</ResponsiveContainer>
	);
}

export default SimpleDynamicBarchart;
