import { Grid, Skeleton, Typography } from '@mui/material';
import { useEffect, useState, useMemo } from 'react';
import { useQueryClient } from 'react-query';

// Our Components
import AutocompleteTextinput from 'components/Input/AutocompleteTextinput';
import Dropdown from 'components/Dropdown/Dropdown';
import LoanForms from 'components/PriceALoan/LoanForms';
import PageHeading from 'components/PageHeading/PageHeading';
import PriceALoanNewClientForm from 'components/PriceALoan/PriceALoanNewClientForm';
import PriceALoanRelevantLoans from 'components/PriceALoan/PriceALoanRelevantLoans';

// Our Hooks
import useGetClients from 'hooks/useGetClients';
import useGetClientsProfile from 'hooks/useGetClientsProfile';
import useGetProfile from 'hooks/useGetProfile';
import useGetWalletOverviewAsAdvisor from 'hooks/useGetWalletOverviewAsAdvisor';
import useQueryGetHubspotAccount from 'hooks/hubspot/useQueryGetHubspotAccount';

// Our utils
import { dataIsValid } from 'shared/utils';

// Our Query Keys
import { WALLET_OVERVIEW_DATA } from 'shared/query-keys';

// Our Routes
import { LOAN_INQUIRY_ROUTE } from 'routes/index';

// Our Constants
import LOAN_INQUIRY_TYPES from 'shared/constants/LoanInquiry/LoanInquiryTypes';

const PRICE_A_LOAN_HEADING = 'Price a Client Loan';
const MAX_DROPDOWN_WIDTH = 350;

function PriceALoanPage() {
	const queryClient = useQueryClient();

	// Deconstruct the URL to grab the client ID if present
	const currentURL = window.location.href;
	// Splits the currentURL by ? characters
	const urlPieces = currentURL.split('?');
	// Slices off the last item of the urlPieces Array, which should be the clientId. Looks like this currently [clientId]
	const idInArray = urlPieces.slice(-1);
	// Pulls the clientId out an array and makes clientId number
	const urlPassedClientId = idInArray[0];

	// Creating a boolean that will help us pre-populated the correct information in state
	// If the passedClientId includes loan-inquiry, there was no ID pass through via the URL
	const urlWithoutId = urlPassedClientId.includes(LOAN_INQUIRY_ROUTE);

	// Fetching this client's wallet overview data, where we'll grab the first and last name to be used
	// to pre-populate the client dropdown.
	const passedClientData = queryClient.getQueryData([
		WALLET_OVERVIEW_DATA,
		urlPassedClientId
	]);

	const passedFullName = `${passedClientData?.firstName} ${passedClientData?.lastName}`;

	// Client State
	const [selectedClient, setSelectedClient] = useState(
		urlWithoutId ? null : { id: urlPassedClientId, name: passedFullName }
	);
	const [clientSearchName, setClientSearchName] = useState(
		urlWithoutId ? '' : passedFullName
	);

	const [selectedLoanType, setSelectedLoanType] = useState('');
	const [selectedClientLoan, setSelectedClientLoan] = useState(null);

	// Only used if new client is selected
	const [clientFirstName, setClientFirstName] = useState('');
	const [clientLastName, setClientLastName] = useState('');

	const {
		isLoading: isGetClientsLoading,
		isSuccess: isGetClientsSuccess,
		data
	} = useGetClients('');

	const { data: userProfileData } = useGetProfile();

	const {
		isLoading: isGetHubspotAccountLoading,
		isSuccess: isGetHubspotAccountSuccess
	} = useQueryGetHubspotAccount(userProfileData);

	// Call react Query to Retrieve Advisor name and email address from USERPROFILE
	const advisorFirstName = userProfileData?.firstName;
	const advisorLastName = userProfileData?.lastName;
	const advisorName = `${advisorFirstName} ${advisorLastName}`;
	const advisorEmail = userProfileData?.email;
	const advisorCompany = userProfileData?.companyName;
	const advisorWebsite = userProfileData?.companyWebsite;

	const advisorInfo = {
		advisorName,
		advisorEmail,
		advisorCompany,
		advisorWebsite
	};

	const isSomeClientSelected = selectedClient ?? false;

	// Trigger to fire client profile endpoint call
	const enabledGatherClientProfileCall =
		isGetClientsSuccess &&
		dataIsValid(data) &&
		!!isSomeClientSelected &&
		selectedClient.id !== 0;

	const { data: clientProfileData, isSuccess: clientProfileDataIsSuccess } =
		useGetClientsProfile(
			selectedClient?.id ?? 0,
			enabledGatherClientProfileCall,
			(profileData) => {
				const clientProfile = profileData;
				if (dataIsValid(clientProfile)) {
					return clientProfile;
				}
				return clientProfile;
			}
		);

	// Trigger to fire client wallet overview endpoint call
	const enabledGatherClientWalletOverviewCall =
		selectedClient?.id !== null &&
		selectedClient?.id !== 0 &&
		selectedClient?.id !== undefined &&
		clientProfileDataIsSuccess;

	const { data: clientWalletOverviewData } = useGetWalletOverviewAsAdvisor(
		selectedClient?.id ?? 0,
		enabledGatherClientWalletOverviewCall,
		(walletOverviewData) => {
			if (dataIsValid(walletOverviewData)) {
				return walletOverviewData;
			}
			return walletOverviewData;
		}
	);

	// Creates a label set for each client. Sets these labels to an array of "items", which is used to populate the options within the client selection dropdown
	const listOfClients = useMemo(() => {
		if (isGetClientsSuccess) {
			const clientList = data.map(({ fullName, clientId }) => ({
				name: fullName,
				id: clientId
			}));

			return [{ name: 'New Client', id: 0 }, ...clientList];
		}

		return [];
	}, [data]);

	const handleClientSelect = (
		currentSelectedClient,
		updateSelectedClient
	) => {
		updateSelectedClient(currentSelectedClient);
		setSelectedClient(currentSelectedClient);
		setSelectedLoanType('');
	};

	const handleLoanTypeChange = (value) => {
		setSelectedLoanType(value);
		setSelectedClientLoan(null);
	};

	const handleClientChange = (incomingValue) => {
		if (incomingValue === '') {
			setSelectedClient('');
		}
		setClientSearchName(incomingValue);
	};

	useEffect(() => {
		// Clean up
		setSelectedClientLoan(null);
	}, [selectedClient]);

	const isLoading = isGetClientsLoading || isGetHubspotAccountLoading;
	const isSuccess = isGetClientsSuccess && isGetHubspotAccountSuccess;

	// New Client Checks
	const isLoanSelectedAndClientSelected = selectedLoanType && selectedClient;
	const isNewClientSelected = selectedClient?.id === 0;
	const isClientNameReady =
		isNewClientSelected && clientFirstName !== '' && clientLastName !== '';

	if (isLoading) {
		return (
			<>
				<PageHeading
					title={PRICE_A_LOAN_HEADING}
					subtitleText="Submitting a pricing request through Sora enables you to uncover loan offers and rates, without sharing any client personally identifiable information with lenders. Clients are never contacted without your express consent."
				/>

				<Grid container spacing={2}>
					<Grid item xs={12}>
						<Skeleton
							variant="rectangular"
							height={500}
							sx={{ marginBottom: 4 }}
						/>
					</Grid>
				</Grid>
			</>
		);
	}

	if (isSuccess) {
		const hasClients = listOfClients.length > 0;
		return (
			<>
				<PageHeading
					title={PRICE_A_LOAN_HEADING}
					subtitleText={
						<Typography variant="body2" sx={{ marginBottom: 4 }}>
							Submitting a pricing request through Sora enables
							you to uncover loan offers and rates, without
							sharing any client personally identifiable
							information with lenders.
							<br />
							<br />
							Clients are never contacted without your express
							consent.
						</Typography>
					}
				/>

				<Grid
					item
					xs={12}
					sx={{
						display: 'flex',
						alignItems: 'center',
						marginBottom: 4
					}}
				>
					<Typography variant="subtitle2" sx={{ marginRight: 6 }}>
						Select Client:
					</Typography>

					{hasClients && (
						<AutocompleteTextinput
							autoCompleteSX={{
								flexGrow: 2,
								maxWidth: MAX_DROPDOWN_WIDTH
							}}
							forcePopupIcon
							getOptionLabel={({ name }) => name}
							helperText=""
							handleChange={handleClientChange}
							handleSelect={handleClientSelect}
							label=""
							value={clientSearchName}
							optionList={listOfClients}
							withoutFilter={false}
							renderOption={(props, option) => (
								<li {...props} key={option.id}>
									{option.name}
								</li>
							)}
						/>
					)}
					{!hasClients && (
						<Typography variant="body1">
							No clients were found. <br /> <br />
							Please add some or try again later.
						</Typography>
					)}
				</Grid>

				<Grid
					container
					item
					xs={12}
					sx={{ alignItems: 'center', marginBottom: 4 }}
				>
					<Typography
						variant="subtitle2"
						sx={{ display: 'block', marginRight: 8 }}
					>
						Loan Type:
					</Typography>

					{!selectedClient && (
						<Typography variant="body1">
							Please select a client.
						</Typography>
					)}

					{isSomeClientSelected && (
						<Dropdown
							sx={{ flexGrow: 2, maxWidth: MAX_DROPDOWN_WIDTH }}
							items={LOAN_INQUIRY_TYPES}
							onChange={handleLoanTypeChange}
							selected={selectedLoanType}
						/>
					)}
				</Grid>

				<Grid container item xs={12} sx={{ alignItems: 'center' }}>
					<PriceALoanRelevantLoans
						selectedLoanType={selectedLoanType}
						clientWalletOverviewData={clientWalletOverviewData}
						selectedClientLoan={selectedClientLoan}
						setSelectedClientLoan={setSelectedClientLoan}
					/>
				</Grid>

				{isLoanSelectedAndClientSelected && isNewClientSelected && (
					<PriceALoanNewClientForm
						clientFirstName={clientFirstName}
						clientLastName={clientLastName}
						setClientFirstName={setClientFirstName}
						setClientLastName={setClientLastName}
					/>
				)}

				<LoanForms
					advisorInfo={advisorInfo}
					clientFirstName={clientFirstName}
					clientLastName={clientLastName}
					clientProfileData={clientProfileData}
					clientWalletOverviewData={clientWalletOverviewData}
					selectedLoanType={selectedLoanType}
					selectedClient={selectedClient}
					selectedClientLoan={selectedClientLoan}
					isClientNameReady={isClientNameReady}
					isNewClientSelected={isNewClientSelected}
				/>
			</>
		);
	}
}

export default PriceALoanPage;
