import { Box, Typography } from '@mui/material';

function BarChartItem({ background, text, textColor, percent }) {
	return (
		<Box
			sx={{
				background,
				borderRadius: '10px 10px 0px 0px;',
				opacity: 0.9,
				height: percent,
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center'
			}}
		>
			<Typography
				variant="subtitle2"
				gutterBottom
				sx={{ color: textColor, transform: 'rotate(-90deg)' }}
			>
				{text}
			</Typography>
		</Box>
	);
}

export default BarChartItem;
