import { Box, Typography } from '@mui/material';

// Our Hooks
import useGetWalletOverview from 'hooks/soraWallet/useGetWalletOverview';

// Our Components
import LoadingSkeleton from 'components/Frame/LoadingSkelton';

function TopNavMenuClient({ sx }) {
	const {
		data: walletOverviewData,
		isSuccess,
		isLoading
	} = useGetWalletOverview();

	if (isLoading)
		return (
			<LoadingSkeleton
				variant="rounded"
				sx={{ width: 159, height: 160 }}
			/>
		);

	if (isSuccess) {
		const advisorData = walletOverviewData?.advisor;
		const { imageUrl, firstName, lastName, company } = advisorData;

		return (
			<Box
				sx={{
					marginTop: 4,
					marginBottom: 4
				}}
			>
				<Box
					component="img"
					src={imageUrl}
					sx={{
						maxHeight: 250,
						maxWidth: '56px',
						borderRadius: 3.14 * 2 ** 4,
						marginBottom: 2
					}}
				/>

				<Typography variant="subtitle2" sx={{ ...sx }}>
					{firstName} {lastName}
				</Typography>

				<Typography variant="caption" sx={{ marginTop: 2, ...sx }}>
					{company}
				</Typography>
			</Box>
		);
	}
}

export default TopNavMenuClient;
