import { useMutation, useQueryClient } from 'react-query';

// Our Axios Instance
import axiosInstance from 'services/API/API';

// Our Query Keys
import { ANALYTICS_ASSUMPTIONS_SELECTED_CLIENT } from 'shared/query-keys';

// Our Endpoints
import { assumptionsAnalyticsSaveUrl } from 'shared/api-urls';

function useMutateSaveAssumptions() {
	const queryClient = useQueryClient();

	return useMutation(
		async (assumptionsPayload) => {
			const response = await axiosInstance.post(
				assumptionsAnalyticsSaveUrl,
				assumptionsPayload
			);

			return response.data;
		},
		{
			onSuccess: async () => {
				await queryClient.invalidateQueries(
					ANALYTICS_ASSUMPTIONS_SELECTED_CLIENT
				);
			}
		}
	);
}

export default useMutateSaveAssumptions;
