import { Grid, Typography } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { useState } from 'react';

// Our Components
import SettingsTabs from 'components/Settings/SettingsTabs';

function SettingsPage() {
	const [tabPanelSelected, setTabPanelSelected] = useState(0);

	return (
		<>
			<Helmet>
				<title>Settings</title>
			</Helmet>

			<Grid container>
				<Grid item xs={12}>
					<Typography
						variant="h1Gascogne"
						component="h1"
						gutterBottom
					>
						Settings
					</Typography>

					<SettingsTabs
						tabPanelSelected={tabPanelSelected}
						setTabPanelSelected={setTabPanelSelected}
						sx={{ height: '100%' }}
					/>
				</Grid>
			</Grid>
		</>
	);
}

export default SettingsPage;
