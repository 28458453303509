import { dataIsValid } from 'shared/utils';
import getMostRecentTime from 'shared/utils/date/getMostRecentTime';

const getLastUpdatedTimeStamp = (walletOverviewData) => {
	const { liabilities } = walletOverviewData.liability;

	const removeLiabilitiesWithoutLastRefresh = liabilities.filter(
		({ lastRefreshedDate }) => dataIsValid(lastRefreshedDate)
	);

	const recentTimeStampStrings = removeLiabilitiesWithoutLastRefresh.map(
		({ lastRefreshedDate, lastUpdatedDate }) =>
			dataIsValid(lastRefreshedDate) ? lastRefreshedDate : lastUpdatedDate
	);

	const mostRecentTimeStamp = getMostRecentTime(recentTimeStampStrings);

	return mostRecentTimeStamp;
};

export default getLastUpdatedTimeStamp;
