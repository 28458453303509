/**
 *
 * @param {string} sentence
 * @returns `string` splits a string that is a list of comma separated items i.e. "SAMBIT BASU,PARAMITA DAS" -> "SAMBIT BASU, PARAMITA DAS"
 */

function splitByComma(sentence) {
	const separatedByCommas = sentence.split(',');

	const reformedSentence = separatedByCommas.join(', ');
	return reformedSentence.toString();
}

export default splitByComma;
