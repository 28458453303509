// Sorts by TradelineType
const sortByTradelineType = (liabilities) =>
	liabilities.sort(
		(
			{ tradeLineType: tradeLineTypeA },
			{ tradeLineType: tradeLineTypeB }
		) => {
			if (tradeLineTypeA < tradeLineTypeB) return -1;
			if (tradeLineTypeA > tradeLineTypeB) return 1;
			return 0;
		}
	);

export default sortByTradelineType;
