// Our Components
import TextInput from 'components/Input/TextInput';

// Input Types
import { TEXT } from 'components/Input/Types';

// Our Common Prop types
import CommonProps from 'shared/prop-types';

const MINIMUM_PASSWORD_LENGTH = 7;

function PasswordTextInput({ password, handlePasswordChange, sx }) {
	const passwordLengthCheck = password.length < MINIMUM_PASSWORD_LENGTH;
	return (
		<TextInput
			id="password"
			label="Password"
			type={TEXT}
			onChange={handlePasswordChange}
			inputProps={{
				'data-test': 'password-input'
			}}
			value={password}
			error={password.length < MINIMUM_PASSWORD_LENGTH}
			helperText={
				passwordLengthCheck
					? `Password must be minimum of ${MINIMUM_PASSWORD_LENGTH} characters`
					: ''
			}
			sx={sx}
			withVisibleToggle
			defaultVisibility={false}
		/>
	);
}

PasswordTextInput.propTypes = {
	sx: CommonProps.sx
};

PasswordTextInput.defaultProps = {
	sx: {}
};

export default PasswordTextInput;
