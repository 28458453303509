import { useQuery } from 'react-query';

// Hubspot Axios Instance
import HubSpotAPI from 'services/API/HubSpotAPI';

// Query Keys
import { HUBSPOT_ACCOUNT } from 'shared/query-keys';

// Constants
import { DEFAULT_STALE_TIME_FIVE_MINUTES } from 'shared/constants';

function useQueryGetHubspotAccount(userProfileData) {
	return useQuery(
		HUBSPOT_ACCOUNT,
		async () => {
			const {
				email,
				companyName,
				companyWebsite,
				firstName,
				lastName,
				phoneNumber
			} = userProfileData;

			const advisorCompanyInfo = {
				properties: {
					name: companyName,
					domain: companyWebsite
				}
			};

			const advisorContactInfo = {
				properties: {
					email,
					firstname: firstName,
					lastname: lastName,
					phone: phoneNumber,
					company: companyName,
					website: companyWebsite
				}
			};

			const advisorAccountInfo = {
				advisorCompanyInfo,
				advisorContactInfo
			};

			const response = await HubSpotAPI.post(
				'getOrCreate/advisor',
				advisorAccountInfo
			);

			return response.data;
		},
		{
			staleTime: DEFAULT_STALE_TIME_FIVE_MINUTES
		}
	);
}

export default useQueryGetHubspotAccount;
