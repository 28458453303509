import { useState } from 'react';
import { Box, Typography } from '@mui/material';

// Our Components
import Modal from 'components/Modal/Modal';
import { PrimaryButton } from 'components/Button/Button';

const modes = ['xs', 'sm', 'md', 'lg', 'xl'];

function DesignPage() {
	const [open, setOpen] = useState(false);
	const [mode, setMode] = useState('md');

	return (
		<>
			<Typography
				variant="subtitle1"
				sx={{ display: 'block', marginTop: 4, marginLeft: 4 }}
			>
				Modal modes available
			</Typography>
			<Box sx={{ display: 'flex', gap: 2, marginLeft: 4 }}>
				{modes.map((currentMode) => (
					<PrimaryButton
						onClick={() => {
							setMode(currentMode);
							setOpen(true);
						}}
					>
						{currentMode}
					</PrimaryButton>
				))}
			</Box>
			<Modal
				isOpen={open}
				handleClose={() => setOpen(false)}
				maxWidth={mode}
			>
				<Typography variant="subtitle1">
					Current Mode: {mode}
				</Typography>
			</Modal>
		</>
	);
}

export default DesignPage;
