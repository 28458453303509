import { useMutation } from 'react-query';

// Our Axios instance
import axiosInstance from 'services/API/API';

// Our endpoint
import { generateReport } from 'shared/api-urls';

function useMutateGenerateReport() {
	return useMutation((reportQuery) =>
		axiosInstance.post(generateReport, reportQuery)
	);
}

export default useMutateGenerateReport;
