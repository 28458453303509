import PropTypes from 'prop-types';
import { Box, Grid, Typography, Paper, Stack } from '@mui/material';
import { useTheme } from '@emotion/react';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';

// Our Components
import EditRequestInfoModal from 'components/Tasks/EditRequestInfoModel';
import { TertiaryButton } from 'components/Button/Button';

// Our Utils
import { dollarFormatter, decodedLoanType, dataIsValid } from 'shared/utils';

// Our constants
import { ADVISOR_LOAN_REQUEST_STATUS_CONSTANTS } from 'shared/constants';

function LoanRequestCard({
	newLoanDetails,
	advisorEmail,
	acceptedOfferId,
	acres,
	addressLine1,
	addressLine2,
	cardHeading,
	city,
	clientName,
	companyName,
	county,
	downPaymentPercent,
	employmentStatus,
	estimatedHomeValue,
	estimatedPurchaseValue,
	loanAmount,
	loanTenure,
	mileage,
	newLoanOffers,
	offerType,
	outstandingBalance,
	rateOfCurrentMortgage,
	reasonForLoan,
	state,
	sx,
	timeUntilConstruction,
	totalAnnualIncome,
	totalAssetsValue,
	tradelineType,
	yearOfVehicle,
	zipCode
}) {
	const SoraTheme = useTheme();

	const { primary } = SoraTheme.palette;
	const [formattedYearOfVehicle, setFormattedYearOfVehicle] = useState(null);
	const [selectedLoanOfferData, setSelectedLoanOfferData] = useState([]);

	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isFormCompleted, setIsFormCompleted] = useState(false);

	const handleChange = () => {
		setIsModalOpen(!isModalOpen);
	};

	const isLoanOfferSelected =
		dataIsValid(acceptedOfferId) && acceptedOfferId !== 0;

	useEffect(() => {
		if (yearOfVehicle !== undefined) {
			const formatYear = format(new Date(yearOfVehicle), 'yyyy');
			setFormattedYearOfVehicle(formatYear);
		}
		if (acceptedOfferId && acceptedOfferId !== 0) {
			const selectedOffer = newLoanOffers.filter(
				(offer) => offer.id === acceptedOfferId
			);
			setSelectedLoanOfferData(selectedOffer);
		}
		if (
			newLoanDetails?.faStatus ===
				ADVISOR_LOAN_REQUEST_STATUS_CONSTANTS.FA_SEARCH_RATES ||
			newLoanDetails?.faStatus ===
				ADVISOR_LOAN_REQUEST_STATUS_CONSTANTS.UNDER_REVIEW ||
			newLoanDetails?.faStatus ===
				ADVISOR_LOAN_REQUEST_STATUS_CONSTANTS.PROCESSING ||
			newLoanDetails?.faStatus ===
				ADVISOR_LOAN_REQUEST_STATUS_CONSTANTS.FA_COMPLETED ||
			newLoanDetails?.faStatus ===
				ADVISOR_LOAN_REQUEST_STATUS_CONSTANTS.FA_CANCELED ||
			newLoanDetails?.faStatus ===
				ADVISOR_LOAN_REQUEST_STATUS_CONSTANTS.FA_REJECTED
		) {
			setIsFormCompleted(true);
		}
	}, [acceptedOfferId, newLoanDetails, newLoanOffers, yearOfVehicle]);

	return (
		<>
			<Paper
				elevation={3}
				square={false}
				component={Stack}
				sx={{
					borderRadius: '20px',
					padding: 4,
					flexDirection: 'column',
					gap: 2,
					boxSizing: 'border-box',
					height: '100%',
					position: 'relative',
					...sx
				}}
			>
				{cardHeading && (
					<Grid container>
						<Grid
							item
							xs={12}
							sx={{ marginBottom: 2, paddingRight: 4 }}
						>
							<Box
								sx={{
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'space-between'
								}}
							>
								<Typography
									variant="h2Gascogne"
									component="h2"
									gutterBottom
									sx={{ color: primary.indigo }}
								>
									{cardHeading}
								</Typography>

								{!isLoanOfferSelected &&
									newLoanDetails?.faStatus !==
										ADVISOR_LOAN_REQUEST_STATUS_CONSTANTS.FA_COMPLETED &&
									newLoanDetails?.faStatus !==
										ADVISOR_LOAN_REQUEST_STATUS_CONSTANTS.FA_REJECTED &&
									newLoanDetails?.faStatus !==
										ADVISOR_LOAN_REQUEST_STATUS_CONSTANTS.FA_CANCELED && (
										<TertiaryButton
											disabled={
												newLoanDetails?.faStatus ===
												'CONTACT_LENDER'
											}
											sx={{ marginTop: 1 }}
											onClick={handleChange}
										>
											{isFormCompleted
												? 'Update information'
												: 'Add missing information'}
										</TertiaryButton>
									)}
							</Box>
						</Grid>
					</Grid>
				)}
				<Grid container>
					{clientName && (
						<Grid item xs={6} sx={{ marginBottom: 2 }}>
							<Typography
								variant="subtitle1"
								component="p"
								gutterBottom
								sx={{ fontWeight: 'bold', marginBottom: 1 }}
							>
								Client Name
							</Typography>
							<Typography
								variant="body2"
								component="p"
								gutterBottom
								sx={{ marginRight: 4 }}
							>
								{clientName}
							</Typography>
						</Grid>
					)}

					<Grid item xs={6} sx={{ marginBottom: 2 }}>
						<Typography
							variant="subtitle1"
							component="p"
							gutterBottom
							sx={{ fontWeight: 'bold', marginBottom: 1 }}
						>
							Loan Type
						</Typography>
						<Typography
							variant="body2"
							component="p"
							gutterBottom
							sx={{ marginRight: 4 }}
						>
							{decodedLoanType(tradelineType, offerType)}
						</Typography>
					</Grid>

					{loanAmount && (
						<Grid item xs={6} sx={{ marginBottom: 2 }}>
							<Typography
								variant="subtitle1"
								component="p"
								gutterBottom
								sx={{ fontWeight: 'bold', marginBottom: 1 }}
							>
								Loan Amount
							</Typography>
							<Typography
								variant="body2"
								component="p"
								gutterBottom
								sx={{ marginRight: 4 }}
							>
								{dollarFormatter.format(loanAmount)}
							</Typography>
						</Grid>
					)}

					{loanTenure && (
						<Grid item xs={6} sx={{ marginBottom: 2 }}>
							<Typography
								variant="subtitle1"
								component="p"
								gutterBottom
								sx={{ fontWeight: 'bold', marginBottom: 1 }}
							>
								Loan Term (years)
							</Typography>
							<Typography
								variant="body2"
								component="p"
								gutterBottom
								sx={{ marginRight: 4 }}
							>
								{loanTenure}
							</Typography>
						</Grid>
					)}

					{selectedLoanOfferData[0]?.lender && (
						<Grid item xs={6} sx={{ marginBottom: 2 }}>
							<Typography
								variant="subtitle1"
								component="p"
								gutterBottom
								sx={{ fontWeight: 'bold', marginBottom: 1 }}
							>
								Selected Lender
							</Typography>
							<Typography
								variant="body2"
								component="p"
								gutterBottom
								sx={{ marginRight: 4 }}
							>
								{selectedLoanOfferData[0]?.lender}
							</Typography>
						</Grid>
					)}

					{selectedLoanOfferData[0]?.rate && (
						<Grid item xs={6} sx={{ marginBottom: 2 }}>
							<Typography
								variant="subtitle1"
								component="p"
								gutterBottom
								sx={{ fontWeight: 'bold', marginBottom: 1 }}
							>
								Selected Interest Rate
							</Typography>
							<Typography
								variant="body2"
								component="p"
								gutterBottom
								sx={{ marginRight: 4 }}
							>
								{selectedLoanOfferData[0]?.rate} %
							</Typography>
						</Grid>
					)}

					{selectedLoanOfferData[0]?.monthlyPayment && (
						<Grid item xs={6} sx={{ marginBottom: 2 }}>
							<Typography
								variant="subtitle1"
								component="p"
								gutterBottom
								sx={{ fontWeight: 'bold', marginBottom: 1 }}
							>
								Selected Monthly Payment
							</Typography>
							<Typography
								variant="body2"
								component="p"
								gutterBottom
								sx={{ marginRight: 4 }}
							>
								{dollarFormatter.format(
									selectedLoanOfferData[0]?.monthlyPayment
								)}
							</Typography>
						</Grid>
					)}

					{/* CONSTRUCTION LOAN */}
					{decodedLoanType(tradelineType, offerType) ===
						'Construction Loan' && (
						// eslint-disable-next-line react/jsx-no-useless-fragment
						<>
							{addressLine1 && (
								<Grid item xs={6} sx={{ marginBottom: 2 }}>
									<Typography
										variant="subtitle1"
										component="p"
										gutterBottom
										sx={{
											fontWeight: 'bold',
											marginBottom: 1
										}}
									>
										Address
									</Typography>
									<Typography
										variant="body2"
										component="p"
										gutterBottom
										sx={{ marginRight: 4, lineHeight: 1.3 }}
									>
										{addressLine1}
										<br />
										{addressLine2 && (
											<>
												{addressLine2}
												<br />
											</>
										)}
										{city},&nbsp;
										{state}&nbsp;
										{zipCode}
									</Typography>
								</Grid>
							)}
						</>
					)}

					{/* New Automotive Loan */}
					{decodedLoanType(tradelineType, offerType) ===
						'New Automotive Loan' && (
						<>
							<Grid item xs={6} sx={{ marginBottom: 2 }}>
								<Typography
									variant="subtitle1"
									component="p"
									gutterBottom
									sx={{ fontWeight: 'bold', marginBottom: 1 }}
								>
									Vehicle Purchase Value
								</Typography>
								<Typography
									variant="body2"
									component="p"
									gutterBottom
									sx={{ marginRight: 4 }}
								>
									{dollarFormatter.format(
										estimatedPurchaseValue
									)}
								</Typography>
							</Grid>

							<Grid item xs={6} sx={{ marginBottom: 2 }}>
								<Typography
									variant="subtitle1"
									component="p"
									gutterBottom
									sx={{ fontWeight: 'bold', marginBottom: 1 }}
								>
									Year of Vehicle
								</Typography>
								<Typography
									variant="body2"
									component="p"
									gutterBottom
									sx={{ marginRight: 4 }}
								>
									{formattedYearOfVehicle || 'NA'}
								</Typography>
							</Grid>

							<Grid item xs={6} sx={{ marginBottom: 2 }}>
								<Typography
									variant="subtitle1"
									component="p"
									gutterBottom
									sx={{ fontWeight: 'bold', marginBottom: 1 }}
								>
									State of Purchase
								</Typography>
								<Typography
									variant="body2"
									component="p"
									gutterBottom
									sx={{ marginRight: 4 }}
								>
									{state || 'NA'}
								</Typography>
							</Grid>
						</>
					)}

					{/* Automotive Refinance Loan */}
					{decodedLoanType(tradelineType, offerType) ===
						'Automotive Refinance Loan' && (
						<>
							<Grid item xs={6} sx={{ marginBottom: 2 }}>
								<Typography
									variant="subtitle1"
									component="p"
									gutterBottom
									sx={{ fontWeight: 'bold', marginBottom: 1 }}
								>
									Vehicle Mileage
								</Typography>
								<Typography
									variant="body2"
									component="p"
									gutterBottom
									sx={{ marginRight: 4 }}
								>
									{mileage || 'NA'}
								</Typography>
							</Grid>

							<Grid item xs={6} sx={{ marginBottom: 2 }}>
								<Typography
									variant="subtitle1"
									component="p"
									gutterBottom
									sx={{ fontWeight: 'bold', marginBottom: 1 }}
								>
									State of Purchase
								</Typography>
								<Typography
									variant="body2"
									component="p"
									gutterBottom
									sx={{ marginRight: 4 }}
								>
									{state || 'NA'}
								</Typography>
							</Grid>
							<Grid item xs={6} sx={{ marginBottom: 2 }}>
								<Typography
									variant="subtitle1"
									component="p"
									gutterBottom
									sx={{ fontWeight: 'bold', marginBottom: 1 }}
								>
									Vehicle Model
								</Typography>
								<Typography
									variant="body2"
									component="p"
									gutterBottom
									sx={{ marginRight: 4 }}
								>
									{state || 'NA'}
								</Typography>
							</Grid>
						</>
					)}

					{/* Cashout Refinance */}
					{decodedLoanType(tradelineType, offerType) ===
						'Cash Out Refinance Loan' && (
						<>
							{addressLine1 && (
								<Grid item xs={6} sx={{ marginBottom: 2 }}>
									<Typography
										variant="subtitle1"
										component="p"
										gutterBottom
										sx={{
											fontWeight: 'bold',
											marginBottom: 1
										}}
									>
										Address
									</Typography>
									<Typography
										variant="body2"
										component="p"
										gutterBottom
										sx={{ marginRight: 4, lineHeight: 1.3 }}
									>
										{addressLine1}
										<br />
										{addressLine2 && (
											<>
												{addressLine2}
												<br />
											</>
										)}
										{city},&nbsp;
										{state}&nbsp;
										{zipCode}
									</Typography>
								</Grid>
							)}
							<Grid item xs={6} sx={{ marginBottom: 2 }}>
								<Typography
									variant="subtitle1"
									component="p"
									gutterBottom
									sx={{ fontWeight: 'bold', marginBottom: 1 }}
								>
									Outstanding Balance
								</Typography>
								<Typography
									variant="body2"
									component="p"
									gutterBottom
									sx={{ marginRight: 4 }}
								>
									{dollarFormatter.format(
										outstandingBalance
									) || 'NA'}
								</Typography>
							</Grid>

							<Grid item xs={6} sx={{ marginBottom: 2 }}>
								<Typography
									variant="subtitle1"
									component="p"
									gutterBottom
									sx={{ fontWeight: 'bold', marginBottom: 1 }}
								>
									Estimated Home Value
								</Typography>
								<Typography
									variant="body2"
									component="p"
									gutterBottom
									sx={{ marginRight: 4 }}
								>
									{dollarFormatter.format(
										estimatedPurchaseValue
									) || 'NA'}
								</Typography>
							</Grid>
						</>
					)}

					{/* HELOC */}
					{decodedLoanType(tradelineType, offerType) ===
						'HELOC Loan' && (
						<>
							{addressLine1 && (
								<Grid item xs={6} sx={{ marginBottom: 2 }}>
									<Typography
										variant="subtitle1"
										component="p"
										gutterBottom
										sx={{
											fontWeight: 'bold',
											marginBottom: 1
										}}
									>
										Address
									</Typography>
									<Typography
										variant="body2"
										component="p"
										gutterBottom
										sx={{ marginRight: 4, lineHeight: 1.5 }}
									>
										{addressLine1}
										<br />
										{addressLine2 && (
											<>
												{addressLine2}
												<br />
											</>
										)}
										{city},&nbsp;
										{state}&nbsp;
										{zipCode}
									</Typography>
								</Grid>
							)}
							<Grid item xs={6} sx={{ marginBottom: 2 }}>
								<Typography
									variant="subtitle1"
									component="p"
									gutterBottom
									sx={{ fontWeight: 'bold', marginBottom: 1 }}
								>
									Outstanding Balance
								</Typography>
								<Typography
									variant="body2"
									component="p"
									gutterBottom
									sx={{ marginRight: 4 }}
								>
									{dollarFormatter.format(
										outstandingBalance
									) || 'NA'}
								</Typography>
							</Grid>

							<Grid item xs={6} sx={{ marginBottom: 2 }}>
								<Typography
									variant="subtitle1"
									component="p"
									gutterBottom
									sx={{ fontWeight: 'bold', marginBottom: 1 }}
								>
									Estimated Home Value
								</Typography>
								<Typography
									variant="body2"
									component="p"
									gutterBottom
									sx={{ marginRight: 4 }}
								>
									{dollarFormatter.format(
										estimatedHomeValue
									) || 'NA'}
								</Typography>
							</Grid>
						</>
					)}

					{/* Reverse Mortgage */}
					{decodedLoanType(tradelineType, offerType) ===
						'Reverse Mortgage Loan' && (
						<>
							{addressLine1 && (
								<Grid item xs={6} sx={{ marginBottom: 2 }}>
									<Typography
										variant="subtitle1"
										component="p"
										gutterBottom
										sx={{
											fontWeight: 'bold',
											marginBottom: 1
										}}
									>
										Details
									</Typography>
									<Typography
										variant="body2"
										component="p"
										gutterBottom
										sx={{ marginRight: 4, lineHeight: 1.3 }}
									>
										{addressLine1}
										<br />
										{addressLine2 && (
											<>
												{addressLine2}
												<br />
											</>
										)}
										{city},&nbsp;
										{state}&nbsp;
										{zipCode}
									</Typography>
								</Grid>
							)}
							<Grid item xs={6} sx={{ marginBottom: 2 }}>
								<Typography
									variant="subtitle1"
									component="p"
									gutterBottom
									sx={{ fontWeight: 'bold', marginBottom: 1 }}
								>
									Outstanding Balance
								</Typography>
								<Typography
									variant="body2"
									component="p"
									gutterBottom
									sx={{ marginRight: 4 }}
								>
									{dollarFormatter.format(
										outstandingBalance
									) || 'NA'}
								</Typography>
							</Grid>

							<Grid item xs={6} sx={{ marginBottom: 2 }}>
								<Typography
									variant="subtitle1"
									component="p"
									gutterBottom
									sx={{ fontWeight: 'bold', marginBottom: 1 }}
								>
									Estimated Home Value
								</Typography>
								<Typography
									variant="body2"
									component="p"
									gutterBottom
									sx={{ marginRight: 4 }}
								>
									{dollarFormatter.format(
										estimatedHomeValue
									) || 'NA'}
								</Typography>
							</Grid>
						</>
					)}

					{/* Farm Loan */}
					{decodedLoanType(tradelineType, offerType) ===
						'New Farm Loan' && (
						<>
							{addressLine1 && (
								<Grid item xs={6} sx={{ marginBottom: 2 }}>
									<Typography
										variant="subtitle1"
										component="p"
										gutterBottom
										sx={{
											fontWeight: 'bold',
											marginBottom: 1
										}}
									>
										Details
									</Typography>
									<Typography
										variant="body2"
										component="p"
										gutterBottom
										sx={{ marginRight: 4, lineHeight: 1.3 }}
									>
										{addressLine1}
										<br />
										{addressLine2 && (
											<>
												{addressLine2}
												<br />
											</>
										)}
										{city},&nbsp;
										{state}&nbsp;
										{zipCode}
									</Typography>
								</Grid>
							)}

							<Grid item xs={6} sx={{ marginBottom: 2 }}>
								<Typography
									variant="subtitle1"
									component="p"
									gutterBottom
									sx={{ fontWeight: 'bold', marginBottom: 1 }}
								>
									Number of Acres
								</Typography>
								<Typography
									variant="body2"
									component="p"
									gutterBottom
									sx={{ marginRight: 4 }}
								>
									{acres || 'NA'}
								</Typography>
							</Grid>
						</>
					)}

					{/* Lot Loan */}
					{decodedLoanType(tradelineType, offerType) ===
						'New Lot Loan' && (
						<>
							<Grid item xs={6} sx={{ marginBottom: 2 }}>
								<Typography
									variant="subtitle1"
									component="p"
									gutterBottom
									sx={{ fontWeight: 'bold', marginBottom: 1 }}
								>
									County
								</Typography>
								<Typography
									variant="body2"
									component="p"
									gutterBottom
									sx={{ marginRight: 4 }}
								>
									{county || 'NA'}
								</Typography>
							</Grid>

							<Grid item xs={6} sx={{ marginBottom: 2 }}>
								<Typography
									variant="subtitle1"
									component="p"
									gutterBottom
									sx={{ fontWeight: 'bold', marginBottom: 1 }}
								>
									State
								</Typography>
								<Typography
									variant="body2"
									component="p"
									gutterBottom
									sx={{ marginRight: 4 }}
								>
									{state || 'NA'}
								</Typography>
							</Grid>

							<Grid item xs={6} sx={{ marginBottom: 2 }}>
								<Typography
									variant="subtitle1"
									component="p"
									gutterBottom
									sx={{ fontWeight: 'bold', marginBottom: 1 }}
								>
									Number of Acres
								</Typography>
								<Typography
									variant="body2"
									component="p"
									gutterBottom
									sx={{ marginRight: 4 }}
								>
									{acres || 'NA'}
								</Typography>
							</Grid>

							<Grid item xs={6} sx={{ marginBottom: 2 }}>
								<Typography
									variant="subtitle1"
									component="p"
									gutterBottom
									sx={{ fontWeight: 'bold', marginBottom: 1 }}
								>
									Time Until Construction Starts
								</Typography>
								<Typography
									variant="body2"
									component="p"
									gutterBottom
									sx={{ marginRight: 4 }}
								>
									{timeUntilConstruction
										? `${timeUntilConstruction} months`
										: 'NA'}
								</Typography>
							</Grid>
						</>
					)}

					{/* New Mortgage Loan */}
					{decodedLoanType(tradelineType, offerType) ===
						'New Mortgage Loan' && (
						<>
							{estimatedPurchaseValue && (
								<Grid item xs={6} sx={{ marginBottom: 2 }}>
									<Typography
										variant="subtitle1"
										component="p"
										gutterBottom
										sx={{
											fontWeight: 'bold',
											marginBottom: 1
										}}
									>
										Estimated purchase value
									</Typography>
									<Typography
										variant="body2"
										component="p"
										gutterBottom
										sx={{ marginRight: 4 }}
									>
										{dollarFormatter.format(
											estimatedPurchaseValue
										)}
									</Typography>
								</Grid>
							)}

							<Grid item xs={6} sx={{ marginBottom: 2 }}>
								<Typography
									variant="subtitle1"
									component="p"
									gutterBottom
									sx={{
										fontWeight: 'bold',
										marginBottom: 1
									}}
								>
									Address
								</Typography>
								<Typography
									variant="body2"
									component="p"
									gutterBottom
									sx={{ marginRight: 4, lineHeight: 1.3 }}
								>
									{addressLine1}
									<br />
									{addressLine2 && (
										<>
											{addressLine2}
											<br />
										</>
									)}
									{city},&nbsp;
									{state}&nbsp;
									{zipCode}
								</Typography>
							</Grid>

							{downPaymentPercent && (
								<Grid item xs={6} sx={{ marginBottom: 2 }}>
									<Typography
										variant="subtitle1"
										component="p"
										gutterBottom
										sx={{
											fontWeight: 'bold',
											marginBottom: 1
										}}
									>
										Down Payment
									</Typography>
									<Typography
										variant="body2"
										component="p"
										gutterBottom
										sx={{ marginRight: 4 }}
									>
										{downPaymentPercent
											? `${downPaymentPercent} %`
											: 'NA'}
									</Typography>
								</Grid>
							)}
						</>
					)}

					{/* Mortgage Refinance Loan */}
					{decodedLoanType(tradelineType, offerType) ===
						'Mortgage Refinance Loan' && (
						<>
							<Grid item xs={6} sx={{ marginBottom: 2 }}>
								<Typography
									variant="subtitle1"
									component="p"
									gutterBottom
									sx={{
										fontWeight: 'bold',
										marginBottom: 1
									}}
								>
									Address
								</Typography>
								<Typography
									variant="body2"
									component="p"
									gutterBottom
									sx={{ marginRight: 4, lineHeight: 1.3 }}
								>
									{addressLine1}
									<br />
									{addressLine2 && (
										<>
											{addressLine2}
											<br />
										</>
									)}
									{city},&nbsp;
									{state}&nbsp;
									{zipCode}
								</Typography>
							</Grid>

							<Grid item xs={6} sx={{ marginBottom: 2 }}>
								<Typography
									variant="subtitle1"
									component="p"
									gutterBottom
									sx={{ fontWeight: 'bold', marginBottom: 1 }}
								>
									Interest Rate
								</Typography>
								<Typography
									variant="body2"
									component="p"
									gutterBottom
									sx={{ marginRight: 4 }}
								>
									{rateOfCurrentMortgage
										? `${rateOfCurrentMortgage} %`
										: 'NA'}
								</Typography>
							</Grid>
						</>
					)}

					{/* New Personal Loan */}
					{decodedLoanType(tradelineType, offerType) ===
						'New Personal Loan' && (
						<>
							{addressLine1 && (
								<Grid item xs={6} sx={{ marginBottom: 2 }}>
									<Typography
										variant="subtitle1"
										component="p"
										gutterBottom
										sx={{
											fontWeight: 'bold',
											marginBottom: 1
										}}
									>
										Address
									</Typography>
									<Typography
										variant="body2"
										component="p"
										gutterBottom
										sx={{ marginRight: 4, lineHeight: 1.3 }}
									>
										{addressLine1}
										<br />
										{addressLine2 && (
											<>
												{addressLine2}
												<br />
											</>
										)}
										{city},&nbsp;
										{state}&nbsp;
										{zipCode}
									</Typography>
								</Grid>
							)}

							{totalAnnualIncome && (
								<Grid item xs={6} sx={{ marginBottom: 2 }}>
									<Typography
										variant="subtitle1"
										component="p"
										gutterBottom
										sx={{
											fontWeight: 'bold',
											marginBottom: 1
										}}
									>
										Total Annual Income
									</Typography>
									<Typography
										variant="body2"
										component="p"
										gutterBottom
										sx={{ marginRight: 4 }}
									>
										{dollarFormatter.format(
											totalAnnualIncome
										)}
									</Typography>
								</Grid>
							)}

							{totalAssetsValue && (
								<Grid item xs={6} sx={{ marginBottom: 2 }}>
									<Typography
										variant="subtitle1"
										component="p"
										gutterBottom
										sx={{
											fontWeight: 'bold',
											marginBottom: 1
										}}
									>
										Total Assets Value
									</Typography>
									<Typography
										variant="body2"
										component="p"
										gutterBottom
										sx={{ marginRight: 4 }}
									>
										{dollarFormatter.format(
											totalAssetsValue
										)}
									</Typography>
								</Grid>
							)}

							{employmentStatus && (
								<Grid item xs={6} sx={{ marginBottom: 2 }}>
									<Typography
										variant="subtitle1"
										component="p"
										gutterBottom
										sx={{
											fontWeight: 'bold',
											marginBottom: 1
										}}
									>
										Employment Status
									</Typography>
									<Typography
										variant="body2"
										component="p"
										gutterBottom
										sx={{ marginRight: 4 }}
									>
										{employmentStatus}
									</Typography>
								</Grid>
							)}
						</>
					)}

					{/* Personal Refinance Loan */}
					{decodedLoanType(tradelineType, offerType) ===
						'Personal Refinance Loan' && (
						<>
							<Grid item xs={6} sx={{ marginBottom: 2 }}>
								<Typography
									variant="subtitle1"
									component="p"
									gutterBottom
									sx={{ fontWeight: 'bold', marginBottom: 1 }}
								>
									Address
								</Typography>
								<Typography
									variant="body2"
									component="p"
									gutterBottom
									sx={{ marginRight: 4 }}
								>
									{addressLine1}
									<br />
									{addressLine2 && (
										<>
											{addressLine2}
											<br />
										</>
									)}
									{city},&nbsp;
									{state}&nbsp;
									{zipCode}
								</Typography>
							</Grid>

							{totalAnnualIncome && (
								<Grid item xs={6} sx={{ marginBottom: 2 }}>
									<Typography
										variant="subtitle1"
										component="p"
										gutterBottom
										sx={{
											fontWeight: 'bold',
											marginBottom: 1
										}}
									>
										Total Annual Income
									</Typography>
									<Typography
										variant="body2"
										component="p"
										gutterBottom
										sx={{ marginRight: 4 }}
									>
										{dollarFormatter.format(
											totalAnnualIncome
										)}
									</Typography>
								</Grid>
							)}

							{totalAssetsValue && (
								<Grid item xs={6} sx={{ marginBottom: 2 }}>
									<Typography
										variant="subtitle1"
										component="p"
										gutterBottom
										sx={{
											fontWeight: 'bold',
											marginBottom: 1
										}}
									>
										Total Assets Value
									</Typography>
									<Typography
										variant="body2"
										component="p"
										gutterBottom
										sx={{ marginRight: 4 }}
									>
										{dollarFormatter.format(
											totalAssetsValue
										)}
									</Typography>
								</Grid>
							)}

							{employmentStatus && (
								<Grid item xs={6} sx={{ marginBottom: 2 }}>
									<Typography
										variant="subtitle1"
										component="p"
										gutterBottom
										sx={{
											fontWeight: 'bold',
											marginBottom: 1
										}}
									>
										Employment Status
									</Typography>
									<Typography
										variant="body2"
										component="p"
										gutterBottom
										sx={{ marginRight: 4 }}
									>
										{employmentStatus}
									</Typography>
								</Grid>
							)}
						</>
					)}

					{/* Student Refinance Loan */}
					{decodedLoanType(tradelineType, offerType) ===
						'Student Refinance Loan' && (
						<Grid item xs={6} sx={{ marginBottom: 2 }}>
							<Typography
								variant="subtitle1"
								component="p"
								gutterBottom
								sx={{ fontWeight: 'bold', marginBottom: 1 }}
							>
								Address
							</Typography>
							<Typography
								variant="body2"
								component="p"
								gutterBottom
								sx={{ marginRight: 4 }}
							>
								{addressLine1}
								<br />
								{addressLine2 && (
									<>
										{addressLine2}
										<br />
									</>
								)}
								{city},&nbsp;
								{state}&nbsp;
								{zipCode}
							</Typography>
						</Grid>
					)}

					{/* Commercial Loan */}
					{decodedLoanType(tradelineType, offerType) ===
						'Commercial Loan' && (
						<>
							<Grid item xs={6} sx={{ marginBottom: 2 }}>
								<Typography
									variant="subtitle1"
									component="p"
									gutterBottom
									sx={{ fontWeight: 'bold', marginBottom: 1 }}
								>
									Company Name
								</Typography>
								<Typography
									variant="body2"
									component="p"
									gutterBottom
									sx={{ marginRight: 4 }}
								>
									{companyName || 'NA'}
								</Typography>
							</Grid>

							<Grid item xs={6} sx={{ marginBottom: 2 }}>
								<Typography
									variant="subtitle1"
									component="p"
									gutterBottom
									sx={{ fontWeight: 'bold', marginBottom: 1 }}
								>
									Company HQ Location
								</Typography>
								<Typography
									variant="body2"
									component="p"
									gutterBottom
									sx={{ marginRight: 4, lineHeight: 1.6 }}
								>
									{addressLine1}
									<br />
									{addressLine2 && (
										<>
											{addressLine2}
											<br />
										</>
									)}
									{city},&nbsp;
									{state}&nbsp;
									{zipCode}
								</Typography>
							</Grid>

							<Grid item xs={6} sx={{ marginBottom: 2 }}>
								<Typography
									variant="subtitle1"
									component="p"
									gutterBottom
									sx={{ fontWeight: 'bold', marginBottom: 1 }}
								>
									Primary Use
								</Typography>
								<Typography
									variant="body2"
									component="p"
									gutterBottom
									sx={{ marginRight: 4 }}
								>
									{reasonForLoan || 'NA'}
								</Typography>
							</Grid>
						</>
					)}
				</Grid>
			</Paper>

			<EditRequestInfoModal
				newLoanDetails={newLoanDetails}
				isOpen={isModalOpen}
				handleClose={() => setIsModalOpen(false)}
				advisorEmail={advisorEmail}
			/>
		</>
	);
}

LoanRequestCard.propTypes = {
	cardHeading: PropTypes.string
};

LoanRequestCard.defaultProps = {
	cardHeading: null
};

export default LoanRequestCard;
