import { useQueries } from 'react-query';
import { useParams } from 'react-router-dom';

// Our Axios
import axiosInstance from 'services/API/API';

// Our Endpoints
import { chatGptInsightsUrl } from 'shared/api-urls';

// Our Query Keys
import { GPT_REWRITTEN_INSIGHTS } from 'shared/query-keys';

// Calculations
import { getAllFrontendInsights } from 'components/ExportableReport/getFrontEndInsights';

// Selectors
import selectInsightsDataFromWalletOverview from 'selectors/selectInsightsDataFromWalletOverview';

// Our Constants
import {
	DEFAULT_STALE_TIME_FIVE_MINUTES,
	FE_CHATGPT_ONLY
} from 'shared/constants';

/*
 if insights_mode is 0 -> FE rules with chatGpt FE_CHATGPT_ONLY
 if insights_mode is 1 -> BE rules no chatGpt
 if insights_mode is 2 -> BE rules with chatGpt
*/

const INSIGHTS_MODE = process.env.REACT_APP_INSIGHTS_MODE;

function useQueriesInsightsToChatGpt(isReady, walletOverviewData) {
	const { clientId } = useParams();

	const DYNAMIC_CHATGPT_INSIGHTS_QUERY_KEY = clientId
		? [GPT_REWRITTEN_INSIGHTS, clientId]
		: [GPT_REWRITTEN_INSIGHTS];

	const requiredInsightsData =
		selectInsightsDataFromWalletOverview(walletOverviewData);

	const safeInsights =
		getAllFrontendInsights(
			requiredInsightsData.liabilities,
			requiredInsightsData.clientFinancialData
		) ?? [];

	return useQueries(
		safeInsights.map((singleInsight, index) => ({
			queryKey: [...DYNAMIC_CHATGPT_INSIGHTS_QUERY_KEY, index],
			queryFn: async () => {
				const indexOfColon = singleInsight.indexOf(':');
				const insightType = singleInsight.slice(0, indexOfColon);
				const response = await axiosInstance.post(chatGptInsightsUrl, {
					snippet: singleInsight
				});

				const insight = response.data.data.rewritten;

				return { insightType, insightTextOutput: insight };
			},
			refetchOnWindowFocus: false,
			enabled: isReady && INSIGHTS_MODE === FE_CHATGPT_ONLY,
			staleTime: DEFAULT_STALE_TIME_FIVE_MINUTES
		}))
	);
}

export default useQueriesInsightsToChatGpt;
