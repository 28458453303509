import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@emotion/react';
import { useNavigate, useParams } from 'react-router-dom';
import { useQueryClient } from 'react-query';

// Our Query Keys
import { FA_CURRENT_CLIENT_ID, WALLET_OVERVIEW_DATA } from 'shared/query-keys';

// Our Components
import CardItem from 'components/SoraWallet/Cards/CardItem';
import { TertiaryButton } from 'components/Button/Button';

// Our Assets
import PiggyIcon from 'assets/icons/carousel_icons/piggyIcon.svg';

// OUr Utils
import { dollarFormatter } from 'shared/utils';

// Our Routes
import { REFINANCE_OFFER_ROUTE } from 'routes';

function UntappedSavingsCard({
	calculatedValue,
	heading,
	tooltTipText,
	withoutButton
}) {
	const SoraTheme = useTheme();
	const navigate = useNavigate();
	const queryClient = useQueryClient();
	const { primary } = SoraTheme.palette;
	const { tradelineId } = useParams();

	const clientId = queryClient.getQueryData(FA_CURRENT_CLIENT_ID);

	const dynamicKey = clientId
		? [WALLET_OVERVIEW_DATA, clientId]
		: WALLET_OVERVIEW_DATA;

	const walletOverviewData = queryClient.getQueryData(dynamicKey);

	const clientDataForOfferRoute = {
		firstName: walletOverviewData?.firstName,
		lastName: walletOverviewData?.lastName,
		clientId
	};

	const formattedCalculatedValue = dollarFormatter.format(calculatedValue);
	const displayCTAButton = calculatedValue > 0;

	return (
		<CardItem
			withoutButton
			toolTip={tooltTipText}
			sx={{
				display: 'flex',
				flexDirection: 'row',
				justifyContent: 'space-between',
				paddingBottom: 1,
				background: 'linear-gradient(180deg, white 0%, #F5F8FA 100%)',
				boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.20)'
			}}
		>
			<Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
				<Typography variant="subtitle1">{heading}</Typography>
				<Typography variant="h4" sx={{ color: primary.indigo }}>
					{formattedCalculatedValue}
				</Typography>
				{!withoutButton && displayCTAButton && (
					<TertiaryButton
						sx={{ marginTop: 2, marginBottom: 2 }}
						onClick={() =>
							navigate(REFINANCE_OFFER_ROUTE, {
								state: {
									clientData: clientDataForOfferRoute,
									tradelineId
								}
							})
						}
					>
						View Loan Matches
					</TertiaryButton>
				)}
			</Box>

			<Box
				component="img"
				src={PiggyIcon}
				sx={{
					display: { xs: 'none', md: 'block' },
					position: 'absolute',
					right: '0.1rem',
					bottom: 0,
					width: '6rem !important',
					height: '6rem !important',
					marginBottom: '0.375rem !important'
				}}
			/>
		</CardItem>
	);
}

UntappedSavingsCard.propTypes = {
	heading: PropTypes.string.isRequired,
	tooltTipText: PropTypes.string.isRequired,
	calculatedValue: PropTypes.number.isRequired,
	withoutButton: PropTypes.bool
};

UntappedSavingsCard.defaultProps = {
	withoutButton: false
};

export default UntappedSavingsCard;
