import { Typography } from '@mui/material';

import CommonPropTypes from 'shared/prop-types';
import PropTypes from 'prop-types';

function LiabilityFormHeading({ headingText, variant, sx, component }) {
	return (
		<Typography
			data-test="heading"
			variant={variant}
			component={component}
			sx={{ sx }}
		>
			{headingText}
		</Typography>
	);
}

LiabilityFormHeading.propTypes = {
	sx: CommonPropTypes.sx,
	variant: CommonPropTypes.typography,
	component: PropTypes.string
};

LiabilityFormHeading.defaultProps = {
	sx: {},
	variant: 'h1Gascogne',
	component: 'h1'
};

export default LiabilityFormHeading;
