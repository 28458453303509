import { useState, useMemo } from 'react';
import { Box, Grid, Skeleton, Typography } from '@mui/material';
import { Inbox } from '@talkjs/react';
import { useTheme } from '@emotion/react';

// Components
import AutocompleteTextinput from 'components/Input/AutocompleteTextinput';
import Dropdown from 'components/Dropdown/Dropdown';
import Modal from 'components/Modal/Modal';
import { TertiaryButton } from 'components/Button/Button';

// Our Hooks
import useGetAllAdvisors from 'hooks/admin/queries/useGetAllAdvisors';

// TalkJsHooks
import useGetAllMessagesForCurrentUser from 'hooks/talkjs/queries/useGetAllMessagesForCurrentUser';
import useMutateCreateGeneralChannel from 'hooks/talkjs/mutations/useMutateCreateGeneralChannel';

// Our Utils
import Loader from 'components/Loader/index';

// Constants
const DROP_DOWN_OPTIONS = ['Browse advisors'];

const MODAL_TITLE = {
	'Browse advisors': 'Choose an advisor',
	'Create a new topic': 'Create a new topic'
};

function AdminMessagesPage() {
	const SoraTheme = useTheme();

	// Advisor State
	const [selectedAdvisor, setSelectedAdvisor] = useState(null);
	const [currentConversation, setCurrentConversation] = useState(null);
	const [advisorSearchName, setAdvisorSearchName] = useState('');
	const [modalSelectedItem, setModalSelectedItem] = useState('');
	// const [topicName, setTopicName] = useState('');

	const createAdvisorGeneralChannel = useMutateCreateGeneralChannel();

	const {
		isLoading: isGetAdvisorsLoading,
		data,
		isSuccess: isGetAdvisorsSuccess
	} = useGetAllAdvisors();

	const {
		isLoading: isGetAllMessagesLoading,
		isSuccess: isGetAllMessaagesSuccess
	} = useGetAllMessagesForCurrentUser(setCurrentConversation);

	const isPageLoading = isGetAdvisorsLoading || isGetAllMessagesLoading;

	const isPageReady = isGetAdvisorsSuccess && isGetAllMessaagesSuccess;

	// Creates a label set for each client. Sets these labels to an array of "items", which is used to populate the options within the client selection dropdown
	const listOfAdvisors = useMemo(() => {
		if (isGetAdvisorsSuccess) {
			const advisorList = data.map(({ fullName, advisorId }) => ({
				name: fullName,
				id: advisorId
			}));

			return advisorList;
		}

		return [];
	}, [data]);

	const handleClientSelect = (
		currentSelectedAdvisor,
		updateSelectedAdvisor
	) => {
		updateSelectedAdvisor(currentSelectedAdvisor);
		setSelectedAdvisor(currentSelectedAdvisor);
	};

	const handleClientChange = (incomingValue) => {
		if (incomingValue === '') {
			setSelectedAdvisor('');
		}
		setAdvisorSearchName(incomingValue);
	};

	if (isPageLoading) {
		return (
			<Grid
				item
				container
				xs={12}
				sx={{
					height: '100%',
					width: '100%',
					flexGrow: 2,
					alignItems: 'stretch'
				}}
			>
				<Grid item xs={12}>
					<Typography
						variant="h1Gascogne"
						sx={{ display: 'block', marginBottom: 6 }}
					>
						Messages
					</Typography>
					<Box>
						<Typography
							variant="subtitle1"
							sx={{ marginBottom: 1 }}
						>
							Start a new channel
						</Typography>
						<Typography variant="body2">
							Channels organize conversations into dedicated
							spaces. Choose a specific client or topic below to
							chat with Sora about.
						</Typography>
					</Box>
					<Box
						sx={{
							marginLeft: 2,
							marginTop: 4,
							height: '100%',
							width: '100%',
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'flex-start'
						}}
					>
						<Box sx={{ width: 298, marginBottom: 4 }}>
							<Skeleton
								sx={{
									height: '100%',
									width: '100%'
								}}
							/>
						</Box>
						<Box
							sx={{
								width: '100%',
								flexGrow: 2,
								maxHeight: 650
							}}
						>
							<Skeleton
								sx={{
									height: '100%',
									width: '100%'
								}}
							/>
						</Box>
					</Box>
				</Grid>
			</Grid>
		);
	}

	if (isPageReady) {
		return (
			<>
				<Grid
					item
					xs={12}
					sx={{
						marginBottom: 2
					}}
				>
					<Typography
						variant="h1Gascogne"
						sx={{ display: 'block', marginBottom: 4 }}
					>
						Message Center
					</Typography>
					<Typography variant="subtitle2">
						Welcome to the Sora Message Center
					</Typography>

					<Typography variant="body2" sx={{ marginBottom: 4 }}>
						<br />
						You can use the General channel to send secure inquiries
						to Sora. <br />
						To request support for a particular client, select the
						client from the dropdown below and submit your inquiry.
						<br />
						Your clients do not see any messages submitted through
						the Message Center.
					</Typography>

					<Dropdown
						sx={{ maxWidth: 300 }}
						items={DROP_DOWN_OPTIONS}
						label=""
						onChange={setModalSelectedItem}
						selected={modalSelectedItem}
						placeholder="Select Advisor"
						variant="outlined"
					/>

					{modalSelectedItem !== '' && (
						<Modal
							isOpen={modalSelectedItem !== ''}
							handleClose={() => setModalSelectedItem('')}
							TitleComponent={
								<Typography
									variant="h2Gascogne"
									sx={{
										color: SoraTheme.palette.primary.indigo
									}}
								>
									{MODAL_TITLE[modalSelectedItem]}
								</Typography>
							}
						>
							{isGetAdvisorsSuccess && listOfAdvisors.length > 0 && (
								<AutocompleteTextinput
									autoCompleteSX={{
										marginBottom: 4
									}}
									forcePopupIcon
									freeSolo={false}
									getOptionLabel={({ name }) => name}
									helperText="Select a client from the dropdown to create a channel about"
									handleChange={handleClientChange}
									handleSelect={handleClientSelect}
									label=""
									value={advisorSearchName}
									optionList={listOfAdvisors}
									withoutFilter={false}
									renderOption={(props, option) => (
										<li {...props} key={option.id}>
											{option.name}
										</li>
									)}
									withTag
								/>
							)}

							{createAdvisorGeneralChannel.isLoading && (
								<Loader />
							)}

							{!createAdvisorGeneralChannel.isLoading && (
								<TertiaryButton
									isDisabled={selectedAdvisor === null}
									onClick={() => {
										const { id: advisorId } =
											selectedAdvisor;

										createAdvisorGeneralChannel.mutate(
											advisorId,
											{
												onSuccess: (conversationId) => {
													setCurrentConversation(
														conversationId
													);

													setSelectedAdvisor(null);
													setAdvisorSearchName('');
													setModalSelectedItem('');
												}
											}
										);
									}}
								>
									Next
								</TertiaryButton>
							)}
						</Modal>
					)}
				</Grid>

				<Grid item xs={12} sx={{ marginLeft: 2, marginRight: 2 }}>
					<Inbox
						conversationId={currentConversation}
						style={{
							height: 600,
							width: '100%'
						}}
						messageField={{
							placeholder: 'Write a message...',
							visible: true
						}}
						loadingComponent={
							<Skeleton
								variant="rectangular"
								sx={{
									height: '100%',
									width: '100%'
								}}
							/>
						}
					/>
				</Grid>
			</>
		);
	}
}

export default AdminMessagesPage;
