/**
 *
 * @returns {string} - A string representation of the date one month from now in ISO format
 */
function createDefaultCloseDate() {
	const currentDate = new Date();
	const oneMonthFromNow = new Date(
		currentDate.getFullYear(),
		currentDate.getMonth() + 1,
		currentDate.getDate()
	);
	const formattedDate = oneMonthFromNow.toISOString();
	return formattedDate;
}

export default createDefaultCloseDate;
