import { useQueryClient, useMutation } from 'react-query';
import {
	ERROR_MESSAGE_DATA,
	CLIENT_REMOVED_DATA,
	FALIABILITYSUMMARY
} from 'shared/query-keys';

import { removeClientUrl } from 'shared/api-urls';
import axiosInstance from 'services/API/API';

const useMutateRemoveClient = () => {
	const queryClient = useQueryClient();
	return useMutation(
		async (selectedClientId) => {
			const removeClientUrlWithClientId =
				removeClientUrl + selectedClientId;

			await axiosInstance.patch(removeClientUrlWithClientId);
		},
		{
			onSuccess: async (data) => {
				queryClient.setQueryData(CLIENT_REMOVED_DATA, data);

				// invalidating query which removes the client from the advisors list of clients on the /dashboard page.
				await queryClient.invalidateQueries(FALIABILITYSUMMARY);
			},
			onError: (error) => {
				queryClient.setQueryData(ERROR_MESSAGE_DATA, error.message);
			}
		}
	);
};

export default useMutateRemoveClient;
