import { Radio } from '@mui/material';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';

import PropTypes from 'prop-types';
import CommonPropTypes from 'shared/prop-types';

function SquareRadioButton({ label, value, disabled, sx }) {
	return (
		<Radio
			checkedIcon={<CheckBoxIcon />}
			icon={<CheckBoxOutlineBlankIcon />}
			value={value}
			label={label}
			disabled={disabled}
			sx={sx}
		/>
	);
}

SquareRadioButton.propTypes = {
	label: PropTypes.string.isRequired,
	value: PropTypes.string.isRequired,
	disabled: PropTypes.bool,
	sx: CommonPropTypes.sx
};

SquareRadioButton.defaultProps = {
	disabled: false,
	sx: {}
};

export default SquareRadioButton;
