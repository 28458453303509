import { useMutation, useQueryClient } from 'react-query';

// Our Axios
import axiosInstance from 'services/API/API';

// Our Endpoints
import { loanTaskIsDoneUrl } from 'shared/api-urls';
import { ADVISOR_LOAN_REQUESTS } from 'shared/query-keys';

function useMutateMarkLoanTaskDone() {
	const queryClient = useQueryClient();
	return useMutation(
		async (taskToComplete) => {
			// Trigger the change
			await axiosInstance.patch(loanTaskIsDoneUrl, taskToComplete);

			return taskToComplete;
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries(ADVISOR_LOAN_REQUESTS);
			}
		}
	);
}
export default useMutateMarkLoanTaskDone;
