import { useState, useCallback } from 'react';
import { Typography } from '@mui/material';
import { useTheme } from '@emotion/react';

// Our Components
import DebtToleranceModal from 'components/Debt-Tolerance/DebtToleranceModal';

// Our Hooks
import useMutateGetMagicToken from 'hooks/passwordless/useMutateGetMagicToken';

function InviteButtonClientDTA({ clientData }) {
	const SoraTheme = useTheme();
	const { primary } = SoraTheme.palette;
	// must be an object with an email key
	const { email: selectedClientEmail } = clientData;

	// Mutations
	const getMagicToken = useMutateGetMagicToken();

	const { isLoading, data } = getMagicToken;

	// Local state
	const [isModalOpen, setIsModalOpen] = useState(false);

	const closeModal = useCallback(() => {
		setIsModalOpen(false);
		getMagicToken.reset();
	}, []);

	return (
		<>
			<Typography
				variant="body2"
				sx={{
					display: 'inline-block',
					cursor: 'pointer',
					color: primary.black,
					marginLeft: 0.5,
					marginRight: 0.5,
					textDecoration: 'underline 1px solid black',
					textUnderlineOffset: 2,
					fontWeight: 700
				}}
				onClick={() => {
					setIsModalOpen(true);
					getMagicToken.mutate({
						email: selectedClientEmail,
						route: '/dashboard/debt-tolerance-assessment'
					});
				}}
			>
				here
			</Typography>

			<DebtToleranceModal
				isLoading={isLoading}
				isModalOpen={isModalOpen}
				closeModal={closeModal}
				uniqueUrl={data?.data}
			/>
		</>
	);
}

export default InviteButtonClientDTA;
