import { useMutation } from 'react-query';
import { getAuth, signOut } from 'firebase/auth';

const useMutateSignOutUser = () => {
	const auth = getAuth();

	return useMutation(
		() =>
			// Call firebase signOut with firebase auth
			signOut(auth),
		{
			onSuccess: () => {
				window.location.href = 'https://www.sorafinance.com';
			}
		}
	);
};

export default useMutateSignOutUser;
