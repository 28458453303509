import { Typography } from '@mui/material';

// Our Components
import Loader from 'components/Loader';
import OfferTable from 'components/Offers/OfferTable/OfferTable';
import OfferColumns from 'components/Offers/OfferColumns/OfferColumns';

// Our Hooks
import useGetNewCashOutOffers from 'hooks/useGetCashOutOffers';

function CashoutOffer({ setIsModalOpen }) {
	const offerType = 'cash_out';

	const {
		data: cashoutOffers,
		error,
		isSuccess: getCashoutOffersIsSuccess,
		isLoading: getCashoutOfferIsLoading
	} = useGetNewCashOutOffers(offerType);

	if (error) {
		return (
			<Typography variant="h2Gascogne" component="h2">
				Whoops. Something went wrong with the offers.
			</Typography>
		);
	}

	if (getCashoutOfferIsLoading) return <Loader />;

	if (getCashoutOffersIsSuccess) {
		const clientHasNoOffers = cashoutOffers.length < 1;

		if (clientHasNoOffers) {
			return (
				<Typography variant="h4">
					Seems like no offers were found.
				</Typography>
			);
		}
		return (
			<>
				<OfferTable offerType={offerType} offers={cashoutOffers} />
				<OfferColumns
					offerType={offerType}
					offers={cashoutOffers}
					setIsModalOpen={setIsModalOpen}
				/>
			</>
		);
	}
}

export default CashoutOffer;
