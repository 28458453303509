/**
 *
 * @param {string} dob
 */
const convertDateStringToMethodfiFormat = (dob) => {
	const isString = typeof dob === 'string';

	// if the input is not a string version of dob just return it bro.
	if (!isString) return dob;

	// guaranteed string
	if (dob.includes('/')) {
		const splitDate = dob.split('/');

		const year = splitDate[2];
		const day = splitDate[1];
		const month = splitDate[0];

		return `${year}-${month}-${day}`;
	}

	return dob;
};

export default convertDateStringToMethodfiFormat;
