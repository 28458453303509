import { useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';

// Our Axios
import axiosInstance from 'services/API/API';

// Our Endpoint
import { cashoutOfferUrl } from 'shared/api-urls';

// Our Query Keys
import { CASH_OUT_OFFERS } from 'shared/query-keys';

function useGetNewCashOutOffers() {
	const location = useLocation();

	const { state } = location;

	const { tradelineId } = state;

	return useQuery(
		CASH_OUT_OFFERS,
		async () => {
			const res = await axiosInstance.get(
				`${cashoutOfferUrl}/${tradelineId}`
			);

			return res.data.data;
		},
		{
			refetchOnWindowFocus: false
		}
	);
}

export default useGetNewCashOutOffers;
