import { useMutation } from 'react-query';

// Our Axios Instance
import axiosInstance from 'services/API/API';

// Our Endpoint urls
import { maskAsAnotherUser } from 'shared/api-urls';

function useMutateRemoveUserMask() {
	return useMutation(async () => {
		const response = await axiosInstance.post(maskAsAnotherUser, {
			unmasq: true
		});
		const userUnmaskedData = response.data.data;

		return userUnmaskedData;
	});
}

export default useMutateRemoveUserMask;
