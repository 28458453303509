import { useParams, useNavigate } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import { Box, Typography } from '@mui/material/index';
import { Helmet } from 'react-helmet-async';
import { useEffect } from 'react';

// Our Components
import Loader from 'components/Loader/index';

// Our Hooks
import useMutateAddEmployee from 'hooks/employeeOnboarding/useMutateAddEmployee';

// Our Query Keys
import {
	CLIENTINVITECODE,
	EMPLOYEE_USER_DATA,
	INVITEE_EMAIL,
	ROLE
} from 'shared/query-keys';

function VerifyEmployee() {
	const queryClient = useQueryClient();
	const navigate = useNavigate();
	const addEmployee = useMutateAddEmployee();

	const { employeeData } = useParams();

	const decodedInfo = window.atob(employeeData);

	const parsedJson = JSON.parse(decodedInfo);

	const {
		companyUrl,
		email,
		firstName: firstname,
		lastName: lastname
	} = parsedJson;

	useEffect(() => {
		addEmployee.mutate(
			{ companyUrl, email, firstname, lastname },
			{
				onSuccess: (userInviteData) => {
					const { invitedCode } = userInviteData;
					queryClient.setQueryData(ROLE, 'CLIENT');
					queryClient.setQueryData(INVITEE_EMAIL, email);
					queryClient.setQueryData(CLIENTINVITECODE, invitedCode);
					queryClient.setQueryData(EMPLOYEE_USER_DATA, parsedJson);
					navigate('/signup');
				}
			}
		);
	}, []);

	const { isLoading, isError } = addEmployee;

	return (
		<>
			<Helmet>
				<title>Verify Employee</title>
			</Helmet>
			<Box
				sx={{
					height: '100vh',
					width: '100%',
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					marginLeft: 2,
					marginRight: 2
				}}
			>
				{!isError && (
					<Typography
						variant="h1Gascogne"
						component="h1"
						sx={{ marginTop: 4 }}
					>
						Checking Employee Eligibility
					</Typography>
				)}

				{isLoading && <Loader size={60} boxSX={{ marginTop: 10 }} />}
				{isError && (
					<>
						<Typography variant="h5" sx={{ marginTop: 20 }}>
							This email id is already in use.
						</Typography>
						<Typography variant="h5" sx={{ marginTop: 2 }}>
							Please try again with a different email address or
							reach out to contact@sorafinance.com for support
						</Typography>
					</>
				)}
			</Box>
		</>
	);
}

export default VerifyEmployee;
