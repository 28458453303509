const getDwellingType = (propertyType) => {
	const dwellingMap = {
		SINGLE_FAMILY: 'Single family home',
		TOWNHOUSE_OR_CONDO: 'Townhouse',
		MULTIPLE_UNIT: 'Multi-family home',
		SFR: 'Single family home'
	};

	return dwellingMap[propertyType] ?? '';
};

export default getDwellingType;
