import { useMutation, useQueryClient } from 'react-query';
import emailjs from '@emailjs/browser';

// Our Query Keys
import { SUCCESS_MESSAGE_DATA } from 'shared/query-keys';

// Our Utils
import { pullUrlFromInsight, removeLinkFromInsight } from 'shared/utils';

// EmailJs variables
const EMAIL_SERVICE_ID = process.env.REACT_APP_EMAILJS_SERVICE_ID;
const TEMPLATE_WITH_LINK = process.env.REACT_APP_EMAILJS_NEW_LOAN_TEMPLATE_ID;
const TEMPLATE_WITHOUT_LINK =
	process.env.REACT_APP_EMAILJS_INSIGHT_INQUIRY_WITHOUT_LINK;
const EMAIL_PUBLIC_KEY = process.env.REACT_APP_EMAILJS_PUBLIC_KEY;

function useMutationSendNewLoanInquiryInsightEmail(isClient) {
	const queryClient = useQueryClient();

	const successMessage = isClient
		? 'Your financial advisor will be in touch with you soon.'
		: 'Sora will be in touch with you soon.';

	return useMutation(
		async ({ insightRelatedWalletOverviewData, insightTextOutput }) => {
			const { advisorEmail, advisorName, email, firstName, lastName } =
				insightRelatedWalletOverviewData;
			const insightUrl = pullUrlFromInsight(insightTextOutput);
			const insightWithoutLink = removeLinkFromInsight(insightTextOutput);

			const emailSubject = `New Insight triggered | ${firstName} ${lastName}`;

			let emailJsParams = {
				name: `${firstName} ${lastName}`,
				subject: emailSubject
			};

			const advisorData = `Advisor who triggered this inquiry:
				Advisor Name: ${advisorName}
				Advisor Email: ${advisorEmail}`;

			const clientData = `Client this invite was triggered for: 
			${firstName} ${lastName}
			Email: ${email}`;

			const message = `${advisorData}
			
			${clientData}`;

			emailJsParams = {
				...emailJsParams,
				emailAddress: email,
				message,
				insightWithoutLink,
				insightUrl
			};
			// Because we cannot put conditionals within the emailjs templates, I have to
			// conditionally choose which email template to trigger. The one with the link, if it's there, otherwise the template without the link element
			if (insightUrl.includes('https://')) {
				await emailjs.send(
					EMAIL_SERVICE_ID,
					TEMPLATE_WITH_LINK,
					emailJsParams,
					EMAIL_PUBLIC_KEY
				);
			} else {
				await emailjs.send(
					EMAIL_SERVICE_ID,
					TEMPLATE_WITHOUT_LINK,
					emailJsParams,
					EMAIL_PUBLIC_KEY
				);
			}
		},
		{
			onSuccess: () => {
				queryClient.setQueryData(SUCCESS_MESSAGE_DATA, successMessage);
			}
		}
	);
}

export default useMutationSendNewLoanInquiryInsightEmail;
