import { forwardRef } from 'react';
import { Box, Typography } from '@mui/material';
import styled from 'styled-components';

// Our Components
import ExportableReportHeading from 'components/ExportableReport/Sections/ExportableReportHeading';
import ExportableReportClientDetails from 'components/ExportableReport/Sections/ExportableReportClientDetails';
import ExportableReportCharts from 'components/ExportableReport/Charts/ExportableReportCharts';
import ExportableReportInsights from 'components/ExportableReport/ExportableReportInsights';
import ExportableTable from 'components/ExportableReport/Sections/ExportableTable';
import ExportableReportFooter from 'components/ExportableReport/Sections/ExportableReportFooter';

// Our Hooks
import useGetWalletOverview from 'hooks/soraWallet/useGetWalletOverview';

// Our utils
import sortLiabilitiesByKey from 'shared/utils/walletOverview/sortByKey';

// This forces MUI to adhere to printing layout as landscape!
const StyledBox = styled(Box)`
	@page {
		margin: 30px !important;
	}
`;

const ExportableReport = forwardRef((props, ref) => {
	const {
		data: walletOverviewData,
		isSuccess,
		isLoading
	} = useGetWalletOverview();

	if (isLoading) return <h1>Loading...</h1>;

	if (isSuccess) {
		const {
			advisor,
			annualIncome,
			creditScore,
			firstName,
			lastName,
			liability,
			address
		} = walletOverviewData;

		const { liabilities, total: liabilitiesTotalBalance } = liability;

		const sortedLiabilities = sortLiabilitiesByKey(
			liabilities,
			'outstandingBalance',
			true
		);

		const clientFullName = `${firstName} ${lastName}`;

		return (
			<StyledBox
				ref={ref}
				{...props}
				sx={{
					visibility: 'hidden',
					overflow: 'hidden',
					width: 0,
					height: 0,
					'@media print': {
						visibility: 'visible',
						display: 'flex',
						padding: 2,
						flexDirection: 'column',
						justifyContent: 'center',
						width: '100%',
						height: '100%'
					}
				}}
			>
				<ExportableReportHeading
					advisor={advisor}
					clientFullName={clientFullName}
				/>

				<ExportableReportClientDetails
					annualIncome={annualIncome}
					address={address}
					creditScore={creditScore}
				/>

				<ExportableReportCharts />

				<ExportableReportInsights
					walletOverviewData={walletOverviewData}
					headingText="Sora Insights:"
				/>

				<ExportableTable
					liabilities={sortedLiabilities}
					liabilitiesTotalBalance={liabilitiesTotalBalance}
				/>

				<ExportableReportFooter />
			</StyledBox>
		);
	}

	return <Typography>Something went wrong!</Typography>;
});

export default ExportableReport;
