import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import HomeIcon from '@mui/icons-material/Home';

import { dollarFormatter } from 'shared/utils';

// Our Constants
import { HELOC } from 'shared/constants';

function OtherLoanRowItem({
	otherLoanName,
	balance,
	rate,
	isFullyPaid,
	tradeLineType
}) {
	const formattedBalance = balance && dollarFormatter.format(balance);
	const rateWithCheck = rate ?? 0; // if rate is missing or null it will default to zero

	// If isFullyPaid is undefined, it's a heloc loan since that field doesn't exist on a returned heloc liability
	const parsedIsFullyPaid = isFullyPaid ?? 'N/A';

	const iconForRow = () => {
		if (tradeLineType === HELOC) {
			return <HomeIcon sx={{ marginRight: 2 }} />;
		}
		return <CreditCardIcon sx={{ marginRight: 2 }} />;
	};

	// Heloc loans display 'N/A', credit cards show either close icon or check icon
	// eslint-disable-next-line consistent-return
	const paidFullyColumnValue = () => {
		// First case will on my Heloc loans
		if (parsedIsFullyPaid && isFullyPaid) {
			return 'N/A';
		}
		// Credit card loans paid off each month
		if (parsedIsFullyPaid) {
			return <CheckIcon />;
		}
		// Credit card loans NOT paid off each month
		if (!parsedIsFullyPaid && !isFullyPaid) {
			return <CloseIcon />;
		}
	};

	return (
		<>
			<Box sx={{ display: 'inline-flex' }}>
				{iconForRow()}
				<Typography
					sx={{
						width: '15ch',
						overflow: 'hidden',
						textOverflow: 'ellipsis',
						whiteSpace: 'nowrap'
					}}
				>
					{otherLoanName}
				</Typography>
			</Box>
			<Typography sx={{ width: 180 }}> {formattedBalance}</Typography>
			<Typography sx={{ width: 160 }}>{rateWithCheck}%</Typography>
			<Box
				sx={{
					width: 150,
					display: 'flex',
					justifyContent: 'center'
				}}
			>
				{paidFullyColumnValue()}
			</Box>
		</>
	);
}

OtherLoanRowItem.propTypes = {
	balance: PropTypes.number.isRequired,
	otherLoanName: PropTypes.string.isRequired,
	isFullyPaid: PropTypes.bool,
	rate: PropTypes.number.isRequired,
	tradeLineType: PropTypes.string.isRequired
};

OtherLoanRowItem.defaultProps = {
	isFullyPaid: true
};

export default OtherLoanRowItem;
