import {
	generatePath,
	Outlet,
	useLocation,
	useNavigate,
	useParams
} from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Box, Grid, Typography } from '@mui/material';
import { useTheme } from '@emotion/react';
import { useQueryClient } from 'react-query';
import { useMemo } from 'react';

// Our Components
import Alert from 'components/Alert';
import LiabilityFormHeading from 'components/Client/Onboarding/LiabilityFormHeading';
import SelectLoanLiability from 'components/Client/newLoan/SelectLoanLiabilityCard';
import { PrimaryButton } from 'components/Button/Button';

// Routes
import {
	ADVISOR_ADD_NEW_AUTO_ROUTE,
	ADVISOR_ADD_NEW_CREDIT_CARD_ROUTE,
	ADVISOR_ADD_NEW_COMMERCIAL_LOAN_ROUTE,
	ADVISOR_ADD_NEW_MORTGAGE_ROUTE,
	ADVISOR_ADD_NEW_PERSONAL_ROUTE,
	ADVISOR_ADD_NEW_STUDENT_ROUTE,
	ADVISOR_BASE_NEW_LOAN_SELECTION_ROUTE,
	ADVISOR_NEW_LOAN_SELECTION_ROUTE,
	CLIENT_ADD_NEW_AUTO_ROUTE,
	CLIENT_ADD_NEW_CREDIT_CARD_ROUTE,
	CLIENT_ADD_NEW_COMMERCIAL_LOAN_ROUTE,
	CLIENT_ADD_NEW_MORTGAGE_ROUTE,
	CLIENT_ADD_NEW_PERSONAL_ROUTE,
	CLIENT_ADD_NEW_STUDENT_ROUTE,
	CLIENT_NEW_LOAN_SELECTION_ROUTE
} from 'routes';

// Query Keys
import { ERROR_MESSAGE_DATA } from 'shared/query-keys';

function NewLoanSelection() {
	// Defining useNavigate from react router dom
	const queryClient = useQueryClient();
	const SoraTheme = useTheme();
	const { primary } = SoraTheme.palette;

	const navigate = useNavigate();
	const location = useLocation();

	const { state: locationState } = location;

	const isAtAddNewLoanSelectionPage = () => {
		// Series of checks to figure out if we're on the loan selection page or
		// on a new liability form page
		if (location.pathname.includes('mortgage')) {
			return false;
		}
		if (location.pathname.includes('auto')) {
			return false;
		}
		if (location.pathname.includes('student')) {
			return false;
		}
		if (location.pathname.includes('personal')) {
			return false;
		}
		if (location.pathname.includes('creditcard')) {
			return false;
		}

		if (location.pathname.includes('commercial')) {
			return false;
		}

		if (
			location.pathname === ADVISOR_NEW_LOAN_SELECTION_ROUTE ||
			CLIENT_NEW_LOAN_SELECTION_ROUTE
		) {
			return true;
		}

		return false;
	};

	const atAdvisorUrl = location.pathname.includes(
		ADVISOR_BASE_NEW_LOAN_SELECTION_ROUTE
	);

	const isClient = !atAdvisorUrl;
	const params = useParams();
	const clientId = params?.clientId;

	// Mortgage
	const mortgagePath = !isClient
		? ADVISOR_ADD_NEW_MORTGAGE_ROUTE
		: CLIENT_ADD_NEW_MORTGAGE_ROUTE;

	const payload = !isClient ? { clientId } : {};

	const updateMortgageRoute = useMemo(
		() => generatePath(mortgagePath, payload),
		[isClient]
	);

	// Auto
	const autoPath = !isClient
		? ADVISOR_ADD_NEW_AUTO_ROUTE
		: CLIENT_ADD_NEW_AUTO_ROUTE;

	const updateAutoRoute = useMemo(
		() => generatePath(autoPath, payload),
		[isClient]
	);

	// Student
	const studentPath = !isClient
		? ADVISOR_ADD_NEW_STUDENT_ROUTE
		: CLIENT_ADD_NEW_STUDENT_ROUTE;

	const updateStudentRoute = useMemo(
		() => generatePath(studentPath, payload),
		[isClient]
	);

	// Personal
	const personalPath = !isClient
		? ADVISOR_ADD_NEW_PERSONAL_ROUTE
		: CLIENT_ADD_NEW_PERSONAL_ROUTE;

	const updatePersonalRoute = useMemo(
		() => generatePath(personalPath, payload),
		[isClient]
	);

	// Credit Card
	const creditCardPath = !isClient
		? ADVISOR_ADD_NEW_CREDIT_CARD_ROUTE
		: CLIENT_ADD_NEW_CREDIT_CARD_ROUTE;

	const updateCreditCardRoute = useMemo(
		() => generatePath(creditCardPath, payload),
		[isClient]
	);

	// Commercial Loan

	const commerialPath = !isClient
		? ADVISOR_ADD_NEW_COMMERCIAL_LOAN_ROUTE
		: CLIENT_ADD_NEW_COMMERCIAL_LOAN_ROUTE;

	const updateCommerialRoute = useMemo(
		() => generatePath(commerialPath, payload),
		[isClient]
	);

	const ERROR_MESSAGE = queryClient.getQueryData(ERROR_MESSAGE_DATA);

	if (isAtAddNewLoanSelectionPage())
		return (
			<Box sx={{ marginLeft: 2, marginRight: 2, marginTop: 4 }}>
				<Helmet>
					<title>Additional Loan</title>
				</Helmet>

				{/* Once data binding begins we will add Mortgage specific information to this header */}
				<LiabilityFormHeading
					headingText="Manually add existing loans"
					sx={{ marginBottom: 2 }}
				/>
				{ERROR_MESSAGE && <Alert variant="error" />}
				<Typography variant="body1" color={primary.black}>
					Click on the card to add a liability.
				</Typography>
				<Grid item xs={12} sx={{ marginTop: 4, marginBottom: 4 }}>
					<Box
						sx={{
							display: 'flex',
							gap: 6,
							width: '100%'
						}}
					>
						<SelectLoanLiability
							LiabilityType="Mortgage"
							loanHeading="Home Loan"
							onClickNavRoute={updateMortgageRoute}
							routeState={locationState}
							withoutCheckBox
						/>
						<SelectLoanLiability
							LiabilityType="Auto"
							loanHeading="Auto Loan"
							onClickNavRoute={updateAutoRoute}
							routeState={locationState}
							withoutCheckBox
						/>
					</Box>
					<Box
						sx={{
							display: 'flex',
							gap: 6,
							width: '100%'
						}}
					>
						<SelectLoanLiability
							LiabilityType="Student"
							loanHeading="Student Loan"
							onClickNavRoute={updateStudentRoute}
							routeState={locationState}
							withoutCheckBox
						/>
						<SelectLoanLiability
							LiabilityType="Personal"
							loanHeading="Personal Loan"
							onClickNavRoute={updatePersonalRoute}
							routeState={locationState}
							withoutCheckBox
						/>
					</Box>
					<Box
						sx={{
							display: 'flex',
							gap: 6,
							width: '100%'
						}}
					>
						<SelectLoanLiability
							LiabilityType="CreditCard"
							loanHeading="Credit Card"
							onClickNavRoute={updateCreditCardRoute}
							routeState={locationState}
							withoutCheckBox
						/>

						<SelectLoanLiability
							LiabilityType="Commercial"
							loanHeading="Commercial Loan"
							onClickNavRoute={updateCommerialRoute}
							routeState={locationState}
							withoutCheckBox
							imageSX={{ marginBottom: 2 }}
						/>
					</Box>
				</Grid>

				<PrimaryButton
					data-test="done"
					onClick={() => {
						navigate(-1);
					}}
				>
					Done
				</PrimaryButton>
			</Box>
		);

	return <Outlet />;
}

export default NewLoanSelection;
