import PropTypes from 'prop-types';
import { Typography } from '@mui/material';

// Our Utils
import { dollarFormatter } from 'shared/utils';

import normalizeLoanType from 'shared/utils/clientOnboarding/normalizeLoanType';

// Our Constants
import { CREDITCARD, MORTGAGE, HELOC } from 'shared/constants';

const getFormattedLoanType = (loanType) => {
	if (loanType === CREDITCARD) return 'Credit card';
	if (loanType === MORTGAGE) return 'Home';
	if (loanType === HELOC) return 'HELOC';

	// normalizeLoanType turns AUTO to Auto
	return normalizeLoanType(loanType);
};

function LiabilityFoundRowItem({ balance, lender, loanType }) {
	const formattedBalance = balance && dollarFormatter.format(balance);
	const formattedLoanType = getFormattedLoanType(loanType);

	return (
		<>
			<Typography sx={{ width: 100 }}>{formattedLoanType}</Typography>
			<Typography sx={{ width: 150, marginRight: -6 }}>
				{formattedBalance}
			</Typography>
			<Typography
				sx={{
					flexGrow: 2,
					whiteSpace: 'nowrap',
					overflow: 'hidden',
					textOverflow: 'ellipsis'
				}}
			>
				{lender}
			</Typography>
		</>
	);
}

LiabilityFoundRowItem.propTypes = {
	balance: PropTypes.number.isRequired,
	lender: PropTypes.string.isRequired,
	loanType: PropTypes.string.isRequired
};

export default LiabilityFoundRowItem;
