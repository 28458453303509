import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

// Our Axios
import axiosInstance from 'services/API/API';

// Our Endpoints
import { getSoraInsightsUrl } from 'shared/api-urls';

// Our Query Keys
import { SORA_INSIGHTS } from 'shared/query-keys';

// Our Constants
import {
	DEFAULT_STALE_TIME_FIVE_MINUTES,
	BE_NO_CHATGPT,
	BE_WITH_CHATGPT
} from 'shared/constants';

/*
 if insights_mode is 0 -> FE rules with chatGpt
 if insights_mode is 1 -> BE rules no chatGpt
 if insights_mode is 2 -> BE rules with chatGpt
*/
const INSIGHTS_MODE = process.env.REACT_APP_INSIGHTS_MODE;

const IS_BE_INSIGHTS_MODE =
	INSIGHTS_MODE === BE_NO_CHATGPT || INSIGHTS_MODE === BE_WITH_CHATGPT;

const INSIGHT_TYPE_TO_RULE_MAPPING = {
	auto_rule: 'Auto',
	auto_loan_rule: 'Auto',
	credit_rule: 'Credit Card',
	student_loan_rule: 'Student',
	credit_score_rule: 'Credit Score',
	mortgage_loan_rule: 'Mortgage',
	sora_score_rule: 'Sora Score'
};

const stripRule = (incomingRuleName) => {
	const lastIndexOfUnderScore = incomingRuleName.lastIndexOf('_');
	return incomingRuleName.slice(0, lastIndexOfUnderScore);
};

const CREDIT_RULE_EXCEPTION_IDS = new Set(['17', '18', '19']);

const FIVE_SECONDS = 5000;

function useGetAllRuleEngineInsights() {
	const { clientId } = useParams();

	const DYNAMIC_CHATGPT_INSIGHTS_QUERY_KEY = clientId
		? [SORA_INSIGHTS, clientId]
		: [SORA_INSIGHTS];

	return useQuery(
		DYNAMIC_CHATGPT_INSIGHTS_QUERY_KEY,
		async () => {
			const ammendQueryParam = clientId ? `/${clientId}` : '';

			// eslint-disable-next-line no-promise-executor-return
			await new Promise((resolve) => setTimeout(resolve, FIVE_SECONDS));

			const response = await axiosInstance.get(
				`${getSoraInsightsUrl}${ammendQueryParam}`
			);

			const responseData = response.data.data;

			const insights = responseData.result;

			return insights;
		},
		{
			enabled: IS_BE_INSIGHTS_MODE,
			staleTime: DEFAULT_STALE_TIME_FIVE_MINUTES,
			select: (insightsResults) => {
				if (INSIGHTS_MODE === BE_NO_CHATGPT) {
					const exisitingInsights = insightsResults.map(
						({ output: insightTextOutput, ruleName, ruleId }) => {
							// This rule is an outlier where its ruleName is "credit_score_rule_9"
							// The formatting algoithm below takes the first two words of the ruleName
							// e.g. credit_score and formats it
							// this rule id will be an exception to the algorithm
							if (ruleId === 28) {
								return {
									insightTextOutput,
									insightType: 'Debt to Income Ratio',
									ruleName,
									ruleId
								};
							}
							const insightType =
								INSIGHT_TYPE_TO_RULE_MAPPING[
									stripRule(ruleName)
								] ?? 'Sora Insight';

							return {
								insightTextOutput,
								insightType,
								ruleName,
								ruleId
							};
						}
					);

					const filteredAllOtherThanCredit = exisitingInsights
						.filter(
							(obj, index) =>
								index ===
								exisitingInsights.findIndex(
									(o) => obj.ruleId === o.ruleId
								)
						)
						.filter(({ ruleName }) => {
							const currentStripedRuleName = stripRule(ruleName);
							// this give credit_rule

							return currentStripedRuleName !== 'credit_rule';
						});

					const filteredCreditRules = exisitingInsights.filter(
						({ ruleName }) => {
							const currentStripedRuleName = stripRule(ruleName);
							// this give credit_rule

							return currentStripedRuleName === 'credit_rule';
						}
					);

					const cleanedFilteredCreditRules = filteredCreditRules.map(
						(currentCreditRule) => {
							const { ruleName } = currentCreditRule;
							const lastIndexOfUnderScore =
								ruleName.lastIndexOf('_');

							const currentRuleId = ruleName.slice(
								lastIndexOfUnderScore + 1
							);

							return { ...currentCreditRule, currentRuleId };
						}
					);

					const exceptionCreditRuleIds =
						cleanedFilteredCreditRules.filter(({ currentRuleId }) =>
							CREDIT_RULE_EXCEPTION_IDS.has(currentRuleId)
						);

					const otherCreditRuleIds = cleanedFilteredCreditRules
						.filter(
							({ currentRuleId }) =>
								!CREDIT_RULE_EXCEPTION_IDS.has(currentRuleId)
						)
						.slice(0, 1);

					const finalCreditRules = [
						...exceptionCreditRuleIds,
						...otherCreditRuleIds
					].map((currentCredit) => {
						const tempCurrentCredit = currentCredit;
						delete tempCurrentCredit.currentRuleId;
						return tempCurrentCredit;
					});

					const finalListOfInsights = [
						...filteredAllOtherThanCredit,
						...finalCreditRules
					];

					return finalListOfInsights;
				}

				if (INSIGHTS_MODE === BE_WITH_CHATGPT)
					return insightsResults.map(
						({ chatGptRewritten: insightTextOutput, ruleName }) => {
							const insightType =
								INSIGHT_TYPE_TO_RULE_MAPPING[
									stripRule(ruleName)
								] ?? 'Sora Insight';

							return { insightTextOutput, insightType };
						}
					);
				return [];
			}
		}
	);
}

export default useGetAllRuleEngineInsights;
