import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import { useMemo } from 'react';

// Our Components
import Dropdown from 'components/Dropdown/Dropdown';

// Our Constants
import { US_STATE_OPTIONS } from 'shared/constants';

// Our PropTypes
import CommonPropTypes from 'shared/prop-types';

function USStateDropdown({
	dropDownLabelSX,
	dropDownSX,
	gridColumns,
	helperText,
	label,
	setState,
	state,
	sx,
	tabIndex
}) {
	const US_STATE_LIST = useMemo(() => US_STATE_OPTIONS, []);

	return (
		<Grid item xs={gridColumns} sx={sx}>
			<Dropdown
				helperText={helperText}
				items={US_STATE_LIST}
				label={label}
				labelSx={dropDownLabelSX}
				onChange={setState}
				placeholder="State"
				selected={state}
				sx={dropDownSX}
				tabIndex={tabIndex}
				variant="outlined"
			/>
		</Grid>
	);
}

USStateDropdown.propTypes = {
	dropDownLabelSX: CommonPropTypes.sx,
	dropDownSX: CommonPropTypes.sx,
	gridColumns: PropTypes.number,
	helperText: PropTypes.string,
	state: PropTypes.string.isRequired,
	setState: PropTypes.func.isRequired,
	label: PropTypes.string,
	sx: CommonPropTypes.sx
};

USStateDropdown.defaultProps = {
	dropDownLabelSX: {},
	dropDownSX: {},
	gridColumns: 5,
	helperText: '',
	label: 'State',
	sx: {}
};

export default USStateDropdown;
