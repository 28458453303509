import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import BasicFrame from 'components/Frame/Basic';
import { Grid } from '@mui/material';

// Our components
import TextInput from 'components/Input/TextInput';
import PasswordTextInput from 'components/Input/PasswordTextInput';

// Out Hooks
import useMutateAddUserMask from 'hooks/useMutateAddUserMask';
import useMutateUnmaskUser from 'hooks/useMutateRemoveUserMask';
import { PrimaryButton } from 'components/Button/Button';

const iFrameLogin = process.env.REACT_APP_LOGIN_URL;
const iFrameSettings = process.env.REACT_APP_SETTINGS_URL;

function SuperAdmin() {
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [index, setIndex] = useState(1);
	const [iframeUrl, setIFrameUrl] = useState(iFrameLogin);

	const maskAsAnotherUser = useMutateAddUserMask();

	const unmaskAsAnotherUser = useMutateUnmaskUser();

	// Handle field change
	const handleEmailChange = (value) => {
		setEmail(value);
	};

	const handlePasswordChange = (value) => {
		setPassword(value);
	};

	const handleIFrameUrlChange = (value) => {
		setIFrameUrl(value);
	};
	// Masking user
	const addUserMask = (e) => {
		e.preventDefault();

		maskAsAnotherUser.mutate(
			{ userEmail: email, masqSecret: password, unmasq: false },
			{
				onSuccess: () => {
					// Changing the index of the iframe to refresh the page
					setIndex(index + 1);
					// Setting iframe to the /setting page, easy place to verify you're looking at the right user after masking as them
					handleIFrameUrlChange(iFrameSettings);
				},
				onError: () => {
					// eslint-disable-next-line no-console
					console.error('Failure while masking user');
				}
			}
		);
	};

	// Taking mask off
	const removeUserMask = (e) => {
		e.preventDefault();

		unmaskAsAnotherUser.mutate(
			{},
			{
				onSuccess: () => {
					// This refreshes the iframe
					setIndex(index + 1);
				},
				onError: () => {
					// eslint-disable-next-line no-console
					console.error('Failure while unmasking user.');
				}
			}
		);
	};

	return (
		<>
			<Helmet>
				<title>Super Admin</title>
			</Helmet>
			<BasicFrame
				sx={{
					height: '100vh',
					width: '100%',
					paddingTop: 4,
					paddingLeft: 6
				}}
			>
				<Grid item xs={4} sx={{ marginBottom: 10 }}>
					<TextInput
						id="email"
						label="Email address"
						type="email"
						onChange={handleEmailChange}
						value={email}
						error={
							email === '' ||
							!email.includes('.') ||
							!email.includes('@')
						}
						helperText={
							email === '' ||
							!email.includes('.') ||
							!email.includes('@')
								? 'Please enter a valid email address'
								: ''
						}
						sx={{ marginRight: 2 }}
					/>
				</Grid>
				<Grid item xs={4}>
					<PasswordTextInput
						password={password}
						handlePasswordChange={handlePasswordChange}
					/>
				</Grid>
				<Grid item xs={4}>
					<PrimaryButton
						sx={{ marginTop: 5, marginLeft: 4 }}
						onClick={addUserMask}
					>
						Mask as user
					</PrimaryButton>
					<PrimaryButton
						sx={{ marginTop: 5, marginLeft: 4 }}
						onClick={removeUserMask}
					>
						Remove user mask
					</PrimaryButton>
				</Grid>
				<Grid item xs={12}>
					<iframe
						key={index}
						title="SoraApplication"
						src={iframeUrl}
						height="620"
						width="95%"
					/>
				</Grid>
			</BasicFrame>
		</>
	);
}

export default SuperAdmin;
