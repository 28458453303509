import 'react-multi-carousel/lib/styles.css';
import Carousel from 'react-multi-carousel';

// Our Components
import CarouselButtonGroup from 'components/Carousel/CarouselButtonGroup';

// Our Constants
import { DEFAULT_INSIGHTS_CAROUSEL_BREAK_POINTS } from 'shared/constants';

function StandardCarousel({ carouselItems }) {
	return (
		<Carousel
			infinite
			customButtonGroup={<CarouselButtonGroup />}
			responsive={DEFAULT_INSIGHTS_CAROUSEL_BREAK_POINTS}
			arrows={false}
			draggable
			renderButtonGroupOutside
			renderDotsOutside={false}
			shouldResetAutoplay={false}
			partialVisible
		>
			{carouselItems}
		</Carousel>
	);
}

export default StandardCarousel;
