import { Helmet } from 'react-helmet-async';
import { Box, Grid, Typography } from '@mui/material';
import { useState, useMemo } from 'react';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import emailjs from '@emailjs/browser';

// Our Components
import CurrencyTextInput from 'components/Input/CurrencyTextInput';
import Dropdown from 'components/Dropdown/Dropdown';
import Loader from 'components/Loader';
import Modal from 'components/Modal/Modal';
import { PrimaryButton } from 'components/Button/Button';
import StandardDatePicker from 'components/DatePicker/StandardDatePicker';
import TextInput from 'components/Input/TextInput';

// Our Hooks
import useGetProfile from 'hooks/useGetProfile';

// Our Utils
import {
	dollarFormatter,
	isSubmissionReady,
	roundToDecimal
} from 'shared/utils';

// Our Routes
import { CLIENT_SORAWALLET_ROUTE } from 'routes';

// Our Constants
import { TEXT } from 'components/Input/Types';

const AUTO_CONDITION_OPTIONS = ['New', 'Used', 'Certified Pre-owned'];

// EmailJs variables
const EMAIL_SERVICE_ID = process.env.REACT_APP_EMAILJS_SERVICE_ID;
const EMAIL_TEMPLATE_ID = process.env.REACT_APP_EMAILJS_NEW_LOAN_TEMPLATE_ID;
const EMAIL_PUBLIC_KEY = process.env.REACT_APP_EMAILJS_PUBLIC_KEY;

function AutoLoanQuestionnaire() {
	const navigate = useNavigate();

	// Financial Related Auto State
	const [estimatedPurchaseValue, setEstimatedPurchaseValue] = useState('');
	const [estimatedDownPayment, setEstimatedDownPayment] = useState('');

	// Automobile related state
	const [autoMake, setAutoMake] = useState('');
	const [autoModel, setAutoModel] = useState('');
	const [autoYear, setAutoYear] = useState(null);
	const [autoCondition, setAutoCondition] = useState('');

	// Modal State
	const [isModalOpen, setIsModalOpen] = useState(false);

	const requiredFormData = [estimatedPurchaseValue, estimatedDownPayment];

	const isFormReady = useMemo(
		() => isSubmissionReady(requiredFormData),
		requiredFormData
	);

	const { isLoading, isSuccess, data: userProfileData } = useGetProfile();

	const sendNewLoanInquiry = useMutation(
		async () => {
			if (userProfileData) {
				const { email, firstName, lastName } = userProfileData;
				const loanType = 'Auto';
				const emailSubject = `New Auto Loan Request from ${firstName} ${lastName} for a ${autoCondition} ${autoMake} ${autoModel}`;

				const estimatedPercentDown =
					roundToDecimal(
						estimatedDownPayment / estimatedPurchaseValue
					) * 100 ?? 'NA';

				const message = `
			New Auto Loan Request:

			Full Name: ${firstName} ${lastName}

			Email: ${email}

            Estimated Purchase Value: ${dollarFormatter.format(
				estimatedPurchaseValue
			)}

            Estimated Down Payment: ${dollarFormatter.format(
				estimatedDownPayment
			)}

            Estimated Percent Down: ${estimatedPercentDown}%

            Vehicle Make: ${autoMake}

            Vehicle Model: ${autoModel}

            Vehicle Year: ${autoYear && autoYear.getFullYear()}

            Vehicle Condition: ${autoCondition}

		`;

				const emailJsParams = {
					name: `${firstName} ${lastName}`,
					subject: emailSubject,
					emailAddress: email,
					loanType,
					message
				};

				await emailjs.send(
					EMAIL_SERVICE_ID,
					EMAIL_TEMPLATE_ID,
					emailJsParams,
					EMAIL_PUBLIC_KEY
				);
			}
		},
		{ onSuccess: () => setIsModalOpen(true) }
	);

	const { isLoading: submissionIsLoading } = sendNewLoanInquiry;

	return (
		<Box
			sx={{
				marginLeft: 2,
				marginRight: 2,
				height: '100%',
				width: '100%'
			}}
		>
			<Helmet>
				<title>New Auto Loan Questionnaire</title>
			</Helmet>

			<Grid item xs={12} sx={{ marginBottom: 4 }}>
				<Typography
					variant="h1Gascogne"
					component="h1"
					gutterBottom
					sx={{ marginTop: 4 }}
				>
					Give us information about your new car
				</Typography>
			</Grid>

			{isLoading && <Loader size={60} boxSX={{ marginTop: 10 }} />}
			{isSuccess && (
				<Grid container item spacing={2}>
					<Grid item xs={12} md={6}>
						<CurrencyTextInput
							label="Estimated purchase value"
							subLabel="Estimated price of vehicle"
							value={estimatedPurchaseValue}
							onChange={setEstimatedPurchaseValue}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<CurrencyTextInput
							label="Estimated Down Payment"
							value={estimatedDownPayment}
							onChange={setEstimatedDownPayment}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<TextInput
							value={autoMake}
							onChange={setAutoMake}
							type={TEXT}
							label="Vehicle Make"
							subLabel="Vehicle Manufacturer"
						/>
					</Grid>
					<Grid item xs={12} md={6} sx={{ marginBottom: 4 }}>
						<TextInput
							value={autoModel}
							onChange={setAutoModel}
							type={TEXT}
							label="Vehicle Model"
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<StandardDatePicker
							inputFormat="yyyy"
							views={['year']}
							label="Vehicle Year"
							value={autoYear}
							onChange={setAutoYear}
							helperText="What year was this vehicle released?"
						/>
					</Grid>
					<Grid item xs={12} md={6} sx={{ marginBottom: 4 }}>
						<Dropdown
							label="Vehicle condition"
							items={AUTO_CONDITION_OPTIONS}
							selected={autoCondition}
							onChange={setAutoCondition}
							helperText="New, Used, Certified Pre-owned"
						/>
					</Grid>

					<Grid item xs={12}>
						{submissionIsLoading && <Loader />}
						{!submissionIsLoading && (
							<PrimaryButton
								isDisabled={!isFormReady}
								onClick={() => sendNewLoanInquiry.mutate({})}
							>
								Apply for car loan
							</PrimaryButton>
						)}
					</Grid>
				</Grid>
			)}
			<Modal
				title="A loan concierge will contact you shortly"
				subtitle="Thank you for choosing Sora"
				isOpen={isModalOpen}
				handleClose={() => setIsModalOpen(false)}
			>
				<PrimaryButton
					onClick={() => {
						navigate(CLIENT_SORAWALLET_ROUTE);
						setIsModalOpen(false);
					}}
				>
					Back to wallet
				</PrimaryButton>
			</Modal>
		</Box>
	);
}

export default AutoLoanQuestionnaire;
