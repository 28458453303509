import { useState } from 'react';

// Our Components
import Alert from 'components/Alert';
import ContactUserForm from 'components/FADashboard/Clients/ContactUserForm';
import Modal from 'components/Modal/Modal';
import RefinanceOffer from 'components/Offers/OfferPageBaseOnType/RefinanceOffer';
import CashoutOffer from 'components/Offers/OfferPageBaseOnType/CashoutOffer';
import NewLoanOffer from 'components/Offers/OfferPageBaseOnType/NewLoanOffer';

// Our Hooks
import useMutateContactClient from 'hooks/useMutateContactClient';

function Offers({ clientId, name, offerType, role }) {
	const [isModalOpen, setIsModalOpen] = useState(false);

	// Mutation
	const contactClient = useMutateContactClient();
	const { isError: contactClientError, isSuccess: contactClientSuccess } =
		contactClient;

	const handleContactSubmission = ({ messageText }) => {
		setIsModalOpen(false);
		contactClient.mutate({ clientId, messageText });
	};

	const isRefinanceOffer = offerType === 'refinance';
	const isCashoutOffer = offerType === 'cash_out';
	const isNewLoanOffer = offerType === 'new_loan';

	const isAdvisor = role === 'ADVISOR';

	return (
		<>
			{isAdvisor && contactClientSuccess && <Alert variant="success" />}
			{isAdvisor && contactClientError && <Alert variant="error" />}

			{isRefinanceOffer && (
				<RefinanceOffer setIsModalOpen={setIsModalOpen} />
			)}

			{isCashoutOffer && <CashoutOffer setIsModalOpen={setIsModalOpen} />}

			{isNewLoanOffer && <NewLoanOffer setIsModalOpen={setIsModalOpen} />}

			{isAdvisor && (
				<Modal
					isOpen={isModalOpen}
					handleClose={() => setIsModalOpen(!isModalOpen)}
					title="Contact Client"
					subtitle="Edit the fields below to send a custom note to your client"
				>
					<ContactUserForm
						clientData={{ clientId, name }}
						handleContactSubmission={handleContactSubmission}
						subjectLine="Sora has a new offer for you"
					/>
				</Modal>
			)}
		</>
	);
}

export default Offers;
