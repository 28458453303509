import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import CommonPropTypes from 'shared/prop-types';

// Helper TabPanel Commpoent
function TabPanel(props) {
	const { children, value, index, sx, ...other } = props;

	const isCurrentPanelSelected = value === index;

	return (
		<Box
			component="div"
			role="tabpanel"
			hidden={!isCurrentPanelSelected}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{isCurrentPanelSelected && <Box sx={sx}>{children}</Box>}
		</Box>
	);
}

TabPanel.propTypes = {
	sx: CommonPropTypes.sx,
	value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
	index: PropTypes.number.isRequired
};

TabPanel.defaultProps = {
	sx: {}
};

export default TabPanel;
