import { useNavigate } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import { Helmet } from 'react-helmet-async';
import { Box, Grid, Typography } from '@mui/material';

// Our Query Keys
import { ERROR_MESSAGE_DATA, SUCCESS_MESSAGE_DATA } from 'shared/query-keys';
// Our Components
import Alert from 'components/Alert';
import { PrimaryButton, SecondaryButton } from 'components/Button/Button';

import {
	LIABILITY_ADDITIONAL_ROUTE,
	CLIENT_ONBOARDING_ADDITIONAL_DETAILS_ROUTE
} from 'routes';

// Hooks
import useCleanUpQueries from 'hooks/useCleanUpQueries';

function UnableToVerify() {
	const navigate = useNavigate();
	const queryClient = useQueryClient();
	const heading = queryClient.getQueryData(ERROR_MESSAGE_DATA);

	const wasMethodFiSuccessful =
		queryClient.getQueryData(SUCCESS_MESSAGE_DATA) ===
		'Sora successfully gathered your liability information.';

	useCleanUpQueries([ERROR_MESSAGE_DATA, SUCCESS_MESSAGE_DATA]);

	return (
		<>
			<Helmet>
				<title>Unable to verify</title>
			</Helmet>
			<Grid
				item
				xs={12}
				md={8}
				sx={{
					height: '100vh',
					width: '100%',
					marginLeft: 2,
					marginRight: 2
				}}
			>
				{wasMethodFiSuccessful && <Alert variant="success" />}
				{heading !== undefined ? (
					<>
						<Typography variant="h1Gascogne" marginTop={4}>
							{heading[0]}
						</Typography>
						<Typography variant="body2" marginTop={1}>
							{heading[1]}
						</Typography>
					</>
				) : (
					<>
						<Typography
							variant="h1Gascogne"
							component="h1"
							marginTop={4}
						>
							We were unable to verify your information
						</Typography>

						<Typography variant="body2" marginTop={2}>
							Please click below to add your loans manually or go
							back to the start of the flow
						</Typography>
					</>
				)}

				<Box
					sx={{
						display: 'flex',
						flexDirection: 'row',
						alignItems: 'center',
						gap: '32px',
						marginTop: '40px'
					}}
				>
					<PrimaryButton
						onClick={() => navigate(LIABILITY_ADDITIONAL_ROUTE)}
					>
						Add loans manually
					</PrimaryButton>

					<SecondaryButton
						onClick={() =>
							navigate(CLIENT_ONBOARDING_ADDITIONAL_DETAILS_ROUTE)
						}
					>
						Back to start
					</SecondaryButton>
				</Box>
			</Grid>
		</>
	);
}

export default UnableToVerify;
