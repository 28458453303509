import { Skeleton } from '@mui/material';
import { useTheme } from '@emotion/react';
import { useMemo } from 'react';
import PropTypes from 'prop-types';

// Our Components
import AutocompleteTextinput from 'components/Input/AutocompleteTextinput';

// Our Hooks
import useGetClients from 'hooks/useGetClients';

function AnalyticsClientList({
	disabled,
	handleClientSelect,
	clientSearchName,
	handleClientSearchName,
	selectedClient
}) {
	const SoraTheme = useTheme();

	const { isLoading, isSuccess, data } = useGetClients();

	const opacity = disabled ? 0.5 : 1;

	// Creates a label set for each client. Sets these labels to an array of "items", which is used to populate the options within the client selection dropdown
	const listOfClients = useMemo(() => {
		if (isSuccess) {
			const clientList = data.map(({ fullName, clientId }) => ({
				name: fullName,
				id: clientId
			}));

			return clientList;
		}

		return [];
	}, [data]);

	if (isLoading) {
		return <Skeleton variant="rectangular" width="100%" height={50} />;
	}

	return (
		<AutocompleteTextinput
			autoCompleteSelectedValue={selectedClient}
			sx={{
				backgroundColor: SoraTheme.palette.primary.white,
				color: 'black',
				opacity
			}}
			disabled={disabled}
			disablePortal
			forcePopupIcon
			getOptionLabel={({ name }) => name}
			helperText=""
			handleChange={handleClientSearchName}
			handleSelect={handleClientSelect}
			label=""
			placeholder="Select Client"
			value={clientSearchName}
			optionList={listOfClients}
			withoutFilter={false}
			renderOption={(props, option) => (
				<li {...props} key={option.id}>
					{option.name}
				</li>
			)}
		/>
	);
}

AnalyticsClientList.propTypes = {
	disabled: PropTypes.bool,
	handleClientSelect: PropTypes.func.isRequired,
	clientSearchName: PropTypes.string.isRequired,
	handleClientSearchName: PropTypes.func.isRequired
};

AnalyticsClientList.defaultProps = {
	disabled: false
};

export default AnalyticsClientList;
