import { useTheme } from '@emotion/react';
import { useState, useCallback } from 'react';
import { Badge, Box, Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';

// Icons
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';

// Our Components
import AvatarUpload from 'components/FileUpload/AvatarUpload';
import Loader from 'components/Loader/index';
import Modal from 'components/Modal/Modal';
import ProfilePicturePlaceHolder from 'components/Avatar/ProfilePicturePlaceHolder';

// our Hooks
import useGetProfilePictureOrCompanyLogo from 'hooks/advisorProfile/useGetProfilePictureOrCompanyLogo';
import { PrimaryButton } from 'components/Button/Button';

// helper function
function getMarginProperties(displayProfilePicture, isCorporate) {
	const isProfilePicturePresent = !!displayProfilePicture;

	if (isProfilePicturePresent) {
		if (isCorporate) return { marginRight: 4, marginBottom: 4 };
		return { marginRight: 2, marginBottom: 1 };
	}

	if (isCorporate) return { marginRight: 8, marginBottom: 10 };

	return { marginRight: 5, marginBottom: 6 };
}

// Main Component
function ProfileAvatar({ isCorporate }) {
	const SoraTheme = useTheme();
	const { primary } = SoraTheme.palette;

	// Constants
	const modalTitle = isCorporate
		? 'Update your company logo here'
		: 'Update your profile photo here';

	const labelHeading = isCorporate ? 'Company Logo' : 'Profile Photo';

	// Styling based on whether is profile picture or company logo
	const badgeOverLap = isCorporate ? 'rectangular' : 'circular';
	const borderRadius = isCorporate ? 0 : 200;
	const displayDimensions = isCorporate
		? { maxHeight: 200, maxWidth: 600 }
		: { height: 200, width: 200 };

	// Local state
	const [isModalOpen, setIsModalOpen] = useState(false);

	const { isLoading, isSuccess, data } =
		useGetProfilePictureOrCompanyLogo(isCorporate);

	const closeModal = useCallback(() => setIsModalOpen(false), []);

	if (isLoading) return <Loader size={60} />;

	if (isSuccess) {
		const { imageUrl, timestamp } = data;
		const displayProfilePicture = imageUrl;
		const displayFallBack = !imageUrl;

		const labelMarginRightStyling = isCorporate && displayFallBack ? 3 : 0;
		const labelGap = displayFallBack ? 0 : 2;

		const badgeMarginProperties = getMarginProperties(
			displayProfilePicture,
			isCorporate
		);

		return (
			<Badge
				overlap={badgeOverLap}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
				badgeContent={
					<Box
						sx={{
							bgcolor: 'white',
							borderRadius: 200,
							height: 50,
							width: 50,
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							...badgeMarginProperties
						}}
					>
						<PhotoCameraIcon
							sx={{
								width: 30,
								height: 30,
								border: `2px solid ${primary.darkGrey}`,
								color: primary.darkGrey,
								borderRadius: 200,
								padding: '2px',
								'&:hover': {
									opacity: 0.6,
									cursor: 'pointer'
								}
							}}
							onClick={() => setIsModalOpen(true)}
						/>
					</Box>
				}
			>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						justifyContent: 'center',
						gap: labelGap
					}}
				>
					<Typography
						variant="subtitle2"
						sx={{ marginRight: labelMarginRightStyling }}
					>
						{labelHeading}
					</Typography>
					{displayProfilePicture && (
						<Box
							key={timestamp}
							component="img"
							src={imageUrl}
							sx={{
								borderRadius,
								...displayDimensions
							}}
						/>
					)}

					{displayFallBack && (
						<ProfilePicturePlaceHolder isCorporate={isCorporate} />
					)}
				</Box>

				<Modal
					title={modalTitle}
					isOpen={isModalOpen}
					handleClose={closeModal}
				>
					<Grid container>
						<Grid item xs={12}>
							<AvatarUpload
								appear
								isCorporate={isCorporate}
								withoutAnimation
							/>
						</Grid>
						<Grid item xs={12}>
							<PrimaryButton
								sx={{ marginTop: 4 }}
								onClick={closeModal}
							>
								Close
							</PrimaryButton>
						</Grid>
					</Grid>
				</Modal>
			</Badge>
		);
	}
}

ProfileAvatar.propTypes = {
	isCorporate: PropTypes.bool
};

ProfileAvatar.defaultProps = {
	isCorporate: false
};

export default ProfileAvatar;
