/**
 * This function filters tradelines by tradeline type inclusively
 * e.g. tradelines, MORTGAGE -> returns all tradelines that are MORTGAGE
 * @param {Array<Tradeline>} tradelines
 * @param {string} tradelineType
 * @returns {Array<Tradeline>}
 */

function filterByTradelineType(tradelines, tradelineType) {
	return tradelines.filter(
		(tradeline) => tradeline.tradeLineType === tradelineType
	);
}

export default filterByTradelineType;
