import axios from 'axios';

const baseURL = process.env.REACT_APP_API_BASE_URL;

const axiosInstance = axios.create({
	baseURL,
	headers: {
		'Content-Type': 'application/json',
		'Access-Control-Allow-Credentials': true,
		Accept: 'application/json'
	},
	withCredentials: true
});

export default axiosInstance;
