import axiosInstance from 'services/API/API';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
// URLs
import { userExecuteUrl } from 'shared/api-urls';

// Our routes
import { CLIENT_SORAWALLET_ROUTE } from 'routes';

function useMutateExecuteLoan() {
	const navigate = useNavigate();
	return useMutation(
		async (loanId) => {
			const res = await axiosInstance.get(`${userExecuteUrl}/${loanId}`);

			return res.data.data;
		},
		{
			onSuccess: () => {
				navigate(CLIENT_SORAWALLET_ROUTE);
			}
		}
	);
}

export default useMutateExecuteLoan;
