import { useMutation } from 'react-query';

// Hubspot Axios Instance
import HubSpotAPI from 'services/API/HubSpotAPI';

function useMutateCreateHubspotAdvisor() {
	return useMutation(async (newAdvisorData) => {
		const response = await HubSpotAPI.post(
			'create/advisor',
			newAdvisorData
		);

		return response.data;
	});
}

export default useMutateCreateHubspotAdvisor;
