import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

// Our Axios Instance
import axiosInstance from 'services/API/API';

// Our endpoint
import { commercialLoanEndpoint } from 'shared/api-urls';

// Our Query Keys
import { COMMERCIAL_LOAN_LIABILITY } from 'shared/query-keys';

function useQueryGetCommercialLiability(enabled) {
	// const queryClient = useQueryClient();

	const { tradeLineId } = useParams();

	return useQuery(
		[COMMERCIAL_LOAN_LIABILITY, tradeLineId],
		async () => {
			const response = await axiosInstance.get(
				`${commercialLoanEndpoint}/${tradeLineId}`
			);
			const { data } = response.data;

			return data;
		},
		{
			enabled: enabled ?? false,
			onSuccess: () => {}
		}
	);
}

export default useQueryGetCommercialLiability;
