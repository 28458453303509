import { useMutation, useQueryClient } from 'react-query';

// our axios
import axiosInstance from 'services/API/API';

// Our Endpoints
import { setChosenLoanOfferUrl } from 'shared/api-urls';

// our query keys
import {
	ADVISOR_LOAN_REQUESTS,
	ERROR_MESSAGE_DATA,
	LOAN_REQUEST
} from 'shared/query-keys';

function useMutateUpdateChosenOffer() {
	const queryClient = useQueryClient();
	return useMutation(
		async ({ newLoanId, offerId, offeredBySora }) => {
			const response = await axiosInstance.patch(
				`${setChosenLoanOfferUrl}`,
				{
					newLoanId,
					offerId,
					offeredBySora
				}
			);

			return response.data;
		},
		{
			onSuccess: (loanRequestId) => {
				queryClient.refetchQueries({
					queryKey: LOAN_REQUEST,
					loanRequestId
				});
				queryClient.invalidateQueries(ADVISOR_LOAN_REQUESTS);
			}
		},
		{
			onError: (error) => {
				queryClient.setQueryData(ERROR_MESSAGE_DATA, error);
			}
		}
	);
}

export default useMutateUpdateChosenOffer;
