import { Box, Grid, Typography } from '@mui/material';
import { useState, useMemo, useEffect } from 'react';
import { useTheme } from '@emotion/react';
import { useLocation } from 'react-router-dom';

// Our Components
import Alert from 'components/Alert';
import CurrencyTextInput from 'components/Input/CurrencyTextInput';
import LiquidityOptions from 'components/Analytics/Liquidity/LiquidityOptions';
import LiquidityTable from 'components/Analytics/Liquidity/LiquidityTable';
import LoadingSkeleton from 'components/Frame/LoadingSkelton';
import Loader from 'components/Loader';
import { TertiaryButton } from 'components/Button/Button';
import ProjectionEndYearDropdown from 'components/Dropdown/analytics/ProjectionEndYearDropdown';
import ResultChart from 'components/Analytics/Results/ResultChart';
import StateAlert from 'components/Alert/StateAlert';

// Our Hooks
import useQueryGetAssumptions from 'hooks/analytics/assumptions/useQueryGetAssumptions';
import useMutateSaveAssumptions from 'hooks/analytics/assumptions/useMutateSaveAssumptions';
import useMutateGetLiquidAnalysis from 'hooks/analytics/calculations/useMutateGetLiquidAnalysis';

// Our Utils
import { dataIsValid, isFormValueReady } from 'shared/utils';

// Local Constants
const LIQUIDITY_TYPE_MAPPING = {
	'Sell Assets': 'asset',
	'Interest Only Loan': 'interest_only_liability',
	'Amortized Loan': 'liability'
};

const LIQUIDITY_PILL_OPTIONS_DEFAULT_STATE = [];

// helper fns
const constructLiquidityAnalysisPayload = (
	assumptions,
	selectedLiquidityOptions,
	formattedProjectionEndYear,
	amountNeeded,
	discountRate,
	prePopulatedClientData
) => {
	const { name } = prePopulatedClientData;

	const findLastSpace = name.lastIndexOf(' ');
	const firstName = name.slice(0, findLastSpace);
	const lastName = name.slice(findLastSpace + 1);

	const liquidityAnalysisPayload = {
		client_first_name: firstName,
		client_last_name: lastName,
		cash_required: +amountNeeded,
		risk_free_return_rate: +discountRate,
		horizon_years: formattedProjectionEndYear
	};

	selectedLiquidityOptions.forEach((option) => {
		const { type, interestRate, loanTerm } = option;
		const liquidityType = LIQUIDITY_TYPE_MAPPING[type];

		liquidityAnalysisPayload[liquidityType] = {
			liability_interest_rate: +interestRate,
			loan_terms_years: +loanTerm
		};
	});

	const { assets, dividendReturnRate, dividendTax, capitalGainsTax } =
		assumptions;

	const investmentAccount = assets.find(
		(asset) => asset.type === 'Investment accounts'
	) ?? { presentValue: '0', firstGrowth: '6' };

	const { presentValue, firstGrowth } = investmentAccount;

	liquidityAnalysisPayload.asset = {
		asset_starting_value: +presentValue,
		// TODO: if interest rate is available in state, use that, otherwise use the first growth rate
		annual_growth_interest_rate: +firstGrowth,
		dividend_yearly_return_rate: +dividendReturnRate,
		dividend_tax_rate: +dividendTax,
		cap_gains_tax_rate: +capitalGainsTax
	};

	return liquidityAnalysisPayload;
};

const constructChartData = (liquidityAnalysisData) => {
	const keysToIgnore = ['response_text1', 'response_text2'];

	const liquidityKeysAvailable = Object.keys(liquidityAnalysisData).filter(
		(key) => !keysToIgnore.includes(key)
	);

	const liquidityDataAvailable = liquidityKeysAvailable.filter((key) => {
		const currentKeyData = liquidityAnalysisData[key];

		return dataIsValid(currentKeyData);
	});

	const liquidityChartDataConfig = {
		assets: {
			name: 'Assets',
			fill: '#2985C2'
		},
		liability_data: {
			name: 'Amortized Loan',
			fill: '#FFBB00'
		},
		interest_only_liability_data: {
			name: 'Interest Only Loan',
			fill: '#44AD95'
		}
	};

	const chartData = liquidityDataAvailable.map((key) => {
		const currentChartConfig = liquidityChartDataConfig[key];
		const currentChartData = liquidityAnalysisData[key];

		const value = currentChartData.change_in_net_worth;

		return {
			...currentChartConfig,
			value
		};
	});

	return chartData;
};

function LiquidityAnalyzer() {
	const SoraTheme = useTheme();
	const { primary } = SoraTheme.palette;
	const { state } = useLocation();

	// Mutations
	const mutateLiquidityAnalysis = useMutateGetLiquidAnalysis();
	const mutateSaveAssumptions = useMutateSaveAssumptions();

	const {
		isLoading: isLiquidityAnalysisLoading,
		isSuccess: isLiquidityAnalysisSuccess,
		isError: isLiquidityAnalysisError,
		data: liquidityAnalysisData
	} = mutateLiquidityAnalysis;

	// State
	const [amountNeeded, setAmountNeeded] = useState('');
	const [selectedLiquidityOptions, setSelectedLiquidityOptions] = useState(
		LIQUIDITY_PILL_OPTIONS_DEFAULT_STATE
	);
	const [projectedYearsFromNow, setProjectedYearsFromNow] =
		useState('5 years from now');
	const [isStateAlertOpen, setIsStateAlertOpen] = useState(false);
	const [isSellAssetsSelected, setIsSellAssetsSelected] = useState(false);

	// Form Value Readiness
	const isAmountNeededReady =
		isFormValueReady(amountNeeded) && amountNeeded > 0;

	const isSelectedLiquidityOptionsReady =
		selectedLiquidityOptions.length > 0 &&
		selectedLiquidityOptions.every(
			({ interestRate, loanTerm }) =>
				isFormValueReady(interestRate) &&
				isFormValueReady(loanTerm) &&
				+interestRate > 0 &&
				+loanTerm > 0
		);

	const isProjectionYearsFromNowReady = isFormValueReady(
		projectedYearsFromNow
	);

	const isLiquidityAnalyzerReady =
		isAmountNeededReady &&
		isSelectedLiquidityOptionsReady &&
		isProjectionYearsFromNowReady;

	const prePopulatedClientData = state?.clientSelected;

	const {
		isLoading,
		isSuccess,
		data: assumptions
	} = useQueryGetAssumptions(prePopulatedClientData?.id);

	useEffect(() => {
		mutateLiquidityAnalysis.reset();
		setAmountNeeded('');
		setProjectedYearsFromNow('5 years from now');
		setSelectedLiquidityOptions(LIQUIDITY_PILL_OPTIONS_DEFAULT_STATE);
	}, [prePopulatedClientData?.id]);

	useEffect(() => {
		if (isSuccess && dataIsValid(assumptions)) {
			const {
				amountNeeded: existingAmount,
				projectedYearsFromNow: existingProjectedYearsFromNow,
				liquidityOptions: existingLiquidityOptions
			} = assumptions;

			if (existingAmount) {
				setAmountNeeded(existingAmount);
			}

			if (existingProjectedYearsFromNow) {
				const formattedProjectedYearsFromNow = `${existingProjectedYearsFromNow} years from now`;

				setProjectedYearsFromNow(formattedProjectedYearsFromNow);
			}

			if (existingLiquidityOptions) {
				setSelectedLiquidityOptions(existingLiquidityOptions);
			}
		}
	}, [assumptions, isSuccess]);

	const submitLiquidityAnalysis = () => {
		const isAssumptionsReady = dataIsValid(assumptions);

		if (!isAssumptionsReady) {
			setIsStateAlertOpen(true);
			return;
		}

		// Extract num from string and convert to number
		const formattedProjectionEndYear = Number.parseInt(
			projectedYearsFromNow,
			10
		);

		const saveAnalysisPayload = {
			...assumptions,
			amountNeeded: +amountNeeded,
			projectedYearsFromNow: formattedProjectionEndYear,
			liquidityOptions: selectedLiquidityOptions
		};

		const { discountRate } = assumptions;

		// TODO: Add edge case if investment account is not found
		const liquidityAnalysisPayload = constructLiquidityAnalysisPayload(
			assumptions,
			selectedLiquidityOptions,
			formattedProjectionEndYear,
			amountNeeded,
			discountRate,
			prePopulatedClientData
		);

		mutateSaveAssumptions.mutate(saveAnalysisPayload);
		mutateLiquidityAnalysis.mutate(liquidityAnalysisPayload);
	};

	const formattedChartData = useMemo(() => {
		if (!isLiquidityAnalysisSuccess) return [];

		// will have data to work with
		const chartData = constructChartData(liquidityAnalysisData);

		if (!isSellAssetsSelected) {
			const filteredChartData = chartData.filter(
				(item) => item.name !== 'Assets'
			);

			return filteredChartData.map((item) => ({
				...item,
				lender: ''
			}));
		}

		return chartData.map((item) => ({
			...item,
			lender: ''
		}));
	}, [
		isSellAssetsSelected,
		liquidityAnalysisData,
		isLiquidityAnalysisSuccess
	]);

	if (isLoading) {
		return <LoadingSkeleton />;
	}

	if (isSuccess) {
		return (
			<Box
				sx={{
					width: '100%',
					height: '100%',
					backgroundColor: 'white',
					padding: 7,
					borderRadius: 3
				}}
			>
				{isLiquidityAnalysisError && <Alert variant="error" />}

				{isStateAlertOpen && (
					<StateAlert
						variant="error"
						isOpen={isStateAlertOpen}
						setIsOpen={setIsStateAlertOpen}
						message="Please update assumptions to continue"
					/>
				)}

				<Typography variant="h2Gascogne" sx={{ color: primary.indigo }}>
					Liquidity Analysis
				</Typography>

				<Typography variant="body2">
					Discover the cheapest way for your client to access
					liquidity today
				</Typography>

				<Grid container sx={{ marginTop: 4, marginBottom: 4 }}>
					<Grid item xs={12} sm={4}>
						<CurrencyTextInput
							variant="h3Gascogne"
							label="Amount Needed"
							value={amountNeeded}
							error={!isAmountNeededReady}
							helperText="Amount available to invest must be greater than $0"
							onChange={setAmountNeeded}
							labelSx={{
								display: 'block',
								marginBottom: 1.5
							}}
						/>
					</Grid>
				</Grid>

				<Typography variant="h3Gascogne">Liquidity Options</Typography>

				<Typography variant="body2" sx={{ marginBottom: 2 }}>
					Select as many as you&apos;d like
				</Typography>

				<LiquidityOptions
					selectedLiquidityOptions={selectedLiquidityOptions}
					setSelectedLiquidityOptions={setSelectedLiquidityOptions}
					isSellAssetsSelected={isSellAssetsSelected}
					setIsSellAssetsSelected={setIsSellAssetsSelected}
				/>

				<Box sx={{ marginTop: 4, maxWidth: 800, marginBottom: 4 }}>
					<Typography
						variant="h3Gascogne"
						sx={{ display: 'block', marginBottom: 1 }}
					>
						Details
					</Typography>
					<LiquidityTable
						rowsState={selectedLiquidityOptions}
						setRowsState={setSelectedLiquidityOptions}
					/>
				</Box>

				<ProjectionEndYearDropdown
					projectedYearsFromNow={projectedYearsFromNow}
					setProjectedYearsFromNow={setProjectedYearsFromNow}
					labelVariant="h3Gascogne"
					labelSx={{ display: 'block', marginBottom: 1.5 }}
					sx={{
						width: 266.5,
						marginBottom: 4
					}}
				/>

				{isLiquidityAnalysisLoading && (
					<Loader boxSX={{ marginTop: 3 }} />
				)}

				{!isLiquidityAnalysisLoading && (
					<TertiaryButton
						disabled={!isLiquidityAnalyzerReady}
						onClick={submitLiquidityAnalysis}
					>
						Compare Options
					</TertiaryButton>
				)}

				{isLiquidityAnalysisSuccess && (
					<ResultChart
						subtitleText="Change in Future Net Worth ($K)"
						withTooltip
						tooltipText="This plot shows the impact of using different sources of liquidity today on your future net worth"
						caption={
							liquidityAnalysisData.response_text2 ??
							'something went wrong.'
						}
						recommendation={
							liquidityAnalysisData.response_text1 ??
							'something went wrong.'
						}
						isSwitchable={false}
						isFutureMode={false}
						setIsFutureMode={() => {}}
						currentBarchartData={formattedChartData}
						reset={() => mutateLiquidityAnalysis.reset()}
					/>
				)}
			</Box>
		);
	}
}

export default LiquidityAnalyzer;
