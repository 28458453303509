import { Grid, Link as ExternalLink, Typography } from '@mui/material';
import { useTheme } from '@emotion/react';

function Footer() {
	const SoraTheme = useTheme();
	const { primary } = SoraTheme.palette;

	return (
		<Grid
			item
			container
			sx={{
				height: 48,
				borderTop: `0.25px solid ${primary.black}`,
				justifyContent: 'center',
				alignItems: 'center',
				gap: 4
			}}
		>
			<Typography
				variant="footerText"
				sx={{
					textDecoration: 'none'
				}}
			>
				© 2024 Sora Finance
			</Typography>
			<ExternalLink
				href="https://www.sorafinance.com/privacy-policy"
				variant="footerText"
				sx={{
					color: `${SoraTheme.palette.primary.black}`
				}}
			>
				Privacy Policy
			</ExternalLink>
			<ExternalLink
				href="https://www.sorafinance.com/terms-of-service"
				variant="footerText"
				sx={{
					color: `${SoraTheme.palette.primary.black}`
				}}
			>
				Terms of Service
			</ExternalLink>
		</Grid>
	);
}

export default Footer;
