import { Helmet } from 'react-helmet-async';
import { Grid, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';

// imported Icons
import AddIcon from '@mui/icons-material/Add';

// Our Components
import Loader from 'components/Loader';
import FreemiumModal from 'components/Modal/fremium/FreemiumModal';
import { TertiaryButton } from 'components/Button/Button';

// Our Hooks
import useGetProfile from 'hooks/useGetProfile';

// Our Selectors
import selectFremiumProfileStatus from 'selectors/selectFremiumProfileStatus';

// Our Routes
import { INVITE_ROUTE } from 'routes';

function FADashboardHeading({ isLoading }) {
	const navigate = useNavigate();
	const [isFreemiumModalOpen, setIsFreemiumModalOpen] = useState(false);

	const { data: fremiumProfileStatus } = useGetProfile(
		selectFremiumProfileStatus
	);

	const shouldShowFreemiumModal =
		!fremiumProfileStatus.premium && fremiumProfileStatus.clientCount >= 5;

	return (
		<>
			<Helmet>
				<title>Financial advisor dashboard</title>
			</Helmet>

			<FreemiumModal
				isOpen={isFreemiumModalOpen}
				setIsOpen={setIsFreemiumModalOpen}
			/>

			{/* Child Grid container for Your highlights */}
			<Grid
				container
				sx={{
					justifyContent: 'space-between',
					alignItems: 'flex-end',
					marginBottom: 4
				}}
			>
				<Typography variant="h1Gascogne" component="h1">
					Liability Dashboard
				</Typography>

				<TertiaryButton
					sx={{ marginRight: 4 }}
					startIcon={<AddIcon />}
					onClick={() => {
						if (shouldShowFreemiumModal) {
							setIsFreemiumModalOpen(true);
						} else {
							navigate(INVITE_ROUTE);
						}
					}}
				>
					Add Client
				</TertiaryButton>
			</Grid>

			{isLoading && (
				<Grid
					container
					item
					xs={12}
					sx={{
						display: 'flex',
						alignItems: 'flex-start',
						justifyContent: 'center'
					}}
				>
					<Loader size={70} />
				</Grid>
			)}
		</>
	);
}

export default FADashboardHeading;
