import { useParams } from 'react-router-dom';
import { useQueryClient, useQuery } from 'react-query';

// Our Axios Instance
import axiosInstance from 'services/API/API';

// Our Endpoint
import { getDtsStatusUrl } from 'shared/api-urls';

// Our Query Keys
import { DTS_STATUS, ERROR_MESSAGE_DATA } from 'shared/query-keys';

// Constants
import { DEFAULT_STALE_TIME_FIVE_MINUTES } from 'shared/constants';

// our hooks
import useGetProfile from 'hooks/useGetProfile';

function useGetDts(select) {
	const queryClient = useQueryClient();
	const { clientId } = useParams();
	const { data: profileData } = useGetProfile();

	const userId = !clientId ? profileData.id : clientId;

	return useQuery(
		[DTS_STATUS, userId],
		async () => {
			const response = await axiosInstance.get(getDtsStatusUrl + userId);
			const { data } = response.data;

			return data;
		},
		{
			onError: (error) => {
				queryClient.setQueryData(ERROR_MESSAGE_DATA, error);
			},
			staleTime: DEFAULT_STALE_TIME_FIVE_MINUTES,
			refetchOnWindowFocus: false,
			select: (dtsData) => {
				if (select) return select(dtsData);
				return dtsData;
			}
		}
	);
}

export default useGetDts;
