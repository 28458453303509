import { useMemo } from 'react';

// Our Components
import AutoRefiForm from 'components/Forms/NewLoanForms/auto/AutoRefiForm';
import NewAutoLiability from 'components/Forms/NewLoanForms/auto/NewAutoForm';
import CashOutRefinanceForm from 'components/Forms/NewLoanForms/otherLoanForms/CashOutRefiForm';
import ConstructionLoanForm from 'components/Forms/NewLoanForms/otherLoanForms/ConstructionLoanForm';
import HelocLoanForm from 'components/Forms/NewLoanForms/heloc/HelocForm';
import MortgageRefiForm from 'components/Forms/NewLoanForms/mortgage/MortgageRefinanceForm';
import NewMortgageLoanForm from 'components/Forms/NewLoanForms/mortgage/NewMortgageForm';
import FarmForm from 'components/Forms/NewLoanForms/otherLoanForms/FarmLoanForm';
import LotForm from 'components/Forms/NewLoanForms/otherLoanForms/LotLoanForm';
import ReverseMortgageLoanForm from 'components/Forms/NewLoanForms/otherLoanForms/ReverseMortgageForm';
import SmallBusinessForm from 'components/Forms/NewLoanForms/otherLoanForms/SmallBusinessForm';
import NewPersonalForm from 'components/Forms/NewLoanForms/personal/NewPersonalForm';
import PersonalRefiForm from 'components/Forms/NewLoanForms/personal/PersonalRefinanceForm';
import StudentLoanRefiForm from 'components/Forms/NewLoanForms/student/StudentLoanRefinanceForm';
import Modal from 'components/Modal/Modal';

// Utils
import { decodedLoanType } from 'shared/utils';

function EditRequestInfoModal({
	advisorEmail,
	newLoanDetails,
	isOpen,
	handleClose
}) {
	// eslint-disable-next-line prefer-destructuring
	const tradelineType = newLoanDetails?.tradelineType;
	// eslint-disable-next-line prefer-destructuring
	const offerType = newLoanDetails?.offerType;

	const finalizedLoanType = useMemo(
		() => decodedLoanType(tradelineType, offerType),
		[tradelineType, offerType]
	);

	const isNewAutoLoan = finalizedLoanType === 'New Automotive Loan';
	const isNewMortgageLoan = finalizedLoanType === 'New Mortgage Loan';
	const isConstructionLoan = finalizedLoanType === 'Construction Loan';
	const isNewPersonalLoan = finalizedLoanType === 'New Personal Loan';
	const isNewFarmLoan = finalizedLoanType === 'New Farm Loan';
	const isNewLotLoan = finalizedLoanType === 'New Lot Loan';
	const isCommercialLoan = finalizedLoanType === 'Commercial Loan';
	const isAutoRefinanceLoan =
		finalizedLoanType === 'Automotive Refinance Loan';
	const isPersonalRefinanceLoan =
		finalizedLoanType === 'Personal Refinance Loan';
	const isMortgageRefinanceLoan =
		finalizedLoanType === 'Mortgage Refinance Loan';
	const isStudentRefinanceLoan =
		finalizedLoanType === 'Student Refinance Loan';
	const isReverseMortgageLoan = finalizedLoanType === 'Reverse Mortgage Loan';
	const isHelocLoan = finalizedLoanType === 'HELOC Loan';
	const isCashOutRefinanceLoan =
		finalizedLoanType === 'Cash Out Refinance Loan';

	return (
		<Modal
			isOpen={isOpen}
			handleClose={handleClose}
			title="Edit loan request information"
			sx={{}}
		>
			{isNewAutoLoan && (
				<NewAutoLiability
					advisorName={newLoanDetails?.advisorName}
					advisorEmail={advisorEmail}
					additionalNotesValue={newLoanDetails?.additionalNotes}
					clientId={newLoanDetails?.id}
					creditScoreValue={newLoanDetails?.creditScore}
					conditionValue={newLoanDetails?.condition}
					countyValue={newLoanDetails?.county}
					dealershipSaleValue={newLoanDetails?.dealershipSale}
					downPaymentPercentValue={newLoanDetails?.downPaymentPercent}
					estimatedPurchaseAmount={
						newLoanDetails?.estimatedPurchaseValue
					}
					emailAddressValue={newLoanDetails?.email}
					firstName={newLoanDetails?.firstName}
					lastName={newLoanDetails?.lastName}
					loanAmountValue={newLoanDetails?.loanAmount}
					loanTenureValue={newLoanDetails?.loanTenure}
					loanRequestId={newLoanDetails?.id}
					stateValue={newLoanDetails?.state}
					yearOfVehicleValue={newLoanDetails?.yearOfVehicle}
					postSuccessEvent={handleClose}
				/>
			)}

			{isNewMortgageLoan && (
				<NewMortgageLoanForm
					addressLine1Value={newLoanDetails?.addressLine1}
					addressLine2Value={newLoanDetails?.addressLine2}
					loanRequestId={newLoanDetails?.id}
					advisorName={newLoanDetails?.advisorName}
					advisorEmail={advisorEmail}
					emailAddressValue={newLoanDetails?.email}
					additionalNotesValue={newLoanDetails?.additionalNotes}
					cityValue={newLoanDetails?.city}
					stateValue={newLoanDetails?.state}
					zipCodeValue={newLoanDetails?.zipCode}
					clientId={newLoanDetails?.id}
					creditScoreValue={newLoanDetails?.creditScore}
					firstName={newLoanDetails?.firstName}
					lastName={newLoanDetails?.lastName}
					downPaymentPercentValue={newLoanDetails?.downPaymentPercent}
					estimatedPurchaseAmount={
						newLoanDetails?.estimatedPurchaseValue
					}
					annualIncome={newLoanDetails?.totalAnnualIncome}
					firstTimeHomeBuyerValue={newLoanDetails?.firstTimeHomeBuyer}
					ownershipTenureValue={newLoanDetails?.ownershipTenure}
					primaryUseValue={newLoanDetails?.primaryUse}
					propertyTypeValue={newLoanDetails?.propertyType}
					veteranStatusValue={newLoanDetails?.isVeteran}
					loanAmountValue={newLoanDetails?.loanAmount}
					postSuccessEvent={handleClose}
				/>
			)}

			{isConstructionLoan && (
				<ConstructionLoanForm
					loanRequestId={newLoanDetails?.id}
					advisorName={newLoanDetails?.advisorName}
					advisorEmail={advisorEmail}
					additionalNotesValue={newLoanDetails?.additionalNotes}
					addressLine1Value={newLoanDetails?.addressLine1}
					addressLine2Value={newLoanDetails?.addressLine2}
					annualIncome={newLoanDetails?.totalAnnualIncome}
					clientId={newLoanDetails?.id}
					cityValue={newLoanDetails?.city}
					creditScoreValue={newLoanDetails?.creditScore}
					downPaymentPercentValue={newLoanDetails?.downPaymentPercent}
					desiredLoanTermValue={newLoanDetails?.desiredLoanTerm}
					emailAddressValue={newLoanDetails?.email}
					firstName={newLoanDetails?.firstName}
					stateValue={newLoanDetails?.state}
					zipCodeValue={newLoanDetails?.zipCode}
					lastName={newLoanDetails?.lastName}
					loanAmountValue={newLoanDetails?.loanAmount}
					loanTypeValue={newLoanDetails?.loanType}
					primaryUseValue={newLoanDetails?.primaryUse}
					propertyTypeValue={newLoanDetails?.propertyType}
					postSuccessEvent={handleClose}
				/>
			)}

			{isNewPersonalLoan && (
				<NewPersonalForm
					loanRequestId={newLoanDetails?.id}
					advisorName={newLoanDetails?.advisorName}
					advisorEmail={advisorEmail}
					emailAddressValue={newLoanDetails?.email}
					additionalNotesValue={newLoanDetails?.additionalNotes}
					addressLine1Value={newLoanDetails?.addressLine1}
					addressLine2Value={newLoanDetails?.addressLine2}
					cityValue={newLoanDetails?.city}
					stateValue={newLoanDetails?.state}
					zipCodeValue={newLoanDetails?.zipCode}
					employerValue={newLoanDetails?.employer}
					jobTitleValue={newLoanDetails?.jobTitle}
					veteranStatusValue={newLoanDetails?.isVeteran}
					annualIncome={newLoanDetails?.totalAnnualIncome}
					assetsAmount={newLoanDetails?.totalAssets}
					rentOrOwnValue={newLoanDetails?.rentOrOwn}
					monthlyHousingPaymentValue={
						newLoanDetails?.monthlyHousingPayment
					}
					clientId={newLoanDetails?.id}
					creditScoreValue={newLoanDetails?.creditScore}
					dobValue={newLoanDetails?.dob}
					employmentStatusValue={newLoanDetails?.employmentStatus}
					firstName={newLoanDetails?.firstName}
					lastName={newLoanDetails?.lastName}
					postSuccessEvent={handleClose}
					currentTermValue={newLoanDetails?.currentTerm}
					loanAmountValue={newLoanDetails?.loanAmount}
					tenureValue={newLoanDetails?.ownershipTenure}
					employmentStartDate={newLoanDetails?.startDate}
				/>
			)}

			{isNewFarmLoan && (
				<FarmForm
					loanRequestId={newLoanDetails?.id}
					advisorName={newLoanDetails?.advisorName}
					advisorEmail={advisorEmail}
					emailAddressValue={newLoanDetails?.email}
					clientId={newLoanDetails?.id}
					creditScoreValue={newLoanDetails?.creditScore}
					firstName={newLoanDetails?.firstName}
					lastName={newLoanDetails?.lastName}
					addressLine1Value={newLoanDetails?.addressLine1}
					addressLine2Value={newLoanDetails?.addressLine2}
					cityValue={newLoanDetails?.city}
					stateValue={newLoanDetails?.state}
					zipCodeValue={newLoanDetails?.zipCode}
					additionalNotesValue={newLoanDetails?.additionalNotes}
					acresValue={newLoanDetails?.acres}
					landUseValue={newLoanDetails?.landUse}
					postSuccessEvent={handleClose}
				/>
			)}

			{isNewLotLoan && (
				<LotForm
					loanRequestId={newLoanDetails?.id}
					advisorName={newLoanDetails?.advisorName}
					advisorEmail={advisorEmail}
					emailAddressValue={newLoanDetails?.email}
					clientId={newLoanDetails?.id}
					firstName={newLoanDetails?.firstName}
					lastName={newLoanDetails?.lastName}
					creditScoreValue={newLoanDetails?.creditScore}
					annualIncome={newLoanDetails?.totalAnnualIncome}
					stateValue={newLoanDetails?.state}
					countyValue={newLoanDetails?.county}
					estimatedPurchaseAmount={
						newLoanDetails?.estimatedPurchaseValue
					}
					downPaymentPercentValue={newLoanDetails?.downPaymentPercent}
					acresValue={newLoanDetails?.acres}
					landUseValue={newLoanDetails?.landUse}
					timeUntilConstructionValue={
						newLoanDetails?.timeUntilConstruction
					}
					desiredLoanTermValue={newLoanDetails?.desiredLoanTerm}
					additionalNotesValue={newLoanDetails?.additionalNotes}
					postSuccessEvent={handleClose}
				/>
			)}

			{isCommercialLoan && (
				<SmallBusinessForm
					loanRequestId={newLoanDetails?.id}
					advisorName={newLoanDetails?.advisorName}
					advisorEmail={advisorEmail}
					emailAddressValue={newLoanDetails?.email}
					clientId={newLoanDetails?.id}
					firstName={newLoanDetails?.firstName}
					lastName={newLoanDetails?.lastName}
					reasonForLoanValue={newLoanDetails?.reasonForLoan}
					loanAmountValue={newLoanDetails?.loanAmount}
					companyNameValue={newLoanDetails?.companyName}
					companyTypeValue={newLoanDetails?.companyType}
					companyWebsiteValue={newLoanDetails?.companyWebsite}
					loanTypeValue={newLoanDetails?.loanType}
					addressLine1Value={newLoanDetails?.addressLine1}
					addressLine2Value={newLoanDetails?.addressLine2}
					cityValue={newLoanDetails?.city}
					stateValue={newLoanDetails?.state}
					zipCodeValue={newLoanDetails?.zipCode}
					revenueLastYearValue={newLoanDetails?.revenueLastYear}
					incomeLastYearValue={newLoanDetails?.incomeLastYear}
					revenue2YearsAgoValue={newLoanDetails?.revenue2YearsAgo}
					income2YearsAgoValue={newLoanDetails?.income2YearsAgo}
					creditScoreValue={newLoanDetails?.creditScore}
					additionalNotesValue={newLoanDetails?.additionalNotes}
					postSuccessEvent={handleClose}
				/>
			)}

			{isAutoRefinanceLoan && (
				<AutoRefiForm
					loanRequestId={newLoanDetails?.id}
					advisorName={newLoanDetails?.advisorName}
					loanAmountValue={newLoanDetails?.loanAmount}
					advisorEmail={advisorEmail}
					emailAddressValue={newLoanDetails?.email}
					clientId={newLoanDetails?.id}
					firstName={newLoanDetails?.firstName}
					lastName={newLoanDetails?.lastName}
					conditionValue={newLoanDetails?.condition}
					dateOfBirth={newLoanDetails?.dob}
					mileage={newLoanDetails?.vehicleMileage}
					stateValue={newLoanDetails?.state}
					vinValue={newLoanDetails?.vehicleVINNumber}
					yearOfVehicleValue={newLoanDetails?.yearOfVehicle}
					additionalNotesValue={newLoanDetails?.additionalNotes}
					currentLoanRemainingTermValue={
						newLoanDetails?.currentRemainingTerm
					}
					currentLoanInterestRateValue={
						newLoanDetails?.currentInterestRate
					}
					postSuccessEvent={handleClose}
				/>
			)}

			{isPersonalRefinanceLoan && (
				<PersonalRefiForm
					loanRequestId={newLoanDetails?.id}
					advisorName={newLoanDetails?.advisorName}
					advisorEmail={advisorEmail}
					additionalNotesValue={newLoanDetails?.additionalNotes}
					employmentStartDate={newLoanDetails?.startDate}
					clientId={newLoanDetails?.id}
					creditScoreValue={newLoanDetails?.creditScore}
					firstName={newLoanDetails?.firstName}
					lastName={newLoanDetails?.lastName}
					veteranStatusValue={newLoanDetails?.isVeteran}
					employerValue={newLoanDetails?.employer}
					employmentStatusValue={newLoanDetails?.employmentStatus}
					jobTitleValue={newLoanDetails?.jobTitle}
					dobValue={newLoanDetails?.dob}
					addressLine1Value={newLoanDetails?.addressLine1}
					addressLine2Value={newLoanDetails?.addressLine2}
					cityValue={newLoanDetails?.city}
					stateValue={newLoanDetails?.state}
					zipCodeValue={newLoanDetails?.zipCode}
					emailAddressValue={newLoanDetails?.email}
					annualIncome={newLoanDetails?.totalAnnualIncome}
					assetsAmount={newLoanDetails?.totalAssetsValue}
					outstandingBalance={
						newLoanDetails?.currentLoanOutstandingBalance
					}
					interestRate={newLoanDetails?.currentInterestRate}
					yearsRemaining={newLoanDetails?.currentLoanTerm}
					postSuccessEvent={handleClose}
					currentTermValue={newLoanDetails?.currentTerm}
					rentOrOwnValue={newLoanDetails?.rentOrOwn}
					monthlyHousingPaymentValue={
						newLoanDetails?.monthlyHousingPayment
					}
				/>
			)}

			{isMortgageRefinanceLoan && (
				<MortgageRefiForm
					addressLine1Value={newLoanDetails?.addressLine1}
					addressLine2Value={newLoanDetails?.addressLine2}
					loanRequestId={newLoanDetails?.id}
					advisorName={newLoanDetails?.advisorName}
					advisorEmail={advisorEmail}
					cityValue={newLoanDetails?.city}
					stateValue={newLoanDetails?.state}
					zipCodeValue={newLoanDetails?.zipCode}
					currentOutstandingBalance={newLoanDetails?.loanAmount}
					desiredInterestTypeValue={
						newLoanDetails?.desiredInterestType
					}
					annualIncome={newLoanDetails?.totalAnnualIncome}
					clientId={newLoanDetails?.id}
					creditScoreValue={newLoanDetails?.creditScore}
					currentMortgageBalance={
						newLoanDetails?.currentMortgageBalance
					}
					currentInterestRateValue={
						newLoanDetails?.currentInterestRate
					}
					emailAddressValue={newLoanDetails?.email}
					estimatedValue={newLoanDetails?.estimatedPurchaseValue}
					firstName={newLoanDetails?.firstName}
					lastName={newLoanDetails?.lastName}
					primaryUseValue={newLoanDetails?.primaryUse}
					veteranStatusValue={newLoanDetails?.isVeteran}
					propertyTypeValue={newLoanDetails?.propertyType}
					additionalNotesValue={newLoanDetails?.additionalNotes}
					tenureValue={newLoanDetails?.ownershipTenure}
					postSuccessEvent={handleClose}
					remainingTermValue={newLoanDetails?.remainingTerm}
				/>
			)}

			{isStudentRefinanceLoan && (
				<StudentLoanRefiForm
					loanRequestId={newLoanDetails?.id}
					firstName={newLoanDetails?.firstName}
					lastName={newLoanDetails?.lastName}
					advisorName={newLoanDetails?.advisorName}
					advisorEmail={advisorEmail}
					additionalNotesValue={newLoanDetails?.additionalNotes}
					employmentStartDate={newLoanDetails?.startDate}
					clientId={newLoanDetails?.id}
					loanTypeValue={newLoanDetails?.loanType}
					outstandingBalance={
						newLoanDetails?.currentLoanOutstandingBalance
					}
					rentOrOwnValue={newLoanDetails?.rentOrOwn}
					monthlyHousingPaymentValue={
						newLoanDetails?.monthlyHousingPayment
					}
					addressLine1Value={newLoanDetails?.addressLine1}
					addressLine2Value={newLoanDetails?.addressLine2}
					cityValue={newLoanDetails?.city}
					stateValue={newLoanDetails?.state}
					zipCodeValue={newLoanDetails?.zipCode}
					annualIncome={newLoanDetails?.totalAnnualIncome}
					assetsAmount={newLoanDetails?.totalAssetsValue}
					dobValue={newLoanDetails?.dob}
					educationLevelValue={newLoanDetails?.educationLevel}
					emailAddressValue={newLoanDetails?.email}
					employerValue={newLoanDetails?.employer}
					employmentStatusValue={newLoanDetails?.employmentStatus}
					graduationYearValue={newLoanDetails?.graduationDate}
					lenderType={newLoanDetails?.currentLoanType}
					schoolValue={newLoanDetails?.school}
					postSuccessEvent={handleClose}
				/>
			)}

			{isReverseMortgageLoan && (
				<ReverseMortgageLoanForm
					loanRequestId={newLoanDetails?.id}
					firstName={newLoanDetails?.firstName}
					lastName={newLoanDetails?.lastName}
					advisorName={newLoanDetails?.advisorName}
					advisorEmail={advisorEmail}
					addressLine1Value={newLoanDetails?.addressLine1}
					addressLine2Value={newLoanDetails?.addressLine2}
					cityValue={newLoanDetails?.city}
					stateValue={newLoanDetails?.state}
					zipCodeValue={newLoanDetails?.zipCode}
					annualIncome={newLoanDetails?.totalAnnualIncome}
					clientId={newLoanDetails?.id}
					creditScoreValue={newLoanDetails?.creditScore}
					emailAddressValue={newLoanDetails?.email}
					employmentStatusValue={newLoanDetails?.employmentStatus}
					estimatedValue={newLoanDetails?.estimatedHomeValue}
					outstandingBalanceValue={newLoanDetails?.outstandingBalance}
					primaryUseValue={newLoanDetails?.primaryUse}
					requestAmountValue={newLoanDetails?.requestAmount}
					ownershipTypeValue={newLoanDetails?.ownershipType}
					additionalNotesValue={newLoanDetails?.additionalNotes}
					postSuccessEvent={handleClose}
				/>
			)}

			{isHelocLoan && (
				<HelocLoanForm
					loanRequestId={newLoanDetails?.id}
					additionalNotesValue={newLoanDetails?.additionalNotes}
					firstName={newLoanDetails?.firstName}
					lastName={newLoanDetails?.lastName}
					advisorName={newLoanDetails?.advisorName}
					advisorEmail={advisorEmail}
					addressLine1Value={newLoanDetails?.addressLine1}
					addressLine2Value={newLoanDetails?.addressLine2}
					cityValue={newLoanDetails?.city}
					stateValue={newLoanDetails?.state}
					zipCodeValue={newLoanDetails?.zipCode}
					annualIncome={newLoanDetails?.totalAnnualIncome}
					clientId={newLoanDetails?.id}
					creditScoreValue={newLoanDetails?.creditScore}
					emailAddressValue={newLoanDetails?.email}
					employmentStatusValue={newLoanDetails?.employmentStatus}
					estimatedValue={newLoanDetails?.estimatedPurchaseValue}
					outstandingBalanceValue={newLoanDetails?.outstandingBalance}
					primaryUseValue={newLoanDetails?.primaryUse}
					requestedLoanAmount={newLoanDetails?.loanAmount}
					estimatedHomeValueAmount={
						newLoanDetails?.estimatedHomeValue
					}
					ownershipTypeValue={newLoanDetails?.ownershipType}
					postSuccessEvent={handleClose}
				/>
			)}

			{isCashOutRefinanceLoan && (
				<CashOutRefinanceForm
					addressLine1Value={newLoanDetails?.addressLine1}
					addressLine2Value={newLoanDetails?.addressLine2}
					loanRequestId={newLoanDetails?.id}
					additionalNotesValue={newLoanDetails?.additionalNotes}
					firstName={newLoanDetails?.firstName}
					lastName={newLoanDetails?.lastName}
					advisorName={newLoanDetails?.advisorName}
					advisorEmail={advisorEmail}
					clientId={newLoanDetails?.id}
					emailAddressValue={newLoanDetails?.email}
					cityValue={newLoanDetails?.city}
					stateValue={newLoanDetails?.state}
					zipCodeValue={newLoanDetails?.zipCode}
					annualIncome={newLoanDetails?.totalAnnualIncome}
					currentLoanInterestRateValue={
						newLoanDetails?.currentInterestRate
					}
					currentLoanRemainingTermValue={
						newLoanDetails?.currentRemainingTerm
					}
					requestedLoanAmount={newLoanDetails?.loanAmount}
					ownershipTypeValue={newLoanDetails?.ownershipType}
					primaryUseValue={newLoanDetails?.primaryUse}
					propertyTypeValue={newLoanDetails?.propertyType}
					outstandingBalanceValue={newLoanDetails?.outstandingBalance}
					estimatedValue={newLoanDetails?.estimatedPurchaseValue}
					employmentStatusValue={newLoanDetails?.employmentStatus}
					estimatedHomeValueAmount={
						newLoanDetails?.estimatedHomeValue
					}
					postSuccessEvent={handleClose}
					remainingTermValue={newLoanDetails?.remainingTerm}
					desiredInterestTypeValue={
						newLoanDetails?.desiredInterestType
					}
					currentInterestRateValue={
						newLoanDetails?.currentInterestRate
					}
					ownershipTenureValue={newLoanDetails?.ownershipTenure}
				/>
			)}
		</Modal>
	);
}

export default EditRequestInfoModal;
