import axios from 'axios';

const baseURL = process.env.REACT_APP_ANALYTICS_URL;

const analyticsAxiosInstance = axios.create({
	baseURL,
	headers: {
		'sora-api-key': process.env.REACT_APP_ANALYTICS_API_KEY
	}
});

export default analyticsAxiosInstance;
