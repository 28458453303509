import { useNavigate } from 'react-router-dom';

// Our Routes
import { ADVISOR_ANALYTICS } from 'routes';

function useSmartAnalyticsTabNavigation() {
	const navigate = useNavigate();

	// Define default state properties
	const defaultState = {
		tabIndex: 0,
		clientSelected: null
	};

	return (incomingState = {}, replace = true) => {
		// Merge default state with incoming state
		const state = { ...defaultState, ...incomingState };
		navigate(ADVISOR_ANALYTICS, { state, replace });
	};
}

export default useSmartAnalyticsTabNavigation;
