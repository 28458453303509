import { useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';

// Our PropTypes
import CommonPropTypes from 'shared/prop-types';

function TableHeading({
	tableHeadingItemSX,
	tableHeadingSX,
	headingVariant,
	tableHeadings
}) {
	const optimizedTableHeading = useMemo(
		() =>
			tableHeadings.map((heading) => (
				<Typography
					key={uuidv4()}
					variant={headingVariant}
					sx={tableHeadingItemSX}
				>
					{heading}
				</Typography>
			)),
		[]
	);
	return (
		<Box
			sx={{
				width: '100%',
				display: 'flex',
				justifyContent: 'space-between',
				borderBottom: '2px solid #C7C7C7',
				paddingBottom: 1,
				...tableHeadingSX
			}}
		>
			{optimizedTableHeading}
		</Box>
	);
}

TableHeading.propTypes = {
	tableHeadings: PropTypes.arrayOf(PropTypes.string).isRequired,
	tableHeadingSX: CommonPropTypes.sx,
	tableHeadingItemSX: CommonPropTypes.sx,
	headingVariant: CommonPropTypes.typography
};

TableHeading.defaultProps = {
	tableHeadingSX: {},
	tableHeadingItemSX: {},
	headingVariant: 'subtitle2'
};

export default TableHeading;
