import { useState, useMemo, useEffect } from 'react';
import { Box, Grid, Skeleton, Typography } from '@mui/material';
import { Inbox } from '@talkjs/react';
import { useTheme } from '@emotion/react';

// Components
import AutocompleteTextinput from 'components/Input/AutocompleteTextinput';
import Dropdown from 'components/Dropdown/Dropdown';
import Modal from 'components/Modal/Modal';
import { TertiaryButton } from 'components/Button/Button';
import Loader from 'components/Loader/index';

// Our Hooks
import useGetClients from 'hooks/useGetClients';
import useGetProfile from 'hooks/useGetProfile';

// TalkJsHooks
import useGetAllMessagesForCurrentUser from 'hooks/talkjs/queries/useGetAllMessagesForCurrentUser';
import useMutateCreateClientSpecificChannel from 'hooks/talkjs/mutations/useMutateCreateClientSpecificChannel';

// Local Constants
const DROP_DOWN_OPTIONS = ['Browse clients'];
const MODAL_TITLE = {
	'Browse clients': 'Choose a client',
	'Create a new topic': 'Create a new topic'
};

// Advisor Inbox
function MessagesPage() {
	const SoraTheme = useTheme();

	// Getting variables from the url if present
	const urlString = window.location.search;
	const urlParams = new URLSearchParams(urlString);
	const clientIdFromUrl = urlParams.get('clientId');
	const clientFirstNameFromUrl = urlParams.get('firstName');
	const clientLastNameFromUrl = urlParams.get('lastName');

	// Client State
	const [selectedClient, setSelectedClient] = useState(null);
	const [currentConversation, setCurrentConversation] = useState(null);
	const [clientSearchName, setClientSearchName] = useState('');
	const [modalSelectedItem, setModalSelectedItem] = useState('');
	const [passedClientId, setPassedClientId] = useState(
		clientIdFromUrl || null
	);
	const [passedClientFirstName, setPassedClientFirstName] = useState(
		clientFirstNameFromUrl || null
	);
	const [passedClientLastName, setPassedClientLastName] = useState(
		clientLastNameFromUrl || null
	);
	// const [topicName, setTopicName] = useState('');

	const createClientSpecificChannel = useMutateCreateClientSpecificChannel();

	const {
		isLoading: isGetProfileLoading,
		data: advisorProfileData,
		isSuccess: isGetProfileSuccess
	} = useGetProfile();

	const {
		isLoading: isGetClientsLoading,
		data,
		isSuccess: isGetClientSuccess
	} = useGetClients('');

	const {
		isLoading: isGetAllMessagesLoading,
		isSuccess: isGetAllMessagesSuccess,
		data: allMessagesData
	} = useGetAllMessagesForCurrentUser(setCurrentConversation);

	useEffect(() => {
		if (isGetProfileSuccess && isGetAllMessagesSuccess && allMessagesData) {
			// passedClientId, passedClientFirstName & passedClientLastName is only present in the URL and not null when advisor is directed to this page from the dashboard/tasks page Loan Requests tab via the Contact Sora link
			if (
				passedClientId !== null &&
				passedClientFirstName !== null &&
				passedClientLastName !== null
			) {
				const clientFullName = `${passedClientFirstName} ${passedClientLastName}`;

				createClientSpecificChannel.mutate(
					{
						advisorId: advisorProfileData.id,
						clientInfo: { name: clientFullName, id: passedClientId }
					},
					{
						onSuccess: (conversationId) => {
							// advisor is directed to correct conversation thread on the /messages page.
							setCurrentConversation(conversationId);
						}
					}
				);
			}
			// Otherwise continue as usual aka previously coded
			else if (allMessagesData.length < 1) {
				// do nothing
			} else {
				const unreadMessages = allMessagesData.filter(
					({ isUnread }) => isUnread === true
				);

				if (unreadMessages.length < 1) {
					const firstMessage = allMessagesData[0];
					setCurrentConversation(firstMessage.id);
				} else {
					setCurrentConversation(unreadMessages[0].id);
				}
			}
		}
	}, [isGetAllMessagesSuccess, isGetAllMessagesSuccess]);

	// Creates a label set for each client. Sets these labels to an array of "items", which is used to populate the options within the client selection dropdown
	const listOfClients = useMemo(() => {
		if (isGetClientSuccess) {
			const clientList = data.map(({ fullName, clientId }) => ({
				name: fullName,
				id: clientId
			}));

			return clientList;
		}

		return [];
	}, [data]);

	const handleClientSelect = (
		currentSelectedClient,
		updateSelectedClient
	) => {
		updateSelectedClient(currentSelectedClient);
		setSelectedClient(currentSelectedClient);
	};

	const handleClientChange = (incomingValue) => {
		if (incomingValue === '') {
			setSelectedClient('');
		}
		setClientSearchName(incomingValue);
	};

	if (isGetClientsLoading || isGetProfileLoading || isGetAllMessagesLoading) {
		return (
			<>
				<Grid item xs={12} sx={{ marginBottom: 2 }}>
					<Typography
						variant="h1Gascogne"
						sx={{ display: 'block', marginBottom: 6 }}
					>
						Message Center
					</Typography>

					<Typography variant="subtitle2">
						Welcome to the Sora Message Center
					</Typography>

					<Typography variant="body2" sx={{ marginBottom: 4 }}>
						<br />
						You can use General channel to send secure inquiries to
						Sora. <br />
						To request support for a particular client, select the
						client from the dropdown below and submit your inquiry.
						<br />
						Your clients do not see any messages submitted through
						the Message Center.
					</Typography>
				</Grid>

				<Grid item xs={12}>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'flex-start'
						}}
					>
						<Skeleton
							sx={{
								height: 200,
								width: 298
							}}
						/>

						<Skeleton
							sx={{
								height: 350,
								width: '90%'
							}}
						/>
					</Box>
				</Grid>
			</>
		);
	}

	if (isGetClientSuccess && isGetProfileSuccess && isGetAllMessagesSuccess) {
		return (
			<>
				<Grid
					item
					xs={12}
					sx={{
						marginBottom: 2
					}}
				>
					<Typography
						variant="h1Gascogne"
						sx={{ display: 'block', marginBottom: 4 }}
					>
						Message Center
					</Typography>
					<Typography variant="subtitle2">
						Welcome to the Sora Message Center
					</Typography>

					<Typography variant="body2" sx={{ marginBottom: 4 }}>
						<br />
						You can use the General channel to send secure inquiries
						to Sora. <br />
						To request support for a particular client, select the
						client from the dropdown below and submit your inquiry.
						<br />
						Your clients do not see any messages submitted through
						the Message Center.
					</Typography>

					<Dropdown
						sx={{ maxWidth: 300 }}
						items={DROP_DOWN_OPTIONS}
						label=""
						onChange={setModalSelectedItem}
						selected={modalSelectedItem}
						placeholder="Select Client"
						variant="outlined"
					/>

					{modalSelectedItem !== '' && (
						<Modal
							isOpen={modalSelectedItem !== ''}
							handleClose={() => setModalSelectedItem('')}
							TitleComponent={
								<Typography
									variant="h2Gascogne"
									sx={{
										color: SoraTheme.palette.primary.indigo
									}}
								>
									{MODAL_TITLE[modalSelectedItem]}
								</Typography>
							}
						>
							{/* <TextInput
										variant={TEXT}
										sx={{ marginBottom: 4 }}
									/> */}
							{isGetClientSuccess && listOfClients.length > 0 && (
								<AutocompleteTextinput
									autoCompleteSX={{
										marginBottom: 4
									}}
									forcePopupIcon
									freeSolo={false}
									getOptionLabel={({ name }) => name}
									helperText="Select a client from the dropdown to create a channel about"
									handleChange={handleClientChange}
									handleSelect={handleClientSelect}
									label=""
									value={clientSearchName}
									optionList={listOfClients}
									withoutFilter={false}
									renderOption={(props, option) => (
										<li {...props} key={option.id}>
											{option.name}
										</li>
									)}
									withTag
								/>
							)}

							{createClientSpecificChannel.isLoading && (
								<Loader />
							)}

							{!createClientSpecificChannel.isLoading && (
								<TertiaryButton
									isDisabled={selectedClient === null}
									onClick={() => {
										const { id: advisorId } =
											advisorProfileData;

										createClientSpecificChannel.mutate(
											{
												advisorId,
												clientInfo: selectedClient
											},
											{
												onSuccess: (conversationId) => {
													setCurrentConversation(
														conversationId
													);

													setSelectedClient(null);
													setClientSearchName('');
													setModalSelectedItem('');
												}
											}
										);
									}}
								>
									Next
								</TertiaryButton>
							)}
						</Modal>
					)}
				</Grid>

				<Grid item xs={12}>
					<Inbox
						conversationId={currentConversation}
						style={{
							height: 540,
							width: '100%'
						}}
						messageField={{
							placeholder: 'Write a message...',
							visible: true
						}}
						loadingComponent={
							<Skeleton
								variant="rectangular"
								sx={{
									height: '100%',
									width: '100%'
								}}
							/>
						}
					/>
				</Grid>
			</>
		);
	}
}

export default MessagesPage;
