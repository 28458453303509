import { useTheme } from '@emotion/react';
import { Box, Typography } from '@mui/material';

// Our Components
import CardItem from 'components/SoraWallet/Cards/CardItem';
import CardHeading from 'components/SoraWallet/Cards/CardHeading';
import SoraToolTip from 'components/Tooltip/index';

import WalletToolTips from 'components/SoraWallet/WalletToolTips.json';

// Our Utils
import { dollarFormatter } from 'shared/utils';

// Our Assets
import WalletGraph from 'assets/icons/wallet_icons/WalletGraph.svg';

function UpsidePotentialUntappedSavings({ upsidePotential, untappedSavings }) {
	const cardImage = WalletGraph;

	const SoraTheme = useTheme();
	const { primary } = SoraTheme.palette;

	const formattedUpsidePotential = dollarFormatter.format(upsidePotential);
	const formattedUntappedSavings = dollarFormatter.format(untappedSavings);

	return (
		<CardItem
			withoutButton
			buttonSx={{ alignSelf: 'center' }}
			sx={{
				background: 'linear-gradient(180deg, #295983 0%, #91B0CB 100%)',
				gap: '20px',
				padding: '20px 28px 16px 28px'
			}}
		>
			<Box>
				<CardHeading
					title="Total Upside Potential"
					sx={{ color: primary.white }}
				>
					<SoraToolTip
						text={WalletToolTips.upsidePotential}
						toolTipSx={{
							color: primary.white,
							marginLeft: 1,
							fontSize: '1.2rem'
						}}
						placement="bottom"
					/>
				</CardHeading>

				<Typography
					variant="h4"
					sx={{ color: primary.white, marginBottom: 2 }}
				>
					{formattedUpsidePotential}
				</Typography>
			</Box>
			<Box>
				<CardHeading
					title="Total Untapped Savings"
					sx={{ color: primary.white }}
				>
					<SoraToolTip
						text={WalletToolTips.untappedSavings}
						toolTipSx={{
							color: primary.white,
							marginLeft: 1,
							fontSize: '1.2rem'
						}}
						placement="bottom"
					/>
				</CardHeading>
				<Typography variant="h4" sx={{ color: primary.white }}>
					{formattedUntappedSavings}
				</Typography>
			</Box>

			<Box
				component="img"
				src={cardImage}
				sx={{
					display: { xs: 'none', md: 'block' },
					position: 'absolute',
					right: '1rem',
					bottom: 0,
					width: '4.6rem !important',
					height: '6.6rem !important',
					marginBottom: '0.5rem !important'
				}}
			/>
		</CardItem>
	);
}

export default UpsidePotentialUntappedSavings;
