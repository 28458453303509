import PropTypes from 'prop-types';
import { useTheme } from '@emotion/react';
import { Box } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';

// Our Components
import OfferTableCell from 'components/Offers/OfferTable/OfferTableCell';
import OfferTableRowHeading from 'components/Offers/OfferTable/OfferTableRowHeading';

// Constants
import { ACCEPTED_OFFER_KEYS } from 'shared/constants';

function OffersTableRowItem({
	currentOfferKey,
	currentOfferData,
	existingClientOffer,
	isLast,
	offerType
}) {
	// If we got this page we at least have 1 element in data.
	const SoraTheme = useTheme();

	const { primary } = SoraTheme.palette;

	const borderBottomStyle = isLast ? '' : `1px solid ${primary.lightShade}`;
	const paddingBottom = offerType === 'new_loan' ? 5 : 2;

	// if more than two offers are returned use space-between.
	// if two offers or less are returned use gap 10
	// this fixes the spacing between the offers
	// adjustSpacing horizontally
	const adjustSpacing =
		currentOfferData?.length > 2
			? { justifyContent: 'space-between' }
			: { gap: 10 };

	return (
		<Box
			sx={{
				borderBottom: borderBottomStyle,
				paddingTop: 4,
				paddingBottom,
				display: 'flex',
				boxSizing: 'border-box',
				...adjustSpacing
			}}
		>
			<OfferTableRowHeading currentOfferKey={currentOfferKey} />
			{currentOfferData.map((offerData) => (
				<OfferTableCell
					key={uuidv4()}
					currentOfferKey={currentOfferKey}
					currentOfferData={offerData}
					existingClientOffer={existingClientOffer}
				/>
			))}
		</Box>
	);
}

OffersTableRowItem.propTypes = {
	currentOfferKey: PropTypes.oneOf(ACCEPTED_OFFER_KEYS).isRequired,
	currentOfferData: PropTypes.arrayOf(
		PropTypes.oneOfType([PropTypes.number, PropTypes.string])
	).isRequired,
	isLast: PropTypes.bool
};

OffersTableRowItem.defaultProps = {
	isLast: false
};

export default OffersTableRowItem;
