import { Helmet } from 'react-helmet-async';
import { Grid } from '@mui/material';
import { useLocation } from 'react-router-dom';

// Our Components
import AddingClientsTabs from 'components/Tabs/AddingClientsTabs';

function AddingClientsPage() {
	const { state } = useLocation();

	const defaultTab = state?.startingIndex;

	return (
		<Grid container>
			<Helmet>
				<title>Add Clients</title>
			</Helmet>

			<AddingClientsTabs defaultIndex={defaultTab ?? 0} />
		</Grid>
	);
}

export default AddingClientsPage;
