import { useMutation } from 'react-query';

// Our Axios
import axiosInstance from 'services/API/API';

// Our Endpoints
import { getLoanRequestDocDownloadUrl } from 'shared/api-urls';

// Our Utils
import programaticDownload from 'shared/utils/developmentQualityOfLife/programaticDownload';

function useMutateLoanRequestDocumentDownload() {
	return useMutation(
		async ({ loanRequestId, masterDocumentId, clientId }) => {
			const fullUrl = `${getLoanRequestDocDownloadUrl}/${loanRequestId}/${masterDocumentId}?client-id=${clientId}`;

			const response = await axiosInstance.post(fullUrl);

			const downloadDocumentData = response.data.data;

			return downloadDocumentData;
		},
		{
			onSuccess: (documentData) => {
				const { fileContent, fileName } = documentData;

				programaticDownload(fileContent, fileName);
			}
		},
		{
			refetchOnWindowFocus: false
		}
	);
}

export default useMutateLoanRequestDocumentDownload;
