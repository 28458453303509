import { dollarFormatter } from 'shared/utils';

// Helper Function
const isIncomingDataTypeOfString = (incomingData) =>
	typeof incomingData === 'string';

// Actual Utility function
function createFormattedRowData(name, currentLoanData, bestOfferLoanData) {
	return [
		name,
		isIncomingDataTypeOfString(currentLoanData)
			? currentLoanData
			: dollarFormatter.format(currentLoanData ?? 0),
		isIncomingDataTypeOfString(bestOfferLoanData)
			? bestOfferLoanData
			: dollarFormatter.format(bestOfferLoanData ?? 0)
	];
}

export default createFormattedRowData;
