// This maps the dropdown options with what the BE expects
// lefthand side is what the UI shows and the right are the values expected by the BE
const PROPERTY_TYPES_TO_ENUM_MAPPING = (propertyType) => {
	const PROPERTY_TO_ENUM_MAPPING = {
		Primary: 'PRIMARY',
		'Secondary/Vacation': 'SECONDARY',
		Rental: 'INVESTMENT'
	};

	return PROPERTY_TO_ENUM_MAPPING[propertyType];
};

export default PROPERTY_TYPES_TO_ENUM_MAPPING;
