import { CircularProgress, Box, Typography } from '@mui/material';
import { useTheme } from '@emotion/react';

function SoraInsightsLoadingCarousel() {
	const SoraTheme = useTheme();

	const { primary } = SoraTheme.palette;

	const { grey } = primary;

	return (
		<Box
			width="100%"
			height={300}
			sx={{
				borderRadius: 6,
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				justifyContent: 'center'
			}}
		>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					justifyContent: 'center',
					marginBottom: 6
				}}
			>
				<CircularProgress sx={{ color: grey, marginBottom: 2 }} />

				<Typography variant="body1">
					We&apos;re refreshing your insights with the latest
					information provided.
				</Typography>
				<Typography variant="body1" sx={{ marginTop: 0.5 }}>
					Sora&apos;s unique insights are generated in real time and
					may take up to 15 seconds to load.
				</Typography>
			</Box>
		</Box>
	);
}

export default SoraInsightsLoadingCarousel;
