import { Box, Grid, Typography } from '@mui/material';
import { useTheme } from '@emotion/react';

// our Components
import SelectLoanLiabilityCard from 'components/Client/newLoan/SelectLoanLiabilityCard';
import LoanOrderedListDetails from 'components/Client/newLoan/loanOrderedListDetails';
import listDetails from 'components/Client/newLoan/listDetails.json';

// Our Routes
import { NEW_LOAN_AUTO_ROUTE } from 'routes';

function AddManualLoan() {
	const SoraTheme = useTheme();

	const { primary } = SoraTheme.palette;

	return (
		<Grid container sx={{ marginLeft: 2 }}>
			<Grid item xs={10} sx={{ marginBottom: 4 }}>
				<Typography
					variant="h1Gascogne"
					component="h1"
					gutterBottom
					sx={{ marginTop: 4 }}
				>
					What kind of loan are you looking for?
				</Typography>
			</Grid>
			<Grid item xs={2} />

			<Grid item xs={12} md={6}>
				<SelectLoanLiabilityCard
					LiabilityType="Mortgage"
					loanHeading="Home Loan"
					withoutCheckBox
				/>
			</Grid>
			<Grid item xs={12} md={6}>
				<SelectLoanLiabilityCard
					LiabilityType="Auto"
					loanHeading="Auto Loan"
					onClickNavRoute={NEW_LOAN_AUTO_ROUTE}
					withoutCheckBox
				/>
			</Grid>
			<Grid item xs={12} md={6}>
				<SelectLoanLiabilityCard
					disable
					LiabilityType="Student"
					loanHeading="Student Loan"
					withoutCheckBox
				/>
			</Grid>
			<Grid item xs={12} md={6}>
				<SelectLoanLiabilityCard
					disable
					LiabilityType="Personal"
					loanHeading="Personal Loan"
					withoutCheckBox
				/>
			</Grid>

			<Grid item xs={12}>
				<Box
					sx={{
						padding: { xs: 4, md: 15 },
						paddingTop: 6,
						paddingBottom: 6,
						bgcolor: primary.indigo,
						marginTop: 4,
						marginLeft: -2
					}}
				>
					<Typography
						variant="h4"
						gutterBottom
						color={primary.iceBlue}
					>
						Everything you need to know
					</Typography>
					<LoanOrderedListDetails details={listDetails[0]} />
					<LoanOrderedListDetails details={listDetails[1]} />
				</Box>
			</Grid>
		</Grid>
	);
}

export default AddManualLoan;
