import { Grid } from '@mui/material';
import { useState } from 'react';
import PropTypes from 'prop-types';

// Our Component
import AutocompleteTextinput from 'components/Input/AutocompleteTextinput';
import Dropdown from 'components/Dropdown/Dropdown';
import TextInput from 'components/Input/TextInput';

// Our Hooks
import useGetGooglePlaceAutoComplete from 'hooks/google/useGetGooglePlaceAutoComplete';

// Our input types
import { NUMBER, TEXT } from 'components/Input/Types';

// Our Constants
import { US_STATE_OPTIONS } from 'shared/constants';

function GetFullAddressPartialForm({
	addressLine1,
	addressLine1TabIndex,
	setAddressLine1,
	addressLine2TabIndex,
	addressLine2,
	setAddressLine2,
	city,
	cityTabIndex,
	children,
	setCity,
	state,
	stateTabIndex,
	setState,
	zipCode,
	setZipCode,
	zipCodeTabIndex,
	label,
	disableAutoComplete,
	setDisableAutoComplete,
	isRequired
}) {
	const currentURL = window.location.href;
	const atLoanRequestDetailsUrl = currentURL.includes('loan-request-details');
	const [suggestions, setSuggestions] = useState([]);

	useGetGooglePlaceAutoComplete(
		addressLine1,
		setSuggestions,
		disableAutoComplete
	);

	const handleSelect = (selectedAddress, updateSelectedAddress) => {
		if (selectedAddress) {
			const selectedSplitAddress = selectedAddress
				.split(',')
				.map((entry) => entry.trim());

			const filteredResults = selectedSplitAddress.filter(
				(entry) => entry !== 'USA'
			);

			const hasAddressData = filteredResults.length > 1;

			if (hasAddressData) {
				const selectedAddressLine1 = filteredResults[0];
				const selectedCity = filteredResults[1];
				const selectedState =
					filteredResults[filteredResults.length - 1];

				setAddressLine1(selectedAddressLine1);
				updateSelectedAddress(selectedAddressLine1);
				setCity(selectedCity);
				setState(selectedState);
			}
		} else {
			setAddressLine1('');
			updateSelectedAddress('');
		}
	};

	const handleOnChange = (newValue) => {
		if (disableAutoComplete) setDisableAutoComplete(!disableAutoComplete);
		setAddressLine1(newValue);
	};

	return (
		<>
			<Grid item xs={12} marginBottom={4}>
				<AutocompleteTextinput
					required={isRequired}
					handleChange={handleOnChange}
					handleSelect={handleSelect}
					label={label}
					value={addressLine1}
					optionList={suggestions}
					tabIndex={addressLine1TabIndex}
					inputProps={{
						'data-test': 'addressLine1',
						tabIndex: addressLine1TabIndex
					}}
				/>
			</Grid>

			<Grid item xs={12} marginBottom={4}>
				<TextInput
					inputProps={{
						'data-test': 'addressLine2',
						tabIndex: addressLine2TabIndex
					}}
					type={TEXT}
					subLabel="Address line 2"
					value={addressLine2}
					onChange={setAddressLine2}
				/>
			</Grid>

			<Grid item xs={6} marginBottom={4}>
				<TextInput
					label={atLoanRequestDetailsUrl ? 'City *' : ''}
					inputProps={{
						'data-test': 'city',
						tabIndex: cityTabIndex
					}}
					type={TEXT}
					subLabel="City"
					value={city}
					onChange={setCity}
				/>
			</Grid>
			<Grid item xs={6}>
				<Dropdown
					label={atLoanRequestDetailsUrl ? 'State *' : ''}
					dataTestTag="state"
					variant="outlined"
					helperText="State"
					items={US_STATE_OPTIONS}
					selected={state}
					onChange={setState}
					placeholder="State"
					tabIndex={stateTabIndex}
				/>
			</Grid>

			<Grid item xs={6}>
				<TextInput
					type={NUMBER}
					label={atLoanRequestDetailsUrl ? 'Zip code *' : ''}
					subLabel="Zip / Postal Code"
					value={zipCode}
					onChange={setZipCode}
					inputProps={{
						onWheel: (e) => e.target.blur(),
						'data-test': 'zip',
						tabIndex: zipCodeTabIndex
					}}
				/>
			</Grid>
			{children}
		</>
	);
}

GetFullAddressPartialForm.propTypes = {
	addressLine1: PropTypes.string.isRequired,
	setAddressLine1: PropTypes.func.isRequired,
	addressLine2: PropTypes.string.isRequired,
	setAddressLine2: PropTypes.func.isRequired,
	city: PropTypes.string.isRequired,
	setCity: PropTypes.func.isRequired,
	state: PropTypes.string.isRequired,
	setState: PropTypes.func.isRequired,
	zipCode: PropTypes.string.isRequired,
	setZipCode: PropTypes.func.isRequired,
	disableAutoComplete: PropTypes.bool,
	setDisableAutoComplete: PropTypes.func,
	label: PropTypes.string
};

GetFullAddressPartialForm.defaultProps = {
	disableAutoComplete: false,
	setDisableAutoComplete: () => null,
	label: 'Address'
};

export default GetFullAddressPartialForm;
