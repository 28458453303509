import { Box, Typography } from '@mui/material';
import { useTheme } from '@emotion/react';
import { useMemo } from 'react';

// Our Component
import CardItem from 'components/SoraWallet/Cards/CardItem';

// Our Utility
import { getFormattedLiabilityRate, roundToDecimal } from 'shared/utils';

function InterestAndYearsRemainingCard({ remainingMonth, currentLoanDetails }) {
	const { interestRate } = currentLoanDetails;
	const SoraTheme = useTheme();

	const { primary } = SoraTheme.palette;

	const cardImage =
		'https://prod-public-asset.s3.amazonaws.com/SoraAssets/coinHand.png';

	const formattedLiabilityRate = useMemo(
		() => getFormattedLiabilityRate(interestRate),
		[interestRate]
	);
	const displayedValue =
		remainingMonth < 12 ? '< 1' : roundToDecimal(remainingMonth / 12, 1);
	return (
		<CardItem
			withoutButton
			buttonSx={{ alignSelf: 'center' }}
			sx={{
				background:
					'linear-gradient(180deg, rgba(38, 133, 193, 0.3) 0%, rgba(0, 156, 255, 0.051) 100%);'
			}}
		>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'space-evenly',
					marginBottom: 3,
					minHeight: 74
				}}
			>
				<Typography variant="subtitle1" sx={{ marginBottom: 1 }}>
					Interest Rate
				</Typography>

				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						minHeight: 42
					}}
				>
					<Typography variant="h4" sx={{ color: primary.indigo }}>
						{formattedLiabilityRate}
					</Typography>
				</Box>
			</Box>

			<Box sx={{ display: 'flex' }}>
				<Typography variant="subtitle1">Years Remaining</Typography>
			</Box>

			<Typography variant="h4" sx={{ color: primary.indigo }}>
				{displayedValue}
			</Typography>
			<Box
				component="img"
				src={cardImage}
				sx={{
					display: { xs: 'none', md: 'block' },
					position: 'absolute',
					right: '.5rem',
					bottom: 0,
					width: '5.5rem !important',
					height: ' 5.5rem !important',
					marginBottom: '0.375rem !important',
					objectFit: 'contain'
				}}
			/>
		</CardItem>
	);
}

export default InterestAndYearsRemainingCard;
