import axiosInstance from 'services/API/API';
import { useQuery } from 'react-query';

// Our Endpoint
import { analyticsLiabilityUrl } from 'shared/api-urls';

// Our Query Keys
import { CLIENT_ANALYTICS_LIABILITY_DATA } from 'shared/query-keys';

// Our Constants
import { MORTGAGE, DEFAULT_STALE_TIME_FIVE_MINUTES } from 'shared/constants';

function useGetClientLiabilitiesAnalytics(clientId, enabled, select) {
	const searchParams = new URLSearchParams([['tradeLineType', MORTGAGE]]);

	const extendedUrlEndpoint = `${analyticsLiabilityUrl}/${clientId}`;

	return useQuery(
		[CLIENT_ANALYTICS_LIABILITY_DATA, clientId],
		async () => {
			const response = await axiosInstance.get(extendedUrlEndpoint, {
				params: searchParams
			});
			const clientWalletOverviewData = response.data.data;
			return clientWalletOverviewData;
		},
		{
			staleTime: DEFAULT_STALE_TIME_FIVE_MINUTES,
			select,
			enabled
		}
	);
}

export default useGetClientLiabilitiesAnalytics;
