import MuiFormControlLabel from '@mui/material/FormControlLabel';
import MuiCheckbox from '@mui/material/Checkbox';
import PropTypes from 'prop-types';
import CommonPropTypes from 'shared/prop-types';

function Checkbox({ label, checked, onChange, sx, disabled, checkboxSx }) {
	const handleChange = (event) => {
		onChange(event.target.checked);
	};

	return (
		<MuiFormControlLabel
			control={
				<MuiCheckbox
					checked={checked}
					onChange={handleChange}
					inputProps={{ 'aria-label': 'controlled' }}
					sx={checkboxSx}
				/>
			}
			label={label}
			sx={sx}
			disabled={disabled}
		/>
	);
}

Checkbox.defaultProps = {
	sx: {},
	disabled: false
};

Checkbox.propTypes = {
	// Accepting string or object as label
	// Object is used when we need to pass a link into the label (ie. Sign up form)
	label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
	checked: PropTypes.bool.isRequired,
	onChange: PropTypes.func.isRequired,
	sx: CommonPropTypes.sx,
	disabled: PropTypes.bool
};

export default Checkbox;
