// Utils
import sortLiabilitiesByKey from 'shared/utils/walletOverview/sortByKey';

// Our constants
import { CREDITCARD } from 'shared/constants';

function selectLiabilitiesDataFromWalletOverview(walletOverviewData) {
	if (!walletOverviewData)
		return { liabilities: [], clientFinancialData: {} };

	const { liability } = walletOverviewData;
	const { liabilities } = liability;

	const filteredLiabilities = liabilities.filter(
		(cLiability) => cLiability.tradeLineType !== CREDITCARD
	);

	const sortedLiabilitiesByTradelineType = sortLiabilitiesByKey(
		filteredLiabilities,
		'tradeLineType'
	);

	return sortedLiabilitiesByTradelineType;
}

export default selectLiabilitiesDataFromWalletOverview;
