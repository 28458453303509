import { useQuery } from 'react-query';
import axiosInstance from 'services/API/API';

// Our Query Keys
import { FACLIENTS } from 'shared/query-keys';

// Our Endpoints
// This now uses the new /v1 version
import { faClients } from 'shared/api-urls';

// Our Utils
import { dataIsValid } from 'shared/utils';

function useGetClients(filterBy = '', enabled = true) {
	const sanitizedFilter = filterBy.trim().toLowerCase();

	return useQuery(
		FACLIENTS,
		async () => {
			const response = await axiosInstance.get(faClients);
			const clientList = response.data.data?.clients;

			// if clientList exists go into this block
			if (dataIsValid(clientList)) {
				// I noticed its possible for clients to exist in the new endpoint that still have fullName missing
				// This check ensures the app does not crash by removing clients who have a missing fullName.
				// AFAIK the only possible way for this to happen is if someone accepts an invite but does not populate the form in client additional details.
				const filteredClientListFullNameIsPresent = clientList.filter(
					(client) => dataIsValid(client?.fullName)
				);

				return filteredClientListFullNameIsPresent;
			}

			// If we reach this block it means that clients was not returned to us properly.
			return [];
		},
		{
			select: (listOfClients) =>
				filterBy === ''
					? listOfClients
					: listOfClients.filter((clientInfo) =>
							clientInfo.fullName
								.trim()
								.toLowerCase()
								.includes(sanitizedFilter)
					  ),
			staleTime: 30000,
			enabled,
			refetchOnWindowFocus: false
		}
	);
}

export default useGetClients;
