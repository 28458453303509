import { useMutation } from 'react-query';

// Our Axios
import axiosInstance from 'services/API/API';

// Our Endpoints
import { postCreateTalkJsConversation } from 'shared/api-urls';

function useMutateCreateClientSpecificChannel() {
	return useMutation(async ({ advisorId, clientInfo }) => {
		const { name: selectedClientName, id: clientId } = clientInfo;

		const response = await axiosInstance.post(
			postCreateTalkJsConversation,
			{
				participants: [advisorId],
				subject: `#${selectedClientName}`,
				topic: clientId
			}
		);

		const { conversationId } = response.data.data;

		return conversationId;
	});
}

export default useMutateCreateClientSpecificChannel;
