import { Chip, Grid } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';

// Our Components
import ColumnItem from 'components/DataTable/ColumnItem';
import DataTable from 'components/DataTable/DataTable';
import LoanTasksLoadingCheckbox from 'components/Tasks/LoanTasksLoadingCheckbox';

// Our Utils
import { dataIsValid, decodedLoanType } from 'shared/utils';
import formatDate from 'shared/utils/date/formatDate';

// Our Routes
import { LOAN_INQUIRY_DETAILS_ROUTE } from 'routes/index';

// Our constants
import { LOAN_REQUESTS_ADVISOR_PROGRESS_FLAGS } from 'shared/constants';

const TASK_STATUS_COLOR_MAPPING = {
	PENDING_INFO: {
		bgcolor: '#97D8E8',
		color: '#000000',
		text: 'Pending information'
	},
	FA_SEARCH_RATES: {
		bgcolor: '#FFBC00',
		color: '#000000',
		text: 'Looking for offers'
	},
	FA_SELECT_RATES: {
		bgcolor: '#FFBC00',
		color: '#000000',
		text: 'Select Rates'
	},
	SELECT_RATE: {
		bgcolor: '#FFBC00',
		color: '#000000',
		text: 'Select loan offer'
	},
	UNDER_REVIEW: {
		bgcolor: '#FFBC00',
		color: '#000000',
		text: 'Under Review'
	},
	PROCESSING: {
		bgcolor: '#FFBC00',
		color: '#000000',
		text: 'In Underwriting'
	},
	FA_REJECTED: {
		bgcolor: '#979797',
		color: '#000000',
		text: 'Rejected'
	},
	FA_CLOSED: {
		bgcolor: '#979797',
		color: '#000000',
		text: 'Closed'
	},
	FA_CANCELLED: {
		bgcolor: '#979797',
		color: '#000000',
		text: 'Canceled'
	},
	FA_COMPLETED: {
		bgcolor: '#FF8300',
		color: '#000000',
		text: 'Completed'
	}
};

// IMPORTANT mutation - mutates the mapping above
LOAN_REQUESTS_ADVISOR_PROGRESS_FLAGS.map((key) => {
	TASK_STATUS_COLOR_MAPPING[key] = {
		'Add Rates': {
			color: 'black',
			bgcolor: 'lightBlue',
			text: 'In progress'
		}
	};
	return [];
});

function LoanTasksTable({
	handleRowClick,
	taskList,
	withOnboardNowOverlay,
	noLoanTasks
}) {
	const mutatedRowData = taskList.map(
		({
			clientId,
			firstName,
			id,
			lastName,
			lastUpdated,
			offerType,
			faStatus,
			taskDone,
			tradelineType
		}) => {
			const isNameInvalid =
				!dataIsValid(firstName) || !dataIsValid(lastName);

			const fullName = isNameInvalid
				? 'Unknown'
				: `${firstName} ${lastName}`;

			return {
				id: uuidv4(),
				clientFirstName: firstName,
				clientLastName: lastName,
				loanRequestId: id,
				clientId,
				done: taskDone,
				lastUpdated,
				loanType: decodedLoanType(tradelineType, offerType),
				name: fullName,
				faStatus,
				tradelineType
			};
		}
	);

	// data Keys
	const loanType = 'loanType';
	const name = 'name';
	const lastUpdated = 'lastUpdated';
	const faStatus = 'faStatus';
	const done = 'done';

	const tableHeadings = [
		ColumnItem(loanType, 'Loan Type', 2),
		ColumnItem(name, 'Client Name', 2),
		ColumnItem(lastUpdated, 'Last Updated', 2, {
			valueFormatter: (params) => {
				const { value } = params;
				const formattedDate = formatDate(value);
				return formattedDate;
			}
		}),
		ColumnItem(faStatus, 'Status', 2, {
			renderCell: (params) => {
				const { value } = params;
				const statusConfig = TASK_STATUS_COLOR_MAPPING[value];

				const bgcolor = statusConfig?.bgcolor;
				const color = statusConfig?.color;
				const text = statusConfig?.text;

				const themeTextColor = color ?? 'black';
				const themeBackgroundColor = bgcolor ?? 'lightBlue';
				return (
					<Chip
						label={text}
						sx={{
							color: themeTextColor,
							bgcolor: themeBackgroundColor,
							paddingLeft: 1,
							paddingRight: 1,
							width: '100%',
							maxWidth: 200
						}}
					/>
				);
			}
		}),
		ColumnItem(done, 'Done', 1, {
			sortable: false,
			renderCell: (params) => {
				const { row } = params;

				return <LoanTasksLoadingCheckbox rowData={row} />;
			}
		})
	];

	return (
		<Grid item xs={12}>
			<DataTable
				columns={tableHeadings}
				rows={mutatedRowData.reverse()}
				disableSelectionOnClick
				rowHeight={60}
				withOnboardNowOverlay={withOnboardNowOverlay ?? false}
				noLoanTasks={noLoanTasks ?? false}
				handleRowClick={(e) => {
					handleRowClick(e);
				}}
			/>
		</Grid>
	);
}

export default LoanTasksTable;
