import { useQuery } from 'react-query';

// Api URL
import { getUserLiabilitiesUrl } from 'shared/api-urls';

// Our axios Instance
import axiosInstance from 'services/API/API';

// Our query Keys
import { USER_OTHER_LOANS } from 'shared/query-keys';

// APP CONSTANTS
import { CREDITCARD, HELOC } from 'shared/constants';

// Utils
import getNonNullValues from 'shared/utils/clientOnboarding/getNonNullValues';

// staleTime CONST
// 1000 ms -> 1s * 60s -> 60s -> 1m * 5 -> 5m
const FIVE_MINUTES = 1000 * 60 * 5;

// Other loans are currently Credit card and Heloc loans
function useGetAllUserOtherLoans() {
	return useQuery(
		USER_OTHER_LOANS,
		async () => {
			const response = await axiosInstance.get(getUserLiabilitiesUrl);

			const liabilitiesFound = await response.data;
			const otherLoanItems = liabilitiesFound.data.filter(
				({ tradeLineType }) =>
					tradeLineType === CREDITCARD || tradeLineType === HELOC
			);

			return otherLoanItems;
		},
		{
			refetchOnWindowFocus: false,
			staleTime: FIVE_MINUTES,
			select: (otherLoanItems) => {
				const creditCardDataWithoutNullValues = otherLoanItems.map(
					({
						interestRate,
						lender,
						outstandingBalance,
						paidFully,
						tradeLineType
					}) =>
						getNonNullValues({
							balance: outstandingBalance,
							otherLoan: lender,
							isFullyPaid: paidFully,
							rate: interestRate,
							tradeLineType
						})
				);
				return creditCardDataWithoutNullValues;
			}
		}
	);
}

export default useGetAllUserOtherLoans;
