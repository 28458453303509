import { AUTO, CREDITCARD, HELOC, MORTGAGE, STUDENT } from 'shared/constants';

// Utils
import calculateEquityAvailable from 'shared/utils/calculations/calculateEquityAvailable';
import { dollarFormatter, dataIsValid } from 'shared/utils';
import normalizeLoanType from 'shared/utils/clientOnboarding/normalizeLoanType';

// Questions Related
const MINIMUM_ACCEPTED_RATE = 0;

// Insights Related
const MINIMUM_CREDIT_CARD_COUNT = 4;
const MINIMUM_ASSET_VALUE = 1000000;
const MINIMUM_CREDIT_SCORE = 800; // The minimum to trigger top 17%
const MAXIMUM_ACCEPTED_MORTGAGE_RATE = 8;
const MINIMUM_KUDDOS_MORTGAGE_RATE = 3;
const MAXIMUM_ACCEPTED_AUTO_RATE = 7;
// const BEST_STUDENT_LOAN_RATE = 4.5;
const MAXIMUM_ACCEPTED_DTI = 40;

const filterLiabilitiesByTradelineType = (liabilityList, tradelineType) =>
	liabilityList.filter(
		(currentLiability) => currentLiability?.tradeLineType === tradelineType
	);

function getMortgageWithLargestBalance(listOfMortgages) {
	let largestValue = -Infinity;
	let refToLargestValue = null;

	listOfMortgages.forEach((currentMortgage) => {
		if (currentMortgage?.outstandingBalance > largestValue) {
			largestValue = currentMortgage.outstandingBalance;
			refToLargestValue = currentMortgage;
		}
	});

	return refToLargestValue;
}

/**
 *
 * @param {Liability[]} sortedLiabilities
 * @returns
 */

export function getExportableReportQuestions(sortedLiabilities) {
	// Questions is always returned
	const questions = [
		'Is any of the data incorrect?',
		'What are your debt-related goals?'
	];

	// For only one heloc.
	const HELOC_TRADELINES = filterLiabilitiesByTradelineType(
		sortedLiabilities,
		HELOC
	);

	// eslint-disable-next-line array-callback-return
	HELOC_TRADELINES.forEach(({ lender }) => {
		questions.push(
			`Do you intend to draw down this HELOC ${lender} further?`
		);
	});

	// Student Tradelines
	const STUDENT_TRADELINES = filterLiabilitiesByTradelineType(
		sortedLiabilities,
		STUDENT
	);

	// returns the first instance in which a student tradeline is present and it has an interest rate of exactly 0
	const firstStudentWithRateZero = STUDENT_TRADELINES.find(
		(studentTradeline) => studentTradeline?.interestRate === 0
	);

	if (firstStudentWithRateZero) {
		// if we are here we know some student loan was found with an interest rate of zero
		if (STUDENT_TRADELINES.length === 1) {
			// we know the user only has 1 student loan push the question.
			const studentTradelineLenderName = STUDENT_TRADELINES[0].lender;

			questions.push(
				`For the ${studentTradelineLenderName}, can you confirm the rate?`
			);
		} else if (STUDENT_TRADELINES.length > 1) {
			// we know that we found at least 1 element in the list that has an interest rate of 0.
			// we know that they have more than 1 student loan
			// what is left to determine is does some other insight get triggered
			const someOtherStudentTradelineWithZeroRate =
				STUDENT_TRADELINES.some(
					(studentTradeline) =>
						studentTradeline?.interestRate === 0 &&
						studentTradeline?.tradelineId !==
							firstStudentWithRateZero.tradelineId
				);

			if (someOtherStudentTradelineWithZeroRate) {
				questions.push(
					'For your student loans that have rates of 0%, can you confirm the rates?'
				);
			} else {
				const studentTradelineLenderName =
					firstStudentWithRateZero.lender;
				questions.push(
					`For the ${studentTradelineLenderName}, can you confirm the rate?`
				);
			}
		}
	}

	sortedLiabilities.forEach((currentLiability) => {
		const {
			interestRate: currentLiabilityRate,
			lender: currentLenderName,
			tradeLineType: currentTradelineType
		} = currentLiability;

		const isLiabilityRateAccepted =
			currentLiabilityRate <= MINIMUM_ACCEPTED_RATE;

		if (isLiabilityRateAccepted && currentTradelineType !== STUDENT) {
			const formattedTradelineType =
				currentTradelineType === CREDITCARD
					? 'Credit Card'
					: normalizeLoanType(currentTradelineType);
			const withLoanText =
				currentTradelineType !== CREDITCARD ? 'loan' : '';
			questions.push(
				`What is the rate of this ${formattedTradelineType} ${withLoanText} from ${currentLenderName}?`
			);
		}
	});

	return questions;
}

/**
 *
 * @param {Array<liabilities>} liabilities
 * @param {{ annualIncome: number ,assetsAmount: number, assetsAmount: number, DTI: number }} clientFinancialDetails
 * @returns
 */

export function getAllFrontendInsights(
	liabilities,
	clientFinancialDetails,
	insights = [
		'Interest rates: Sora forecasts the federal-funds rate to reach 5.5% by the year-end of 2023. It is anticipated to decrease to approximately 4.75% by the conclusion of 2024.'
	]
) {
	const MORTGAGE_TRADELINES = filterLiabilitiesByTradelineType(
		liabilities,
		MORTGAGE
	);

	const AUTO_TRADELINES = filterLiabilitiesByTradelineType(liabilities, AUTO);

	const CREDIT_CARD_TRADELINES = filterLiabilitiesByTradelineType(
		liabilities,
		CREDITCARD
	);

	const STUDENT_TRADELINES = filterLiabilitiesByTradelineType(
		liabilities,
		STUDENT
	);

	const ALL_TRADELINES_WITHOUT_CREDIT_CARD = [
		...MORTGAGE_TRADELINES,
		...AUTO_TRADELINES,
		...STUDENT_TRADELINES
	];

	const HAS_NO_MORTGAGE = MORTGAGE_TRADELINES.length < 1;

	const HAS_MORE_THAN_FOUR_CREDIT_CARDS =
		CREDIT_CARD_TRADELINES.length >= MINIMUM_CREDIT_CARD_COUNT;

	const CURRENT_DTI = clientFinancialDetails?.DTI;

	const mortgageKuddosTracker = [];

	// Mortgage Related
	MORTGAGE_TRADELINES.forEach(({ interestRate, lender }) => {
		if (interestRate > MAXIMUM_ACCEPTED_MORTGAGE_RATE) {
			insights.push(
				`Refinance: Given your ${lender} home loan of ${interestRate}%, we may be able to refinance you into a lower rate.`
			);
		}

		// Kuddos
		if (interestRate < MINIMUM_KUDDOS_MORTGAGE_RATE) {
			mortgageKuddosTracker.push(lender);
		}
	});

	if (MORTGAGE_TRADELINES.length > 0) {
		const mortgageWithLargestBalance =
			getMortgageWithLargestBalance(MORTGAGE_TRADELINES);

		const HAS_HELOC_ELIGIBILITY =
			mortgageWithLargestBalance &&
			mortgageWithLargestBalance?.outstandingBalance &&
			mortgageWithLargestBalance?.clientHome?.assedValue &&
			mortgageWithLargestBalance?.outstandingBalance > 0 &&
			mortgageWithLargestBalance.clientHome?.assedValue > 0;

		// mortgageWithLargestBalance must not be null, must have required properties and those properties must be greater than zero
		if (HAS_HELOC_ELIGIBILITY) {
			const mortgageLiabilitySelected =
				mortgageWithLargestBalance.clientHome;

			const mortgageLiabilitySelectedBalance =
				mortgageWithLargestBalance.outstandingBalance;

			// helocAmount is equityAvailable
			const helocAmount = calculateEquityAvailable(
				mortgageLiabilitySelected,
				mortgageLiabilitySelectedBalance
			);

			if (helocAmount > 0) {
				const formattedHelocAmount =
					dollarFormatter.format(helocAmount);

				insights.push(
					`Heloc Eligibility: You can tap into the equity of your home using a HELOC of up to ${formattedHelocAmount}.`
				);
			}
		}
	}

	if (HAS_NO_MORTGAGE) {
		insights.push(
			'Credit Score: We recommend ensuring your monthly rent payments are reported to the credit agencies to help build your credit score.'
		);
	}

	// Auto Related
	AUTO_TRADELINES.forEach(
		({ lender: currentAutoLender, interestRate: currentAutoRate }) => {
			if (currentAutoRate > MAXIMUM_ACCEPTED_AUTO_RATE) {
				insights.push(
					`Refinance: Given your ${currentAutoLender} auto loan of ${currentAutoRate}%, we may be able to refinance you into a lower rate.`
				);
			}
		}
	);

	// DTI Related
	if (CURRENT_DTI > MAXIMUM_ACCEPTED_DTI) {
		insights.push(
			'Debt / Income: We recommend keeping Debt to Income ratios under 40%'
		);
	}

	// Asset related
	if (clientFinancialDetails?.assetsAmount !== 0 && clientFinancialDetails) {
		if (clientFinancialDetails.assetsAmount > MINIMUM_ASSET_VALUE) {
			insights.push(
				'Line of credit: Given your assets, we can open the lowest rate line of credit to tap into for liquidity as needed.'
			);
		}
	}

	// Kuddos
	if (
		clientFinancialDetails?.creditScore !== 0 &&
		clientFinancialDetails?.creditScore
	) {
		if (clientFinancialDetails.creditScore > MINIMUM_CREDIT_SCORE) {
			insights.push(
				'Credit Score: Well done! You are in the top 17% of Americans.'
			);
		}

		if (clientFinancialDetails.creditScore >= 720) {
			insights.push(
				`Credit Score: Great job! You have a strong credit score of ${clientFinancialDetails.creditScore}. You will likely be able to qualify for most types of credit with favourable terms and interest rates.`
			);
		} else {
			insights.push(
				`Credit Score: Focus on moving from ${clientFinancialDetails.creditScore} to 720 for better pricing.`
			);
		}
	}

	if (mortgageKuddosTracker.length > 0) {
		const mortgageKuddosString = mortgageKuddosTracker.reduce(
			(prev, current) => `${prev}, ${current}`
		);

		const isOrAre = mortgageKuddosTracker.length > 1 ? 'are' : 'is';
		const withS = mortgageKuddosTracker.length > 1 ? 's' : '';

		insights.push(
			`Mortgage: Congratulations! Your ${mortgageKuddosString} mortgage rate${withS} ${isOrAre} lower than 85% of American homeowners.`
		);
	}

	// Credit Card related
	// CREDIT_CARD_TRADELINES.forEach((currentCreditCardTradeline) => {
	// 	const hasMonthlyPayment =
	// 		currentCreditCardTradeline?.monthlyPay !== 0 &&
	// 		currentCreditCardTradeline?.monthlyPay;

	// 	if (hasMonthlyPayment) {
	// 		const {
	// 			lender: currentCreditCardLender,
	// 			outstandingBalance: currentCreditCardBalance,
	// 			interestRate: currentCreditCardInterestRate
	// 		} = currentCreditCardTradeline;
	// 		const formattedBalance = dollarFormatter.format(
	// 			currentCreditCardBalance
	// 		);

	// 		insights.push(
	// 			`Credit Card: We noticed a monthly payment on your ${currentCreditCardLender} credit card. If you are carrying the balance of ${formattedBalance}, we recommend paying it off as soon as possible given its ${currentCreditCardInterestRate}% interest rate.`
	// 		);
	// 	}
	// });

	if (HAS_MORE_THAN_FOUR_CREDIT_CARDS) {
		// Small optimization where we only check the credit cards if the user has 4 or more credit cards
		const hasZeroBalanceCreditCreditCard = CREDIT_CARD_TRADELINES.some(
			(creditCard) => creditCard?.outstandingBalance < 1
		);

		if (hasZeroBalanceCreditCreditCard) {
			insights.push(
				'Credit Card Optimization: Sora recommends 2-3 credit cards for credit score purposes and to maximize rewards.'
			);
		}
	}

	return insights;
}

export function getFrontendMortgageReportInsights(
	mortgageLiability,
	mortgageInsights = []
) {
	const isMissingInterestRate = !dataIsValid(mortgageLiability?.interestRate);
	const isMissingLender = !dataIsValid(mortgageLiability?.lender);

	if (isMissingInterestRate || isMissingLender) return mortgageInsights; // return an empty insights array

	// safe to capture interestRate and lender
	const { interestRate, lender } = mortgageLiability;

	if (interestRate > MAXIMUM_ACCEPTED_MORTGAGE_RATE) {
		mortgageInsights.push(
			`Refinance: Given your ${lender} home loan of ${interestRate}%, we may be able to refinance you into a lower rate.`
		);
	}

	const HAS_HELOC_ELIGIBILITY = dataIsValid(
		mortgageLiability?.equityAvailable
	);

	// mortgageWithLargestBalance must not be null, must have required properties and those properties must be greater than zero
	if (HAS_HELOC_ELIGIBILITY) {
		const helocAmount = mortgageLiability.equityAvailable;

		if (helocAmount > 0) {
			const formattedHelocAmount = dollarFormatter.format(helocAmount);

			mortgageInsights.push(
				`Heloc Eligibility: You can tap into the equity of your home using a HELOC of up to ${formattedHelocAmount}.`
			);
		}
	}

	if (interestRate < MINIMUM_KUDDOS_MORTGAGE_RATE) {
		mortgageInsights.push(
			'Mortgage: Congratulations! Your mortgage rate is lower than 85% of American homeowners.'
		);
	}

	return mortgageInsights;
}
