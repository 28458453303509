import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import axiosInstance from 'services/API/API';

// Api Endpoints
import { getMethodfiLiabilities } from 'shared/api-urls';

// Additional useMutation
import useMutateUpdateOnboardingStatus from 'hooks/clientOnboarding/mutations/useMutateUpdateOnboardingStatus';

// Our Routes
import {
	CLIENT_ONBOARDING_UNABLE_TO_VERIFY_ROUTE,
	CLIENT_ONBOARDING_LIABILITIES_IDENTIFIED_ROUTE
} from 'routes';

import { LIABILITY_ACCOUNT_CONNECTED } from 'shared/constants';

function useMutateGetMethodfiLiabilities() {
	const navigate = useNavigate();
	const updateOnboardingStatus = useMutateUpdateOnboardingStatus();

	return useMutation(
		async (accountHolderId) => {
			const response = await axiosInstance.get(
				`${getMethodfiLiabilities}${accountHolderId}`
			);
			const methodfiData = response.data.data;

			// Updates Onboarding Status
			await updateOnboardingStatus.mutate(LIABILITY_ACCOUNT_CONNECTED);
			return methodfiData;
		},
		{
			onSuccess: () => {
				navigate(CLIENT_ONBOARDING_LIABILITIES_IDENTIFIED_ROUTE);
			},
			onError: () => {
				navigate(CLIENT_ONBOARDING_UNABLE_TO_VERIFY_ROUTE);
			}
		}
	);
}

export default useMutateGetMethodfiLiabilities;
