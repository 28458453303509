import { useTheme } from '@emotion/react';
import { TextField, InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import PropTypes from 'prop-types';

// Common Prop Types
import CommonPropTypes from 'shared/prop-types';

function SearchInput({ sx, value, onChange }) {
	const SoraTheme = useTheme();

	return (
		<TextField
			placeholder="Client Name"
			autoComplete="off"
			variant="outlined"
			fullWidth
			value={value}
			onChange={onChange}
			InputProps={{
				startAdornment: (
					<InputAdornment position="start">
						<SearchIcon
							sx={{
								color: SoraTheme.palette.primary.black,
								fontSize: '1.2rem'
							}}
						/>
					</InputAdornment>
				),
				style: {
					fontWeight: 400,
					fontSize: '0.8rem',
					lineHeight: '1.3rem',
					letterSpacing: '0rem',
					height: 42
				}
			}}
			sx={{
				borderRadius: 50,
				backgroundColor: '#fff',
				'& .MuiOutlinedInput-notchedOutline': {
					border: 'none'
				},
				'& .MuiInputBase-input::placeholder': {
					fontWeight: 400,
					fontSize: '0.8rem',
					lineHeight: '1.3rem',
					letterSpacing: '0rem'
				},
				height: 42
			}}
		/>
	);
}

SearchInput.propTypes = {
	sx: CommonPropTypes.sx,
	onChange: PropTypes.func,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

SearchInput.defaultProps = {
	sx: {},
	onChange: () => {},
	value: undefined
};

export default SearchInput;
