// Utils
import { dataIsValid } from 'shared/utils';

/**
 * Calculates the total property value of mortgages that have a valid client home.
 *
 * @param {Array} mortgages - An array of mortgages.
 * @returns {number} - The total property value of the mortgages.
 */
function getTotalPropertyValue(mortgages) {
	const filteredByHome = mortgages.filter((mortgage) =>
		dataIsValid(mortgage.clientHome)
	);

	const clientHomes = filteredByHome.map(({ clientHome }) => clientHome);

	// Filtered by home means that the mortgage has a home
	const uniqueAddresses = new Set(
		clientHomes.map(
			({ addressLine1, addressLine2 }) =>
				`${addressLine1} ${addressLine2}`
		)
	);

	let sumOfPropertyValues = 0;

	uniqueAddresses.forEach((address) => {
		const currentHomeValue = clientHomes.find(
			({ addressLine1, addressLine2 }) =>
				`${addressLine1} ${addressLine2}` === address
		);

		sumOfPropertyValues += currentHomeValue.estimatedValue;
	});

	return sumOfPropertyValues;
}

export default getTotalPropertyValue;
