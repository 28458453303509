import { Box } from '@mui/material';

// Our Theme
import SoraTheme from 'theme';

// Our Components
import LoadingSkeleton from 'components/Frame/LoadingSkelton';
import NavMenuAdvisor from 'components/NavMenu/NavMenuAdvisor';
import NavMenuClient from 'components/NavMenu/NavMenuClient';
import TopNavMenuAdvisor from 'components/NavMenu/TopNavMenuAdvisor';
import TopNavMenuClient from 'components/NavMenu/TopNavMenuClient';
import NavSubMenu from 'components/NavMenu/NavBarComponents/NavSubMenu';

// Hooks
import useGetProfile from 'hooks/useGetProfile';

// Constants
import { CLIENT } from 'shared/constants';

function DesktopLeftNav() {
	const { isLoading, isSuccess, data } = useGetProfile();

	if (isLoading) {
		return (
			<Box
				sx={{
					background:
						'linear-gradient(180deg, #206EA7 0%, rgba(38, 133, 193, 0.00) 100%);',
					height: '100%',
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'space-between',
					alignItems: 'center'
				}}
			>
				<Box>
					<LoadingSkeleton />
				</Box>

				<Box
					component="img"
					src="https://prod-public-asset.s3.amazonaws.com/SoraLogos/SoraLogoIndigo.svg"
					sx={{ width: '100%', height: 50, marginBottom: 8 }}
				/>
			</Box>
		);
	}

	if (isSuccess) {
		const roleFromApi = data?.role;
		const isClient = roleFromApi === CLIENT;

		const {
			profilePicUrl: advisorProfilePic,
			companyName,
			firstName,
			lastName
		} = data;

		return (
			<Box
				sx={{
					background:
						'linear-gradient(180deg, #206EA7 0%, rgba(38, 133, 193, 0.00) 100%);',
					height: '100%',
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'space-between'
				}}
			>
				<Box
					sx={{
						display: 'flex',
						marginLeft: 6,
						justifyContent: 'left'
					}}
				>
					{isClient ? (
						<TopNavMenuClient
							sx={{ color: SoraTheme.palette.primary.white }}
						/>
					) : (
						<TopNavMenuAdvisor
							firstName={firstName}
							lastName={lastName}
							companyName={companyName}
							profilePicUrl={advisorProfilePic}
							sx={{ color: SoraTheme.palette.primary.white }}
						/>
					)}
				</Box>

				<Box sx={{ marginLeft: 6, marginRight: 6 }}>
					<hr style={{ color: SoraTheme.palette.primary.white }} />
				</Box>

				{/* Main Nav Options */}
				<Box sx={{ marginLeft: 2 }}>
					{isClient ? (
						<NavMenuClient variant="desktop" />
					) : (
						<NavMenuAdvisor variant="desktop" />
					)}
				</Box>
				<Box
					sx={{
						display: 'flex',
						height: '100%'
					}}
				/>

				<Box
					component="img"
					src="https://prod-public-asset.s3.amazonaws.com/SoraLogos/SoraLogoIndigo.svg"
					sx={{
						height: '30px',
						maxWidth: '80px',
						marginBottom: 3,
						marginLeft: 6
					}}
				/>

				<NavSubMenu isClient={isClient} variant="desktop" />
			</Box>
		);
	}
}

export default DesktopLeftNav;
