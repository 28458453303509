function getPropertyUse(propertyUse) {
	const propertyUseMap = {
		PRIMARY: 'Primary residence',
		SECONDARY: 'Secondary/Vacation residence',
		INVESTMENT: 'Investment property'
	};

	return propertyUseMap[propertyUse] ?? '';
}

export default getPropertyUse;
