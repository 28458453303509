import { Grid } from '@mui/material';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

// Our Components
import Offers from 'components/Offers/Offers';
import OffersPageHeading from 'components/Offers/OffersPageHeading';
import Loader from 'components/Loader';

// Our Hooks
import useGetProfile from 'hooks/useGetProfile';

// Constants
const SUPPORTED_OFFER_TYPES = ['new_loan', 'cash_out', 'refinance'];

function OffersPage({ OfferType }) {
	const location = useLocation();

	const { clientData } = location.state;

	const { isLoading, isSuccess, data } = useGetProfile();

	const { firstName } = clientData;

	if (isLoading) {
		return <Loader />;
	}

	if (isSuccess) {
		const { role } = data;
		return (
			<Grid container>
				<Grid item xs={12} sx={{ marginBottom: 6 }}>
					<OffersPageHeading OfferType={OfferType} />
				</Grid>
				<Grid item xs={11}>
					<Offers
						clientId={clientData?.clientId}
						offerType={OfferType}
						name={firstName}
						role={role}
					/>
				</Grid>
				<Grid item xs={1} />
			</Grid>
		);
	}
}

OffersPage.propTypes = {
	OfferType: PropTypes.oneOf(SUPPORTED_OFFER_TYPES).isRequired
};

export default OffersPage;
