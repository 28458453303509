import {
	Box,
	FormGroup,
	FormControlLabel,
	Switch,
	Typography
} from '@mui/material';

function ResultsSwitch({ checked, setChecked }) {
	return (
		<FormGroup sx={{ marginTop: 4 }}>
			<Box display="flex" alignItems="center">
				<Typography
					marginRight={1}
					sx={{
						'&:hover': { cursor: 'pointer' },
						userSelect: 'none'
					}}
					onClick={() => setChecked(!checked)}
				>
					Present Value
				</Typography>
				<FormControlLabel
					control={
						<Switch
							checked={checked}
							onChange={() => setChecked(!checked)}
						/>
					}
					label="Future Value"
					labelPlacement="end"
					componentsProps={{
						typography: {
							sx: {
								userSelect: 'none'
							}
						}
					}}
				/>
			</Box>
		</FormGroup>
	);
}

export default ResultsSwitch;
