import { dataIsValid } from 'shared/utils';

const states = {
	Alabama: 'AL',
	Alaska: 'AK',
	Arizona: 'AZ',
	Arkansas: 'AR',
	California: 'CA',
	Colorado: 'CO',
	Connecticut: 'CT',
	Delaware: 'DE',
	Florida: 'FL',
	Georgia: 'GA',
	Hawaii: 'HI',
	Idaho: 'ID',
	Illinois: 'IL',
	Indiana: 'IN',
	Iowa: 'IA',
	Kansas: 'KS',
	Kentucky: 'KY',
	Louisiana: 'LA',
	Maine: 'ME',
	Maryland: 'MD',
	Massachusetts: 'MA',
	Michigan: 'MI',
	Minnesota: 'MN',
	Mississippi: 'MS',
	Missouri: 'MO',
	Montana: 'MT',
	Nebraska: 'NE',
	Nevada: 'NV',
	'New Hampshire': 'NH',
	'New Jersey': 'NJ',
	'New Mexico': 'NM',
	'New York': 'NY',
	'North Carolina': 'NC',
	'North Dakota': 'ND',
	Ohio: 'OH',
	Oklahoma: 'OK',
	Oregon: 'OR',
	Pennsylvania: 'PA',
	'Rhode Island': 'RI',
	'South Carolina': 'SC',
	'South Dakota': 'SD',
	Tennessee: 'TN',
	Texas: 'TX',
	Utah: 'UT',
	Vermont: 'VT',
	Virginia: 'VA',
	Washington: 'WA',
	'West Virginia': 'WV',
	Wisconsin: 'WI',
	Wyoming: 'WY'
};

/**
 * @param {string} stateName
 * @returns {string} - The state abbreviation
 */

function getStateAbbreviation(stateName) {
	const isStateMissing = !dataIsValid(stateName);

	if (isStateMissing) return '';

	const abbreviations = Object.values(states);

	const isAlreadyAbbreviated = stateName.length === 2;

	if (isAlreadyAbbreviated) {
		// If input is an abbreviation, validate it
		const upperInput = stateName.toUpperCase();
		return abbreviations.includes(upperInput) ? upperInput : 't';
	}

	// If input is a full name, find the corresponding abbreviation
	const abbreviation =
		states[
			stateName.charAt(0).toUpperCase() + stateName.slice(1).toLowerCase()
		];

	return abbreviation ?? '';
}

export default getStateAbbreviation;
