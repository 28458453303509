import {
	Table,
	TableContainer,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	Typography,
	Paper
} from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import PropTypes from 'prop-types';
import { useMemo } from 'react';

// Our Prop Types
import CommonPropTypes from 'shared/prop-types';

// Constants
const defaultTableStyles = {
	maxWidth: '100%',
	overflowX: 'auto',
	height: '100%'
};

function ResponsiveTable({
	ContainerComponent,
	columns,
	rows,
	elevation,
	makeLastBold,
	columnCellSx,
	firstColumnSx,
	secondColumnSx,
	rowSx,
	cellSx,
	sx,
	emptyTableMessage
}) {
	const tableColumns = useMemo(
		() =>
			columns.map((columnHeading, index) => {
				const isFirst = index === 0;
				const isSecond = index === 1;

				const finalColumnFirst = isFirst
					? { ...columnCellSx, ...firstColumnSx }
					: columnCellSx;

				const finalColumnStylingSecond = isSecond
					? { ...columnCellSx, ...secondColumnSx }
					: columnCellSx;

				const finalColumnStyling = isFirst
					? finalColumnFirst
					: finalColumnStylingSecond;

				const isConfig = typeof columnHeading === 'object';

				if (isConfig) {
					const { props, value } = columnHeading;

					return (
						<TableCell
							key={uuidv4()}
							sx={finalColumnStyling}
							{...props}
						>
							{value}
						</TableCell>
					);
				}

				return (
					<TableCell key={uuidv4()} sx={finalColumnStyling}>
						<Typography
							variant="body2"
							sx={{ whiteSpace: 'pre-line' }}
						>
							{columnHeading}
						</Typography>
					</TableCell>
				);
			}),
		[columns, columnCellSx]
	);

	const rowKeys = useMemo(() => rows.map(() => uuidv4()), []);

	const cellKeys = useMemo(
		() => rows.map((row) => row.map(() => uuidv4())),
		[]
	);

	const tableRows = rows.map((currentRow, index) => {
		const isLastRow = index === rows.length - 1 && makeLastBold;
		const rowKey = rowKeys[index];

		const currentTableCells = currentRow.map((currentCell, cIndex) => {
			const isConfig = typeof currentCell === 'object';
			const cellKey = cellKeys[index][cIndex];

			if (isConfig) {
				const { props, value } = currentCell;

				return (
					<TableCell key={cellKey} sx={cellSx} {...props}>
						{value}
					</TableCell>
				);
			}

			return (
				<TableCell key={cellKey} sx={cellSx}>
					{isLastRow && <strong>{currentCell}</strong>}
					{!isLastRow && currentCell}
				</TableCell>
			);
		});

		return (
			<TableRow sx={rowSx} key={rowKey}>
				{currentTableCells}
			</TableRow>
		);
	});

	return (
		<TableContainer
			component={ContainerComponent}
			sx={{ ...defaultTableStyles, ...sx }}
			elevation={elevation ?? 4}
		>
			<Table>
				<TableHead>
					<TableRow>
						{tableColumns.slice(0, 1)}
						{tableColumns.slice(1)}
					</TableRow>
				</TableHead>
				{!emptyTableMessage && <TableBody>{tableRows}</TableBody>}
			</Table>
			{emptyTableMessage}
		</TableContainer>
	);
}

ResponsiveTable.propTypes = {
	ContainerComponent: PropTypes.elementType,
	elevation: PropTypes.number,
	makeLastBold: PropTypes.bool,
	columnCellSx: CommonPropTypes.sx,
	cellSx: CommonPropTypes.sx,
	firstColumnSx: CommonPropTypes.sx,
	secondColumnSx: CommonPropTypes.sx,
	sx: CommonPropTypes.sx,
	rowSx: CommonPropTypes.sx
};

ResponsiveTable.defaultProps = {
	ContainerComponent: Paper,
	columnCellSx: {},
	cellSx: {},
	firstColumnSx: {},
	secondColumnSx: {},
	elevation: 4,
	makeLastBold: true,
	sx: {},
	rowSx: {}
};

export default ResponsiveTable;
