import { Box } from '@mui/material';
import { useCallback, useState, useMemo } from 'react';
import { useTheme } from '@emotion/react';

// Our Components
import DynamicTabs from 'components/Tabs/DynamicTabs';
import InterestSavingsTab from 'components/FADashboard/InterestSavingsTab';
import NetPresentValueTab from 'components/FADashboard/NetPresentValueTab';
import UpsidePotentialTab from 'components/FADashboard/UpsidePotentialTab';

const TABS_NAMES = [
	{ type: 'Interest Savings' },
	{ type: 'Net Present Value' },
	{ type: 'Upside Potential' }
];

function LoanComparisonTabs({
	analyticsSavingData,
	analyticsAmortizationData,
	analyticsNPVData,
	analyticsUpsidePotentialData
}) {
	const SoraTheme = useTheme();

	const { disabledGrey } = SoraTheme.palette.primary;

	// Currently selected Tab
	const [tabPanelSelected, setTabPanelSelected] = useState(0);

	const handleTabSelection = useCallback((e, value) => {
		setTabPanelSelected(value);
	}, []);

	const tabPanelItems = useMemo(
		() => [
			{
				children: (
					<InterestSavingsTab
						analyticsSavingData={analyticsSavingData}
						analyticsAmortizationData={analyticsAmortizationData}
					/>
				)
			},
			{
				children: (
					<NetPresentValueTab analyticsNPVData={analyticsNPVData} />
				)
			},
			{
				children: (
					<UpsidePotentialTab
						analyticsUpsidePotentialData={
							analyticsUpsidePotentialData
						}
					/>
				)
			}
		],
		[
			analyticsSavingData,
			analyticsAmortizationData,
			analyticsNPVData,
			analyticsUpsidePotentialData
		]
	);

	return (
		<Box
			sx={{
				marginTop: 8
			}}
		>
			<DynamicTabs
				tabItems={TABS_NAMES}
				tabItemSX={{
					padding: 0,
					textTransform: 'none',
					marginRight: 5
				}}
				tabsSX={{
					marginBottom: 4,
					borderBottom: `1px solid ${disabledGrey}`,
					'& .MuiTabs-scrollButtons.Mui-disabled': {
						opacity: 0.3
					}
				}}
				tabPanelItems={tabPanelItems}
				value={tabPanelSelected}
				handleChange={handleTabSelection}
				aria-label="Settings tabs"
			/>
		</Box>
	);
}

export default LoanComparisonTabs;
