import axios from 'axios';

const baseURL = `${process.env.REACT_APP_INTEGRATIONS_SERVICE_URL}/hubspot/`;

const HubSpotAPI = axios.create({
	baseURL,
	headers: {
		Api_key: `${process.env.REACT_APP_INTEFGRATIONS_SERVICE_API_KEY}`
	}
});

export default HubSpotAPI;
