import { useQueryClient } from 'react-query';
import { useLocation } from 'react-router-dom';
import { useRef } from 'react';
import { Dialog, Box, Typography } from '@mui/material';
import {
	CircleStencil,
	Cropper,
	CropperPreview,
	RectangleStencil
} from 'react-advanced-cropper';
import 'react-advanced-cropper/dist/style.css'; // The css styling that shows the cropper squares, etc.

// Our Components
import Loader from 'components/Loader/index';
import {
	PrimaryButton,
	SecondaryButton,
	TextButton
} from 'components/Button/Button';

// Our Hooks
import useMutateUploadProfilePicture from 'hooks/advisorProfile/useMutateUploadProfilePicture';
import useMutateUploadCompanyLogo from 'hooks/advisorProfile/useMutateUploadCompanyLogo';

// our query keys
import {
	ADVISOR_PROFILE_PICTURE,
	ADVISOR_COMPANY_LOGO,
	TEMP_CROPPED_COMPANY_LOGO_DATA
} from 'shared/query-keys';

// Our Routes
import { SETTINGS_ROUTE } from 'routes';

const getMimeTypeMapping = {
	'image/jpeg': 'JPEG',
	'image/png': 'PNG'
};

function ImageCropper({
	isBackdropOpen,
	setIsBackdropOpen,
	files,
	gracefullyCloseBackdrop,
	imageSrc,
	isCorporate,
	setCroppedImage
}) {
	const queryClient = useQueryClient();
	const { pathname } = useLocation();
	const SelectedStencil = isCorporate ? RectangleStencil : CircleStencil;
	const uploadProfilePicture = useMutateUploadProfilePicture();
	const uploadCompanyLogo = useMutateUploadCompanyLogo();

	const { isLoading: isProfileUploadLoading } = uploadProfilePicture;
	const { isLoading: isCompanyLogoLoading } = uploadCompanyLogo;

	const borderRadiusBasedOnIsCorporate = isCorporate ? 0 : 150;

	const previewRef = useRef(null);
	const cropperRef = useRef(null);

	const onUpdate = () => {
		previewRef.current?.refresh();
	};

	const getCroppedImage = () => {
		const croppedImage = cropperRef?.current?.getCanvas()?.toDataURL();
		const currentFile = files[0]?.fileData;
		const indexOfSemiColon = croppedImage.indexOf(',');
		const cleanseFile = croppedImage.slice(indexOfSemiColon + 1);
		const soraMimeType = getMimeTypeMapping[currentFile.type];
		if (isCorporate) {
			if (pathname === SETTINGS_ROUTE) {
				uploadCompanyLogo.mutate(
					{
						fileType: soraMimeType,
						fileName: currentFile.name,
						fileContent: cleanseFile
					},
					{
						onSuccess: async () => {
							await queryClient.invalidateQueries(
								ADVISOR_COMPANY_LOGO
							);
							setCroppedImage(croppedImage);
							setIsBackdropOpen(false);
						}
					}
				);
			} else {
				queryClient.setQueryData(TEMP_CROPPED_COMPANY_LOGO_DATA, {
					fileType: soraMimeType,
					fileName: currentFile.name,
					fileContent: cleanseFile
				});

				setCroppedImage(croppedImage);
				setIsBackdropOpen(false);
			}
		} else {
			// uploadProfile
			uploadProfilePicture.mutate(
				{
					fileType: soraMimeType,
					fileName: currentFile.name,
					fileContent: cleanseFile
				},
				{
					onSuccess: async () => {
						if (pathname === SETTINGS_ROUTE) {
							await queryClient.invalidateQueries(
								ADVISOR_PROFILE_PICTURE
							);
						}
						setCroppedImage(croppedImage);
						setIsBackdropOpen(false);
					}
				}
			);
		}
	};

	const resetCrop = () => {
		cropperRef?.current?.reset();
	};

	const doABarrelRoll = () => {
		const interVal = setInterval(() => {
			const currentImageDegrees =
				cropperRef?.current?.getState().transforms.rotate;

			if (currentImageDegrees >= 360) {
				clearInterval(interVal);
				resetCrop();
			} else {
				cropperRef?.current?.rotateImage(180);
			}
		}, 400);
	};

	return (
		<Dialog
			fullScreen
			open={isBackdropOpen}
			onClose={() => gracefullyCloseBackdrop()}
		>
			<Box
				sx={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					width: '100%',
					height: '100%',
					gap: 4
				}}
			>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						gap: 2
					}}
				>
					<Typography variant="h6">
						Crop your profile picture below
					</Typography>
					<Cropper
						style={{ width: 500, height: 500 }}
						ref={cropperRef}
						src={imageSrc}
						stencilComponent={SelectedStencil}
						stencilProps={{
							grid: true
						}}
						onUpdate={onUpdate}
						className="cropper"
					/>
				</Box>

				<Box
					sx={{
						height: 500,
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						gap: 2
					}}
				>
					<Typography variant="h6">Preview</Typography>
					<CropperPreview
						style={{
							width: 300,
							height: 300,
							borderRadius: borderRadiusBasedOnIsCorporate
						}}
						ref={previewRef}
						cropper={cropperRef}
						className="preview"
					/>
					{(isProfileUploadLoading || isCompanyLogoLoading) && (
						<Loader />
					)}
					{!isCompanyLogoLoading && !isProfileUploadLoading && (
						<PrimaryButton onClick={getCroppedImage}>
							Select & Crop
						</PrimaryButton>
					)}

					<SecondaryButton onClick={resetCrop}>Reset</SecondaryButton>
					<TextButton onClick={doABarrelRoll}>
						Do a barrel roll
					</TextButton>
				</Box>
			</Box>
		</Dialog>
	);
}

export default ImageCropper;
