import { useMutation, useQueryClient } from 'react-query';
import { newHomeUrl } from 'shared/api-urls';
import axiosInstance from 'services/API/API';

import { ERROR_MESSAGE_DATA } from 'shared/query-keys';

function useMutateNewHome() {
	const queryClient = useQueryClient();
	return useMutation(
		(homeData) =>
			axiosInstance
				.post(newHomeUrl, homeData)
				.then((res) => res.data.data),
		{
			onError: (error) => {
				queryClient.setQueryData(ERROR_MESSAGE_DATA, error.message);
			}
		}
	);
}

export default useMutateNewHome;
