import RefreshIcon from '@mui/icons-material/Refresh';
import { Typography } from '@mui/material';

// Our Components
import { TextButton } from 'components/Button/Button';

function RefreshButton({ disable, isLoading, handleOnClick, buttonText }) {
	return (
		<TextButton
			aria-label="refresh"
			disabled={disable || isLoading}
			onClick={() => handleOnClick()}
			sx={{ color: '#323232', textDecoration: 'none' }}
		>
			{isLoading && (
				<RefreshIcon
					sx={{
						color: '#323232',
						marginRight: 1,
						'@keyframes rotateAnimation': {
							from: { transform: 'rotate(0deg)' },
							to: { transform: 'rotate(360deg)' }
						},
						animation: 'rotateAnimation 2s linear infinite'
					}}
				/>
			)}

			{!isLoading && (
				<RefreshIcon
					sx={{
						opacity: disable ? 0.5 : 1,
						color: '#323232',
						marginRight: 1
					}}
				/>
			)}

			<Typography variant="caption" sx={{ lineHeight: '1.5' }}>
				{buttonText}
			</Typography>
		</TextButton>
	);
}

export default RefreshButton;
