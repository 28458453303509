import LoanComparisonTabs from 'components/Analytics/LoanComparisonTabs';

function LoanComparisonGraphSection({
	analyticsSavingData,
	analyticsAmortizationData,
	analyticsNPVData,
	analyticsUpsidePotentialData
}) {
	return (
		<LoanComparisonTabs
			analyticsSavingData={analyticsSavingData}
			analyticsAmortizationData={analyticsAmortizationData}
			analyticsNPVData={analyticsNPVData}
			analyticsUpsidePotentialData={analyticsUpsidePotentialData}
		/>
	);
}

export default LoanComparisonGraphSection;
