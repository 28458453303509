import PropTypes from 'prop-types';
import { Box, Typography, Paper, Stack } from '@mui/material';
import { useTheme } from '@emotion/react';

// Our Components
import { TertiaryButton } from 'components/Button/Button';
import SoraToolTip from 'components/Tooltip/index';

// Our Utility
import { roundToDecimal } from 'shared/utils';

function CardItem({
	balance,
	buttonText,
	buttonSx,
	balanceSx,
	cardHeading,
	onClickHandler,
	rate,
	toolTip,
	withoutButton,
	sx,
	children
}) {
	const SoraTheme = useTheme();

	const { primary } = SoraTheme.palette;

	return (
		<Paper
			elevation={3}
			square={false}
			component={Stack}
			sx={{
				borderRadius: '20px',
				padding: '28px',
				flexDirection: 'column',
				gap: 2,
				boxSizing: 'border-box',
				height: '100%',
				position: 'relative',
				...sx
			}}
		>
			{toolTip && (
				<SoraToolTip
					text={toolTip}
					toolTipSx={{
						cursor: 'pointer',
						position: 'absolute',
						top: '20px',
						right: '20px'
					}}
				/>
			)}

			{cardHeading && (
				<Typography variant="subtitle1" gutterBottom>
					{cardHeading}
				</Typography>
			)}

			{balance && (
				<Typography
					variant="h5"
					gutterBottom
					color={primary.indigo}
					sx={{ ...balanceSx }}
				>
					{balance}
				</Typography>
			)}
			{(rate === 0 || rate) && (
				<Box sx={{ flexGrow: 2, textAlign: 'center' }}>
					<Typography
						variant="h5"
						gutterBottom
						color={primary.indigo}
					>
						{roundToDecimal(rate)}%
					</Typography>
					<Typography variant="caption" gutterBottom>
						Rate
					</Typography>
				</Box>
			)}
			{children}
			{!withoutButton && (
				<TertiaryButton
					sx={{
						marginTop: 2,
						minWidth: 135,
						padding: 1.5,
						...buttonSx
					}}
					onClick={() => onClickHandler()}
				>
					{buttonText}
				</TertiaryButton>
			)}
		</Paper>
	);
}

CardItem.propTypes = {
	cardHeading: PropTypes.string,
	buttonText: PropTypes.string,
	balance: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	rate: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	withoutButton: PropTypes.bool,
	// eslint-disable-next-line react/forbid-prop-types
	toolTip: PropTypes.oneOfType([
		PropTypes.object,
		PropTypes.bool,
		PropTypes.string
	])
};

CardItem.defaultProps = {
	cardHeading: null,
	balance: null,
	buttonText: 'See Options',
	rate: null,
	withoutButton: false,
	toolTip: false
};

export default CardItem;
