import { Box, Typography, Paper } from '@mui/material';

// Common Props
import CommonProps from 'shared/prop-types';

function WalletWellDoneCard({ sx }) {
	const SoraMark =
		'https://prod-public-asset.s3.amazonaws.com/SoraLogos/Sora_Mark.png';
	return (
		<Paper
			elevation={3}
			sx={{
				padding: 4,
				paddingTop: 1,
				paddingBottom: 1,
				marginBottom: 1,
				borderRadius: 6,
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'space-evenly',
				minHeight: 195,
				background: 'linear-gradient(180deg, #FFF 0%, #F5F8FA 100%)',
				...sx
			}}
		>
			<Box
				sx={{
					display: 'flex',
					alignItems: 'flex-end',
					gap: 2
				}}
			>
				<Box component="img" src={SoraMark} sx={{ height: '2.5rem' }} />
				<Typography variant="h3Gascogne" sx={{ fontSize: '1.5rem' }}>
					Interest Rates
				</Typography>
			</Box>

			<Typography
				variant="body2"
				sx={{
					textOverflow: 'ellipsis',
					display: '-webkit-box',
					WebkitBoxOrient: 'vertical',
					wordWrap: 'break-word',
					WebkitLineClamp: 4
				}}
			>
				Sora is actively scanning its lender partners and will alert you
				if we find a savings opportunity.
			</Typography>
		</Paper>
	);
}

WalletWellDoneCard.propTypes = {
	sx: CommonProps.sx
};

WalletWellDoneCard.defaultProps = {
	sx: {}
};

export default WalletWellDoneCard;
