import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';

// our components
import ChartItem from 'components/SoraWallet/ChartItem';
import SimpleDynamicBarchart from 'components/Chart/SimpleDynamicBarchart';

// our utils
import { dataIsValid, dollarFormatter, roundToDecimal } from 'shared/utils';

const DEFAULT_SUB_CHART_HEIGHT = 160;

function Chart({ bestOfferLoan, currentLoan, variant }) {
	// TODO Conditionally render a char item based off props
	// Expect an object per Chart Item with Label, variant, 2 separate int values

	const currentLoanYearsRemaining = dataIsValid(currentLoan.remainingMonth)
		? roundToDecimal(currentLoan.remainingMonth / 12)
		: 'N/A';

	const bestOfferLoanYearsRemaining = dataIsValid(
		bestOfferLoan.remainingMonth
	)
		? roundToDecimal(bestOfferLoan.remainingMonth / 12)
		: 'N/A';

	const hasCurrentMonthlyMortgagePayment = dataIsValid(
		currentLoan?.monthlyMortgagePayment
	);

	const currentMonthlyMortgagePayment = hasCurrentMonthlyMortgagePayment
		? currentLoan.monthlyMortgagePayment
		: currentLoan.monthlyPayment;

	const costOfDebtData = [
		{
			name: 'Current Loan',
			value: currentLoan.costOfDebt
		},
		{
			name: 'New Loan',
			value: bestOfferLoan.costOfDebt,
			fill: 'linear-gradient(180deg, #2685C1 0%, rgba(38, 133, 193, 0) 139.01%)'
		}
	].map((item, i) => ({
		...item,
		fill: i === 1 ? 'url(#barGradient)' : 'rgba(200, 233, 241, 0.87)'
	}));

	const monthlyPaymentData = [
		{
			name: 'Current Loan',
			value: currentMonthlyMortgagePayment
		},
		{
			name: 'New Loan',
			value: bestOfferLoan.monthlyPayment
		}
	].map((item, i) => ({
		...item,
		fill: i === 1 ? 'url(#barGradient)' : 'rgba(200, 233, 241, 0.87)'
	}));

	const yearsRemainingData = [
		{
			name: 'Current Loan',
			value: currentLoanYearsRemaining
		},
		{
			name: 'New Loan',
			value: bestOfferLoanYearsRemaining
		}
	].map((item, i) => ({
		...item,
		fill: i === 1 ? 'url(#barGradient)' : 'rgba(200, 233, 241, 0.87)'
	}));

	if (variant === 'new') {
		return (
			<>
				<Box sx={{ height: DEFAULT_SUB_CHART_HEIGHT, width: '100%' }}>
					<Typography variant="subtitle1" sx={{ marginBottom: 1 }}>
						Total Cost Of Debt
					</Typography>
					<SimpleDynamicBarchart data={costOfDebtData} />
				</Box>
				<Box sx={{ height: DEFAULT_SUB_CHART_HEIGHT, width: '100%' }}>
					<Typography variant="subtitle1" sx={{ marginBottom: 1 }}>
						Monthly Payment
					</Typography>
					<SimpleDynamicBarchart
						data={monthlyPaymentData}
						valueFormatter={(monthlyPayment) =>
							`${dollarFormatter.format(monthlyPayment)}/month`
						}
					/>
				</Box>
				<Box sx={{ height: DEFAULT_SUB_CHART_HEIGHT, width: '100%' }}>
					<Typography variant="subtitle1" sx={{ marginBottom: 1 }}>
						Years Remaining
					</Typography>
					<SimpleDynamicBarchart
						data={yearsRemainingData}
						valueFormatter={(years) => `${years} years`}
					/>
				</Box>
			</>
		);
	}

	return (
		<Box
			sx={{
				width: '100%',
				display: 'flex',
				flexDirection: 'row',
				justifyContent: 'space-evenly',
				marginBottom: 3
			}}
		>
			<ChartItem
				label="Total Cost of Debt"
				currentLoanValue={currentLoan.costOfDebt}
				newLoanValue={bestOfferLoan.costOfDebt}
				variant="dollar"
				sx={{ marginLeft: 2 }}
			/>
			<ChartItem
				label="Monthly Payment"
				currentLoanValue={currentMonthlyMortgagePayment}
				newLoanValue={bestOfferLoan.monthlyPayment}
				variant="month"
			/>
			<ChartItem
				label="Years Remaining"
				currentLoanValue={currentLoanYearsRemaining}
				newLoanValue={bestOfferLoanYearsRemaining}
				variant="year"
			/>
		</Box>
	);
}

Chart.propTypes = {
	variant: PropTypes.string
};

Chart.defaultProps = {
	variant: 'default'
};

export default Chart;
