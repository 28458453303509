import { useMutation } from 'react-query';

// our axios
import axiosInstance from 'services/API/API';

// our endpoints
import { uploadCompanyLogo } from 'shared/api-urls';

function useMutateUploadCompanyLogo() {
	return useMutation(async (fileData) => {
		const response = await axiosInstance.post(uploadCompanyLogo, fileData);
		return response.data;
	});
}

export default useMutateUploadCompanyLogo;
