import { useMutation, useQueryClient } from 'react-query';

// our axios
import axiosInstance from 'services/API/API';

// Our Endpoints
import { updateNewLoanInquiryStatusUrl } from 'shared/api-urls';

// our query keys
import {
	ADVISOR_LOAN_REQUESTS,
	ERROR_MESSAGE_DATA,
	LOAN_REQUEST
} from 'shared/query-keys';

function useMutateUpdateLoanRequestStatus() {
	const queryClient = useQueryClient();
	return useMutation(
		async ({ newLoanId, faStatus, adminStatus }) => {
			const response = await axiosInstance.patch(
				`${updateNewLoanInquiryStatusUrl}`,
				[
					{
						newLoanId,
						faStatus,
						adminStatus
					}
				]
			);

			return response.data;
		},
		{
			onSuccess: (loanRequestId) => {
				queryClient.refetchQueries({
					queryKey: LOAN_REQUEST,
					loanRequestId
				});
				queryClient.invalidateQueries(ADVISOR_LOAN_REQUESTS);
			}
		},
		{
			onError: (error) => {
				queryClient.setQueryData(ERROR_MESSAGE_DATA, error);
			}
		}
	);
}

export default useMutateUpdateLoanRequestStatus;
