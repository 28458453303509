import { useMutation } from 'react-query';

// Our Axios
import axiosInstance from 'services/API/API';

// Our Endpoints
import { reInviteClientUrl } from 'shared/api-urls';

function useMutateReinviteClient() {
	return useMutation(async (inviteId) => {
		const response = await axiosInstance.get(
			`${reInviteClientUrl}/${inviteId}`
		);

		return response.data;
	});
}

export default useMutateReinviteClient;
