import { Chip, Grid } from '@mui/material';
import { useTheme } from '@emotion/react';

// Our Components
import ActionsDropdown from 'components/Tasks/OnboardingActionsDropdown';
import ColumnItem from 'components/DataTable/ColumnItem';
import PaginationDataTable from 'components/DataTable/PaginationDataTable';
import TasksLoadingCheckbox from 'components/Tasks/OnboardingTasksLoadingCheckbox';

// Our Utils
import { dataIsValid } from 'shared/utils';
import formatDate from 'shared/utils/date/formatDate';

// Our constants
import { DEFAULT_TASK_ACTION_OPTIONS, INVITED } from 'shared/constants';

// IMPORT STATES
// 'ONBOARDED','PENDING','INVITED','ERROR','DISABLED'

// Local Constants
const TASK_STATUS_COLOR_MAPPING = {
	PENDING: {
		color: 'black',
		bgcolor: 'lightBlue',
		text: 'Not Started'
	},
	INVITED: {
		color: 'white',
		bgcolor: 'orange',
		text: 'Invited'
	},
	ONBOARDED: {
		color: 'black',
		bgcolor: 'pink',
		text: 'Onboarded'
	}
};

function OnboardingTasksTable({
	disableOverlay,
	isLoading,
	page,
	setPage,
	pageSize,
	contactList,
	handleSelected,
	withOnboardNowOverlay,
	totalCount,
	noLoanTasks
}) {
	const SoraTheme = useTheme();
	const { primary } = SoraTheme.palette;

	const mutatedRowData = contactList.map(
		({
			id,
			address,
			assets,
			createdDate,
			dateOfBirth,
			extClientId,
			extClientEmailId,
			extClientFirstName,
			extClientLastName,
			extClientPhone,
			grossAnnualIncome,
			inviteId,
			importStatus,
			taskDone
		}) => {
			const isNameInvalid =
				!dataIsValid(extClientFirstName) ||
				!dataIsValid(extClientLastName);

			const fullName = isNameInvalid
				? 'Unknown'
				: `${extClientFirstName} ${extClientLastName}`;

			return {
				id,
				address,
				assets,
				clientFirstName: extClientFirstName,
				clientLastName: extClientLastName,
				dateCreated: createdDate,
				dateOfBirth,
				externalClientId: extClientId,
				email: extClientEmailId,
				isDone: taskDone,
				grossAnnualIncome,
				inviteId,
				name: fullName,
				phoneNumber: extClientPhone,
				status: importStatus
			};
		}
	);

	// data Keys
	const name = 'name';
	const dateCreated = 'dateCreated';
	const action = 'action';
	const status = 'status';
	const done = 'done';

	const tableHeadings = [
		ColumnItem(name, 'Name', 2),
		ColumnItem(dateCreated, 'Date Created', 2, {
			valueFormatter: (params) => {
				const { value } = params;
				const formattedDate = formatDate(value);
				return formattedDate;
			}
		}),
		ColumnItem(action, 'Action', 2, {
			sortable: false,
			renderCell: (params) => {
				const { row } = params;

				const { status: currentClientStatus, isDone } = row;

				const [COMPLETE_ONBOARD] = DEFAULT_TASK_ACTION_OPTIONS;

				const alreadyInvitedActions =
					currentClientStatus === INVITED
						? [COMPLETE_ONBOARD, 'Reinvite client']
						: DEFAULT_TASK_ACTION_OPTIONS;

				const disableActions =
					isDone || currentClientStatus === 'ONBOARDED';

				return (
					<ActionsDropdown
						page={page}
						actionOptions={alreadyInvitedActions}
						disabled={disableActions}
						sx={{ marginTop: 1, marginBottom: 1 }}
						handleSelected={handleSelected}
						clientData={row}
					/>
				);
			}
		}),
		ColumnItem(status, 'Status', 2, {
			renderCell: (params) => {
				const { value } = params;

				const statusConfig =
					TASK_STATUS_COLOR_MAPPING[value] ??
					TASK_STATUS_COLOR_MAPPING.PENDING;

				const { bgcolor, color, text } = statusConfig;

				const themeTextColor = primary[color] ?? 'black';
				const themeBackgroundColor = primary[bgcolor] ?? 'red';
				return (
					<Chip
						label={text}
						sx={{
							color: themeTextColor,
							bgcolor: themeBackgroundColor,
							paddingLeft: 1,
							paddingRight: 1,
							width: '100%',
							maxWidth: 120
						}}
					/>
				);
			}
		}),
		ColumnItem(done, 'Done', 1, {
			sortable: false,
			renderCell: (params) => {
				const { row } = params;
				return <TasksLoadingCheckbox rowData={row} />;
			}
		})
	];

	return (
		<Grid container item xs={12}>
			<PaginationDataTable
				isLoading={isLoading}
				disableOverlay={disableOverlay}
				pageSize={pageSize}
				page={page}
				setCurrentPage={setPage}
				columns={tableHeadings}
				rows={mutatedRowData}
				rowCount={totalCount}
				rowsPerPageOptions={pageSize}
				disableSelectionOnClick
				rowHeight={60}
				withOnboardNowOverlay={withOnboardNowOverlay ?? false}
				noLoanTasks={noLoanTasks ?? false}
			/>
		</Grid>
	);
}

export default OnboardingTasksTable;
