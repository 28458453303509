import { useMutation, useQueryClient } from 'react-query';

// Our Axios Instance
import axiosInstance from 'services/API/API';

// Our Endpoints
import { userProfile } from 'shared/api-urls';

// Additional useMutation
import useMutateUpdateOnboardingStatus from 'hooks/clientOnboarding/mutations/useMutateUpdateOnboardingStatus';

// our query keys
import {
	USER_DATA,
	ERROR_MESSAGE_DATA,
	USERPROFILEDATA
} from 'shared/query-keys';

// Our Constants
import { SIGNUP_COMPLETED } from 'shared/constants';

// Our Utils
import capitalizeUserProfile from 'shared/utils/formatting/capitalizeUserProfile';

function useMutateAddProfileData() {
	const queryClient = useQueryClient();
	const updateOnboardingStatus = useMutateUpdateOnboardingStatus();

	return useMutation(
		async (userData) => {
			// capitalized User Data
			const formattedUserData = capitalizeUserProfile(userData);

			const response = await axiosInstance.post(
				userProfile,
				formattedUserData
			);

			const userProfileData = response.data.data;

			// Updates Onboarding Status
			await updateOnboardingStatus.mutate(SIGNUP_COMPLETED);

			return userProfileData;
		},
		{
			onSuccess: (data) => {
				queryClient.setQueryData(USER_DATA, data);
				queryClient.invalidateQueries(USERPROFILEDATA);
			},

			onError: (error) => {
				queryClient.setQueryData(ERROR_MESSAGE_DATA, error);
			}
		}
	);
}

export default useMutateAddProfileData;
