/**
 *
 * @param {queryClient} queryClient
 * @param {queryKey} queryKey
 * @param {Number} pageCallBackNumber
 * @param {Array[Contacts]} contactList
 * @param {Number} externalContactId
 * @param {Array[Contacts]} wealthBoxClients
 * @param {INVITED | ONBOARDED} importStatus
 */
const optimisticStatusUpdate = (
	queryClient,
	queryKey,
	pageCallBackNumber,
	contactList,
	externalContactId,
	wealthBoxClients,
	importStatus // INVITED | ONBOARDED
) => {
	const updatedContactList = contactList.map((currentWealthBoxClient) => {
		const isClientWeAreUpdating =
			currentWealthBoxClient?.id === externalContactId;
		if (!isClientWeAreUpdating) return currentWealthBoxClient;

		return {
			...currentWealthBoxClient,
			importStatus
		};
	});

	queryClient.setQueryData([queryKey, pageCallBackNumber], {
		...wealthBoxClients,
		contactList: updatedContactList
	});
};

export default optimisticStatusUpdate;
