import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function useScrollToTop() {
	const { pathname } = useLocation();

	useEffect(() => {
		document.documentElement.scrollTo({
			top: 0,
			left: 0,
			// Can be Smooth, Instant or auto
			// https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollTo
			behavior: 'instant'
		});
	}, [pathname]);

	return null;
}
export default useScrollToTop;
