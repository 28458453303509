import { Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

// Our Routes
import { DASHBOARD_ROUTE } from 'routes';

function TopNavMenuAdvisor({
	profilePicUrl,
	firstName,
	lastName,
	companyName,
	sx
}) {
	const navigate = useNavigate();

	return (
		<Box
			sx={{
				marginTop: 4,
				marginBottom: 4
			}}
		>
			<Box
				component="img"
				src={profilePicUrl}
				sx={{
					maxHeight: 250,
					maxWidth: '56px',
					borderRadius: 3.14 * 2 ** 4,
					marginBottom: 2,
					'&:hover': {
						cursor: 'pointer'
					}
				}}
				onClick={() => navigate(DASHBOARD_ROUTE)}
			/>

			<Typography variant="subtitle2" sx={{ ...sx }}>
				{firstName} {lastName}
			</Typography>

			<Typography variant="caption" sx={{ marginTop: 2, ...sx }}>
				{companyName}
			</Typography>
		</Box>
	);
}

export default TopNavMenuAdvisor;
