import { useMutation } from 'react-query';

// Hubspot Axios Instance
import HubSpotAPI from 'services/API/HubSpotAPI';

function useMutateCreateDeal() {
	return useMutation(async (newDealData) => {
		const response = await HubSpotAPI.post('create/deal', newDealData);

		return response.data;
	});
}

export default useMutateCreateDeal;
