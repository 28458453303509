import { useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';

// Our Components
import RateInput from 'components/Input/RateInput';
import YearInput from 'components/Input/YearInput';
import ResponsiveTable from 'components/Table/ResponsiveTable';

// Local Constants
const DEFAULT_TABLE_CELL_HEIGHT = 40;

// Table Columns
const TABLE_COLUMNS = ['Type', 'Interest Rate', 'Loan Term'];

function LiquidityTable({ rowsState, setRowsState }) {
	const rows = useMemo(
		() =>
			rowsState.map((rowState, index) => {
				const isSellAssets = rowState.type === 'Sell Assets';
				const rowsWithState = [
					{
						props: {
							sx: {
								width: 250,
								backgroundColor: '#A8C5DA',
								position: 'sticky',
								left: 0,
								zIndex: 2,
								border: '0.25px #1C1C1C solid'
							}
						},
						value: rowState.type
					},
					{
						props: {
							sx: {
								position: 'sticky',
								left: 103,
								zIndex: 2,
								border: '0.25px #1C1C1C solid'
							}
						},
						value: (
							<RateInput
								label=""
								subLabel=""
								sx={{
									height: DEFAULT_TABLE_CELL_HEIGHT
								}}
								inputSx={{ height: DEFAULT_TABLE_CELL_HEIGHT }}
								rate={rowState.interestRate}
								setRate={(value) => {
									setRowsState((prevState) => {
										const newRowsState = [...prevState];
										newRowsState[index] = {
											...newRowsState[index],
											interestRate: value
										};
										return newRowsState;
									});
								}}
							/>
						)
					},
					{
						props: {},
						value: (
							// loanTerm
							<YearInput
								sx={{
									height: DEFAULT_TABLE_CELL_HEIGHT
								}}
								disabled={isSellAssets}
								inputSx={{ height: DEFAULT_TABLE_CELL_HEIGHT }}
								value={rowState.loanTerm}
								onChange={(value) => {
									setRowsState((prevState) => {
										const newRowsState = [...prevState];
										newRowsState[index] = {
											...newRowsState[index],
											loanTerm: value
										};
										return newRowsState;
									});
								}}
							/>
						),
						key: uuidv4()
					}
				];

				// If the row has an asset category, add it to the beginning of the row
				return rowsWithState;
			}),
		[rowsState, setRowsState]
	);

	const key = useMemo(() => uuidv4(), [rows.length]);

	return (
		<ResponsiveTable
			key={key}
			columnCellSx={{
				border: '0.25px #1C1C1C solid',
				background: 'rgba(38, 131, 194, 0.10)',
				width: 250
			}}
			cellSx={{
				border: '0.25px #1C1C1C solid'
			}}
			ContainerComponent={null}
			columns={TABLE_COLUMNS}
			rows={rows}
			makeLastBold={false}
		/>
	);
}

export default LiquidityTable;
