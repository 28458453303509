import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import {
	Badge,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Typography
} from '@mui/material';

function NavItem({
	variant,
	onClick,
	isMessages,
	isSuccess,
	unreadMessageCount,
	icon,
	title,
	route,
	color
}) {
	const navigate = useNavigate();
	const isNotDesktop = variant !== 'desktop';

	if (isNotDesktop) {
		return (
			<ListItem
				disablePadding
				onClick={() => {
					if (onClick) {
						onClick();
					} else {
						navigate(route);
					}
				}}
			>
				<ListItemButton sx={{ textAlign: 'center' }}>
					<ListItemText>
						<Typography
							variant="h2Gascogne"
							component="span"
							sx={{
								color
							}}
						>
							{title}
						</Typography>
					</ListItemText>
				</ListItemButton>
			</ListItem>
		);
	}

	return (
		<ListItem
			disablePadding
			onClick={() => {
				if (onClick) {
					onClick();
				} else {
					navigate(route);
				}
			}}
		>
			<ListItemButton>
				<ListItemIcon sx={{ paddingLeft: 2, color }}>
					{isMessages && (
						<Badge
							badgeContent={isSuccess ? unreadMessageCount : 0}
							color="error"
							anchorOrigin={{
								vertical: 'top',
								horizontal: 'right'
							}}
						>
							{icon}
						</Badge>
					)}

					{!isMessages && icon}
				</ListItemIcon>
				<ListItemText>
					<Typography
						variant="body2"
						sx={{
							color,
							fontWeight: 600
						}}
					>
						{title}
					</Typography>
				</ListItemText>
			</ListItemButton>
		</ListItem>
	);
}

NavItem.propTypes = {
	variant: PropTypes.oneOf(['desktop', 'mobile']),
	color: PropTypes.string,
	isMessages: PropTypes.bool
};

NavItem.defaultProps = {
	variant: 'desktop',
	color: '#ffffff',
	isMessages: false
};

export default NavItem;
