import { useQuery, useQueryClient } from 'react-query';
import { useParams, useNavigate } from 'react-router-dom';

// Our Axios
import axiosInstance from 'services/API/API';

// Our Endpoint
import { walletLiabilityURL } from 'shared/api-urls';

// Our Query Keys
import { LIABILITY, FA_CURRENT_CLIENT_ID } from 'shared/query-keys';

// Constants
import { DEFAULT_STALE_TIME_FIVE_MINUTES } from 'shared/constants';

function useGetSoraWalletLiability(isClient) {
	const navigate = useNavigate();
	const { tradelineId, clientId } = useParams();

	const isAdvisor = !isClient;
	const queryClient = useQueryClient();

	const URL = `${walletLiabilityURL}${tradelineId}`;

	const dynamicQueryKey = isClient
		? [LIABILITY, tradelineId]
		: [LIABILITY, tradelineId, clientId];

	if (isAdvisor) {
		// This is setting the clientId for the offers pages.
		queryClient.setQueryData(FA_CURRENT_CLIENT_ID, clientId);
	}

	return useQuery(
		dynamicQueryKey,
		async () => {
			const response = await axiosInstance.get(URL);
			const walletLiabilityData = response.data.data;

			return walletLiabilityData;
		},
		{
			onError: () => {
				if (isAdvisor) {
					navigate('/dashboard');
				}
			},
			staleTime: DEFAULT_STALE_TIME_FIVE_MINUTES
		}
	);
}

export default useGetSoraWalletLiability;
