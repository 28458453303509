import { useQuery, useQueryClient } from 'react-query';
import axiosInstance from 'services/API/API';
import { newOfferUrl } from 'shared/api-urls';
import { NEW_LOAN_OFFERS, FA_CURRENT_CLIENT_ID } from 'shared/query-keys';

function useGetNewLoanOffers() {
	const queryClient = useQueryClient();

	const clientId = queryClient.getQueryData(FA_CURRENT_CLIENT_ID);

	const requestBody = clientId ? { clientId } : {};

	return useQuery(
		NEW_LOAN_OFFERS,
		async () => {
			const res = await axiosInstance.post(newOfferUrl, requestBody);

			return res.data.data;
		},
		{
			refetchOnWindowFocus: false
		}
	);
}

export default useGetNewLoanOffers;
