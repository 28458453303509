import PropTypes from 'prop-types';
import { Skeleton } from '@mui/material';
import CommonPropTypes from 'shared/prop-types';

function LoadingSkeleton({ sx, variant }) {
	return (
		<Skeleton variant={variant} sx={{ width: 300, height: 300, ...sx }} />
	);
}

LoadingSkeleton.propTypes = {
	variant: PropTypes.oneOf(['circular', 'rectangular', 'rounded', 'text']),
	sx: CommonPropTypes.sx
};

LoadingSkeleton.defaultProps = {
	sx: {},
	variant: 'rectangular'
};

export default LoadingSkeleton;
