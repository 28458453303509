import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import {
	Typography,
	List,
	ListItem,
	ListItemText,
	ListItemButton,
	ListItemIcon
} from '@mui/material';
import { v4 as uuidv4 } from 'uuid';

// Our Constants
import CLIENT_NAV_OPTIONS from 'shared/constants/navigation/clientNavOptions';

function NavMenuClient() {
	const SoraTheme = useTheme();
	const { primary } = SoraTheme.palette;
	const navigate = useNavigate();

	return (
		<List>
			{CLIENT_NAV_OPTIONS.map(({ route, icon, title }) => (
				<ListItem
					key={uuidv4()}
					disablePadding
					onClick={() => navigate(route)}
				>
					<ListItemButton>
						<ListItemIcon
							sx={{ paddingLeft: 2, color: primary.white }}
						>
							{icon}
						</ListItemIcon>
						<ListItemText>
							<Typography
								variant="body2"
								sx={{
									color: primary.white
								}}
							>
								{title}
							</Typography>
						</ListItemText>
					</ListItemButton>
				</ListItem>
			))}
		</List>
	);
}

export default NavMenuClient;
