import PropTypes from 'prop-types';
import { useCallback } from 'react';

// Our Prop Types
import CommonPropTypes from 'shared/prop-types';
import { TEXT } from 'components/Input/Types';

// Our Components
import TextInput from 'components/Input/TextInput';

// Our utils
import { isValidSSN } from 'shared/utils';

function formatSSN(inputString) {
	if (inputString === '') return '';
	const cleanString = inputString.replaceAll('-', '');

	if (cleanString.length > 5) {
		const firstThreeDigits = cleanString.slice(0, 3);
		const fourthAndFifthDigits = cleanString.slice(3, 5);
		const restOfDigits = cleanString.slice(5);

		return `${firstThreeDigits}-${fourthAndFifthDigits}-${restOfDigits}`;
	}

	if (cleanString.length > 3) {
		// get the first 3 digits
		const firstThreeDigits = cleanString.slice(0, 3);
		const restOfDigits = cleanString.slice(3);
		return `${firstThreeDigits}-${restOfDigits}`;
	}

	return inputString;
}
function SSNTextInput({ ssnValue, subLabel, label, tabIndex, onChange, sx }) {
	const onChangeHandler = useCallback(
		(incomingSSN) => {
			const isIncomingSSNEmpty = incomingSSN === '';
			if (isIncomingSSNEmpty) {
				onChange('');
				return;
			}

			const isMaxLength = incomingSSN.length > 11;

			if (isMaxLength) return;

			const checkLastValue = incomingSSN[incomingSSN.length - 1] === '-';

			if (checkLastValue) {
				// this is needed to autodelete ssn hypens that look like 123-
				const ssnWithoutLastHyphen = incomingSSN.slice(
					0,
					incomingSSN.length - 1
				);
				onChange(ssnWithoutLastHyphen);
				return;
			}

			const formattedSSN = formatSSN(incomingSSN); // format the string and go ham

			onChange(formattedSSN);
		},
		[ssnValue]
	);

	return (
		<TextInput
			sx={{ sx }}
			label={label}
			type={TEXT}
			onChange={onChangeHandler}
			subLabel={subLabel}
			helperText="Please enter a valid ssn"
			error={!isValidSSN(ssnValue)}
			value={ssnValue}
			inputProps={{
				tabIndex,
				'data-test': 'ssn'
			}}
			withVisibleToggle
			defaultVisibility={false}
		/>
	);
}

SSNTextInput.propTypes = {
	sx: CommonPropTypes.sx,
	tabIndex: PropTypes.string,
	onChange: PropTypes.func.isRequired,
	ssnValue: PropTypes.string.isRequired,
	subLabel: PropTypes.string,
	label: PropTypes.string
};

SSNTextInput.defaultProps = {
	sx: {},
	label: 'Social Security Number',
	tabIndex: '',
	subLabel: 'Optional'
};

export default SSNTextInput;
