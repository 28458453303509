import { useQuery, useQueryClient } from 'react-query';
import { useState, useEffect } from 'react';

// Our stuff
import axiosInstance from 'services/API/API';

// API URL
import { fileUploadUrl, loanDocumentUploadUrl } from 'shared/api-urls';

// Query Keys
import { FILE_UPLOAD, LOAN_REQUEST_RELATED_DOCUMENTS } from 'shared/query-keys';

function getBase64(file) {
	// file is a File Object. - https://developer.mozilla.org/en-US/docs/Web/API/File
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => resolve(reader.result);
		reader.onerror = (error) => reject(error);
	});
}

function getFileSizeAfterEncoding(cleanBase64EncodedFile) {
	return new Blob([cleanBase64EncodedFile]).size; // Blob.size returns an int that represents the byte size of a string in UTF-8 format
}

const uploadAndProcess = async (
	fileData,
	masterDocumentId,
	newLoanId,
	clientId
) => {
	const urlString = window.location.href;
	const onLoanInquiryPage = urlString.includes('loan-request-details');

	const encodedFile = await getBase64(fileData); // result of this is  mimietype base64,DATA
	const commaIndex = encodedFile.indexOf(','); // we only want DATA so we grab the index of comma and only capture data
	const cleanBase64EncodedFile = encodedFile.slice(commaIndex + 1);

	if (onLoanInquiryPage) {
		// mapping to API
		const fileName = fileData.name;
		const fileSize = `${getFileSizeAfterEncoding(cleanBase64EncodedFile)}`;

		const res = await axiosInstance.patch(loanDocumentUploadUrl, {
			description: '',
			fileBase64: cleanBase64EncodedFile,
			fileCategory: 'USER_SUPPLIED_DOC',
			fileName,
			newLoanId,
			fileSize,
			masterDocumentId,
			clientId
		});

		// api response after a file was successfully uploaded.
		return res.data.data;
	}

	// mapping to API
	const fileName = fileData.name;
	const fileSize = `${getFileSizeAfterEncoding(cleanBase64EncodedFile)}`;

	// https://sorafinance.atlassian.net/browse/SWB-194 will have info about file req structure
	const res = await axiosInstance.post(fileUploadUrl, {
		description: '',
		fileBase64: cleanBase64EncodedFile,
		fileName,
		fileSize
	});

	// api response after a file was successfully uploaded.
	return res.data.data;
};

// for now this query assumes it will upload files for the currently signed in user.
// i.e. an FA for now will not upload files on behalf of a client.
function useQueryUploadFile(
	isEnabled,
	fileItemId,
	fileData,
	masterDocumentId,
	newLoanId,
	clientId
) {
	const queryClient = useQueryClient();
	const [uploadIsSuccess, setUploadIsSuccess] = useState(false);

	useEffect(
		// When this hook ceases to exist it will cleanup any keys it created
		() => () => queryClient.removeQueries([FILE_UPLOAD, fileItemId]),
		[]
	);

	return useQuery(
		[FILE_UPLOAD, fileItemId],
		() => uploadAndProcess(fileData, masterDocumentId, newLoanId, clientId),
		{
			enabled: isEnabled && !uploadIsSuccess,
			refetchOnWindowFocus: false,
			onSuccess: (loanRequestId) => {
				setUploadIsSuccess(true);
				// marking this file so that it no longer gets uploaded
				queryClient.invalidateQueries(
					LOAN_REQUEST_RELATED_DOCUMENTS,
					loanRequestId
				);
			}
		}
	);
}

export default useQueryUploadFile;
