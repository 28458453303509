import { Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

// Our components
import { TertiaryButton } from 'components/Button/Button';

// Our Routes
import { INVITE_ROUTE } from 'routes';

function OnboardingTasksNoRowOverlay() {
	const navigate = useNavigate();
	return (
		<Box
			sx={{
				position: 'relative',
				zIndex: 1,
				height: '100%',
				width: '100%',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center'
			}}
		>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					gap: 4
				}}
			>
				<Typography variant="body1">
					You have no pending client onboarding tasks. Click below
					<br /> to integrate with a third-party tool to onboard
					clients.
				</Typography>

				<TertiaryButton
					onClick={() =>
						navigate(INVITE_ROUTE, { state: { startingIndex: 4 } })
					}
				>
					Onboard now
				</TertiaryButton>
			</Box>
		</Box>
	);
}

export default OnboardingTasksNoRowOverlay;
