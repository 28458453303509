import { Box, Typography } from '@mui/material';

import BarChartItem from 'components/SoraWallet/BarChartItem';

import { dollarFormatter } from 'shared/utils';

const getFormattedValue = (value, variant) => {
	switch (variant) {
		case 'dollar':
			return dollarFormatter.format(value);
		case 'month':
			return `${dollarFormatter.format(value)} / month`;
		case 'year':
			return `${value} yrs`;
		default:
			return variant;
	}
};
// Currently this chart item supports only 2
function ChartItem({ label, currentLoanValue, newLoanValue, variant, sx }) {
	const currentLoanPercent = currentLoanValue > newLoanValue ? '100%' : '60%';
	const newLoanPercent = newLoanValue > currentLoanValue ? '100%' : '60%';
	return (
		<Box
			textAlign="center"
			sx={{
				width: '100%',
				margin: '10px 15px 0 15px',
				boxSize: 'border-box',
				...sx
			}}
		>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'flex-end',
					height: 300,
					width: '100%'
				}}
			>
				{/* First Surrounding Bar  */}
				<Box
					sx={{
						height: '100%',
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'flex-end',
						margin: '0 15px'
					}}
				>
					<Typography
						variant="caption"
						sx={{
							width: 70,
							margin: '0 15px'
						}}
					>
						{getFormattedValue(currentLoanValue, variant)}
					</Typography>
					{/* Bar itself */}
					<BarChartItem
						background="rgba(200, 233, 241, 0.87)"
						text="Current loan"
						textColor="black"
						percent={currentLoanPercent}
						style={{ margin: '0 auto;' }}
					/>
				</Box>
				<Box
					sx={{
						height: '100%',
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'flex-end'
					}}
				>
					<Typography
						variant="caption"
						sx={{
							width: 70,
							margin: '0 15px'
						}}
					>
						{getFormattedValue(newLoanValue, variant)}
					</Typography>
					<BarChartItem
						background="linear-gradient(180deg, #2685C1 0%, rgba(38, 133, 193, 0) 139.01%)"
						text="New Loan"
						textColor="white"
						percent={newLoanPercent}
					/>
				</Box>
			</Box>
			<Typography variant="body1" marginTop={1}>
				{label}
			</Typography>
		</Box>
	);
}

export default ChartItem;
