/**
 *
 * @param {Array} conversations
 * @returns {Number}
 */
function countUnreadMessages(conversations) {
	if (!Array.isArray(conversations) || conversations?.length < 1) return 0;

	const collectionOfUnreadMessageCount = conversations.map(
		({ unreadMessageCount }) => unreadMessageCount
	);

	const unreadMessageCount = collectionOfUnreadMessageCount.reduce(
		(prevValue, currentValue) => prevValue + currentValue,
		0
	);

	return unreadMessageCount;
}

export default countUnreadMessages;
