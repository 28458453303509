import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import PropTypes from 'prop-types';

function ProgressMeter({ containerStyles, value, ...otherProps }) {
	return (
		<Box sx={{ width: '100%', ...containerStyles }}>
			<LinearProgress
				variant="determinate"
				sx={{ borderRadius: '10px' }}
				value={value}
				{...otherProps}
			/>
		</Box>
	);
}

ProgressMeter.defaultProps = {
	containerStyles: {}
};
ProgressMeter.propTypes = {
	containerStyles: PropTypes.oneOfType([
		PropTypes.arrayOf(
			PropTypes.oneOfType([
				PropTypes.func,
				PropTypes.object,
				PropTypes.bool
			])
		),
		PropTypes.func,
		PropTypes.object
	]),
	value: PropTypes.number.isRequired
};

export default ProgressMeter;
