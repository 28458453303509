import { BarChart, Bar, CartesianGrid, XAxis, YAxis } from 'recharts';

// Our Utils
import { dollarFormatter } from 'shared/utils';

function ExportableReportBurndownChart({ data }) {
	const dynamicCharts = data.map((currentData, index) => ({
		calculation: currentData,
		name: `Year-${index + 1}`
	}));

	return (
		<BarChart width={400} height={260} data={dynamicCharts}>
			<defs>
				<linearGradient id="customGradient" x1="0" y1="0" x2="0" y2="1">
					<stop offset="0%" stopColor="#206EA7" stopOpacity={1} />
					<stop offset="100%" stopColor="#CCE9FF" stopOpacity={1} />
				</linearGradient>
			</defs>
			<CartesianGrid />
			<XAxis
				dataKey="name"
				tick={{ fill: 'black', fontSize: '0.75rem' }}
				interval={0}
			/>
			<YAxis
				tickFormatter={(value) => {
					const formattedY = dollarFormatter.format(value);
					return formattedY;
				}}
				tick={{ fill: 'black', fontSize: '0.75rem' }}
				width={110}
			/>
			<Bar dataKey="calculation" fill="url(#customGradient)" />
		</BarChart>
	);
}

export default ExportableReportBurndownChart;
