import { Checkbox } from '@mui/material';

// Our Components
import Loader from 'components/Loader/index';

// Our Hooks
import useMutateMarkTaskAsDone from 'hooks/tasks/useMutateMarkTaskAsDone';

function TasksLoadingCheckbox({ rowData }) {
	const markTaskAsDone = useMutateMarkTaskAsDone();

	const { isLoading } = markTaskAsDone;

	const { isDone, id: externalContactId } = rowData;

	const onClick = (e) => {
		e.stopPropagation();
		markTaskAsDone.mutate([{ externalContactId, taskDone: !isDone }]);
	};

	if (isLoading) return <Loader />;

	return (
		<Checkbox
			defaultChecked={isDone}
			onChange={onClick}
			sx={{
				padding: 0,
				marginTop: 1,
				marginBottom: 1,
				color: 'black',
				'&.Mui-checked': {
					color: 'black'
				}
			}}
		/>
	);
}

export default TasksLoadingCheckbox;
