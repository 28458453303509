import { useMutation, useQueryClient } from 'react-query';

// Our Axios Instance
import axiosInstance from 'services/API/API';

// Our endpoint
import { deleteDocumentUrl } from 'shared/api-urls';

// Our Query keys
import {
	ERROR_MESSAGE_DATA,
	SUCCESS_MESSAGE_DATA,
	LOAN_REQUEST_RELATED_DOCUMENTS,
	LOAN_REQUEST
} from 'shared/query-keys';

function useMutateDeleteLoanDocument() {
	const queryClient = useQueryClient();

	return useMutation(
		async (documentId) => {
			const payload = [
				{
					documentId,
					deleted: 'true'
				}
			];

			const response = await axiosInstance.delete(deleteDocumentUrl, {
				data: payload
			});

			return response.data.data;
		},
		{
			onSuccess: (loanRequestId) => {
				queryClient.invalidateQueries(
					LOAN_REQUEST_RELATED_DOCUMENTS,
					loanRequestId
				);

				queryClient.invalidateQueries(LOAN_REQUEST, loanRequestId);

				queryClient.setQueryData(
					SUCCESS_MESSAGE_DATA,
					'Successfully deleted document.'
				);
			},
			onError: () => {
				queryClient.setQueryData(
					ERROR_MESSAGE_DATA,
					'Something went wrong.'
				);
			}
		}
	);
}

export default useMutateDeleteLoanDocument;
