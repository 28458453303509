import { useState, useEffect } from 'react';

// Our components
import SearchInput from 'components/Input/SearchInput';

// Our Hooks
import useDebounce from 'hooks/useDebounce';

function SearchClients({ debounceCallBack, sx }) {
	const [searchData, setSearchData] = useState('');
	const debouncedSearchResult = useDebounce(searchData, 100);

	useEffect(() => {
		debounceCallBack(debouncedSearchResult);
	}, [debouncedSearchResult]);

	return (
		<SearchInput
			autoComplete="off"
			value={searchData}
			onChange={(e) => setSearchData(e.target.value)}
			placeholder="Client Name"
		/>
	);
}

export default SearchClients;
