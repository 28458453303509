import { Grid, Typography } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { useState } from 'react';

// our Component
import FADashboardHeading from 'components/FADashboard/heading/FADashboardHeading';
import HighlightCarousel from 'components/FADashboard/HighlightCarousel/HighlightCarousel';
import LiabilitiesTable from 'components/FADashboard/Liabilities/LiabilitiesTable';
import TableHeadingAndSearch from 'components/FADashboard/TableHeadingAndSearch';

// our Hooks
import useGetFaSummary from 'hooks/useGetFaSummary';

// utils
import { extractSoraScore } from 'shared/utils';

function FADashboardPage() {
	const [filterLiabilities, setFilterLiabilities] = useState('');
	const { data, isLoading, isSuccess, error } =
		useGetFaSummary(filterLiabilities);

	if (error) {
		<>
			<Helmet>
				<title>Financial advisor dashboard</title>
			</Helmet>
			<Typography variant="h5" sx={{ marginTop: 20 }}>
				There was an error fetching your information.
			</Typography>
			<Typography variant="h5" sx={{ marginTop: 2 }}>
				Please refresh the page, if issue persist please reach out to
				sora at contact@sorafinance.com
			</Typography>
		</>;
	}

	if (isLoading) {
		return (
			<Grid container sx={{ alignItems: 'flex-start' }}>
				<FADashboardHeading isLoading={isLoading} />
			</Grid>
		);
	}

	if (isSuccess) {
		const {
			avgSoraScore,
			soraScore,
			summaryLiability,
			pendingOnboardClient,
			untappedSavings,
			upsidePotential,
			totalLoans
		} = data.data; // TODO tidy this up to match other areas

		// Sorting the liabilities by highest amount of untapped savings
		const sortLiabilitiesByUntappedSavingsAmount = summaryLiability
			.sort((a, b) => a.untappedSavings - b.untappedSavings)
			.reverse();

		const hasNoClients = summaryLiability?.length === 0;

		const formattedAvgSoraScore =
			avgSoraScore && extractSoraScore(avgSoraScore);

		const avgSoraScoreToRender =
			soraScore === 0 ? 'NA' : formattedAvgSoraScore;

		return (
			<>
				<FADashboardHeading />
				<Grid item xs={12}>
					<HighlightCarousel
						averageSoraScore={avgSoraScoreToRender}
						pendingClients={pendingOnboardClient}
						untappedSavings={untappedSavings}
						upsidePotential={upsidePotential}
						totalLoansUnderManagement={totalLoans}
					/>
				</Grid>
				{/* This component now has a Grid container */}
				<TableHeadingAndSearch
					disable={hasNoClients}
					deBounceCallBack={setFilterLiabilities}
					tableHeading="Clients"
				/>
				<Grid container item xs={12} sx={{ paddingRight: 4 }}>
					<LiabilitiesTable
						liabilities={sortLiabilitiesByUntappedSavingsAmount}
					/>
				</Grid>
			</>
		);
	}
}

export default FADashboardPage;
