const sortLiabilitiesByKey = (liabilities, key, descending = false) =>
	liabilities.sort((liabilityA, liabilityB) => {
		const liabilityAValue = liabilityA[key];
		const liabilityBValue = liabilityB[key];

		if (descending) {
			if (liabilityAValue > liabilityBValue) return -1;
			if (liabilityAValue < liabilityBValue) return 1;
		} else {
			if (liabilityAValue < liabilityBValue) return -1;
			if (liabilityAValue > liabilityBValue) return 1;
		}

		return 0;
	});

export default sortLiabilitiesByKey;
