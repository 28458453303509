import { useMutation, useQueryClient } from 'react-query';
import { useLocation, useParams } from 'react-router-dom';

// our axios
import axiosInstance from 'services/API/API';

// Our Endpoints
import { getUserLiabilitiesUrl as saveStudentLiabilityURL } from 'shared/api-urls';

// Our Routes
import {
	ADVISOR_BASE_NEW_LOAN_SELECTION_ROUTE,
	CLIENT_ADD_NEW_STUDENT_ROUTE,
	CLIENT_NEW_LOAN_SELECTION_ROUTE,
	LIABILITY_ADDITIONAL_ROUTE
} from 'routes';

// Our Query keys
import {
	CLIENT_OVERVIEW_DATA,
	LIABILITY,
	WALLET_OVERVIEW_DATA,
	USER_LIABILITIES
} from 'shared/query-keys';

function useMutateSaveStudentLiability() {
	const location = useLocation();
	const { pathname } = location;
	const { tradeLineId } = useParams();
	const queryClient = useQueryClient();

	// Booleans
	const isManualEntry = pathname.includes(LIABILITY_ADDITIONAL_ROUTE);
	const clientEnteredThroughAddNewLiabilityFlow = pathname.includes(
		CLIENT_NEW_LOAN_SELECTION_ROUTE
	);
	const advisorEnteredThroughAddNewLiabilityFlow = pathname.includes(
		ADVISOR_BASE_NEW_LOAN_SELECTION_ROUTE
	);
	const onClientStudentPage = pathname.includes(CLIENT_ADD_NEW_STUDENT_ROUTE);

	const urlForRequest =
		isManualEntry ||
		clientEnteredThroughAddNewLiabilityFlow ||
		advisorEnteredThroughAddNewLiabilityFlow ||
		onClientStudentPage
			? saveStudentLiabilityURL
			: `${saveStudentLiabilityURL}${tradeLineId}`;

	return useMutation(async (studentLiabilityData) => {
		if (
			isManualEntry ||
			clientEnteredThroughAddNewLiabilityFlow ||
			advisorEnteredThroughAddNewLiabilityFlow ||
			onClientStudentPage
		) {
			// overwriting data confidence here.
			const studentPayloadWithRateManuallyUpdated = {
				...studentLiabilityData,
				rateManuallyUpdated: true
			};

			const response = await axiosInstance.post(
				urlForRequest,
				studentPayloadWithRateManuallyUpdated
			);

			await queryClient.invalidateQueries(USER_LIABILITIES);
			await queryClient.invalidateQueries(WALLET_OVERVIEW_DATA, {
				refetchInactive: true
			});
			await queryClient.invalidateQueries(CLIENT_OVERVIEW_DATA, {
				refetchInactive: true
			});

			return response.data;
		}

		// is not a manual entry
		const response = await axiosInstance.patch(
			urlForRequest,
			studentLiabilityData
		);

		await queryClient.invalidateQueries(WALLET_OVERVIEW_DATA, {
			refetchInactive: true
		});

		await queryClient.invalidateQueries(LIABILITY, {
			refetchInactive: true
		});

		return response.data;
	});
}

export default useMutateSaveStudentLiability;
