import { useState, useMemo, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import { Box, Grid, Typography } from '@mui/material';
import { Helmet } from 'react-helmet-async';

// Our Query Keys
import { SUCCESS_MESSAGE_DATA } from 'shared/query-keys';

// Hooks
import useMutateSetIncome from 'hooks/Onboarding/Income/useMutateSetIncome';
import useGetIncome from 'hooks/Onboarding/Income/useGetIncome';
import useWasFromWallet from 'hooks/utilityHook/useWasFromWallet';

// Components
import Alert from 'components/Alert';
import CurrencyTextInput from 'components/Input/CurrencyTextInput';
import Loader from 'components/Loader/index';
import { PrimaryButton } from 'components/Button/Button';

// Our Routes
import {
	CLIENT_ONBOARDING_ASSETS_ROUTE,
	CLIENT_SORAWALLET_ROUTE
} from 'routes/index';

// Utils
import { dataIsValid, isSubmissionReady } from 'shared/utils';

function IncomeInfo() {
	const [totalAnnualIncome, setTotalAnnualIncome] = useState(0);
	const isFromWallet = useWasFromWallet();
	const { data, isSuccess, isLoading } = useGetIncome();
	const updateTotalOtherIncome = useMutateSetIncome();
	const { isLoading: setIncomeIsLoading } = updateTotalOtherIncome;
	const queryClient = useQueryClient();
	const navigate = useNavigate();

	const wasMethodFiSuccessful =
		queryClient.getQueryData(SUCCESS_MESSAGE_DATA) ===
		'Sora successfully gathered your liability information.';

	const requiredFormData = [totalAnnualIncome];

	const isFormReady = useMemo(
		() => isSubmissionReady(requiredFormData),

		[totalAnnualIncome]
	);

	const submitIncomeData = () => {
		const mutationPayload = { totalOtherAnnualIncome: totalAnnualIncome };

		if (data && dataIsValid(data) && isSuccess) {
			const { listIncome } = data;
			const firstSourceOfIncome = listIncome[0];
			const { id: incomeId } = firstSourceOfIncome;

			mutationPayload.otherIncomeId = incomeId;
		}

		updateTotalOtherIncome.mutate(mutationPayload, {
			onSuccess: () => {
				if (!isFromWallet) navigate(CLIENT_ONBOARDING_ASSETS_ROUTE);
				if (isFromWallet) navigate(CLIENT_SORAWALLET_ROUTE);
			}
		});
	};

	useEffect(() => {
		if (isSuccess) {
			if (data && dataIsValid(data)) {
				const { totalAnnualIncome: totalAnnualIncomeFromResponse } =
					data;
				if (totalAnnualIncomeFromResponse === '0.0') {
					setTotalAnnualIncome(0);
				} else {
					setTotalAnnualIncome(totalAnnualIncomeFromResponse);
				}
			}
		}
	}, [data]);

	return (
		<Box
			sx={{
				height: '100vh',
				width: '100%',
				overflow: 'auto',
				marginLeft: 2
			}}
		>
			<Helmet>
				<title>Income</title>
			</Helmet>

			<Typography
				variant="h1Gascogne"
				component="h1"
				sx={{ marginTop: 4 }}
			>
				Next, please provide your income information
			</Typography>
			<Typography variant="body1" marginTop={2}>
				Please provide your total household Income below
			</Typography>

			{isLoading && <Loader size={60} />}
			{!isLoading && (
				<Box
					component="form"
					noValidate
					autoComplete="off"
					sx={{ marginRight: 2 }}
				>
					{wasMethodFiSuccessful && <Alert variant="success" />}

					<Grid container columnSpacing={2} marginTop={4}>
						<Grid item xs={12} md={6}>
							<CurrencyTextInput
								label="Total Household annual income"
								value={totalAnnualIncome}
								onChange={setTotalAnnualIncome}
								inputProps={{
									'data-test': 'income'
								}}
							/>
						</Grid>
						<Grid item xs={12} sx={{ marginTop: 4 }}>
							{setIncomeIsLoading ? (
								<Loader />
							) : (
								<PrimaryButton
									data-test="next"
									isDisabled={!isFormReady}
									onClick={submitIncomeData}
								>
									Next
								</PrimaryButton>
							)}
						</Grid>
					</Grid>
				</Box>
			)}
		</Box>
	);
}

export default IncomeInfo;
