import { useTheme } from '@mui/material/styles';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

export function OnboardingLiabilityIcon({ progress }) {
	const SoraTheme = useTheme();
	if (progress >= 25) {
		return (
			<CheckCircleOutlineIcon
				sx={{
					color: SoraTheme.palette.primary.indigo
				}}
			/>
		);
	}
	return (
		<RadioButtonUncheckedIcon
			sx={{
				color: SoraTheme.palette.primary.indigo
			}}
		/>
	);
}

export function OnboardingIncomeIcon({ progress }) {
	const SoraTheme = useTheme();
	if (progress >= 50) {
		return (
			<CheckCircleOutlineIcon
				sx={{
					color: SoraTheme.palette.primary.indigo
				}}
			/>
		);
	}
	return (
		<RadioButtonUncheckedIcon
			sx={{
				color: SoraTheme.palette.primary.indigo
			}}
		/>
	);
}

export function OnboardingAssetsIcon({ progress }) {
	const SoraTheme = useTheme();
	if (progress >= 75) {
		return (
			<CheckCircleOutlineIcon
				sx={{
					color: SoraTheme.palette.primary.indigo
				}}
			/>
		);
	}
	return (
		<RadioButtonUncheckedIcon
			sx={{
				color: SoraTheme.palette.primary.indigo
			}}
		/>
	);
}
