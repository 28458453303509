import NewLoanIcon from '@mui/icons-material/AddCard';
import WalletIcon from '@mui/icons-material/Wallet';

// Our Routes
import { CLIENT_SORAWALLET_ROUTE, SELECT_NEW_LOAN_ROUTE } from 'routes';

const CLIENT_NAV_OPTIONS = [
	{
		icon: <WalletIcon />,
		title: 'Wallet',
		route: CLIENT_SORAWALLET_ROUTE
	},
	{
		icon: <NewLoanIcon />,
		title: 'New Loan',
		route: SELECT_NEW_LOAN_ROUTE
	}
];

export default CLIENT_NAV_OPTIONS;
