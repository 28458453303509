import { Box } from '@mui/material';

// Assets
import SoraLogo from 'assets/images/sora_logos/SoraLogoBlack.svg';
import BG from 'assets/images/sora_logos/BG.jpg';

// Pure component with no props
// renders the box that contains the sora logo and art assocaited with it.
function LoginArtAndSoraLogo() {
	return (
		<>
			{/* SideBar Art */}
			<Box
				component="img"
				src={BG}
				alt="Sign in"
				sx={{ objectFit: 'cover', height: '100%', width: '100%' }}
			/>
			{/* Sora Logo */}
			<Box
				component="img"
				src={SoraLogo}
				alt="Sora Logo"
				sx={{
					position: 'absolute',
					height: '6%',
					width: '7%',
					top: '20px',
					left: '30px'
				}}
			/>
		</>
	);
}

export default LoginArtAndSoraLogo;
