import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';

// Our Components
import SoraToolTip from 'components/Tooltip';

// Our Common Prop types
import CommonProps from 'shared/prop-types';

function HighlightCard({
	background,
	cardHeading,
	cardHeadingVariant,
	cardContentVariant,
	cardContentValue,
	headingColor,
	image,
	imageSX,
	onClick,
	tooltipText,
	withToolTip
}) {
	const isContentValueElement = typeof cardContentValue === 'object';
	const isHeadingValueElement = typeof cardHeading === 'object';

	return (
		<Box
			sx={{
				background,
				padding: 4,
				borderRadius: '20px',
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center',
				height: 170,
				marginRight: 2,
				cursor: onClick ? 'pointer' : 'default'
			}}
			onClick={onClick}
		>
			<Box sx={{ display: 'flex', marginBottom: 2 }}>
				{isHeadingValueElement && cardHeading}
				{!isHeadingValueElement && (
					<Typography
						variant={cardHeadingVariant}
						sx={{
							color: headingColor
						}}
					>
						{cardHeading}
					</Typography>
				)}

				{withToolTip && (
					<SoraToolTip
						text={tooltipText}
						placement="bottom"
						toolTipSx={{
							color: headingColor,
							fontSize: 18,
							position: 'absolute',
							top: 12,
							right: 30
						}}
					/>
				)}
			</Box>

			{cardContentValue && !isContentValueElement && (
				<Typography
					variant={cardContentVariant}
					gutterBottom
					sx={{ color: headingColor }}
				>
					{cardContentValue}
				</Typography>
			)}

			{isContentValueElement && cardContentValue}

			<Box
				component="img"
				src={image}
				alt="placeholder"
				sx={{ ...imageSX }}
			/>
		</Box>
	);
}

HighlightCard.propTypes = {
	background: PropTypes.string,
	cardHeading: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	cardHeadingVariant: PropTypes.string,
	cardContentVariant: PropTypes.string,
	headingColor: PropTypes.string,
	image: PropTypes.string,
	imageSX: CommonProps.sx,
	withToolTip: PropTypes.bool,
	onClick: PropTypes.func
};

HighlightCard.defaultProps = {
	background:
		'linear-gradient(180deg, #2685C1 0%, rgba(0, 156, 255, 0.17) 100%)',
	cardHeading: 'Card Heading',
	cardHeadingVariant: 'h6',
	cardContentVariant: 'h4',
	headingColor: '#295983',
	image: 'https://prod-public-asset.s3.amazonaws.com/engineering_assets/placeholders/HCPlaceholder.svg',
	imageSX: {
		height: 80,
		width: 80,
		position: 'absolute',
		right: 40,
		bottom: 20
	},
	withToolTip: true,
	onClick: null
};

export default HighlightCard;
