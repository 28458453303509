import { useQueryClient, useMutation } from 'react-query';

// Our Axios
import axiosInstance from 'services/API/API';

// Our Query Keys
import { ERROR_MESSAGE_DATA } from 'shared/query-keys';

// Our Endpoint
import { sendGenericEmailUrl } from 'shared/api-urls';

const useMutateSendGenericEmail = () => {
	const queryClient = useQueryClient();

	return useMutation(
		async (passedPayloadData) => {
			// parsing the array of email addresses and formatting correctly for payload
			const toArray = passedPayloadData.sendToArray.map(
				(toAddress) => toAddress
			);

			const genericEmailData = {
				templateName: 'Generic Email Template',
				subject: `${passedPayloadData.subject}`,
				to: toArray,
				msgArgs: {
					firstName: `${passedPayloadData.firstName}`,
					lastName: `${passedPayloadData.lastName}`,
					message: `${passedPayloadData.message}`
				}
			};

			const response = await axiosInstance.post(
				sendGenericEmailUrl,
				genericEmailData
			);

			const genericEmailResponse = response.data.data;

			return genericEmailResponse;
		},
		{
			onError: (error) => {
				queryClient.setQueryData(ERROR_MESSAGE_DATA, error.message);
			}
		}
	);
};

export default useMutateSendGenericEmail;
