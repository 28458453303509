import { Box, Typography } from '@mui/material';

const formatHeadingBasedOnOfferKey = {
	closingCost: 'Closing Costs',
	interestRate: 'Interest Rate',
	monthlyPayUsd: 'Monthly Payment',
	monthlyMortgagePayment: 'Monthly Payment',
	remainingMonth: 'Loan Term',
	soraScore: 'Sora Score',
	tenure: 'Loan Term',
	totalLoanAmount: 'Total Loan Amount',
	totalCashOutAmount: 'Cash Out Amount',
	totalCostOfDebt: 'Total cost of debt'
};

function OfferTableRowHeading({ currentOfferKey }) {
	const formattedHeading = formatHeadingBasedOnOfferKey[currentOfferKey];

	return (
		<Box width="200px">
			<Typography component="div" variant="subtitle1" fontWeight="bold">
				{formattedHeading}
			</Typography>
		</Box>
	);
}

export default OfferTableRowHeading;
