import { useMutation, useQueryClient } from 'react-query';

// Our axios Instance
import axiosInstance from 'services/API/API';

// Our Endpoints
import { clientInvitation as clientInvitationUrl } from 'shared/api-urls';

// Our Query keys
import { ERROR_MESSAGE_DATA } from 'shared/query-keys';

function useMutateAddClientInvitation() {
	const queryClient = useQueryClient();

	return useMutation(
		async (inviteDataObject) => {
			const response = await axiosInstance.post(
				clientInvitationUrl,
				inviteDataObject
			);

			return response.data.data;
		},
		{
			onError: (error) => {
				const errorResponse = error.response.data.data;

				let finalErrorMessage = errorResponse;
				if (errorResponse.includes('already invited with FA')) {
					finalErrorMessage =
						"You've already invited that client, contact them via the Client Status page accessible from your dashboard.";
				}
				queryClient.setQueryData(ERROR_MESSAGE_DATA, finalErrorMessage);
			}
		}
	);
}

export default useMutateAddClientInvitation;
