/**
 *
 * @param {number} creditScore
 * @returns {string} `Excellent | Good | Fair | Bad`
 */

function convertToCreditScoreWordFromValue(creditScore) {
	if (creditScore >= 800) return 'Excellent';
	if (creditScore >= 740) return 'Very Good';
	if (creditScore >= 670) return 'Good';
	if (creditScore >= 579) return 'Fair';

	// anything < 590 is poor!
	return 'Poor';
}

export default convertToCreditScoreWordFromValue;
