import { Typography } from '@mui/material';

// Our Components
import Loader from 'components/Loader';
import OfferTable from 'components/Offers/OfferTable/OfferTable';
import OfferColumns from 'components/Offers/OfferColumns/OfferColumns';

// Our Hooks
import useGetNewLoanOffers from 'hooks/useGetNewLoanOffers';

function NewLoanOffer({ setIsModalOpen }) {
	const offerType = 'new_loan';

	// newLoanOffer refers to a new mortgage offer for now
	// This api gets triggered after a client fills out the home loan questionnaire.

	const {
		data: newLoanOffers,
		error,
		isSuccess: getNewLoanOffersIsSuccess,
		isLoading: getNewLoanOfferIsLoading
	} = useGetNewLoanOffers(offerType);

	if (error) {
		return (
			<Typography variant="h2Gascogne" component="h2">
				Whoops. Something went wrong with the offers.
			</Typography>
		);
	}

	if (getNewLoanOfferIsLoading) return <Loader />;

	if (getNewLoanOffersIsSuccess) {
		const clientHasNoOffers = newLoanOffers.length < 1;

		if (clientHasNoOffers) {
			return (
				<Typography variant="h4">
					Seems like no offers were found.
				</Typography>
			);
		}

		return (
			<>
				<OfferTable offerType={offerType} offers={newLoanOffers} />
				<OfferColumns
					offerType={offerType}
					offers={newLoanOffers}
					setIsModalOpen={setIsModalOpen}
				/>
			</>
		);
	}
}

export default NewLoanOffer;
