import PropTypes from 'prop-types';

export const bestRefiPropShape = {
	costOfRefiUsd: PropTypes.number,
	costOfRefiRatePercent: PropTypes.number,
	savingPotentialUsd: PropTypes.number,
	costOfDebt: PropTypes.number,
	monthlyPayment: PropTypes.number,
	remainingMonth: PropTypes.number,
	interestRate: PropTypes.number
};

export const homePropShape = {
	id: PropTypes.number, // the home id
	addressLine1: PropTypes.string,
	addressLine2: PropTypes.string,
	city: PropTypes.string,
	zipcode: PropTypes.string,
	askingHomeValue: PropTypes.number,
	estimatedHomeValue: PropTypes.number
};

export const currentLoanPropShape = {
	costOfDebt: PropTypes.number,
	monthlyPayment: PropTypes.number,
	remainingMonth: PropTypes.number,
	homeId: PropTypes.number,
	totalOutstandingBalance: PropTypes.number,
	interestRate: PropTypes.number,
	home: PropTypes.shape(homePropShape)
};
