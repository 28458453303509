import { useQueryClient } from 'react-query';
import { useState, useMemo } from 'react';
import { Box, Grid } from '@mui/material';
import { isDate, isMobilePhone } from 'validator';
import { v4 as uuidv4 } from 'uuid';
import PropTypes from 'prop-types';

// our Components
import Alert from 'components/Alert/index';
import CurrencyTextInput from 'components/Input/CurrencyTextInput';
import GetFullAddressPartialForm from 'components/Forms/addressRelated/GetFullAddressPartialForm';
import Loader from 'components/Loader/index';
import PhoneNumberInput from 'components/Input/PhoneNumberInput';
import { PrimaryButton, TertiaryButton } from 'components/Button/Button';
import SSNTextInput from 'components/Input/SSNTextInput';
import StandardDatePicker from 'components/DatePicker/StandardDatePicker';
import TextInput from 'components/Input/TextInput';
import { TEXT } from 'components/Input/Types';

// Our Hooks
import useMutateStartOnboardingProcess from 'hooks/Onboarding/AutomaticOnboarding/useMutateStartOnboardingProcess';

// Our Query Keys
import { SUCCESS_MESSAGE_DATA, ERROR_MESSAGE_DATA } from 'shared/query-keys';

// Our Utils
import {
	clearFormValues,
	dataIsValid,
	isSubmissionReady,
	isValidSSN
} from 'shared/utils';
import getStateAbbreviation from 'shared/utils/formatting/getStateAbbreviation';
import filterCharactersNotInList from 'shared/utils/stringFiltering/filterCharactersNotInList';
import sentenceCapitalize from 'shared/utils/formatting/sentenceCapitalize';
import safeConvertDateToUTC from 'shared/utils/date/safeConvertDateToUTC';

// Our Common Prop types
import CommonProps from 'shared/prop-types';

// Our Constants
import PhoneNumberWhitelist from 'shared/phone-number-whitelist.json';

const FILTER_CHARACTER_LIST = [
	'a',
	'b',
	'c',
	'd',
	'e',
	'f',
	'g',
	'h',
	'i',
	'j',
	'k',
	'l',
	'm',
	'n',
	'o',
	'p',
	'q',
	'r',
	's',
	't',
	'u',
	'v',
	'w',
	'x',
	'y',
	'z',
	' '
];

function AddSingleClientForm({
	buttonContainerSX,
	clientSelected,
	handleClose,
	isModalVersion,
	startOnboardingProcess,
	userProfileData,
	handleOnboardingOptimisiticUpdate
}) {
	const queryClient = useQueryClient();
	const mutateStartOnboardingProcess = useMutateStartOnboardingProcess();
	const onboardingHandler =
		startOnboardingProcess || mutateStartOnboardingProcess;

	const { isError, isSuccess, isLoading } = onboardingHandler;

	// Client to be onboarded pii state
	const [firstName, setFirstName] = useState(
		clientSelected?.clientFirstName ?? ''
	);
	const [lastName, setLastName] = useState(
		clientSelected?.clientLastName ?? ''
	);
	const [piiNumber, setPiiNumber] = useState('');
	const [dob, setDob] = useState(
		safeConvertDateToUTC(clientSelected?.dateOfBirth) || null
	);
	const [phoneNumber, setPhoneNumber] = useState(
		clientSelected?.phoneNumber ?? ''
	);

	// address related fields
	const [addressLine1, setAddressLine1] = useState(
		clientSelected?.address?.street_line_1 ?? ''
	);
	const [addressLine2, setAddressLine2] = useState(
		clientSelected?.address?.street_line_2 ?? ''
	);
	const [city, setCity] = useState(clientSelected?.address?.city ?? '');
	const [state, setState] = useState(
		getStateAbbreviation(clientSelected?.address?.state) ?? ''
	);

	const [zipCode, setZipCode] = useState(
		clientSelected?.address?.zip_code ?? ''
	);

	// Client Income & spouse name state
	const [income, setIncomeAmount] = useState(
		clientSelected?.grossAnnualIncome ?? ''
	);
	const [assets, setAssetAmount] = useState(clientSelected?.assets ?? '');
	const [spouseName, setSpouseName] = useState('');

	// helper state to reset
	const [key, setKey] = useState(uuidv4());

	const requiredFormFields = [
		firstName,
		lastName,
		piiNumber,
		dob,
		phoneNumber,
		addressLine1,
		city,
		state,
		zipCode
	];

	const isValidDate = useMemo(() => {
		const isStringDate = typeof dob === 'string';
		const isDateType = dob instanceof Date;

		if (isStringDate && dob !== '') {
			const safeUTCDateObject = safeConvertDateToUTC(dob);
			const isDateCheckWithConversion = isDate(safeUTCDateObject);
			return isDateCheckWithConversion;
		}

		if (isDateType) {
			return isDate(dob);
		}
		// if dob is null this returns false;
		return false;
	}, [dob]);

	const isValidPhoneNumber = useMemo(() => {
		if (PhoneNumberWhitelist.includes(phoneNumber)) {
			return true;
		}
		return isMobilePhone(phoneNumber, 'en-US');
	}, [phoneNumber]);

	const isFormReady = useMemo(
		() =>
			isSubmissionReady(requiredFormFields) &&
			isValidPhoneNumber &&
			phoneNumber.length > 7 &&
			isValidDate &&
			isValidSSN(piiNumber),
		requiredFormFields
	);

	// Handle Email Send
	const submitAddSingleClientForm = (event) => {
		event.preventDefault();

		const {
			companyName,
			email: advisorEmail,
			firstName: advisorFirstName,
			lastName: advisorLastName
		} = userProfileData;

		const checkForEmptyIncome = income !== '' ? income : '0';
		const checkForEmptyAsset = assets !== '' ? assets : '0';

		const filteredFirstName = filterCharactersNotInList(
			firstName,
			FILTER_CHARACTER_LIST
		);
		const filteredLastName = filterCharactersNotInList(
			lastName,
			FILTER_CHARACTER_LIST
		);

		// formatted means joe -> Joe or "diaz medrano" -> "Diaz Medrano"
		const formattedFirstName = sentenceCapitalize(filteredFirstName);
		const formattedLastName = sentenceCapitalize(filteredLastName);

		const formattedAdvisorFirstName = sentenceCapitalize(advisorFirstName);
		const formattedAdvisorLastName = sentenceCapitalize(advisorLastName);

		const formattedAdvisorFullName = `${formattedAdvisorFirstName} ${formattedAdvisorLastName}`;

		const onboardClientDetails = {
			advisorEmail,
			advisorFullName: formattedAdvisorFullName,
			company: companyName,
			firstName: formattedFirstName,
			lastName: formattedLastName,
			phoneNumber,
			dob,
			piiNumber,
			addressLine1,
			addressLine2,
			city,
			state,
			zipCode,
			incomeAmount: checkForEmptyIncome,
			assetAmount: checkForEmptyAsset
		};

		// if the client selected exists and the is present. Forward that to the request.
		if (dataIsValid(clientSelected?.id)) {
			onboardClientDetails.externalContactId = clientSelected.id;
		}

		onboardingHandler.mutate(onboardClientDetails, {
			onError: (e) => {
				queryClient.setQueryData(ERROR_MESSAGE_DATA, e.message);
			},
			onSuccess: () => {
				queryClient.setQueryData(
					SUCCESS_MESSAGE_DATA,
					'Your client will be added to your account in about 15 minutes. Please refresh your dashboard page to get the latest information.'
				);

				if (handleClose) {
					handleOnboardingOptimisiticUpdate();

					handleClose();
				} else {
					clearFormValues([
						setFirstName,
						setLastName,
						setPiiNumber,
						setAddressLine1,
						setAddressLine2,
						setCity,
						setState,
						setZipCode,
						setPhoneNumber,
						setIncomeAmount,
						setAssetAmount,
						setSpouseName
					]);

					setDob(null);
					setKey(uuidv4());
				}
			}
		});
	};

	return (
		<>
			{isError && <Alert variant="error" />}
			{isSuccess && <Alert variant="success" />}

			<Box
				component="form"
				noValidate
				autoComplete="off"
				sx={{ paddingRight: 2 }}
			>
				<Grid container spacing={2} marginTop={isModalVersion ? 0 : 4}>
					<Grid item xs={12} md={6}>
						<TextInput
							type={TEXT}
							label="First Name"
							subLabel="Use legal name"
							value={firstName}
							onChange={setFirstName}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<TextInput
							type={TEXT}
							label="Last Name"
							subLabel="Use legal name"
							value={lastName}
							onChange={setLastName}
						/>
					</Grid>

					<Grid item xs={12} md={6}>
						<SSNTextInput
							key={key}
							onChange={setPiiNumber}
							ssnValue={piiNumber}
							subLabel=""
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<StandardDatePicker
							key={key}
							id="dob"
							label="Date of Birth"
							helperText="Enter your client's birth date"
							onChange={setDob}
							value={dob}
							error={dob === null || !isValidDate}
							errorText="Date of birth needs to be a valid date"
						/>
					</Grid>

					<Grid item xs={12} md={6}>
						<PhoneNumberInput
							key={key}
							isValidPhoneNumber={isValidPhoneNumber}
							onChange={setPhoneNumber}
							phoneNumber={phoneNumber}
							subLabel="Enter your client's phone number"
							sx={{ marginBottom: 4 }}
						/>
					</Grid>

					{/* Address Rows */}
					<GetFullAddressPartialForm
						addressLine1={addressLine1}
						addressLine2={addressLine2}
						city={city}
						state={state}
						zipCode={zipCode}
						setAddressLine1={setAddressLine1}
						setAddressLine2={setAddressLine2}
						setCity={setCity}
						setState={setState}
						setZipCode={setZipCode}
					/>

					<Grid item xs={12} marginBottom={4} marginTop={4}>
						<hr />
					</Grid>

					<Grid item xs={12} md={6}>
						{/* Income */}
						<CurrencyTextInput
							label="Annual Income"
							subLabel="Optional"
							value={income}
							onChange={setIncomeAmount}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<CurrencyTextInput
							label="Estimated Value of Your Assets"
							subLabel="Optional (Includes cash, checking and savings accounts, stocks, retirement accounts and other assets.)"
							value={assets}
							onChange={setAssetAmount}
							inputprops={{
								'data-test': 'assets'
							}}
						/>
					</Grid>

					<Grid item xs={12} md={6}>
						{/* Spouses name */}
						<TextInput
							type={TEXT}
							label="Spouse's Name"
							subLabel="Optional (Use legal name)"
							value={spouseName}
							onChange={setSpouseName}
						/>
					</Grid>
					<Grid item xs={12} md={6} />

					<Grid
						item
						xs={12}
						sx={{
							...buttonContainerSX
						}}
					>
						{!isLoading && !isModalVersion && (
							<PrimaryButton
								data-test="inviteButton"
								onClick={submitAddSingleClientForm}
								disabled={!isFormReady}
							>
								Add Client
							</PrimaryButton>
						)}
						{!isLoading && isModalVersion && (
							<TertiaryButton
								data-test="inviteButton"
								onClick={submitAddSingleClientForm}
								disabled={!isFormReady}
							>
								Submit
							</TertiaryButton>
						)}
						{isLoading && <Loader />}
					</Grid>
				</Grid>
			</Box>
		</>
	);
}

AddSingleClientForm.propTypes = {
	buttonContainerSX: CommonProps.sx,
	isModalVersion: PropTypes.bool
};

AddSingleClientForm.defaultProps = {
	buttonContainerSX: {
		marginTop: 4
	},
	isModalVersion: false
};

export default AddSingleClientForm;
