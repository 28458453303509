import { formatDistanceToNowStrict, parseISO } from 'date-fns';
import { enUS } from 'date-fns/locale';

const processTime = (inputString) => {
	try {
		const checkThesePatters = {
			seconds: 's',
			second: 's',
			minutes: 'm',
			minute: 'm',
			hours: 'h',
			hour: 'h',
			days: 'd',
			day: 'd',
			month: 'mo',
			months: 'mos',
			years: 'y',
			year: 'y'
		};

		const unitsOfTime = Object.keys(checkThesePatters);

		// eslint-disable-next-line no-restricted-syntax
		for (const unitOfTime of unitsOfTime) {
			const isUnitApplicable = inputString.includes(unitOfTime);

			if (isUnitApplicable) {
				const unitToReplace = checkThesePatters[unitOfTime];
				const replacedString = inputString.replaceAll(
					unitOfTime,
					unitToReplace
				);
				const removeLastSpace = replacedString.lastIndexOf(' ');

				return (
					replacedString.slice(0, removeLastSpace) +
					replacedString.slice(removeLastSpace + 1)
				);
			}
		}
		return inputString;
	} catch (e) {
		console.error(e);
		return inputString;
	}
};

const getTimeAgo = (inputDateTime) => {
	try {
		const formatOptions = {
			locale: enUS,
			includeSeconds: false,
			addSuffix: false
		};
		if (inputDateTime instanceof Date)
			return processTime(
				formatDistanceToNowStrict(inputDateTime, formatOptions)
			);
		const parsedIsoDate = parseISO(inputDateTime, formatOptions);

		return processTime(
			formatDistanceToNowStrict(parsedIsoDate, formatOptions)
		);
	} catch (e) {
		console.error(e);
		return 'N/A';
	}
};

export default getTimeAgo;
