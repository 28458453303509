import { Box, Grid, Tooltip, Typography } from '@mui/material';
import { format, parseISO } from 'date-fns';
import { useState, useEffect } from 'react';
import { useQueryClient } from 'react-query';

import { useTheme } from '@emotion/react';

import UploadOutlinedIcon from '@mui/icons-material/UploadOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import Modal from 'components/Modal/Modal';

// Component
import Loader from 'components/Loader';
import ColumnItem from 'components/DataTable/ColumnItem';
import DataTable from 'components/DataTable/DataTable';
import UploadLoanDocumentsModal from 'components/Tasks/UploadLoanDocsModel';
import { PrimaryButton, SecondaryButton } from 'components/Button/Button';

// Hooks
import useGetRelatedDocuments from 'hooks/useGetLoanRequestRelatedDocuments';
import useMutateDeleteLoanDocument from 'hooks/newLoanInquiries/useMutateDeleteDocument';
import useMutateLoanRequestDocumentDownload from 'hooks/useMutateLoanRequestDocumentDownload';

// Our Constants
import { LOAN_REQUEST_RELATED_DOCUMENTS } from 'shared/query-keys';

function LoanRequestRelatedDocuments({ loanRequestId, clientId }) {
	const SoraTheme = useTheme();
	const queryClient = useQueryClient();
	const { primary } = SoraTheme.palette;
	const { data, isLoading, isSuccess, error } =
		useGetRelatedDocuments(loanRequestId);

	// upload modal
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
	const [selectedDocumentId, setSelectedDocumentId] = useState(null);
	const [documentIdToUpload, setDocumentIdToUpload] = useState(null);

	const deleteDocument = useMutateDeleteLoanDocument();
	const {
		isLoading: isDeleteDocumentIsLoading,
		isSuccess: isDeleteDocumentIsSuccess,
		isError
	} = deleteDocument;

	const downloadDocument = useMutateLoanRequestDocumentDownload();

	const { data: documentData } = downloadDocument;

	useEffect(() => {
		setIsDeleteModalOpen(false);
		queryClient.getQueryData([
			LOAN_REQUEST_RELATED_DOCUMENTS,
			loanRequestId
		]);
	}, [isDeleteDocumentIsSuccess]);

	const clickDownloadDocumentButton = (masterDocumentId) => {
		setSelectedDocumentId(masterDocumentId);
		// event.preventDefault();
		downloadDocument.mutate({
			loanRequestId,
			masterDocumentId,
			clientId
		});
	};

	const isCompleted = 'isCompleted';
	const nameKey = 'name';
	const createdDateKey = 'createdDate';
	const actionColumnKey = 'actionColumn';
	const loanDocumentsKey = 'loanDocuments';
	const documentDescriptionKey = 'documentDescription';

	const LoanRequestDocumentsColumns = [
		ColumnItem(isCompleted, '', 1, {
			renderCell: (params) => {
				const isUploaded = params.row[loanDocumentsKey].length > 0;

				if (isUploaded) {
					return (
						<Tooltip title="File has been uploaded" placement="top">
							<Box>
								<CheckCircleIcon
									sx={{
										width: '1.25rem',
										height: '1.25rem',
										color: primary.indigo,
										borderRadius: 200,
										'&:hover': {
											opacity: 0.6,
											cursor: 'pointer',
											color: primary.black
										}
									}}
								/>
							</Box>
						</Tooltip>
					);
				}
				if (!isUploaded) {
					return (
						<Tooltip title="No file uploaded" placement="top">
							<Box>
								<RadioButtonUncheckedIcon
									sx={{
										width: '1.25rem',
										height: '1.25rem',
										color: primary.indigo,
										borderRadius: 200,
										'&:hover': {
											opacity: 0.6,
											cursor: 'pointer',
											color: primary.black
										}
									}}
								/>
							</Box>
						</Tooltip>
					);
				}

				return 'Error';
			}
		}),
		ColumnItem(nameKey, '', 5, {
			renderCell: (params) => {
				// eslint-disable-next-line prefer-destructuring
				const documentStatus =
					params?.row[loanDocumentsKey][0]?.documentStatus;
				const isRejected = documentStatus === 'Rejected';

				const textShownInPage = params.row[documentDescriptionKey];

				return (
					<>
						{isRejected && (
							<Tooltip
								title="Your document was not approved, please delete and reupload a valid one."
								placement="top"
							>
								<Typography
									sx={{ color: primary.red }}
									variant="body1"
								>
									{textShownInPage}
								</Typography>
							</Tooltip>
						)}

						{!isRejected && (
							<Tooltip title={textShownInPage} placement="top">
								<Typography variant="body1">
									{textShownInPage}
								</Typography>
							</Tooltip>
						)}
					</>
				);
			}
		}),
		ColumnItem(createdDateKey, '', 2, {
			valueGetter: (params) => {
				const singleDocumentData = params.row[loanDocumentsKey][0];
				const documentUploadedDate = parseISO(
					singleDocumentData?.createdDate
				);

				if (singleDocumentData !== undefined) {
					const formattedDate = format(
						new Date(documentUploadedDate),
						'MM/dd/yyyy'
					);
					return formattedDate;
				}

				return 'NA';
			}
		}),
		ColumnItem(actionColumnKey, '', 2, {
			// eslint-disable-next-line consistent-return
			renderCell: (params) => {
				const fileExists = params?.row[loanDocumentsKey]?.length > 0;
				const documentId = params?.row[loanDocumentsKey][0]?.id;
				const masterDocumentId = params?.row?.masterDocumentId;

				// eslint-disable-next-line prefer-destructuring
				const documentStatus =
					params?.row[loanDocumentsKey][0]?.documentStatus;
				const isApproved = documentStatus === 'Approved';

				if (!fileExists) {
					return (
						<Box>
							<Tooltip title="Upload file" placement="top">
								<UploadOutlinedIcon
									sx={{
										width: '1.5rem',
										height: '1.5rem',
										borderRadius: 200,
										marginLeft: 3.2,
										'&:hover': {
											opacity: 0.6,
											cursor: 'pointer',
											color: primary.black
										}
									}}
									onClick={(event) => {
										event.preventDefault();
										setDocumentIdToUpload(masterDocumentId);
										setIsModalOpen(!isModalOpen);
									}}
								/>
							</Tooltip>
						</Box>
					);
				}
				if (fileExists) {
					return (
						<Box>
							<Tooltip title="Download file" placement="top">
								<DownloadOutlinedIcon
									sx={{
										width: '1.5rem',
										height: '1.5rem',
										marginRight: 1,
										borderRadius: 200,
										'&:hover': {
											opacity: 0.6
										}
									}}
									onClick={() =>
										clickDownloadDocumentButton(
											masterDocumentId
										)
									}
								/>
							</Tooltip>
							{isApproved && (
								<Tooltip
									title="Not able to delete an approved file"
									placement="top"
								>
									<DeleteOutlineOutlinedIcon
										sx={{
											width: '1.5rem',
											height: '1.5rem',
											borderRadius: 200,
											opacity: 0.6,
											cursor: 'pointer'
										}}
									/>
								</Tooltip>
							)}
							{!isApproved && (
								<Tooltip title="Delete file" placement="top">
									<DeleteOutlineOutlinedIcon
										sx={{
											width: '1.5rem',
											height: '1.5rem',
											borderRadius: 200,
											'&:hover': {
												opacity: 0.6,
												cursor: 'pointer'
											}
										}}
										onClick={(event) => {
											event.preventDefault();
											setSelectedDocumentId(documentId);
											setIsDeleteModalOpen(
												!isDeleteModalOpen
											);
										}}
									/>
								</Tooltip>
							)}
						</Box>
					);
				}
			}
		})
	];

	if (error) {
		<>
			<Typography variant="h5" sx={{ marginTop: 20 }}>
				There was an error fetching your loan request.
			</Typography>
			<Typography variant="h5" sx={{ marginTop: 2 }}>
				Please refresh the page, if issue persist please reach out to
				sora at contact@sorafinance.com
			</Typography>
		</>;
	}

	if (isLoading) {
		return (
			<Grid
				container
				item
				xs={12}
				sx={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center'
				}}
			>
				<Loader size={70} />
			</Grid>
		);
	}

	if (isSuccess) {
		return (
			<>
				<Grid container>
					<Grid item xs={12}>
						<DataTable
							disableSelectionOnClick
							pageSize={4}
							columns={LoanRequestDocumentsColumns}
							rows={data}
							rowsPerPageOptions={4}
							withRowColorCheck
							height={340}
							rowHeight={60}
							sx={{ top: '-40px', marginBottom: 0 }}
							handleRowClick={() => {}}
						/>
					</Grid>
				</Grid>

				{/* Delete Document Modal */}
				<Modal
					title="Are you sure you'd like to delete this document?"
					isOpen={isDeleteModalOpen}
					handleClose={() => setIsDeleteModalOpen(false)}
				>
					<Box sx={{ marginTop: 4 }}>
						<PrimaryButton
							sx={{ marginRight: 4 }}
							onClick={() =>
								deleteDocument.mutate(
									selectedDocumentId,
									loanRequestId,
									{
										onSuccess: () => {
											setIsDeleteModalOpen(false);
										}
									}
								)
							}
						>
							Delete
						</PrimaryButton>

						<SecondaryButton
							onClick={() => setIsDeleteModalOpen(false)}
						>
							Cancel
						</SecondaryButton>
					</Box>
				</Modal>

				<UploadLoanDocumentsModal
					isOpen={isModalOpen}
					handleClose={() => setIsModalOpen(false)}
					masterDocumentId={documentIdToUpload}
					newLoanId={loanRequestId}
					clientId={clientId}
				/>
			</>
		);
	}
}

export default LoanRequestRelatedDocuments;
