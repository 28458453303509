import { useQuery, useQueryClient } from 'react-query';

// Our axios Instance
import axiosInstance from 'services/API/API';

// Sora API endpoint
import { userProfile } from 'shared/api-urls';

// React Query Keys
import {
	ADVISOR_PROFILE_PICTURE,
	ADVISOR_COMPANY_LOGO,
	USERPROFILEDATA,
	WALLET_OVERVIEW_DATA
} from 'shared/query-keys';

const TWO_MINUTES_STALE_TIME = 60000 * 2;

function useGetProfilePictureOrCompanyLogo(isCorporate) {
	const queryClient = useQueryClient();

	const DYNAMIC_QUERY_KEY = isCorporate
		? ADVISOR_COMPANY_LOGO
		: ADVISOR_PROFILE_PICTURE;

	return useQuery(
		DYNAMIC_QUERY_KEY,
		async () => {
			const res = await axiosInstance.get(userProfile);
			const { timestamp } = res.data.meta;
			const userProfileData = res.data.data;

			// if isCorporate => we want company logo and if we have it return it
			if (isCorporate && userProfileData?.companyLogo)
				return {
					imageUrl: `${userProfileData.companyLogo}?${timestamp}`,
					timestamp
				};

			// if !isCorporate => we want profile picture and if we have it return it
			if (!isCorporate && userProfileData?.profilePicUrl)
				return {
					imageUrl: `${userProfileData.profilePicUrl}?${timestamp}`,
					timestamp
				};

			// if we hit this block we intentionally want this to be null
			return { imageUrl: null, timestamp };
		},
		{
			onSuccess: () => {
				queryClient.refetchQueries({ queryKey: USERPROFILEDATA });
				queryClient.refetchQueries({ queryKey: WALLET_OVERVIEW_DATA });
			},
			refetchOnWindowFocus: false,
			staleTime: TWO_MINUTES_STALE_TIME
		}
	);
}

export default useGetProfilePictureOrCompanyLogo;
