import PropTypes from 'prop-types';

// Our Components
import Dropdown from 'components/Dropdown/Dropdown';

// Common Props
import CommonProps from 'shared/prop-types';

// Local Constants
const YEARS_FROM_NOW_OPTIONS = Array.from(
	{ length: 6 },
	(_, index) => `${(index + 1) * 5} years from now`
);

function ProjectionEndYearDropdown({
	projectedYearsFromNow,
	setProjectedYearsFromNow,
	labelVariant,
	labelSx,
	sx
}) {
	return (
		<Dropdown
			label="Projection end year:"
			labelVariant={labelVariant}
			items={YEARS_FROM_NOW_OPTIONS}
			onChange={setProjectedYearsFromNow}
			selected={projectedYearsFromNow}
			labelSx={labelSx}
			sx={sx}
		/>
	);
}

ProjectionEndYearDropdown.propTypes = {
	projectedYearsFromNow: PropTypes.string.isRequired,
	setProjectedYearsFromNow: PropTypes.func.isRequired,
	labelVariant: PropTypes.string,
	labelSx: CommonProps.sx,
	sx: CommonProps.sx
};

ProjectionEndYearDropdown.defaultProps = {
	labelVariant: 'body2',
	labelSx: {},
	sx: {}
};

export default ProjectionEndYearDropdown;
