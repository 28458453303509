import { Typography, Box } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useTheme } from '@emotion/react';

// Our Components
import Alert from 'components/Alert/index';
import Loader from 'components/Loader/index';
import { TextButton } from 'components/Button/Button';

// Our Routes
import { WEALTH_BOX_REDIRECT_URI } from 'routes/index';

// Our Hooks
import useGetConnectWealthBox from 'hooks/wealthbox/useGetConnectWealthBox';
import useGetProfile from 'hooks/useGetProfile';

const extractBaseUrl = (baseUrl) => {
	const lastIndexOfSlash = baseUrl.lastIndexOf('/');
	return baseUrl.slice(0, lastIndexOfSlash);
};

const wealthBoxAPIPath = 'https://api.crmworkspace.com/oauth/authorize';
const WEALTH_BOX_APP_ID = process.env.REACT_APP_WEALTH_BOX_APP_ID;
const APP_LOGIN_URL = process.env.REACT_APP_LOGIN_URL;
const EXTRACTED_BASE_URL = extractBaseUrl(APP_LOGIN_URL);
const COMPLETE_WEALTH_BOX_URL = `${EXTRACTED_BASE_URL}/${WEALTH_BOX_REDIRECT_URI}`;

function IntegrationCard({ bodyText, isDisabled, logo }) {
	const componentOpacity = isDisabled ? 0.2 : 1;
	const isWealthBox = !isDisabled;

	const { isError, isLoading } = useGetConnectWealthBox(isWealthBox);

	const { data: isWealthBoxAlreadyConnected } = useGetProfile(
		(userProfile) => userProfile?.connectedWealthBox ?? false
	);

	const SoraTheme = useTheme();

	const disableCard = isWealthBox && isWealthBoxAlreadyConnected;

	const { indigo } = SoraTheme.palette.primary;

	const paddingConfig = disableCard
		? { paddingTop: 1, paddingRight: 1, paddingBottom: 3 }
		: { paddingTop: 5 };

	return (
		<>
			{isError && <Alert variant="error" />}
			<Box
				sx={{
					opacity: componentOpacity,
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'space-between',
					border: '1px solid black',
					borderRadius: 4,
					minHeight: 300,
					width: '100%',
					padding: 4,
					...paddingConfig
				}}
			>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column'
					}}
				>
					{isWealthBox && isWealthBoxAlreadyConnected && (
						<CheckCircleIcon
							sx={{
								color: indigo,
								fontSize: '1.8rem',
								alignSelf: 'flex-end'
							}}
						/>
					)}
					<Box
						component="img"
						src={logo}
						sx={{
							width: '80%',
							maxWidth: 160,
							height: 40,
							alignSelf: 'flex-start'
						}}
					/>
				</Box>

				<Typography variant="subtitle" sx={{ lineHeight: '1.5' }}>
					{bodyText}
				</Typography>

				{!isLoading && isWealthBox && (
					<TextButton
						disabled={disableCard}
						onClick={() => {
							const params = {
								client_id: WEALTH_BOX_APP_ID,
								redirect_uri: COMPLETE_WEALTH_BOX_URL,
								response_type: 'code',
								scope: 'login data'
							};
							const searchParams = new URL(wealthBoxAPIPath);
							const keys = Object.keys(params);

							keys.map((key) =>
								searchParams.searchParams.append(
									key,
									params[key]
								)
							);
							const newLocation = searchParams.href;
							window.open(newLocation, '_blank');
						}}
					>
						Connect
					</TextButton>
				)}

				{isWealthBox && isLoading && <Loader />}
			</Box>
		</>
	);
}

export default IntegrationCard;
