import { Box } from '@mui/material';
import { useState, useEffect } from 'react';

// Our Components
import Modal from 'components/Modal/Modal';
import Loader from 'components/Loader/index';
import TextInput from 'components/Input/TextInput';
import { PrimaryButton, SecondaryButton } from 'components/Button/Button';

// helper function

const getErrorMessage = (emailValue, isError) => {
	if (isError)
		return 'We were unable to find an account associated with that account. Please review the email address entered or email contact@sorafinance.com if you are looking to sign up.';

	const isInValid =
		emailValue === '' ||
		!emailValue.includes('.') ||
		!emailValue.includes('@');

	if (isInValid) return 'Please enter a valid email address';
	return '';
};

function PasswordLessModal({
	isModalOpen,
	setIsModalOpen,
	setModalEmail,
	modalEmail,
	getMagicToken
}) {
	const { isSuccess, isLoading, isError } = getMagicToken;
	const [isEmailTriggered, setIsEmailTriggered] = useState(false);

	const toggleModal = () => {
		setIsModalOpen(!isModalOpen);
	};

	useEffect(() => {
		setTimeout(() => {
			setModalEmail('');
			setIsEmailTriggered(false);
		}, 500);
	}, [isModalOpen]);

	useEffect(() => {
		if (isSuccess) {
			setIsEmailTriggered(true);
		}

		return () => setIsEmailTriggered(false);
	}, [isSuccess]);

	const modalErrorMessage = getErrorMessage(modalEmail, isError);

	if (isEmailTriggered)
		return (
			<Modal
				title="We've sent you a login email"
				subtitle="Please be sure to check your spam folder if it does not appear in your inbox"
				isOpen={isModalOpen}
				handleClose={toggleModal}
			>
				<SecondaryButton onClick={toggleModal}>
					Back to Log in
				</SecondaryButton>
			</Modal>
		);

	return (
		<Modal
			title="What's the email address associated to your account?"
			subtitle="We will email you a login link if there is a Sora account associated to the email you enter."
			isOpen={isModalOpen}
			handleClose={toggleModal}
		>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column'
				}}
			>
				<TextInput
					placeholder="Email address"
					id="email"
					type="email"
					onChange={setModalEmail}
					inputProps={{
						'data-test': 'email-input'
					}}
					value={modalEmail}
					error={
						isError ||
						modalEmail === '' ||
						!modalEmail.includes('.') ||
						!modalEmail.includes('@')
					}
					helperText={modalErrorMessage}
					sx={{
						marginTop: 2,
						marginBottom: 6
					}}
				/>

				<Box sx={{ display: 'flex', gap: 2 }}>
					{!isLoading && (
						<PrimaryButton
							disabled={
								modalEmail === '' ||
								!modalEmail.includes('.') ||
								!modalEmail.includes('@')
							}
							onClick={() =>
								getMagicToken.mutate({
									email: modalEmail,
									sendEmail: true
								})
							}
						>
							Send login link
						</PrimaryButton>
					)}
					{isLoading && <Loader />}

					<SecondaryButton onClick={toggleModal}>
						Back to Log in
					</SecondaryButton>
				</Box>
			</Box>
		</Modal>
	);
}

export default PasswordLessModal;
