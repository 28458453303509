import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

// Our axios Instance
import axiosInstance from 'services/API/API';

// Sora API endpoint
import { userProfile } from 'shared/api-urls';

// Our Query Keys
import { USERPROFILEDATA } from 'shared/query-keys';

// Our Routes
import { LOGIN_ROUTE } from 'routes/index';

// if select is passed. PLEASE pass a fn
function useGetProfile(select) {
	const navigate = useNavigate();

	return useQuery(
		USERPROFILEDATA,
		async () => {
			const res = await axiosInstance.get(userProfile);
			const userProfileData = res.data.data;
			return userProfileData;
		},
		{
			// this staleTime is equivalent to 1 minute
			// this stalTime is added to resolve the navbar refreshing every time we click on a button in the nav bar
			// this will be tweaked post mvp
			staleTime: 60000,
			onError: (error) => {
				const json = error.toJSON();
				const statusCode = json.status;

				if (statusCode === 401) {
					navigate(LOGIN_ROUTE);
				}
			},
			select: (userProfileData) => {
				if (select) {
					return select(userProfileData);
				}

				return userProfileData;
			}
		}
	);
}

export default useGetProfile;
