import { v4 as uuidv4 } from 'uuid';
import { useTheme } from '@mui/material/styles';
import { Box, FormHelperText, MenuItem, Select } from '@mui/material';
import PropTypes from 'prop-types';
import { useCallback, useMemo, isValidElement } from 'react';

// Our Components
import DropdownLabel from 'components/Dropdown/DropdownLabel';

// Our PropTypes
import CommonPropTypes from 'shared/prop-types';

// Our Constants
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

function Dropdown({
	dataTestTag,
	disabled,
	helperText,
	items,
	label,
	labelSx,
	labelVariant,
	menuProps,
	onChange,
	placeholder,
	selected,
	selectSX,
	sx,
	variant,
	tabIndex
}) {
	const SoraTheme = useTheme();

	// extracting body1 from Theme since its the only thing used Here.
	const { body1 } = SoraTheme.typography;

	// cache handleChange so that it only gets created Once.
	const handleChange = useCallback(
		({ target: { value } }) => onChange(value),
		[selected]
	);

	// cache menuItems so that it only creates new ones when items change
	const menuItems = useMemo(
		() =>
			items.map((menuItem) => {
				const isNode = isValidElement(menuItem);
				const uniqueIdentifier = uuidv4();
				const colorForCurrentMenuItem =
					selected !== menuItem
						? 'primary.disabled'
						: 'primary.highEmphasis';

				const styleForCurrentMenuItem = {
					...body1,
					color: colorForCurrentMenuItem
				};

				if (isNode) return menuItem;

				return (
					<MenuItem
						key={uniqueIdentifier}
						value={menuItem}
						style={styleForCurrentMenuItem}
						data-test={`${menuItem}`}
					>
						{menuItem}
					</MenuItem>
				);
			}),
		[items]
	);

	// cached the styling to it doesn't create new ones between renders
	const boxContainerStyles = useMemo(() => {
		if (variant === 'outlined')
			return { boxShadow: 'none', borderRadius: 1 };
		return {
			boxShadow:
				'0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);',
			borderRadius: 0
		};
	}, [variant]);

	return (
		<Box sx={sx}>
			<DropdownLabel
				labelText={label}
				sx={labelSx}
				labelVariant={labelVariant}
			/>

			<Select
				disabled={disabled}
				displayEmpty
				value={selected}
				onChange={handleChange}
				renderValue={(s) => {
					if (!s) return placeholder;
					return s;
				}}
				MenuProps={menuProps}
				inputProps={{
					tabIndex,
					'aria-label': 'Without label',
					'data-test': dataTestTag
				}}
				sx={{
					width: '100%',
					...boxContainerStyles,
					...selectSX
				}}
				variant={variant === 'outlined' ? 'outlined' : 'standard'}
			>
				<MenuItem disabled value="">
					{placeholder}
				</MenuItem>
				{menuItems}
			</Select>

			{helperText && (
				<FormHelperText
					variant="outlined"
					color="primary.disabled"
					sx={{
						marginLeft: 2
					}}
				>
					{helperText}
				</FormHelperText>
			)}
		</Box>
	);
}

Dropdown.propTypes = {
	dataTestTag: PropTypes.string,
	disabled: PropTypes.bool,
	helperText: PropTypes.string,
	items: PropTypes.arrayOf(
		PropTypes.oneOfType([PropTypes.string, PropTypes.element])
	).isRequired,
	label: PropTypes.string,
	labelSx: CommonPropTypes.sx,
	labelVariant: PropTypes.string,
	// eslint-disable-next-line react/forbid-prop-types
	menuProps: PropTypes.object,
	onChange: PropTypes.func.isRequired,
	placeholder: PropTypes.string,
	selected: PropTypes.string.isRequired,
	selectSX: CommonPropTypes.sx,
	sx: CommonPropTypes.sx,
	variant: PropTypes.oneOf(['outlined', 'shadowed'])
};

Dropdown.defaultProps = {
	dataTestTag: 'menu',
	disabled: false,
	helperText: null,
	label: null,
	labelVariant: 'subtitle2',
	menuProps: {
		PaperProps: {
			style: {
				maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
				width: 280
			}
		}
	},
	placeholder: '',
	sx: {},
	selectSX: {},
	variant: 'outlined',
	labelSx: {}
};

export default Dropdown;
