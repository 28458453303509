import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

// Our Axios
import axiosInstance from 'services/API/API';

// Our Endpoint
import { getWalletBurnDown } from 'shared/api-urls';

// Our Query Keys
import { BURN_DOWN_CHART_DATA } from 'shared/query-keys';

function useGetBurnDownChart() {
	const { clientId } = useParams();

	return useQuery([BURN_DOWN_CHART_DATA, clientId], async () => {
		const dynamicUrl = clientId
			? `${getWalletBurnDown}/${clientId}`
			: getWalletBurnDown;

		const response = await axiosInstance.get(dynamicUrl);

		return response.data.data;
	});
}

export default useGetBurnDownChart;
