import { useTheme } from '@emotion/react';
import { Menu, MenuItem, TextField } from '@mui/material';
import { useState } from 'react';
import PropTypes from 'prop-types';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useQueryClient } from 'react-query';
import { v4 as uuidv4 } from 'uuid';

// Our components
import Alert from 'components/Alert';
import { TertiaryButton, PrimaryButton } from 'components/Button/Button';
import Modal from 'components/Modal/Modal';

// query keys
import { SUCCESS_MESSAGE_DATA } from 'shared/query-keys';

function ContactClientDropdownButton({ clientName, advisorName }) {
	const SoraTheme = useTheme();
	const queryClient = useQueryClient();
	const { primary } = SoraTheme.palette;
	const [showModal, setShowModal] = useState(false);
	const [showAlert, setShowAlert] = useState(false);
	const [anchorEl, setAnchorEl] = useState(null);
	const [popoverOpen, setPopoverOpen] = useState(false);
	const [templateText, setTemplateText] = useState(
		'This will be template text provided for the selected email.'
	);

	const handleMenuOpen = (e) => {
		setAnchorEl(e.target);
		setPopoverOpen(true);
	};

	const handleMenuClose = () => {
		setPopoverOpen(false);
		setAnchorEl(undefined);
		setShowAlert(false);
	};

	// Current email template options
	const menuOptions = [
		'Lending Support Request',
		'Lines of Credit: Sora Overview',
		'Student Loans: Sora Overview',
		'New Mortgage: Sora Introduction',
		'New Loan / ReFi: Sora Introduction'
	];

	// based on the selected menu item, we set the template text to the correct template to then be displayed within a modal
	const handleMenuItemSelect = (e) => {
		if (e.target.innerHTML.includes('Lending Support Request')) {
			setTemplateText(
				`${clientName}, \n \nReaching out to let you know that I recently began working with Sora Finance (www.sorafinance.com) for my client's lending needs. They can help you shop a number of lenders for competitive rates on your home loan. \n \nIf you are looking for a new loan of any kind, let me know and I can introduce you to Sora. I also use Sora in my business to manage and optimize my client liabilities. Sora constantly monitors your mortgages, auto loans, student loans, and other lines of credit for opportunities to save you interest on payments. \n \nSo feel free to let me know if you are looking for support on those too. \n \nBest, \n${advisorName}`
			);
		} else if (e.target.innerHTML.includes('Lines of Credit')) {
			setTemplateText(
				`${clientName}, \n \nReaching out to let you know that I recently began working with Sora Finance (www.sorafinance.com) to enable lines of credit for my clients. Lines of credit, whether personal or backed by your home equity (HELOC), allow you to access capital quickly when you need it. \n \nThey typically cost nothing if not drawn down, but give you the flexibility to make the most financial optimal decisions. Moreover, by partnering with Sora, I can get you the best rates so that if you need to utilize leverage, you won't be overpaying in interest. Let me know if I can help you open a line of credit or if you'd like to learn more. \n \nBest, \n${advisorName}`
			);
		} else if (e.target.innerHTML.includes('Student Loans')) {
			setTemplateText(
				`${clientName}, \n \nReaching out to let you know that I recently began working with Sora Finance (www.sorafinance.com) to access student loan experts for my clients. Given federal student loan payments are set to restart on October 1 (interest will begin accruing again on September 1), let me know if you would like to meet with the Sora team. \n \nSora can provide you with a personalized plan that takes into account all options - income based repayment, potential forgiveness options, and refinancing (if appropriate) by scanning a large number of lenders. \n \nBest, \n${advisorName}`
			);
		} else if (e.target.innerHTML.includes('New Mortgage')) {
			setTemplateText(
				`${clientName}, \n \nPlease meet the team at Sora Finance (www.sorafinance.com). They can help you shop a number of lenders for competitive rates on a new mortgage. Sora also promises to refinance any new home loan anytime in the next 5 years for no cost! \n \nWe use Sora to manage and optimize client liabilities. Sora constantly monitors your mortgages, auto loans, student loans, and other lines of credit for opportunities to save you interest on payments. \n \nHope you all have a chance to connect. \n \nBest, \n${advisorName}`
			);
		} else if (e.target.innerHTML.includes('New Loan / ReFi')) {
			setTemplateText(
				`${clientName}, \n \nPlease meet the team at Sora Finance (www.sorafinance.com). They can help you shop a number of lenders for competitive rates on any new loan or for a refinance (home, auto, student, personal). \n \nWe use Sora to manage and optimize client liabilities. Sora constantly monitors your mortgages, auto loans, student loans, and other lines of credit for opportunities to save you interest on payments. \nHope you all have a chance to connect. \n \nBest, \n${advisorName}`
			);
		}
		// Closes template selection menu
		setPopoverOpen(false);
		// Removes anchor for menu
		setAnchorEl(undefined);
		// Resets the alert to false incase one is displayed for some reason
		setShowAlert(false);
		// Opens the template model where advisor can copy template text to their clipboard
		setShowModal(true);
	};

	const copyToClipboard = () => {
		// Sets the success message
		queryClient.setQueryData(
			SUCCESS_MESSAGE_DATA,
			'Copied template text to clipboard. Paste it into your email client to edit and send it to your client.'
		);
		// Copy templateText to clipboard
		navigator.clipboard.writeText(templateText);
		// Shows the alert/success message
		setShowAlert(true);
		// Hides the copy template modal
		setShowModal(false);
	};

	return (
		<>
			{showAlert && <Alert variant="success" />}
			<TertiaryButton
				sx={{ minHeight: 40, marginRight: 4 }}
				id="basic-button"
				aria-controls={popoverOpen ? 'basic-menu' : undefined}
				aria-haspopup="true"
				aria-expanded={popoverOpen ? 'true' : undefined}
				onClick={handleMenuOpen}
			>
				Contact Client
				<KeyboardArrowDownIcon
					sx={{ color: primary.white, marginLeft: 1 }}
				/>
			</TertiaryButton>
			<Menu
				id="basic-menu"
				anchorEl={anchorEl}
				open={popoverOpen}
				onClose={handleMenuClose}
				MenuListProps={{
					'aria-labelledby': 'basic-button'
				}}
			>
				{menuOptions.map((option) => (
					<MenuItem
						key={uuidv4()}
						onClick={handleMenuItemSelect}
						sx={{ padding: 2 }}
					>
						{option}
					</MenuItem>
				))}
			</Menu>
			<Modal
				isOpen={showModal}
				handleClose={() => setShowModal(false)}
				title="Here's an Email Template to Send to Your Client"
				subtitle="Copy this text, paste it in your email client and send it to your client"
			>
				<TextField
					disabled
					multiline
					fullWidth
					value={templateText}
					sx={{ marginBottom: 4 }}
				/>

				<PrimaryButton onClick={copyToClipboard}>
					Copy Text
				</PrimaryButton>
			</Modal>
		</>
	);
}

ContactClientDropdownButton.propTypes = {
	clientName: PropTypes.string.isRequired,
	advisorName: PropTypes.string.isRequired
};

export default ContactClientDropdownButton;
