import { useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Box, Typography } from '@mui/material';

// Our Components
import CurrencyTextInput from 'components/Input/CurrencyTextInput';
import Dropdown from 'components/Dropdown/Dropdown';
import RateInput from 'components/Input/RateInput';
import YearInput from 'components/Input/YearInput';
import ResponsiveTable from 'components/Table/ResponsiveTable';
import SoraToolTip from 'components/Tooltip/index';

// Local Constants
const DEFAULT_TABLE_CELL_HEIGHT = 40;

// Table Columns
const TABLE_COLUMNS = [
	'Asset Category',
	'Asset Type',
	'Present value',
	'Annual growth',
	{
		props: {},
		value: (
			<Box
				sx={{
					display: 'flex',
					alignItems: 'center'
				}}
			>
				<Typography variant="body2" sx={{ marginRight: 1 }}>
					Duration of growth
				</Typography>
				<SoraToolTip text="By default, this tool assumes that all of your clients assets will grow at the specified rates in perpetuity (i.e., they will have ‘unlimited’ growth).  If you would like to limit the number of years an asset will grow in value, please select the 'limited' option and fill in the appropriate number of years in the rightmost column" />
			</Box>
		)
	},
	'Years'
];

function DetailedAssetsTable({ rowsState, setRowsState }) {
	const rows = useMemo(
		() =>
			rowsState.map((rowState, index) => {
				const hasAssetCategory = index % 2 === 0;
				const rowsWithState = [
					rowState.assetType,
					{
						props: {},
						value: (
							// presentValue
							<CurrencyTextInput
								sx={{
									height: DEFAULT_TABLE_CELL_HEIGHT
								}}
								inputSx={{ height: DEFAULT_TABLE_CELL_HEIGHT }}
								value={rowState.presentValue}
								onChange={(value) => {
									const newRowsState = [...rowsState];
									newRowsState[index].presentValue = value;
									setRowsState(newRowsState);
								}}
							/>
						),
						key: uuidv4()
					},
					{
						props: {},
						value: (
							// firstPeriodAnnualGrowth
							<RateInput
								label=""
								subLabel=""
								sx={{
									height: DEFAULT_TABLE_CELL_HEIGHT
								}}
								inputSx={{ height: DEFAULT_TABLE_CELL_HEIGHT }}
								rate={rowState.firstPeriodAnnualGrowth}
								setRate={(value) => {
									const newRowsState = [...rowsState];
									newRowsState[
										index
									].firstPeriodAnnualGrowth = value;
									setRowsState(newRowsState);
								}}
							/>
						),
						key: uuidv4()
					},
					{
						props: {},
						value: (
							// durationofGrowth
							<Dropdown
								sx={{
									height: DEFAULT_TABLE_CELL_HEIGHT
								}}
								selectSX={{
									height: DEFAULT_TABLE_CELL_HEIGHT
								}}
								menuProps={{
									PaperProps: {
										sx: {}
									}
								}}
								items={['Unlimited', 'Limited']}
								selected={rowState.durationOfGrowth}
								onChange={(value) => {
									const newRowsState = [...rowsState];
									newRowsState[index].durationOfGrowth =
										value;
									newRowsState[index].years = '';
									setRowsState(newRowsState);
								}}
							/>
						)
					},
					{
						props: {},
						value: (
							// Years
							<YearInput
								disabled={
									rowState.durationOfGrowth === 'Unlimited'
								}
								sx={{
									height: DEFAULT_TABLE_CELL_HEIGHT
								}}
								value={rowState.years}
								onChange={(value) => {
									const newRowsState = [...rowsState];
									newRowsState[index].years = value;
									setRowsState(newRowsState);
								}}
							/>
						),
						key: uuidv4()
					}
				];

				// If the row has an asset category, add it to the beginning of the row
				return hasAssetCategory
					? [
							{
								value: rowState.assetCategory,
								props: {
									rowSpan: 2,
									sx: { backgroundColor: '#A8C5DA' }
								},
								key: uuidv4()
							},
							...rowsWithState
					  ]
					: rowsWithState;
			}),
		[rowsState]
	);

	return (
		<ResponsiveTable
			sx={{ marginTop: 4 }}
			columnCellSx={{
				border: '0.25px #1C1C1C solid',
				background: 'rgba(38, 131, 194, 0.10)'
			}}
			cellSx={{
				border: '0.25px #1C1C1C solid'
			}}
			ContainerComponent={null}
			columns={TABLE_COLUMNS}
			rows={rows}
			makeLastBold={false}
		/>
	);
}

export default DetailedAssetsTable;
