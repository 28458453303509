import { Box } from '@mui/material';

// our Components
import Alert from 'components/Alert/index';
import Loader from 'components/Loader/index';
import { PrimaryButton, SecondaryButton } from 'components/Button/Button';
import Modal from 'components/Modal/Modal';

// Our hooks
import useMutateDeleteLiability from 'hooks/liability/mutations/useMutateDeleteLiability';
import useMutateDeleteCommercialLiability from 'hooks/liability/commercial/useMutateDeleteCommercialLiability';

// Our Utils
import normalizeLoanType from 'shared/utils/clientOnboarding/normalizeLoanType';
import { dollarFormatter } from 'shared/utils';

// our constants
import { CREDITCARD, HELOC, MORTGAGE } from 'shared/constants';

// Helper functions
const getFormattedLiabilityType = (liabilityType) => {
	// These three are the exception to the rule.
	// The exception being noramlizeLoanType does the following
	// AUTO -> Auto. Since Formated Mortgage should be Home we instead return Home instead of the output of normalizeLoanType
	if (liabilityType === MORTGAGE) return 'Home';
	if (liabilityType === CREDITCARD) return 'Credit card';
	if (liabilityType === HELOC) return 'HELOC';

	const formattedLiabilityType = normalizeLoanType(liabilityType);

	return formattedLiabilityType;
};

function DeleteLiabilityModal({ isModalOpen, handleClose, liabilitySelected }) {
	const deleteLiability = useMutateDeleteLiability(handleClose);
	const deleteCommercialLiability =
		useMutateDeleteCommercialLiability(handleClose);

	const { isLoading, isSuccess, isError } = deleteLiability;

	const isDeleteLoading = isLoading || deleteCommercialLiability.isLoading;
	const isDeleteSuccess = isSuccess || deleteCommercialLiability.isSuccess;
	const isDeleteError = isError || deleteCommercialLiability.isError;

	if (liabilitySelected !== null) {
		const { interestRate, lender, outstandingBalance, tradeLineType } =
			liabilitySelected;

		const formattedLiabilityType = getFormattedLiabilityType(tradeLineType);
		const formattedBalance = dollarFormatter.format(outstandingBalance);

		return (
			<Modal
				title="Are you sure you want to delete this liability?"
				isOpen={isModalOpen}
				handleClose={handleClose}
			>
				{formattedLiabilityType} - {lender} - {interestRate}% -{' '}
				{formattedBalance}
				<Box sx={{ marginTop: 2, display: 'flex', gap: 2 }}>
					{isDeleteLoading && <Loader />}
					{!isDeleteLoading && (
						<PrimaryButton
							onClick={() => {
								const isSafeToDelete =
									liabilitySelected &&
									liabilitySelected?.tradelineId;

								if (isSafeToDelete) {
									const formattedTradelineType =
										liabilitySelected.formattedLiabilityType;

									if (
										formattedTradelineType === 'Commercial'
									) {
										deleteCommercialLiability.mutate(
											liabilitySelected?.tradelineId
										);
									} else {
										deleteLiability.mutate(
											liabilitySelected?.tradelineId
										);
									}
								}
							}}
						>
							Yes
						</PrimaryButton>
					)}

					<SecondaryButton onClick={handleClose}>No</SecondaryButton>
				</Box>
			</Modal>
		);
	}
	return (
		<>
			{isDeleteSuccess && <Alert variant="success" />}
			{isDeleteError && <Alert variant="error" />}
		</>
	);
}

export default DeleteLiabilityModal;
