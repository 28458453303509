import { useMutation, useQueryClient } from 'react-query';

// our axios
import axiosInstance from 'services/API/API';

// Our Endpoints
import { loanRequestUrl } from 'shared/api-urls';

// our query keys
import { ERROR_MESSAGE_DATA, LOAN_REQUEST } from 'shared/query-keys';

function useMutateUpdateLoanInquiry() {
	const queryClient = useQueryClient();
	return useMutation(
		async ({ loanRequestId, updatedLoanInquiryData }) => {
			const response = await axiosInstance.patch(
				`${loanRequestUrl}/${loanRequestId}`,
				updatedLoanInquiryData
			);

			return response.data;
		},
		{
			onSuccess: (loanRequestId) => {
				queryClient.refetchQueries({
					queryKey: LOAN_REQUEST,
					loanRequestId
				});
			}
		},
		{
			onError: (error) => {
				queryClient.setQueryData(ERROR_MESSAGE_DATA, error);
			}
		}
	);
}

export default useMutateUpdateLoanInquiry;
