import { Box, Typography } from '@mui/material';

// Our Assets
import SoraLogo from 'assets/images/sora_logos/SoraLogoBlack.svg';

function MobileLoginFallback() {
	return (
		<>
			<Box
				sx={{
					width: '100%',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center'
				}}
			>
				<Box
					component="img"
					src={SoraLogo}
					alt="Sora Logo"
					sx={{ width: 106, height: 40, marginBottom: 3 }}
				/>
			</Box>
			<Typography variant="h3Gascogne" sx={{ textAlign: 'center' }}>
				For an optimal experience, <br />
				please visit our site from a<br />
				computer or tablet.
			</Typography>
		</>
	);
}

export default MobileLoginFallback;
