import { BarChart, Bar, CartesianGrid, XAxis, YAxis, Legend } from 'recharts';
import { useTheme } from '@emotion/react';
import { v4 as uuidv4 } from 'uuid';

// Our Hooks
import useGetWalletOverview from 'hooks/soraWallet/useGetWalletOverview';

// Our Utils
import normalizeLoanType from 'shared/utils/clientOnboarding/normalizeLoanType';
import { dollarFormatter } from 'shared/utils';

// Our Constants
import { CREDITCARD } from 'shared/constants';

const names = ['0-5%', '5-10%', '10-15%', '15+%'];

const getBucketIndex = (interestRate) => {
	if (interestRate <= 5) return 0;
	if (interestRate <= 10) return 1;
	if (interestRate <= 15) return 2;

	return 3;
};

const generateInterestRateCalculation = (liabilities, chartColors) => {
	const interestRateGraph = [new Map(), new Map(), new Map(), new Map()];

	liabilities.map(({ tradeLineType, outstandingBalance, interestRate }) => {
		const liabilityBucketIndex = getBucketIndex(interestRate);
		const relevantMap = interestRateGraph[liabilityBucketIndex];

		relevantMap.set(
			tradeLineType,
			relevantMap.has(tradeLineType)
				? relevantMap.get(tradeLineType) + outstandingBalance
				: outstandingBalance
		);

		return tradeLineType;
	});

	const formattedInterestRateGraph = interestRateGraph.map(
		(hashMap, index) => {
			const currentBucketName = names[index];
			const currentBucket = { name: currentBucketName };

			hashMap.forEach((value, tradeLineType) => {
				const formattedLoanType =
					tradeLineType === CREDITCARD
						? 'Credit Card'
						: normalizeLoanType(tradeLineType);

				const colorForLiability =
					chartColors[tradeLineType.toLowerCase()] ?? 'red;';

				currentBucket[formattedLoanType] = value;

				currentBucket.color = colorForLiability;
			});

			return currentBucket;
		}
	);

	return formattedInterestRateGraph;
};

const renderColorfulLegendText = (value) => (
	<span style={{ color: 'black', fontSize: '0.75rem' }}>{value}</span>
);

function ExportableReportInterestRateChart() {
	const { data: liabilityData, isLoading } = useGetWalletOverview();
	const SoraTheme = useTheme();

	const { chart } = SoraTheme.palette;

	if (isLoading) return <h1>Loading...</h1>;

	const { liability } = liabilityData;

	const { liabilities } = liability;

	const barData = generateInterestRateCalculation(liabilities, chart);

	const dynamicCharts = Object.keys(chart).map((currentKey) => (
		<Bar
			key={uuidv4()}
			dataKey={
				currentKey === 'creditcard'
					? 'Credit Card'
					: normalizeLoanType(currentKey)
			}
			fill={chart[currentKey]}
		/>
	));

	return (
		<BarChart
			barCategoryGap={1}
			barGap={0}
			width={700}
			height={260}
			data={barData}
		>
			<CartesianGrid strokeDasharray="3 3" />
			<XAxis
				dataKey="name"
				tick={{ fill: 'black', fontSize: '0.75rem' }}
			/>
			<YAxis
				// scale="log"
				// domain={[1, 'auto']}
				tickFormatter={(value) => {
					const formattedY = dollarFormatter.format(value);
					return formattedY;
				}}
				tick={{ fill: 'black', fontSize: '0.75rem' }}
				width={110}
			/>

			<Legend formatter={renderColorfulLegendText} />
			{dynamicCharts}
		</BarChart>
	);
}

export default ExportableReportInterestRateChart;
