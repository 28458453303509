import { Box, Typography, Grid } from '@mui/material';
import { useMemo, useState, useEffect } from 'react';
import { useQueryClient } from 'react-query';

// Our Components
import Modal from 'components/Modal/Modal';
import { SecondaryButton, TertiaryButton } from 'components/Button/Button';
import ColumnItem from 'components/DataTable/ColumnItem';
import DataTable from 'components/DataTable/DataTable';
import ColumnVerticalHeader from 'components/DataTable/ColumnVerticalHeader';

// Our Utils
import { dollarFormatter, decodedLoanType } from 'shared/utils';

// Our Hooks 🪝
import useMutateUpdateLoanRequestStatus from 'hooks/newLoanInquiries/useMutateUpdateLoanRequestStatus';
import useMutateSendGenericEmail from 'hooks/emails/useMutateSendGenericEmail';
import useMutateUpdateChosenOffer from 'hooks/newLoanInquiries/useMutateUpdatedChosenOffer';

import { ERROR_MESSAGE_DATA, USERPROFILEDATA } from 'shared/query-keys';
import { ADVISOR_LOAN_REQUEST_STATUS_CONSTANTS } from 'shared/constants';

function NewLoanOptionsTable({
	clientData,
	loanOffers,
	loanRequestId,
	newLoanDetails
}) {
	const queryClient = useQueryClient();

	const updateLoanRequestStatus = useMutateUpdateLoanRequestStatus();

	const addChosenOfferToLoanInquiry = useMutateUpdateChosenOffer();

	const [isRequestReviewModalOpen, setIsRequestReviewModalOpen] =
		useState(false);

	const [selectedLoanOfferData, setSelectedLoanOfferData] = useState({});
	const [isFormCompleted, setIsFormCompleted] = useState(false);

	const { clientName, clientAddress } = clientData;

	// TODO: Refactor this table so that undefined isn't passed in as a string to begin with.
	const isUnSafeAddress = clientAddress.includes('undefined');

	const advisorProfileData = queryClient.getQueryData(USERPROFILEDATA);

	const loanType = decodedLoanType(
		newLoanDetails?.tradelineType,
		newLoanDetails?.offerType
	);

	// eslint-disable-next-line consistent-return
	useEffect(() => {
		let requiredFields = [false];

		if (loanType === 'New Mortgage Loan') {
			requiredFields = [
				newLoanDetails?.propertyType,
				newLoanDetails?.primaryUse,
				newLoanDetails?.totalAnnualIncome,
				newLoanDetails?.downPaymentPercent,
				newLoanDetails?.estimatedPurchaseValue,
				newLoanDetails?.loanAmount,
				newLoanDetails?.ownershipTenure,
				newLoanDetails?.city,
				newLoanDetails?.state,
				newLoanDetails?.zipCode
			];
		}

		if (loanType === 'Mortgage Refinance Loan') {
			requiredFields = [
				newLoanDetails?.propertyType,
				newLoanDetails?.primaryUse,
				newLoanDetails?.loanAmount,
				newLoanDetails?.currentInterestRate,
				newLoanDetails?.ownershipTenure,
				newLoanDetails?.city,
				newLoanDetails?.state,
				newLoanDetails?.zipCode,
				newLoanDetails?.remainingTerm,
				newLoanDetails?.totalAnnualIncome
			];
		}

		if (loanType === 'New Automotive Loan') {
			requiredFields = [
				newLoanDetails?.state,
				newLoanDetails?.estimatedPurchaseValue,
				newLoanDetails?.downPaymentPercent,
				newLoanDetails?.loanAmount,
				newLoanDetails?.loanTenure,
				newLoanDetails?.yearOfVehicle
			];
		}

		if (loanType === 'Automotive Refinance Loan') {
			requiredFields = [
				newLoanDetails?.loanAmount,
				newLoanDetails?.currentInterestRate,
				newLoanDetails?.currentRemainingTerm,
				newLoanDetails?.state,
				newLoanDetails?.vehicleVINNumber,
				newLoanDetails?.vehicleMileage
			];
		}

		if (loanType === 'New Personal Loan') {
			requiredFields = [
				newLoanDetails?.totalAnnualIncome,
				newLoanDetails?.totalAssetsValue,
				newLoanDetails?.loanAmount,
				newLoanDetails?.rentOrOwn,
				newLoanDetails?.monthlyHousingPayment,
				newLoanDetails?.employmentStatus,
				newLoanDetails?.startDate,
				newLoanDetails?.addressLine1,
				newLoanDetails?.city,
				newLoanDetails?.state,
				newLoanDetails?.zipCode
			];
		}

		if (loanType === 'Personal Refinance Loan') {
			requiredFields = [
				newLoanDetails?.totalAnnualIncome,
				newLoanDetails?.totalAssetsValue,
				newLoanDetails?.currentLoanOutstandingBalance,
				newLoanDetails?.currentInterestRate,
				newLoanDetails?.currentTerm,
				newLoanDetails?.rentOrOwn,
				newLoanDetails?.monthlyHousingPayment,
				newLoanDetails?.employmentStatus,
				newLoanDetails?.startDate,
				newLoanDetails?.addressLine1,
				newLoanDetails?.city,
				newLoanDetails?.state,
				newLoanDetails?.zipCode
			];
		}

		const allFieldsCompleted = requiredFields.map((singleField) => {
			if (singleField === undefined || singleField === '') {
				return false;
			}
			return true;
		});

		// If all form fields are completed and the form is current in the pending_info status, since all info is present
		// update the loan status
		if (
			!allFieldsCompleted.includes(false) &&
			newLoanDetails?.faStatus === 'PENDING_INFO'
		) {
			const payload = {
				newLoanId: loanRequestId,
				faStatus: ADVISOR_LOAN_REQUEST_STATUS_CONSTANTS.FA_SEARCH_RATES,
				adminStatus: ADVISOR_LOAN_REQUEST_STATUS_CONSTANTS.SEARCH_RATES
			};

			updateLoanRequestStatus.mutate(payload, {
				onSuccess: () => {
					setIsRequestReviewModalOpen(false);
				}
			});
		}

		return allFieldsCompleted.includes(false)
			? setIsFormCompleted(false)
			: setIsFormCompleted(true);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [loanRequestId, loanType, newLoanDetails]);

	const sendGenericEmail = useMutateSendGenericEmail();
	const triggerGenericEmail = () => {
		const sendToArray = ['lending@sorafinance.com'];
		const subject = 'Loan Request Submitted For Review';
		const firstName = clientName;
		const advisorFullName = `${advisorProfileData?.firstName} ${advisorProfileData?.lastName}`;
		const advisorEmail = advisorProfileData?.email;

		const selectedLoanType = selectedLoanOfferData?.type;
		const selectedLoanAmount = selectedLoanOfferData?.amount;
		const selectedLender = selectedLoanOfferData?.lender;
		const selectedInterestRate = selectedLoanOfferData?.rate;

		const message = `<pre>
		The advisor ${advisorFullName} has request to move forward with a new loan or refinance request. This loan request needs to be reviewed.

		Advisor making request:
		Advisor's name: ${advisorFullName}
		Advisor's email: ${advisorEmail}
		
		They made a request the following CLIENT and loan:

		Client's name: ${firstName}

		Client's selected offer: ${selectedLoanType}
		Client's selected loan amount: ${selectedLoanAmount}
		Client's selected lender: ${selectedLender}
		Client's selected annual interest rate: ${selectedInterestRate}

		<a href="https://admin.sorafinance.com/">Log into the Admin portal</a>
		
		</pre>
	`;

		sendGenericEmail.mutate({ firstName, subject, message, sendToArray });
	};

	const updateLoanRequestWithSelectedOfferId = () => {
		const acceptedOfferId = selectedLoanOfferData?.acceptedOfferId;
		const offeredBySora =
			selectedLoanOfferData?.offeredBySora === 'sora' ? 'Y' : 'N';

		// Call mutation to update user's data
		addChosenOfferToLoanInquiry.mutate(
			{
				newLoanId: loanRequestId,
				offerId: acceptedOfferId,
				offeredBySora
			},
			{
				onError: () => {
					// Set error message
					queryClient.setQueryData(
						ERROR_MESSAGE_DATA,
						'There was an error updating your loan request.'
					);
				}
			}
		);
	};

	const columnsOffers = useMemo(
		() => [
			ColumnItem('lender', 'Lender', 2, {
				renderCell: (params) => {
					const lender = params.value;
					return (
						<Typography
							variant="body1"
							sx={{
								color: 'transparent',
								textShadow: '0 0 8px #000'
							}}
						>
							{lender}
						</Typography>
					);
				}
			}),
			ColumnItem('loanType', 'Loan Type', 2, {
				renderCell: () => (
					<Typography variant="body1">{loanType}</Typography>
				)
			}),
			ColumnItem('rate', 'Rate', 1.1, {
				valueFormatter: (params) => {
					const rate = params.value;
					if (!rate && rate !== 0) return 'NA';
					return `${rate} %`;
				},
				renderHeader: ({ colDef }) => (
					<ColumnVerticalHeader headerName={colDef.headerName} />
				)
			}),
			ColumnItem('upfrontCost', 'Upfront Costs', 0.7, {
				valueFormatter: (params) => {
					const upfrontCost = params.value;
					if (!upfrontCost && upfrontCost !== 0) return 'NA';
					if (upfrontCost === 0) return '$0';
					return dollarFormatter.format(upfrontCost);
				},
				renderHeader: ({ colDef }) => (
					<ColumnVerticalHeader headerName={colDef.headerName} />
				)
			}),
			ColumnItem('monthlyPayment', 'Monthly Payment', 1.1, {
				valueFormatter: (params) => {
					const monthlyPayment = params.value;
					if (!monthlyPayment && monthlyPayment !== 0) return 'NA';
					if (monthlyPayment === 0) return '$0';
					return dollarFormatter.format(monthlyPayment);
				},
				renderHeader: ({ colDef }) => (
					<ColumnVerticalHeader headerName={colDef.headerName} />
				)
			}),
			ColumnItem('request', 'Action', 1, {
				// eslint-disable-next-line react/no-unstable-nested-components
				renderHeader: ({ colDef }) => (
					<ColumnVerticalHeader headerName={colDef.headerName} />
				),
				renderCell: (params) => {
					const rowData = params.row;
					const amount = rowData?.loanAmount;
					const duration = rowData?.loanTerm;
					const rate = rowData?.rate;
					const lender = rowData?.lender;
					const monthlyPayment = rowData?.monthlyPayment;
					const acceptedOfferId = rowData?.id;
					const offeredBySora = rowData?.offeredBy;

					return (
						<TertiaryButton
							variant="contained"
							onClick={() => {
								setSelectedLoanOfferData({
									acceptedOfferId,
									amount,
									duration,
									lender,
									loanType,
									monthlyPayment,
									offeredBySora,
									rate
								});
								setIsRequestReviewModalOpen(true);
							}}
							disabled={!isFormCompleted}
						>
							Request
						</TertiaryButton>
					);
				}
			})
		],
		[isFormCompleted, loanType]
	);

	return (
		<>
			{!isFormCompleted && (
				<Typography
					variant="body1"
					component="p"
					gutterBottom
					sx={{
						textAlign: 'center',
						marginTop: 10
					}}
				>
					Please complete missing information so Sora can search for
					the best market rates for you.
				</Typography>
			)}
			{isFormCompleted && newLoanDetails?.faStatus === 'UNDER_REVIEW' && (
				<Typography
					variant="body1"
					component="p"
					gutterBottom
					sx={{
						textAlign: 'center',
						marginTop: 10
					}}
				>
					We&apos;re working to process your loan request.
				</Typography>
			)}

			{isFormCompleted &&
				newLoanDetails?.faStatus === 'FA_SEARCH_RATES' &&
				loanOffers?.length < 1 && (
					<Typography
						variant="body1"
						component="p"
						gutterBottom
						sx={{
							textAlign: 'center',
							marginTop: 10
						}}
					>
						We&apos;re working to get the best market rates for you.{' '}
						<br />
						Please check in again soon.
					</Typography>
				)}
			{isFormCompleted &&
				newLoanDetails?.faStatus === 'FA_SEARCH_RATES' &&
				loanOffers?.length >= 1 && (
					<>
						<Box
							sx={{
								width: '100%'
							}}
						>
							<DataTable
								columns={columnsOffers}
								rows={loanOffers}
								pageSize={10}
								rowsPerPageOptions={10}
								handleRowClick={() => {}}
								disableSelectionOnClick
							/>
						</Box>

						{/* Review Request Modal */}
						<Modal
							title="Please review and confirm the information provided is correct"
							isOpen={isRequestReviewModalOpen}
							handleClose={() =>
								setIsRequestReviewModalOpen(false)
							}
						>
							<Grid container sx={{ marginTop: 4 }}>
								<Grid
									item
									xs={12}
									md={6}
									sx={{ marginBottom: 4 }}
								>
									<Typography
										variant="h6"
										sx={{ marginBottom: 4 }}
									>
										Client Details:
									</Typography>
									<Box
										sx={{
											display: 'flex',
											justifyContent: 'space-between',
											marginBottom: 2
										}}
									>
										<Box>
											<Typography variant="subtitle1">
												Client Name
											</Typography>
											<Typography variant="caption">
												{clientName}
											</Typography>
										</Box>
									</Box>

									{!isUnSafeAddress && (
										<Box
											sx={{
												display: 'flex',
												justifyContent: 'space-between'
											}}
										>
											<Box>
												<Typography variant="subtitle1">
													Address
												</Typography>
												<Typography variant="caption">
													{clientAddress}
												</Typography>
											</Box>
										</Box>
									)}
								</Grid>

								<Grid item xs={12} md={6}>
									<Typography
										variant="h6"
										sx={{ marginBottom: 4 }}
									>
										Loan Offer Selected:
									</Typography>
									<Box
										sx={{
											display: 'flex',
											justifyContent: 'space-between'
										}}
									>
										<Box>
											<Typography variant="subtitle1">
												Loan Type
											</Typography>
											<Typography variant="caption">
												{loanType}
											</Typography>

											<Typography
												variant="subtitle1"
												sx={{ marginTop: 2 }}
											>
												Loan Amount
											</Typography>
											<Typography variant="caption">
												{dollarFormatter.format(
													selectedLoanOfferData?.amount
												) || 'NA'}
											</Typography>
										</Box>
										<Box>
											<Typography
												variant="subtitle1"
												sx={{ marginRight: 2 }}
											>
												Loan Duration
											</Typography>
											<Typography variant="caption">
												{
													selectedLoanOfferData?.duration
												}
											</Typography>

											<Box>
												<Typography
													variant="subtitle1"
													sx={{ marginTop: 2 }}
												>
													Monthly Payment
												</Typography>
												<Typography variant="caption">
													{dollarFormatter.format(
														selectedLoanOfferData?.monthlyPayment
													) || 'NA'}
												</Typography>
											</Box>
										</Box>
									</Box>

									<Box
										sx={{
											display: 'flex',
											justifyContent: 'space-between',
											marginBottom: 2
										}}
									>
										<Box>
											<Typography
												variant="subtitle1"
												sx={{ marginTop: 2 }}
											>
												Annual Interest Rate
											</Typography>
											<Typography variant="caption">
												{selectedLoanOfferData?.rate}%
											</Typography>
										</Box>
									</Box>
								</Grid>
							</Grid>

							<Box sx={{ marginTop: 4 }}>
								<SecondaryButton
									onClick={() =>
										setIsRequestReviewModalOpen(false)
									}
								>
									Back
								</SecondaryButton>
								<TertiaryButton
									sx={{ marginLeft: 2 }}
									onClick={() => {
										triggerGenericEmail();
										// Also need to trigger an update to the loan request appending the acceptedOfferId to the new loan inquiry
										updateLoanRequestWithSelectedOfferId();
										updateLoanRequestStatus.mutate(
											{
												newLoanId: loanRequestId,
												faStatus:
													ADVISOR_LOAN_REQUEST_STATUS_CONSTANTS.UNDER_REVIEW,
												adminStatus:
													ADVISOR_LOAN_REQUEST_STATUS_CONSTANTS.APPROVE_REQ
											},
											{
												onSuccess: () => {
													setIsRequestReviewModalOpen(
														false
													);
												}
											}
										);
									}}
								>
									Submit
								</TertiaryButton>
							</Box>
						</Modal>
					</>
				)}
		</>
	);
}

export default NewLoanOptionsTable;
