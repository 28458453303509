import PropTypes from 'prop-types';
import MuiButton from '@mui/material/Button';
import { useTheme } from '@emotion/react';
import CommonPropTypes from 'shared/prop-types';

export function PrimaryButton({
	isDisabled,
	onClick,
	sx,
	tabIndex,
	...otherProps
}) {
	return (
		<MuiButton
			variant="contained"
			color="primary"
			sx={{
				width: 'fit-content',
				padding: '8px 16px',
				minHeight: 40,
				textTransform: 'none',
				...sx
			}}
			// 	type="submit" this change can be included later with some Button refactors
			onClick={onClick}
			disableFocusRipple
			disabled={isDisabled}
			tabIndex={tabIndex}
			{...otherProps}
		/>
	);
}
PrimaryButton.defaultProps = {
	isDisabled: false,
	onClick: () => {},
	sx: {}
};
PrimaryButton.propTypes = {
	isDisabled: PropTypes.bool,
	onClick: PropTypes.func,
	sx: CommonPropTypes.sx
};

export function SecondaryButton({ isDisabled, onClick, sx, ...otherProps }) {
	const SoraTheme = useTheme();

	return (
		<MuiButton
			variant="outlined"
			sx={{
				width: 'fit-content',
				padding: '8px 16px',
				minHeight: 40,
				textTransform: 'none',
				color: SoraTheme.palette.primary.black,
				borderColor: SoraTheme.palette.primary.black,
				fontWeight: 200,
				'&:hover': {
					borderColor: SoraTheme.palette.primary.black,
					backgroundColor: SoraTheme.palette.primary.black,
					color: SoraTheme.palette.primary.white
				},
				...sx
			}}
			onClick={onClick}
			disableFocusRipple
			disabled={isDisabled}
			{...otherProps}
		/>
	);
}
SecondaryButton.defaultProps = {
	isDisabled: false,
	onClick: () => {},
	sx: {}
};
SecondaryButton.propTypes = {
	isDisabled: PropTypes.bool,
	onClick: PropTypes.func,
	sx: CommonPropTypes.sx
};

export function TertiaryButton({
	ariaDescribedby,
	isDisabled,
	onClick,
	sx,
	...otherProps
}) {
	const SoraTheme = useTheme();

	return (
		<MuiButton
			variant="contained"
			color="primary"
			sx={{
				backgroundColor: SoraTheme.palette.primary.indigo,
				width: 'fit-content',
				fontWeight: 200,
				padding: '8px 16px',
				minHeight: 40,
				textTransform: 'none',
				...sx,
				'&:hover': {
					backgroundColor: '#204769'
				}
			}}
			aria-describedby={ariaDescribedby}
			onClick={onClick}
			disableFocusRipple
			disabled={isDisabled}
			{...otherProps}
		/>
	);
}
TertiaryButton.defaultProps = {
	isDisabled: false,
	onClick: () => {},
	sx: {},
	ariaDescribedby: ''
};
TertiaryButton.propTypes = {
	isDisabled: PropTypes.bool,
	onClick: PropTypes.func,
	sx: CommonPropTypes.sx,
	ariaDescribedby: PropTypes.string
};

export function QuaternaryButton({
	ariaDescribedby,
	isDisabled,
	onClick,
	sx,
	...otherProps
}) {
	const SoraTheme = useTheme();

	return (
		<MuiButton
			variant="outlined"
			sx={{
				backgroundColor: SoraTheme.palette.primary.white,
				textTransform: 'none',
				fontWeight: 400,
				border: `1px solid ${SoraTheme.palette.primary.black}`,
				borderRadius: '0.25rem',
				padding: '8px 16px',
				whiteSpace: 'nowrap',
				color: SoraTheme.palette.primary.black,
				...sx,
				'&:hover': {
					backgroundColor: SoraTheme.palette.primary.indigo,
					color: SoraTheme.palette.primary.white,
					border: `1px solid ${SoraTheme.palette.primary.white}`
				}
			}}
			aria-describedby={ariaDescribedby}
			onClick={onClick}
			disableFocusRipple
			disabled={isDisabled}
			{...otherProps}
		/>
	);
}
QuaternaryButton.defaultProps = {
	isDisabled: false,
	onClick: () => {},
	sx: {},
	ariaDescribedby: ''
};
QuaternaryButton.propTypes = {
	isDisabled: PropTypes.bool,
	onClick: PropTypes.func,
	sx: CommonPropTypes.sx,
	ariaDescribedby: PropTypes.string
};

export function TextButton({ onClick, sx, ...otherProps }) {
	const SoraTheme = useTheme();

	return (
		<MuiButton
			variant="text"
			sx={{
				width: 'fit-content',
				padding: '8px 0px',
				color: SoraTheme.palette.primary.black,
				textTransform: 'none',
				textDecoration: 'underline',
				...sx
			}}
			onClick={onClick}
			disableFocusRipple
			{...otherProps}
		/>
	);
}
TextButton.defaultProps = {
	onClick: () => {},
	sx: {}
};
TextButton.propTypes = {
	onClick: PropTypes.func,
	sx: CommonPropTypes.sx
};
