import {
	Box,
	Grid,
	Typography,
	Stepper,
	Paper,
	Stack,
	Step,
	StepLabel,
	StepConnector
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { Helmet } from 'react-helmet-async';
import { format } from 'date-fns';
import { useQueryClient } from 'react-query';
import { useState, useEffect, useMemo } from 'react';
import { useTheme } from '@emotion/react';

// Component
import { QuaternaryButton } from 'components/Button/Button';
import EditRequestInfoModal from 'components/Tasks/EditRequestInfoModel';
import Loader from 'components/Loader';
import LoanRequestDetailsCard from 'components/Tasks/LoanRequestDetailsCard';
import LoanRequestRelatedDocuments from 'pages/NewLoan/LoanRequestDetailsDocuments';
import NewLoanOptionsSection from 'components/LoanInquiry/NewLoanOptionsSection/NewLoanOptionsSection';
import SoraToolTip from 'components/Tooltip/index';

// Hooks
import useGetLoanRequestDetails from 'hooks/useGetLoanRequestDetails';

// Query Keys
import { USERPROFILEDATA } from 'shared/query-keys';

// Our Utils
import { decodedLoanType } from 'shared/utils';

// Our Constants
import { ADVISOR_LOAN_REQUEST_STATUS_CONSTANTS } from 'shared/constants';
import {
	STANDARD_REQUEST_STEPS,
	REJECTED_REQUEST_STEPS
} from 'shared/constants/LoanInquiry/LoanInquirySteps';

function LoanInquiryDetailsPage({ mobileView }) {
	const queryClient = useQueryClient();
	const SoraTheme = useTheme();

	const { primary } = SoraTheme.palette;

	const urlString = window.location.search;
	const urlParams = new URLSearchParams(urlString);
	const loanRequestId = urlParams.get('loanRequestId');

	const { data, isLoading, isSuccess, error } =
		useGetLoanRequestDetails(loanRequestId);

	const profileData = queryClient.getQueryData(USERPROFILEDATA);

	const [activeStep, setActiveStep] = useState(1);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isFormCompleted, setIsFormCompleted] = useState(false);

	const handleChange = () => {
		setIsModalOpen(!isModalOpen);
	};

	const loanRequestWithDocuments = useMemo(() => {
		const offerType = data?.newLoanDetails?.offerType;
		const tradelineType = data?.newLoanDetails?.tradelineType;
		const translatedLoanType = decodedLoanType(tradelineType, offerType);

		if (
			translatedLoanType === 'New Mortgage Loan' ||
			translatedLoanType === 'Mortgage Refinance Loan' ||
			translatedLoanType === 'Cash Out Refinance Loan' ||
			translatedLoanType === 'Reverse Mortgage Loan' ||
			translatedLoanType === 'Commercial Loan' ||
			translatedLoanType === 'HELOC Loan'
		) {
			return true;
		}
		return false;
	}, [data]);

	useEffect(() => {
		const currentFaStatus = data?.newLoanDetails?.faStatus;

		if (
			currentFaStatus ===
			ADVISOR_LOAN_REQUEST_STATUS_CONSTANTS.PENDING_INFO
		) {
			setActiveStep(0);
		}

		if (
			currentFaStatus ===
			ADVISOR_LOAN_REQUEST_STATUS_CONSTANTS.FA_SEARCH_RATES
		) {
			setActiveStep(1);
			setIsFormCompleted(true);
		}

		if (
			currentFaStatus ===
			ADVISOR_LOAN_REQUEST_STATUS_CONSTANTS.SELECT_RATE
		) {
			setActiveStep(2);
			setIsFormCompleted(true);
		}

		if (
			currentFaStatus ===
			ADVISOR_LOAN_REQUEST_STATUS_CONSTANTS.UNDER_REVIEW
		) {
			setActiveStep(3);
			setIsFormCompleted(true);
		}

		if (
			currentFaStatus === ADVISOR_LOAN_REQUEST_STATUS_CONSTANTS.PROCESSING
		) {
			setActiveStep(4);
			setIsFormCompleted(true);
		}

		if (
			currentFaStatus ===
				ADVISOR_LOAN_REQUEST_STATUS_CONSTANTS.FA_COMPLETED ||
			currentFaStatus ===
				ADVISOR_LOAN_REQUEST_STATUS_CONSTANTS.FA_CANCELED ||
			currentFaStatus ===
				ADVISOR_LOAN_REQUEST_STATUS_CONSTANTS.FA_REJECTED
		) {
			setActiveStep(6);
			setIsFormCompleted(true);
		}

		return false;
	}, [data]);

	if (error) {
		<>
			<Helmet>
				<title>Loan Request Details</title>
			</Helmet>
			<Typography variant="h5" sx={{ marginTop: 20 }}>
				There was an error fetching your loan request.
			</Typography>
			<Typography variant="h5" sx={{ marginTop: 2 }}>
				Please refresh the page, if issue persist please reach out to
				sora at contact@sorafinance.com
			</Typography>
		</>;
	}

	if (isLoading) {
		return (
			<>
				<Helmet>
					<title>Loan Request Details</title>
				</Helmet>
				<Grid container>
					<Grid item xs={10}>
						<Typography variant="h1Gascogne" gutterBottom>
							Loan Request Details
						</Typography>
					</Grid>

					<Grid
						item
						xs={2}
						sx={{
							alignContent: 'flex-start',
							justifyContent: 'flex-end',
							paddingRight: 2,
							marginBottom: 4
						}}
					>
						<QuaternaryButton
							disabled
							sx={{ marginTop: 1, marginRight: 4 }}
							startIcon={<AddIcon />}
						>
							Invite client to track request
						</QuaternaryButton>
					</Grid>
				</Grid>

				<Grid
					container
					item
					xs={12}
					sx={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center'
					}}
				>
					<Loader size={70} />
				</Grid>
			</>
		);
	}

	if (isSuccess) {
		const { newLoanDetails, newLoanOffers } = data;

		// Data pull off the endpoint response
		const {
			acceptedOfferId,
			acres,
			addressLine1,
			addressLine2,
			city,
			clientId,
			companyName,
			condition,
			county,
			currentMortgageRate,
			desiredLoanTerm,
			downPaymentPercent,
			employmentStatus,
			estimatedHomeValue,
			estimatedPurchaseValue,
			faStatus,
			firstName,
			interestRate,
			lastName,
			lastUpdated,
			loanAmount,
			loanTenure,
			offerType,
			outstandingBalance,
			ownershipTenure,
			ownershipType,
			currentInterestRate,
			reasonForLoan,
			state,
			timeUntilConstruction,
			totalAnnualIncome,
			totalAssetsValue,
			tradelineType,
			vehicleMileage,
			yearOfVehicle,
			zipCode
		} = newLoanDetails;

		// Data used in this component
		const formattedLastUpdated = format(
			new Date(lastUpdated),
			'MM/dd/yyyy'
		);
		const clientName = `${firstName} ${lastName}`;
		const clientAddress = `${addressLine1} ${addressLine2} ${city} ${state}, ${zipCode}`;

		const clientData = { clientName, clientAddress };

		const advisorName = `${profileData?.firstName} ${profileData?.lastName}`;
		const advisorEmail = profileData?.email;

		return (
			<>
				<Helmet>
					<title>Loan Request Details</title>
				</Helmet>

				<Grid container>
					<Grid item xs={12} md={9}>
						<Typography
							variant="h1Gascogne"
							component="h1"
							gutterBottom
							sx={{
								display: { xs: 'block', md: 'inline-block' }
							}}
						>
							Loan Request Details
						</Typography>
						<Typography
							variant="caption"
							component="p"
							gutterBottom
							sx={{
								marginBottom: 2,
								display: { xs: 'block', md: 'inline-block' },
								marginLeft: { xs: 0, md: 2 }
							}}
						>
							Last Updated: {formattedLastUpdated}
						</Typography>
					</Grid>
					<Grid
						item
						xs={12}
						md={3}
						sx={{
							alignContent: 'flex-start',
							justifyContent: 'flex-end',
							marginBottom: 4
						}}
					>
						{!mobileView && (
							<QuaternaryButton
								disabled
								sx={{ marginTop: 1 }}
								startIcon={<AddIcon />}
								// onClick={}
							>
								Invite client to track request
							</QuaternaryButton>
						)}
					</Grid>

					<Grid
						item
						xs={12}
						sx={{
							alignContent: 'flex-start',
							justifyContent: 'flex-end',
							marginBottom: 2
						}}
					>
						<Box sx={{ display: { xs: 'inherit', md: 'flex' } }}>
							<Typography
								variant="caption"
								component="p"
								gutterBottom
								sx={{ fontWeight: 'bold', marginRight: 1 }}
							>
								Client:
							</Typography>
							<Typography
								variant="caption"
								component="p"
								gutterBottom
								sx={{
									marginRight: 2,
									marginBottom: { xs: 2, md: 0 }
								}}
							>
								{clientName}
							</Typography>
							<Typography
								variant="caption"
								component="p"
								gutterBottom
								sx={{
									fontWeight: 'bold',
									marginRight: 1,
									marginTop: { xs: 2, md: 0 }
								}}
							>
								Advisor:
							</Typography>

							<Typography
								variant="caption"
								component="p"
								gutterBottom
							>
								{advisorName}
							</Typography>
						</Box>
					</Grid>

					{mobileView && (
						<Grid
							item
							xs={12}
							sx={{ marginBottom: 2, paddingRight: 4 }}
						>
							<QuaternaryButton
								disabled
								startIcon={<AddIcon />}
								// onClick={}
							>
								Invite client to track request
							</QuaternaryButton>
						</Grid>
					)}

					{/* If status is canceled, we don't show the status bar at all */}
					{faStatus !==
						ADVISOR_LOAN_REQUEST_STATUS_CONSTANTS.FA_CANCELED && (
						<Grid item xs={12}>
							<Stepper
								activeStep={activeStep}
								alternativeLabel
								sx={{
									marginTop: 4,
									marginBottom: 4,
									// Brute Force
									'&.MuiStepper-root.MuiStepper-vertical.MuiStepper-alternativeLabel':
										{ alignItems: 'center' }
								}}
								orientation={
									mobileView ? 'vertical' : 'horizontal'
								}
								connector={!mobileView ? <StepConnector /> : ''}
							>
								{/* If faStatus is rejected, use rejectedRequestSteps */}
								{faStatus ===
									ADVISOR_LOAN_REQUEST_STATUS_CONSTANTS.FA_REJECTED &&
									REJECTED_REQUEST_STEPS.map((label) => (
										<Step key={label}>
											<StepLabel complete="true">
												{label}
											</StepLabel>
										</Step>
									))}
								{/* If faStatus is NOT rejected, use standardRequestSteps */}
								{faStatus !==
									ADVISOR_LOAN_REQUEST_STATUS_CONSTANTS.FA_REJECTED &&
									STANDARD_REQUEST_STEPS.map((label) => (
										<Step key={label}>
											<StepLabel complete="true">
												{label}
											</StepLabel>
										</Step>
									))}
							</Stepper>
						</Grid>
					)}

					<Grid
						item
						xs={12}
						md={6}
						sx={{ marginBottom: 8, paddingRight: { xs: 0, md: 4 } }}
					>
						<LoanRequestDetailsCard
							acceptedOfferId={acceptedOfferId}
							acres={acres}
							addressLine1={addressLine1}
							addressLine2={addressLine2}
							cardHeading="Loan Details"
							city={city}
							clientName={clientName}
							companyName={companyName}
							condition={condition}
							county={county}
							employmentStatus={employmentStatus}
							currentMortgageRate={currentMortgageRate}
							desiredLoanTerm={desiredLoanTerm}
							downPaymentPercent={downPaymentPercent}
							estimatedHomeValue={estimatedHomeValue}
							estimatedPurchaseValue={estimatedPurchaseValue}
							interestRate={interestRate}
							loanAmount={loanAmount}
							loanTenure={loanTenure || ownershipTenure}
							mileage={vehicleMileage}
							newLoanOffers={newLoanOffers}
							newLoanDetails={newLoanDetails}
							offerType={offerType}
							outstandingBalance={outstandingBalance}
							ownershipType={ownershipType}
							rateOfCurrentMortgage={currentInterestRate}
							reasonForLoan={reasonForLoan}
							state={state}
							timeUntilConstruction={timeUntilConstruction}
							totalAnnualIncome={totalAnnualIncome}
							totalAssetsValue={totalAssetsValue}
							tradelineType={tradelineType}
							yearOfVehicle={yearOfVehicle}
							zipCode={zipCode}
						/>
					</Grid>

					{loanRequestWithDocuments && (
						<Grid
							item
							xs={12}
							md={6}
							sx={{ marginBottom: 8, paddingRight: 4 }}
						>
							<Paper
								elevation={3}
								square={false}
								component={Stack}
								sx={{
									borderRadius: '20px',
									padding: 4,
									flexDirection: 'column',
									gap: 2,
									boxSizing: 'border-box',
									height: '100%',
									position: 'relative'
								}}
							>
								<Box
									sx={{
										display: 'flex',
										flexDirection: 'row'
									}}
								>
									<Typography
										variant="h2Gascogne"
										component="h2"
										gutterBottom
										sx={{
											color: primary.indigo,
											marginRight: 4
										}}
									>
										Document Checklist
									</Typography>

									<SoraToolTip text="Sora will only share these documents with your selected lenders in order to facilitate access to best market rates" />
								</Box>

								<Grid container>
									<Grid item xs={12}>
										<LoanRequestRelatedDocuments
											loanRequestId={loanRequestId}
											clientId={clientId}
										/>
									</Grid>
								</Grid>
							</Paper>
						</Grid>
					)}

					{faStatus !==
						ADVISOR_LOAN_REQUEST_STATUS_CONSTANTS.UNDER_REVIEW &&
						faStatus !==
							ADVISOR_LOAN_REQUEST_STATUS_CONSTANTS.PROCESSING &&
						faStatus !==
							ADVISOR_LOAN_REQUEST_STATUS_CONSTANTS.FA_COMPLETED &&
						faStatus !==
							ADVISOR_LOAN_REQUEST_STATUS_CONSTANTS.FA_REJECTED &&
						faStatus !==
							ADVISOR_LOAN_REQUEST_STATUS_CONSTANTS.FA_CANCELED && (
							<NewLoanOptionsSection
								newLoanOffers={newLoanOffers}
								clientData={clientData}
								loanRequestId={loanRequestId}
								newLoanDetails={newLoanDetails}
								faStatus={faStatus}
								handleChange={handleChange}
							/>
						)}
				</Grid>

				<EditRequestInfoModal
					newLoanDetails={newLoanDetails}
					isOpen={isModalOpen}
					handleClose={() => setIsModalOpen(false)}
					advisorEmail={advisorEmail}
				/>
			</>
		);
	}
}

export default LoanInquiryDetailsPage;
