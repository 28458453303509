import { useMutation, useQueryClient } from 'react-query';
import { useLocation, useParams } from 'react-router-dom';

// our axios
import axiosInstance from 'services/API/API';

// Our Endpoints
import { getUserLiabilitiesUrl as saveCreditCardLiabilityURL } from 'shared/api-urls';

// Our Routes
import {
	ADVISOR_BASE_NEW_LOAN_SELECTION_ROUTE,
	CLIENT_ADD_NEW_CREDIT_CARD_ROUTE,
	CLIENT_NEW_LOAN_SELECTION_ROUTE,
	LIABILITY_ADDITIONAL_ROUTE
} from 'routes';

// Our Query keys
import {
	CLIENT_OVERVIEW_DATA,
	LIABILITY,
	WALLET_OVERVIEW_DATA,
	USER_LIABILITIES
} from 'shared/query-keys';

function useMutateSaveCreditCardLiability() {
	const location = useLocation();
	const { pathname } = location;
	const { tradeLineId } = useParams();

	const queryClient = useQueryClient();

	// Booleans
	const isManualEntry = pathname.includes(LIABILITY_ADDITIONAL_ROUTE);
	const clientEnteredThroughAddNewLiabilityFlow = pathname.includes(
		CLIENT_NEW_LOAN_SELECTION_ROUTE
	);
	const advisorEnteredThroughAddNewLiabilityFlow = pathname.includes(
		ADVISOR_BASE_NEW_LOAN_SELECTION_ROUTE
	);
	const onClientCreditCardPage = pathname.includes(
		CLIENT_ADD_NEW_CREDIT_CARD_ROUTE
	);

	const urlForRequest =
		isManualEntry ||
		clientEnteredThroughAddNewLiabilityFlow ||
		advisorEnteredThroughAddNewLiabilityFlow ||
		onClientCreditCardPage
			? saveCreditCardLiabilityURL
			: `${saveCreditCardLiabilityURL}${tradeLineId}`;

	return useMutation(async (creditCardLiabilityData) => {
		if (
			isManualEntry ||
			clientEnteredThroughAddNewLiabilityFlow ||
			advisorEnteredThroughAddNewLiabilityFlow ||
			onClientCreditCardPage
		) {
			// overwriting data confidence here.
			const creditCardPayloadWithRateManuallyUpdated = {
				...creditCardLiabilityData,
				rateManuallyUpdated: true
			};

			// manual entry is a post call
			const response = await axiosInstance.post(
				urlForRequest,
				creditCardPayloadWithRateManuallyUpdated
			);

			await queryClient.invalidateQueries(USER_LIABILITIES);
			await queryClient.invalidateQueries(WALLET_OVERVIEW_DATA, {
				refetchInactive: true
			});
			await queryClient.invalidateQueries(CLIENT_OVERVIEW_DATA, {
				refetchInactive: true
			});

			return response.data;
		}

		// dynamic entries will be patch calls
		const response = await axiosInstance.patch(
			urlForRequest,
			creditCardLiabilityData
		);

		await queryClient.invalidateQueries(WALLET_OVERVIEW_DATA, {
			refetchInactive: true
		});

		await queryClient.invalidateQueries(LIABILITY, {
			refetchInactive: true
		});

		return response.data;
	});
}

export default useMutateSaveCreditCardLiability;
