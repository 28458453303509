import { useMutation } from 'react-query';

// our axios
import axiosInstance from 'services/API/API';

// our endpoints
import { uploadProfilePicture } from 'shared/api-urls';

function useMutateUploadProfilePicture() {
	return useMutation(async (fileData) => {
		const response = await axiosInstance.post(
			uploadProfilePicture,
			fileData
		);

		return response.data;
	});
}

export default useMutateUploadProfilePicture;
