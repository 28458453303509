import { useNavigate } from 'react-router-dom';
import { useQuery, useQueryClient } from 'react-query';
import { Helmet } from 'react-helmet-async';

// Our components
import Loader from 'components/Loader';

// Our axios Instance
import axiosInstance from 'services/API/API';

// Our endpoints
import { userLogin as userLoginUrl } from 'shared/api-urls';

// Our Query Keys
import {
	FIREBASEUSERCREDENTIALS,
	DTS_ROUTE,
	USER_DATA,
	USERPROFILEDATA
} from 'shared/query-keys';

function DtaMagicRoutingUser() {
	const queryClient = useQueryClient();
	const navigate = useNavigate();

	// Retrieve access token for api call
	const fireBaseUserAccessToken = queryClient.getQueryData(
		FIREBASEUSERCREDENTIALS
	);

	const route = queryClient.getQueryData(DTS_ROUTE);

	const {
		isLoading,
		isSuccess,
		data: userProfileData
	} = useQuery(USER_DATA, async () => {
		const loginResponse = await axiosInstance.post(userLoginUrl, {
			firebaseIdToken: fireBaseUserAccessToken
		});
		return loginResponse.data.data;
	});

	if (isLoading)
		return (
			<>
				<Helmet>
					<title>Sora Dts Magic link</title>
				</Helmet>
				<Loader
					boxSX={{
						width: '100%',
						height: '100vh',
						alignItems: 'center',
						justifyContent: 'center'
					}}
					size={100}
				/>
			</>
		);

	if (isSuccess) {
		queryClient.setQueryData(USERPROFILEDATA, userProfileData);
		navigate(route, { replace: true });
	}

	return (
		<>
			<Helmet>
				<title>Sora Dts Magic link</title>
			</Helmet>
			<Loader
				boxSX={{
					width: '100%',
					height: '100vh',
					alignItems: 'center',
					justifyContent: 'center'
				}}
				size={100}
			/>
		</>
	);
}

export default DtaMagicRoutingUser;
