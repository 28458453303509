function DtaQuestionnaire({ iframeUrl, clientFullName }) {
	return (
		<iframe
			title={`Debt Tolerance Assessment for ${clientFullName}`}
			src={iframeUrl}
			height="620"
			width="95%"
			style={{ border: 'none' }}
		/>
	);
}

export default DtaQuestionnaire;
