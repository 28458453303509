import { useMemo, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import PropTypes from 'prop-types';
import { useTheme } from '@emotion/react';

// Our Components
import InsightsCard from 'components/SoraWallet/Cards/insights/InsightsCard';
import InsightsModal from 'components/SoraWallet/Cards/insights/InsightsModal';

// Our Hooks
import useMortgageInsightsToChatGptResult from 'hooks/insights/useMortgageInsightsToChatGptResult';

function SoraInsightsMortgageCarousel({ isClient }) {
	const SoraTheme = useTheme();
	const { primary } = SoraTheme.palette;

	// Local state
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [selectedInsight, setSelectedInsight] = useState(null);

	const openModal = () => setIsModalOpen(true);

	const closeModal = () => {
		setIsModalOpen(false);
		setSelectedInsight(null);
	};

	const onClickCallBack = (currentlySelectedInsight) => {
		setSelectedInsight(currentlySelectedInsight);
		openModal();
	};

	const mortgageInsightsQueries = useMortgageInsightsToChatGptResult(
		true,
		isClient
	);

	const mortgageInsightCards = useMemo(
		() =>
			mortgageInsightsQueries.map((currentInsightQuery) => (
				<Grid
					key={uuidv4()}
					item
					xs={12}
					md={4}
					xl={4}
					sx={{ height: 195 }}
				>
					<InsightsCard
						isClient={isClient}
						currentInsightQuery={currentInsightQuery}
						onClick={onClickCallBack}
						sx={{
							minHeight: 195,
							margin: 0
						}}
						skeletonSx={{
							height: 195
						}}
					/>
				</Grid>
			)),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[mortgageInsightsQueries]
	);

	const currentMortgageHasNoInsights = mortgageInsightCards.length < 1;
	if (currentMortgageHasNoInsights) return <p> </p>;

	return (
		<Grid container item xs={12} columnSpacing={3}>
			<Grid item xs={12}>
				<Typography
					variant="h2Gascogne"
					sx={{
						color: primary.indigo,
						display: 'block',
						marginBottom: 3
					}}
				>
					Sora Insights
				</Typography>
			</Grid>

			<Grid
				container
				item
				xs={12}
				columnSpacing={3}
				sx={{
					minHeight: 195,
					alignItems: 'center'
				}}
			>
				{mortgageInsightCards}
			</Grid>

			{selectedInsight && (
				<InsightsModal
					isModalOpen={isModalOpen}
					closeModal={closeModal}
					selectedInsight={selectedInsight}
				/>
			)}
		</Grid>
	);
}

SoraInsightsMortgageCarousel.propTypes = {
	isClient: PropTypes.bool.isRequired
};

export default SoraInsightsMortgageCarousel;
