import { useQuery } from 'react-query';

// our Axios instance
import axiosInstance from 'services/API/API';

// endpoint
import { getAdvisorsEndpoint } from 'shared/api-urls';

// query keys
import { LIST_OF_ADVISORS } from 'shared/query-keys';

function useGetAllAdvisors() {
	return useQuery(LIST_OF_ADVISORS, async () => {
		const response = await axiosInstance.get(getAdvisorsEndpoint);
		return response.data.data;
	});
}

export default useGetAllAdvisors;
