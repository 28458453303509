import { useTheme } from '@emotion/react';
import { Box } from '@mui/material';
import ArticleIcon from '@mui/icons-material/Article';
import DangerousIcon from '@mui/icons-material/Dangerous';
import fileIcon from 'assets/icons/miscellaneous/fileUploadImagePlaceholder.svg';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import FolderZipIcon from '@mui/icons-material/FolderZip';

function ImageFileIcon() {
	return (
		<Box
			component="img"
			src={fileIcon}
			sx={{
				width: 25,
				height: 30
			}}
		/>
	);
}

function FileTypeIcon({ isRejectedFile, fileType, fileExt }) {
	const SoraTheme = useTheme();
	const { error: errorColor, primary } = SoraTheme.palette;

	const isMicroSoftWordDocument = fileType?.includes(
		'openxmlformats-officedocument.wordprocessingml.document'
	);

	const isZipFile = fileExt.includes('zip');

	if (isRejectedFile)
		return <DangerousIcon sx={{ color: errorColor.main }} />;
	if (fileExt.includes('pdf'))
		return <PictureAsPdfIcon sx={{ color: primary.soraBlue }} />;
	if (isMicroSoftWordDocument || fileExt === 'plain')
		return <ArticleIcon sx={{ color: primary.soraBlue }} />;
	if (isZipFile) return <FolderZipIcon sx={{ color: primary.soraBlue }} />;

	return <ImageFileIcon />;
}

export default FileTypeIcon;
