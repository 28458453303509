function selectInsightsDataFromWalletOverview(walletOverviewData) {
	if (!walletOverviewData)
		return { liabilities: [], clientFinancialData: {} };
	const {
		advisor,
		annualIncome,
		assetsAmount,
		creditScore,
		email,
		dti,
		firstName,
		lastName,
		liability
	} = walletOverviewData;

	const { liabilities } = liability;
	const { name: advisorName, email: advisorEmail } = advisor;

	return {
		advisorEmail,
		advisorName,
		annualIncome,
		clientFinancialData: { assetsAmount, DTI: dti },
		creditScore,
		email,
		firstName,
		lastName,
		liabilities
	};
}

export default selectInsightsDataFromWalletOverview;
