import { Box, IconButton } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

function CarouselButtonGroup(props) {
	const { next, previous } = props;

	return (
		<Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
			<IconButton onClick={() => previous()}>
				<ChevronLeftIcon />
			</IconButton>
			<IconButton onClick={() => next()}>
				<ChevronRightIcon />
			</IconButton>
		</Box>
	);
}

export default CarouselButtonGroup;
