import { useMutation, useQueryClient } from 'react-query';

// Our Axios Instance
import axiosInstance from 'services/API/API';

// Our endpoint
import { getUserLiabilitiesUrl } from 'shared/api-urls';

// Our Query keys
import {
	GPT_REWRITTEN_INSIGHTS,
	ERROR_MESSAGE_DATA,
	SUCCESS_MESSAGE_DATA,
	WALLET_OVERVIEW_DATA,
	LIABILITY
} from 'shared/query-keys';

function useMutateDeleteLiability(handleClose) {
	const queryClient = useQueryClient();

	return useMutation(
		async (tradelineId) => {
			const deleteUrlPath = `${getUserLiabilitiesUrl}${tradelineId}`;

			const response = await axiosInstance.delete(deleteUrlPath);

			await queryClient.invalidateQueries(WALLET_OVERVIEW_DATA);
			await queryClient.invalidateQueries(LIABILITY);
			queryClient.invalidateQueries(GPT_REWRITTEN_INSIGHTS);

			return response.data.data;
		},
		{
			onSuccess: () => {
				queryClient.setQueryData(
					SUCCESS_MESSAGE_DATA,
					'Successfully deleted liability.'
				);
				handleClose(false);
			},
			onError: () => {
				queryClient.setQueryData(
					ERROR_MESSAGE_DATA,
					'Something went wrong.'
				);
				handleClose(false);
			}
		}
	);
}

export default useMutateDeleteLiability;
