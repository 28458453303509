import { Cell, PieChart, Pie, Legend } from 'recharts';
import { useTheme } from '@emotion/react';
import { v4 as uuidv4 } from 'uuid';

// Our Hooks
import useGetWalletOverview from 'hooks/soraWallet/useGetWalletOverview';

// Our Utils
import normalizeLoanType from 'shared/utils/clientOnboarding/normalizeLoanType';
import { CREDITCARD } from 'shared/constants';

function SoraRadianLabel({ name, value, x, y }) {
	// I had to make custom calculations to fix the alignment.
	// Seems to work.
	const modifiedX = x < 180 ? x + 0 : x + 14;
	const modifiedY = x < 180 ? y - 8 : y;
	return (
		<text
			name={name}
			x={modifiedX}
			y={modifiedY}
			fill="black"
			textAnchor="middle"
			dominantBaseline="central"
			fontSize="0.75rem"
		>
			{value}%
		</text>
	);
}

function sumOfBalanceByTradelineType(liabilities, chartColors) {
	const sumHashMap = new Map();

	const sumOfBalanceBasedOnTradelineType = [];

	liabilities.forEach(({ outstandingBalance, tradeLineType }) => {
		if (sumHashMap.has(tradeLineType)) {
			const currentBalanceOfTradelineType = sumHashMap.get(tradeLineType);
			sumHashMap.set(
				tradeLineType,
				currentBalanceOfTradelineType + outstandingBalance
			);
		} else {
			sumHashMap.set(tradeLineType, outstandingBalance);
		}
	});

	const balanceOfLiabilities = liabilities.map(
		({ outstandingBalance }) => outstandingBalance
	);

	const sumOfLiabilities = balanceOfLiabilities.reduce(
		(previous, current) => previous + current,
		0
	);

	sumHashMap.forEach((value, tradeLineType) => {
		const colorForLiability =
			chartColors[tradeLineType.toLowerCase()] ?? 'red';

		const formattedLoanType =
			tradeLineType === CREDITCARD
				? 'Credit Card'
				: normalizeLoanType(tradeLineType);

		const percent = value / sumOfLiabilities;

		const roundedPercent = Math.round(percent * 100);

		sumOfBalanceBasedOnTradelineType.push({
			name: formattedLoanType,
			value: roundedPercent,
			color: colorForLiability
		});
	});

	return sumOfBalanceBasedOnTradelineType;
}

const renderColorfulLegendText = (value) => (
	<span style={{ color: 'black', fontSize: '0.75rem' }}>{value}</span>
);

function ExportableReportPieChart() {
	const { data, isLoading } = useGetWalletOverview();
	const SoraTheme = useTheme();

	const { chart } = SoraTheme.palette;

	if (isLoading) return <h1>Loading...</h1>;
	const { liability } = data;
	const { liabilities } = liability;

	const listOfBalancesByTradelineType = sumOfBalanceByTradelineType(
		liabilities,
		chart
	);

	return (
		<PieChart width={400} height={260}>
			<Pie
				cy="40%"
				data={listOfBalancesByTradelineType}
				dataKey="value"
				isAnimationActive={false}
				labelLine
				label={SoraRadianLabel}
			>
				{listOfBalancesByTradelineType.map((entry) => (
					<Cell key={uuidv4()} fill={entry.color ?? 'blue'} />
				))}
			</Pie>
			<Legend formatter={renderColorfulLegendText} />
		</PieChart>
	);
}

export default ExportableReportPieChart;
