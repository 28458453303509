import { useEffect, useState, useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import { Box, Grid } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';

// Our Components
import CurrencyTextInput from 'components/Input/CurrencyTextInput';
import Dropdown from 'components/Dropdown/Dropdown';
import FinancialGoalDropdown from 'components/Client/Onboarding/FinancialGoalDropdown';
import GetFullAddressPartialForm from 'components/Forms/addressRelated/GetFullAddressPartialForm';
import HomeLoanType from 'pages/Client/Onboarding/Liabilities/HomeLoanType';
import LiabilityFormHeading from 'components/Client/Onboarding/LiabilityFormHeading';
import LiabilityDynamicButton from 'components/Client/Onboarding/LiabilityDynamicButton';
import Loader from 'components/Loader';
import MonthlyPaymentInput from 'components/Client/Onboarding/MonthlyPaymentInput';
import OutstandingBalanceInput from 'components/Client/Onboarding/OutstandingBalanceInput';
import RateInput from 'components/Input/RateInput';
import StandardDatePicker from 'components/DatePicker/StandardDatePicker';
import TextInput from 'components/Input/TextInput';
import { TEXT } from 'components/Input/Types';

// Utils
import { dataIsValid, isSubmissionReady, roundToDecimal } from 'shared/utils';
import dateToIsoFormat from 'shared/utils/clientOnboarding/dateToIsoFormat';
import FINANCIAL_GOAL_TO_ENUM_MAPPING from 'shared/utils/clientOnboarding/financialGoalMapping';
import getDefaultApproxPayoffDate from 'shared/utils/clientOnboarding/getDefaultApproxPayoffDate';
import shouldUpdateRateManuallyUpdated from 'shared/utils/events/shouldUpdateRateManuallyUpdated';
import inverseFinancialGoalMapping from 'shared/utils/clientOnboarding/inverseFinancialGoalMapping';
import PROPERTY_TYPES_TO_ENUM_MAPPING from 'shared/utils/clientOnboarding/propertyTypeToEnumMapping';

// Hooks
import useGetUserMortgageLiability from 'hooks/clientOnboarding/useGetUserMortgageLiability';
import useMutateSaveMortgageLiability from 'hooks/clientOnboarding/mutations/useMutateSaveMortgageLiability';
import normalizeLoanType from 'shared/utils/clientOnboarding/normalizeLoanType';

// Constants
import {
	LOAN_TENURE_OPTIONS,
	MORTGAGE,
	PRE_POPULATE_FIELDS_TO_SKIP,
	TYPES_OF_HOME_PROPERTIES
} from 'shared/constants';

const PROPERTY_OWNERSHIP_TYPES = [
	'0-4 years',
	'5-7 years',
	'8-14 years',
	'15-29 years',
	'30+ years'
];

const HOME_PROPERTIES_TO_SKIP = new Set([
	'id',
	'assedValue',
	'currency',
	'estimatedValue',
	'marketValue',
	'dwellingType'
]);

const DEFAULT_LOAN_INFO = {
	loanTerm: '30-year',
	loanType: 'Fixed'
};

// Helper fns
const getDefaultLoanType = (loanTypeToCheck) => {
	if (loanTypeToCheck === undefined || loanTypeToCheck === null)
		return 'Fixed';
	return loanTypeToCheck === 'fixed' ? 'Fixed' : 'ARM';
};

const convertTenureLengthToRecognizedFormat = (
	tenureLengthInMonths,
	loanType
) => {
	const getDefaultTenureType = () =>
		loanType === 'Fixed' ? '30-year' : '5-year';

	if (tenureLengthInMonths === undefined || tenureLengthInMonths === null)
		return getDefaultTenureType();

	try {
		const calculatedTenureLength = Math.floor(tenureLengthInMonths / 12);
		const calculatedNormalizedTenureLength = `${calculatedTenureLength}-year`;
		const isValidTenureType = LOAN_TENURE_OPTIONS[loanType].includes(
			calculatedNormalizedTenureLength
		);

		// If the calculated tenureType is in the list of accepted tenure types
		// pre populate it
		if (isValidTenureType) {
			return calculatedNormalizedTenureLength;
		}
		// if its not in the default
		return getDefaultTenureType();
	} catch (e) {
		// if something went wrong calculating this just return the default.
		return getDefaultTenureType();
	}
};

function HomeLiability() {
	// these defaults are based on Methodfi Response
	const navigate = useNavigate();
	const { tradeLineId } = useParams();
	const { isLoading, data, isSuccess } = useGetUserMortgageLiability();
	const saveMortgage = useMutateSaveMortgageLiability();

	const { isLoading: isMutationLoading } = saveMortgage;

	// Mortgage Liability Related
	const [approxPayoffDate, setApproxPayoffDate] = useState(
		getDefaultApproxPayoffDate(MORTGAGE)
	);

	const [lender, setLender] = useState('');
	const [loanInfo, setLoanInfo] = useState(DEFAULT_LOAN_INFO);
	const [monthlyPayment, setMonthlyPayment] = useState('');
	const [escrow, setEscrow] = useState('0');
	const [outstandingBalance, setOutstandingBalance] = useState(''); // balance / 100 is bc methodfi provides balance in cents so we divide by 100 to convert into dollars
	const [rate, setRate] = useState('');

	// Address Related
	const [addressLine1, setAddressLine1] = useState('');
	const [addressLine2, setAddressLine2] = useState('');
	const [city, setCity] = useState('');
	const [propertyType, setPropertyType] = useState('Primary'); // this refers to Loan Type field
	const [propertyOwnershipTenure, setPropertyOwnershipTenure] =
		useState('15-29 years'); // how long do you intend to own this home ?!
	const [state, setState] = useState('');
	const [zipCode, setZipCode] = useState('');

	// Financial Goal
	const [financialGoal, setFinancialGoal] = useState(
		'Reduce total cost of debt'
	);

	// separate
	const [disableAutoComplete, setDisableAutoComplete] = useState(false);

	const didRateChange = useMemo(() => {
		// if data is empty false
		if (!dataIsValid(data)) return false;
		// if interestRate is empty false.
		if (!dataIsValid(data?.interestRate)) return false;
		// if rate is empty false.
		if (rate === '') return false;
		// compare NOW
		return +rate !== data.interestRate;
	}, [rate, data]);

	useEffect(() => {
		if (isSuccess) {
			setDisableAutoComplete(true);
			const WANTED_KEY_SET_MAPPING = {
				addressLine1: setAddressLine1,
				addressLine2: setAddressLine2,
				city: setCity,
				expectedPayOffDate: setApproxPayoffDate,
				escrow: setEscrow,
				financialGoal: setFinancialGoal,
				interestRate: setRate,
				lender: setLender,
				loanType: setLoanInfo,
				monthlyPay: setMonthlyPayment,
				outstandingBalance: setOutstandingBalance,
				ownershipTenure: setPropertyOwnershipTenure,
				propertyType: setPropertyType,
				propertyOwnershipTenure: setPropertyOwnershipTenure,
				state: setState,
				tenureMonth: setLoanInfo,
				zipcode: setZipCode
			};

			const homeLiabilityFields = Object.keys(data);

			try {
				homeLiabilityFields.forEach((homeLiabilityField) => {
					if (
						PRE_POPULATE_FIELDS_TO_SKIP.concat(
							'tenureMonth'
						).includes(homeLiabilityField)
					)
						return;

					const currentData = data[homeLiabilityField];
					const setUpdater =
						WANTED_KEY_SET_MAPPING[homeLiabilityField];

					if (homeLiabilityField === 'clientHome') {
						// data in this field is nested so we gotta iterate over that.
						const mortgageSpecificFields = Object.keys(currentData);

						mortgageSpecificFields.forEach(
							(mortgageSpecificField) => {
								// assedValue is not used so skip it.
								if (
									HOME_PROPERTIES_TO_SKIP.has(
										mortgageSpecificField
									)
								)
									return;

								const mortgageSpecificUpdater =
									WANTED_KEY_SET_MAPPING[
										mortgageSpecificField
									];
								const mortgageSpecificData =
									currentData[mortgageSpecificField];

								// This data is may or may not be null
								if (!dataIsValid(mortgageSpecificData)) return;
								if (mortgageSpecificField === 'propertyType') {
									const formattedPropertyType =
										normalizeLoanType(mortgageSpecificData);
									mortgageSpecificUpdater(
										formattedPropertyType
									);
									return;
								}
								mortgageSpecificUpdater(mortgageSpecificData);
							}
						);

						return;
					}

					if (
						homeLiabilityField === 'monthlyPay' ||
						homeLiabilityField === 'outstandingBalance' ||
						homeLiabilityField === 'interestRate' ||
						homeLiabilityField === 'escrow'
					) {
						// Here the values are ints we need to convert them to strings

						if (homeLiabilityField === 'escrow') {
							const isNegative = currentData < 0;

							if (isNegative) return;
						}
						setUpdater(`${currentData}`);
						return;
					}

					if (homeLiabilityField === 'loanType') {
						const storedLoanTerm = data?.tenureMonth;
						const storedLoanType = data?.loanType;
						if (storedLoanTerm && storedLoanType) {
							const normalLoanType = getDefaultLoanType(
								currentData.toLowerCase()
							);
							const loanTermInYears =
								convertTenureLengthToRecognizedFormat(
									storedLoanTerm,
									normalLoanType
								);

							setUpdater({
								loanTerm: loanTermInYears,
								loanType: normalLoanType
							});
							return;
						}

						return;
					}

					if (homeLiabilityField === 'financialGoal') {
						setUpdater(inverseFinancialGoalMapping(currentData));
						return;
					}
					setUpdater(currentData);
				});
			} catch (e) {
				// eslint-disable-next-line no-console
				console.error(e);
			}
		}
	}, [data]);

	useEffect(
		() => () => {
			setAddressLine1('');
			setAddressLine2('');
			setCity('');
			setState('');
			setZipCode('');
		},
		[tradeLineId]
	);

	const formValues = [
		addressLine1,
		approxPayoffDate,
		city,
		financialGoal,
		lender,
		loanInfo.loanTerm,
		loanInfo.loanType,
		monthlyPayment,
		outstandingBalance,
		propertyType,
		propertyOwnershipTenure,
		rate,
		escrow,
		state,
		zipCode
	];

	const isFormReady = useMemo(
		() => isSubmissionReady(formValues) && approxPayoffDate !== null,
		formValues
	);

	const handleApproxPayoffDateChange = (value) => {
		const isValueEmpty = value === '';
		if (isValueEmpty) {
			setApproxPayoffDate('');
			return;
		}
		setApproxPayoffDate(value);
	};

	const paramsFromURL = useParams();
	const clientId = paramsFromURL?.clientId;

	const submitLiabilityData = (route) => {
		const formattedApproxPayOffDate = dateToIsoFormat(approxPayoffDate);

		const formattedFinancialGoal =
			FINANCIAL_GOAL_TO_ENUM_MAPPING(financialGoal);

		const { loanTerm, loanType } = loanInfo;

		const formattedLoanType = loanType.toUpperCase();

		const formattedPropertyType =
			PROPERTY_TYPES_TO_ENUM_MAPPING(propertyType);

		const formattedRate = roundToDecimal(+rate);

		const strippedOwnershipTenure = loanTerm?.split('-')[0];

		const homeData = {
			clientId,
			addressLine1,
			addressLine2,
			approxPayoffDate: formattedApproxPayOffDate,
			city,
			escrow,
			financialGoal: formattedFinancialGoal,
			lender,
			loanType: formattedLoanType,
			monthlyPayment: +monthlyPayment,
			outstandingBalance: +outstandingBalance,
			propertyType: formattedPropertyType,
			propertyOwnershipTenure,
			rate: formattedRate,
			state,
			tenure: strippedOwnershipTenure,
			tradelineType: 'MORTGAGE',
			zipcode: zipCode
		};

		homeData.rateManuallyUpdated = shouldUpdateRateManuallyUpdated(
			data?.rateManuallyUpdated,
			didRateChange
		);

		saveMortgage.mutate(homeData, {
			onSuccess: () => {
				navigate(route);
			}
		});
	};

	if (isLoading)
		return (
			<>
				<Helmet>
					<title>Home Loan</title>
				</Helmet>
				<LiabilityFormHeading headingText="Home Loan" />
				<Loader
					size={60}
					boxSX={{ alignItems: 'center', marginTop: 15 }}
				/>
			</>
		);

	return (
		<>
			<Helmet>
				<title>Home Loan</title>
			</Helmet>

			{/* Once data binding begins we will add Mortgage specific information to this header */}
			<LiabilityFormHeading headingText="Home Loan" />

			<Box component="form" noValidate autoComplete="off">
				<Grid container columnSpacing={2} marginTop={4}>
					<Grid item xs={6}>
						<OutstandingBalanceInput
							outstandingBalance={outstandingBalance}
							setOutstandingBalance={setOutstandingBalance}
						/>
					</Grid>
					<Grid item xs={6} marginBottom={4}>
						<RateInput rate={rate} setRate={setRate} />
					</Grid>

					{/* Uses 12 Grid Columns and has marginBottom 4 */}
					<HomeLoanType
						loanInfo={loanInfo}
						setLoanInfo={setLoanInfo}
					/>

					<Grid item xs={6}>
						<TextInput
							type={TEXT}
							label="Lender"
							subLabel="Enter the name of your lender"
							value={lender}
							onChange={setLender}
						/>
					</Grid>
					<Grid item xs={6} marginBottom={6}>
						<MonthlyPaymentInput
							monthlyPayment={monthlyPayment}
							outstandingBalance={outstandingBalance}
							setMonthlyPayment={setMonthlyPayment}
						/>
					</Grid>

					<Grid item xs={6} marginBottom={6}>
						<CurrencyTextInput
							label="Is any part of the monthly payment for escrow, insurance or property tax? How much?"
							onChange={setEscrow}
							value={escrow}
							subLabel="Optional"
						/>
					</Grid>
					<Grid item xs={6} />

					<Grid item xs={6}>
						<StandardDatePicker
							label="Expected Payoff Date"
							helperText="Enter the approximate payoff date"
							onChange={handleApproxPayoffDateChange}
							value={approxPayoffDate}
							error={approxPayoffDate === ''}
						/>
					</Grid>
					<Grid item xs={6} marginBottom={4}>
						<Dropdown
							items={TYPES_OF_HOME_PROPERTIES}
							selected={propertyType}
							onChange={setPropertyType}
							variant="outlined"
							label="Type of property"
						/>
					</Grid>

					<FinancialGoalDropdown
						financialGoal={financialGoal}
						setFinancialGoal={setFinancialGoal}
						gridColumns={6}
					/>
					<Grid item xs={6}>
						<Dropdown
							items={PROPERTY_OWNERSHIP_TYPES}
							selected={propertyOwnershipTenure}
							onChange={setPropertyOwnershipTenure}
							variant="outlined"
							label="Intended ownership tenure (years)"
							labelSx={{
								whiteSpace: 'nowrap'
							}}
						/>
					</Grid>

					<GetFullAddressPartialForm
						addressLine1={addressLine1}
						addressLine2={addressLine2}
						city={city}
						state={state}
						zipCode={zipCode}
						setAddressLine1={setAddressLine1}
						setAddressLine2={setAddressLine2}
						setCity={setCity}
						setState={setState}
						setZipCode={setZipCode}
						disableAutoComplete={disableAutoComplete}
						setDisableAutoComplete={setDisableAutoComplete}
					/>
					<Grid item xs={12} sx={{ marginTop: 4 }}>
						<LiabilityDynamicButton
							disabled={!isFormReady}
							isMutationLoading={isMutationLoading}
							onClick={submitLiabilityData}
						/>
					</Grid>
				</Grid>
			</Box>
		</>
	);
}

export default HomeLiability;
