// Our Components
import Modal from 'components/Modal/Modal';
import { TertiaryButton } from 'components/Button/Button';

function FreemiumModal({ isOpen, setIsOpen }) {
	const handleUpgradeClick = () => {
		window.open('https://calendly.com/d/g45-sjp-5mz/sora-demo', '_blank');
	};

	return (
		<Modal
			title="You've reached your plan limit"
			subtitle="Click below to speak to your account representative to upgrade your account for full access and to onboard more than five clients"
			isOpen={isOpen}
			handleClose={() => setIsOpen(false)}
			boxSX={{ padding: '32px 24px 24px' }}
		>
			<TertiaryButton onClick={handleUpgradeClick}>
				Upgrade your account
			</TertiaryButton>
		</Modal>
	);
}

export default FreemiumModal;
