// Our Hooks
import useGetBurnDownChart from 'hooks/Burdown/queries/useGetBurnDownChart';

// Our Component
import ExportableReportSingleChart from 'components/ExportableReport/Charts/ExportableReportChartContainer';
import ExportableReportBurndownChart from 'components/ExportableReport/Charts/ExportableReportBurndownChart';
import ExportableReportPieChart from 'components/ExportableReport/Charts/ExportableReportPieChart';
import ExportableReportInterestRateChart from 'components/ExportableReport/Charts/ExportableReportInterestRateChart';
import ExportableReportSection from 'components/ExportableReport/Sections/ExportableReportSection';

function ExportableReportCharts() {
	const { isSuccess, data } = useGetBurnDownChart();

	if (isSuccess) {
		const { burnDownCalculation } = data;
		return (
			<>
				<ExportableReportSection
					sx={{ display: 'flex', justifyContent: 'space-between' }}
				>
					<ExportableReportSingleChart heading="Loan Allocations">
						<ExportableReportPieChart />
					</ExportableReportSingleChart>

					{isSuccess && (
						<ExportableReportSingleChart heading="5-Year Burn Down">
							<ExportableReportBurndownChart
								data={burnDownCalculation}
							/>
						</ExportableReportSingleChart>
					)}
				</ExportableReportSection>
				<ExportableReportSection>
					{/* This section contains the first two charts */}

					<ExportableReportSingleChart
						heading="Interest Rates"
						sx={{ width: 700 }}
					>
						<ExportableReportInterestRateChart />
					</ExportableReportSingleChart>
				</ExportableReportSection>
			</>
		);
	}
}

export default ExportableReportCharts;
