import { Helmet } from 'react-helmet-async';
import { Box } from '@mui/material';

// Our Components
import AdditionalDetailsForm from 'components/Client/Onboarding/AdditionalDetailsForm';

function AdditionalDetailsPage() {
	return (
		<>
			<Helmet>
				<title>Client Onboarding - Additional details</title>
			</Helmet>

			<Box
				sx={{
					height: '100vh',
					width: '100%',
					overflow: 'auto'
				}}
			>
				<AdditionalDetailsForm />
			</Box>
		</>
	);
}

export default AdditionalDetailsPage;
