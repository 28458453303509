const alphabet = [
	'a',
	'b',
	'c',
	'd',
	'e',
	'f',
	'g',
	'h',
	'i',
	'j',
	'k',
	'l',
	'm',
	'n',
	'o',
	'p',
	'q',
	'r',
	's',
	't',
	'u',
	'v',
	'w',
	'x',
	'y',
	'z'
];
/**
 *
 * @param {string} str - input string that will be manipulated
 * @param {string[]} listOfCharacters - list of characters that will be used to filter
 */
function filterCharactersNotInList(str, listOfCharacters = alphabet) {
	const acceptedSetOfCharacters = new Set(listOfCharacters);

	const inputCharacters = str.toLowerCase().split('');

	const filteredCharacters = inputCharacters.filter((currentCharacter) =>
		acceptedSetOfCharacters.has(currentCharacter)
	);

	const filteredString = filteredCharacters.join('');

	return filteredString;
}

export default filterCharactersNotInList;
