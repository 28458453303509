import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Grid, Typography } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';

// Our Components
import LiabilityFormHeading from 'components/Client/Onboarding/LiabilityFormHeading';
import LiabilityFoundRowItem from 'components/Client/Onboarding/Table/LiabilityFoundRowItem';
import Loader from 'components/Loader/index';
import OnboardTable from 'components/Client/Onboarding/Table/OnboardTable';
import { PrimaryButton } from 'components/Button/Button';

// Hooks
import useGetAllUserLiabilities from 'hooks/clientOnboarding/useGetAllUserLiabilities';
import useGetSupportedLiabilities from 'hooks/clientOnboarding/useGetSupportedLiabilities';

// Constants
// Our Constants
import { CREDITCARD } from 'shared/constants'; // tradeline constant
import { OTHER_LOAN_ROUTE, LIABILITY_ADDITIONAL_ROUTE } from 'routes';

const LIABILITIES_FOUND_HEADING = ['Loan Type', 'Balance', 'Lender'];

// Helper fn
// This returns the next route based on the response of Methodfi
function getNextRoute(liabilitiesFound) {
	const noLiabilitiesFound = liabilitiesFound?.length < 1;
	if (noLiabilitiesFound) return LIABILITY_ADDITIONAL_ROUTE;

	// If we are here we FOUND SOME Liability.
	const firstLiabilityFound = liabilitiesFound[0];
	const { tradeLineType, tradelineId } = firstLiabilityFound;
	const isFirstLiabilityCreditCard = tradeLineType === CREDITCARD;

	// Since credit cards aren't shown per card but a list of cards
	// if any Credit card route is found just take them to that static route
	if (isFirstLiabilityCreditCard) return OTHER_LOAN_ROUTE;

	// If we made it here then we have SOME liability found and its not a credit card.
	// lets create the dynamic route here to point to
	const loanType = tradeLineType.toLowerCase();

	return `/client-onboarding/liabilities/${loanType}/${tradelineId}`;
}

// Actual Component
function LiabilitiesList() {
	const { isLoading, isSuccess, data } = useGetAllUserLiabilities();
	const filteredLiabilityList = useGetSupportedLiabilities(data, isSuccess);

	if (isLoading)
		return (
			<Loader
				size={80}
				boxSX={{
					justifyContent: 'center',
					alignItems: 'center'
				}}
			/>
		);

	if (isSuccess) {
		const liabilitiesFound = data.map(
			({ lender, outstandingBalance, tradeLineType }) => (
				<LiabilityFoundRowItem
					key={uuidv4()}
					balance={outstandingBalance}
					lender={lender}
					loanType={tradeLineType}
				/>
			)
		);

		const routeToFirstLiability = getNextRoute(filteredLiabilityList);

		return (
			<>
				<Helmet>
					<title>Liabilities Found</title>
				</Helmet>

				{/* Once data binding begins we will add Mortgage specific information to this header */}
				<LiabilityFormHeading
					headingText="We've identified these liabilities"
					sx={{ marginTop: 4 }}
				/>

				<Typography
					variant="body1"
					sx={{ marginTop: 2, marginBottom: 4 }}
				>
					Let’s proceed with onboarding. You’ll be able to add more
					liabilities later.
				</Typography>

				<Grid item xs={10} sx={{ marginTop: 4, marginBottom: 4 }}>
					{/* Need to decide what to render when liabilitiesFound is an empty array. */}
					<OnboardTable
						tableHeadings={LIABILITIES_FOUND_HEADING}
						tableItems={liabilitiesFound}
						tableHeadingSX={{ justifyContent: 'normal', gap: 6 }}
						tableHeadingItemSX={{ width: 150 }}
						tableRowSX={{ justifyContent: 'normal', gap: 12 }}
					/>
				</Grid>

				<Grid item xs={2} />

				<Link
					to={routeToFirstLiability}
					style={{ textDecoration: 'none' }}
				>
					<PrimaryButton data-test="next">Next</PrimaryButton>
				</Link>
			</>
		);
	}
}

export default LiabilitiesList;
