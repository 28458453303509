// Our components
import Loader from 'components/Loader';

// Our hooks
import useMutateAddClientInvitation from 'hooks/useMutateAddClientInvitation';

export function TriggerClientInvite() {
	const sentClientInvite = useMutateAddClientInvitation();

	const ref = document.referrer;

	// This regex is verifying that the user has come from the Sora squarespace site before triggering a client invitation
	if (ref.match(/^https?:\/\/([^/]+\.)?sorafinance\.com(\/|$)/i)) {
		const inviteDataObject = [
			{
				invitedClientEmail: 'joshtestingWithThisEmail@gmail.com',
				invitedClientName: ''
			}
		];

		sentClientInvite.mutate(inviteDataObject, {
			onSuccess: () => {
				window.location('https://www.sorafinance.com/');
			},
			onError: () => {
				window.location('https://www.sorafinance.com/');
			}
		});
	}

	return <Loader />;
}

export default TriggerClientInvite;
