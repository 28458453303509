import { Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';

function OffersPageHeading({ OfferType }) {
	const location = useLocation();

	const { clientData } = location.state;

	const { firstName } = clientData;

	const isAdvisor =
		clientData.clientId !== undefined && clientData.clientData !== null;

	const Heading = OfferType.split('_').join(' '); // turns new_loan to new loan

	const headingMessage = isAdvisor ? `${firstName}'s` : 'your';

	return (
		<>
			<Typography variant="h1Gascogne" component="h1" gutterBottom>
				Here are {headingMessage} {Heading} options
			</Typography>
			<Typography variant="body2" gutterBottom>
				Click the button below a lender&apos;s logo to execute on a loan
			</Typography>
		</>
	);
}

export default OffersPageHeading;
