import { useState, useEffect } from 'react';
import { useTheme } from '@emotion/react';
import { Box, IconButton, Typography } from '@mui/material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

// Our Components
import CardItem from 'components/SoraWallet/Cards/CardItem';
import CardHeading from 'components/SoraWallet/Cards/CardHeading';
import EditInPlaceInput from 'components/Input/EditInPlaceInput';
import Loader from 'components/Loader/index';

// Hooks
import useMutateSetIncome from 'hooks/Onboarding/Income/useMutateSetIncome';
import useMutateSetAssetValue from 'hooks/Onboarding/Assets/useMutateSetAssets';
import useGetAssets from 'hooks/Onboarding/Assets/useGetAssets';
import useGetIncome from 'hooks/Onboarding/Income/useGetIncome';

// Our Utils
import { dollarFormatter } from 'shared/utils';

// Our Constants
import { NUMBER } from 'components/Input/Types';

// Our Assets
import COIN_HAND_IMAGE from 'assets/images/liability/personal_loan.svg';

function AssetsIncome() {
	const SoraTheme = useTheme();
	const { primary } = SoraTheme.palette;

	const {
		data: assetsData,
		isSuccess: assetsFetchSuccess,
		isLoading: isLoadingGetAssets
	} = useGetAssets();
	const {
		data: incomeData,
		isSuccess: incomeFetchSuccess,
		isLoading: isLoadingGetIncome
	} = useGetIncome();

	// Calling our hook mutation
	const updateIncome = useMutateSetIncome();
	const { isLoading: setIncomeIsLoading } = updateIncome;
	const updateAssets = useMutateSetAssetValue();
	const { isLoading: setAssetsIsLoading } = updateAssets;

	const [assetId, setAssetId] = useState(0);
	const [assetsValue, setAssetsValue] = useState(0);
	const [editingAssets, setEditingAssets] = useState(false);

	const [incomeId, setIncomeId] = useState(0);
	const [incomeValue, setIncomeValue] = useState(0);
	const [editingIncome, setEditingIncome] = useState(false);

	useEffect(() => {
		// data is array in advisor case
		// if the user hasn't finished onboarding the array will be empty
		if (Array.isArray(assetsData) && assetsData?.length === 0) {
			setAssetId(0);
			setAssetsValue(0);
		} else if (Array.isArray(assetsData)) {
			setAssetId(assetsData[0]?.id);
			setAssetsValue(assetsData[0]?.assetAmount);
		}
		// data is not an array as a client
		else {
			setAssetId(assetsData?.id);
			setAssetsValue(assetsData?.assetAmount);
		}
	}, [assetsData]);

	useEffect(() => {
		if (incomeData !== undefined) {
			setIncomeId(incomeData?.listIncome[0]?.id);
			setIncomeValue(incomeData?.totalAnnualIncome);
		}
	}, [incomeData]);

	const handleAssetsValueUpdate = () => {
		const payload =
			assetId === undefined
				? {
						apiCallType: 'POST',
						totalAssets: assetsValue
				  }
				: {
						apiCallType: 'PATCH',
						totalAssets: assetsValue,
						assetIdToUpdate: assetId
				  };

		updateAssets.mutate(payload, {
			onSuccess: () => {
				setEditingAssets(!editingAssets);
			}
		});
	};

	const handleIncomeValueUpdate = () => {
		updateIncome.mutate(
			{ totalOtherAnnualIncome: incomeValue, otherIncomeId: incomeId },
			{
				onSuccess: () => {
					setEditingIncome(!editingIncome);
				}
			}
		);
	};

	if (isLoadingGetAssets || isLoadingGetIncome) {
		return (
			<Loader size={60} boxSX={{ alignItems: 'center', marginTop: 15 }} />
		);
	}

	if (assetsFetchSuccess && incomeFetchSuccess) {
		const formattedAssets = dollarFormatter.format(assetsValue);
		const formattedIncome = dollarFormatter.format(incomeValue);

		return (
			<CardItem
				withoutButton
				buttonSx={{ alignSelf: 'center' }}
				sx={{
					background:
						'linear-gradient(180deg, #B0CDE4 0%, #F5F9FC 100%)',
					gap: '20px',
					padding: '20px 28px 16px 28px'
				}}
			>
				<Box>
					<CardHeading
						title="Assets"
						Icon={EditOutlinedIcon}
						onClick={() => setEditingAssets(!editingAssets)}
					/>

					{!editingAssets && !setAssetsIsLoading && (
						<Typography
							variant="h4"
							sx={{ color: primary.indigo, marginBottom: 2 }}
						>
							{formattedAssets}
						</Typography>
					)}

					{editingAssets && !setAssetsIsLoading && (
						<EditInPlaceInput
							value={assetsValue}
							onChange={setAssetsValue}
							onBlur={handleAssetsValueUpdate}
							type={NUMBER}
						/>
					)}
					{setAssetsIsLoading && (
						<Loader size={30} boxSX={{ alignItems: 'center' }} />
					)}
				</Box>

				<Box>
					<CardHeading
						title="Income"
						Icon={EditOutlinedIcon}
						onClick={() => setEditingIncome(!editingIncome)}
					/>

					{!editingIncome && !setIncomeIsLoading && (
						<Typography variant="h4" sx={{ color: primary.indigo }}>
							{formattedIncome}
						</Typography>
					)}

					{editingIncome && !setIncomeIsLoading && (
						<EditInPlaceInput
							value={incomeValue}
							onChange={setIncomeValue}
							onBlur={handleIncomeValueUpdate}
							type={NUMBER}
						/>
					)}

					{setIncomeIsLoading && (
						<Loader size={30} boxSX={{ alignItems: 'center' }} />
					)}
				</Box>

				<Box
					component="img"
					src={COIN_HAND_IMAGE}
					sx={{
						position: 'absolute',
						right: '-0.5rem',
						bottom: 0,
						width: '7rem !important',
						height: '7rem !important',
						marginBottom: '0.8rem !important'
					}}
				/>
			</CardItem>
		);
	}
}

export default AssetsIncome;
