import React from 'react';
import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import './index.css';

// Firebase and google analytics setup
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';

// Front-end error logs via Sentry
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

// Scroll to top util
import ScrollToTop from 'hooks/utilityHook/useScrollToTop';

// Used by MUI in order to serve our custom SoraTheme
import { ThemeProvider } from '@mui/material/styles';

// Provides css resets for a simple baseline for styles to be built upon
import { CssBaseline } from '@mui/material';
import App from 'App';
import reportWebVitals from './reportWebVitals';

// Sora's defined colors & typography
import SoraTheme from './theme';

const firebaseConfig = {
	apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
	authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
	projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
	storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
	messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
	appId: process.env.REACT_APP_FIREBASE_APP_ID,
	measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const environment = process.env.REACT_APP_ENVIRONMENT;
const isRunningLocally = process.env.NODE_ENV === 'development'; // process.env.NODE_EV will be development if project is being run as local host.

if (!isRunningLocally) {
	Sentry.init({
		dsn: 'https://6a00759ff62c456882f43b3f05f821f3@o1412138.ingest.sentry.io/6750950',
		integrations: [new BrowserTracing()],
		environment,
		// Set tracesSampleRate to 1.0 to capture 100%
		// of transactions for performance monitoring.
		// We recommend adjusting this value in production
		tracesSampleRate: 1.0
	});
	const analytics = getAnalytics(app);
}

ReactDOM.render(
	<HelmetProvider>
		<React.StrictMode>
			<ThemeProvider theme={SoraTheme}>
				<CssBaseline />
				<BrowserRouter>
					<ScrollToTop />
					<App />
				</BrowserRouter>
			</ThemeProvider>
		</React.StrictMode>
	</HelmetProvider>,
	document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
