import {
	FormControlLabel,
	FormGroup,
	Typography,
	Grid,
	Switch
} from '@mui/material';
import styled from '@emotion/styled';
import { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';

// Our components
import Loader from 'components/Loader';
import Alert from 'components/Alert';
import SoraToolTip from 'components/Tooltip/index';

// Hooks
import useGetAlertSettings from 'hooks/useGetAlertSettings';
import useMutateAlertSettings from 'hooks/useMutateAlertSettings';

import { USER_ALERT_SETTINGS } from 'shared/query-keys';

const StyledFormControlLabel = styled(FormControlLabel)`
	& .MuiFormControlLabel-label {
		width: 100%;
		display: flex;
	}
`;

function SingleAlert({
	initialValue,
	alertId,
	stringAlertId,
	alertResponse,
	updateAlertSettings,
	alertCategory
}) {
	const [alert, setAlert] = useState(initialValue ?? false);

	const alertCategoryName = alertCategory;

	const flipSwitch = () => {
		updateAlertSettings.mutate(
			{ value: alert, alertId },
			{
				onSuccess: () => {
					setAlert(!alert);
				}
			}
		);
	};

	let tipMsgText = '';
	const constructedLabel = () => {
		if (alertCategoryName === 'CREDIT CARD') {
			tipMsgText = 'Only applicable if income is populated';

			return (
				<>
					{alertResponse}
					<SoraToolTip
						text={tipMsgText}
						toolTipSx={{
							marginLeft: 2
						}}
					/>
				</>
			);
		}

		if (alertCategoryName === 'MORTGAGES') {
			tipMsgText = 'Only applicable if mortgage rate is high confidence';

			return (
				<>
					{alertResponse}
					<SoraToolTip
						text={tipMsgText}
						toolTipSx={{
							marginLeft: 2
						}}
					/>
				</>
			);
		}

		return alertResponse;
	};

	return (
		<Grid item xs={12} sx={{ margin: 2 }}>
			<FormGroup sx={{ display: 'flex', flexDirection: 'column' }}>
				<StyledFormControlLabel
					control={
						<Switch
							id={stringAlertId}
							checked={alert}
							onChange={flipSwitch}
							sx={{ marginLeft: 2, flexGrow: 0 }}
						/>
					}
					label={constructedLabel()}
					labelPlacement="start"
				/>
			</FormGroup>
		</Grid>
	);
}

function AlertsTab() {
	const queryClient = useQueryClient();

	const {
		isLoading,
		isSuccess,
		data: userAlertSettings
	} = useGetAlertSettings();

	const updateAlertSettings = useMutateAlertSettings();

	// Mutation handlers
	const { isSuccess: isAlertSettingsSuccess, isError: isAlertSettingsError } =
		updateAlertSettings;

	useEffect(
		() => () =>
			queryClient.removeQueries({ queryKey: USER_ALERT_SETTINGS }),
		[]
	);

	const createAlertComponents = () => {
		if (isSuccess && userAlertSettings) {
			// Filter out the last setting, which we're combining with the first setting on the front end
			const filteredAlertSettings = userAlertSettings.slice(0, 7);

			const currentAlertSettings = filteredAlertSettings.map((alert) => {
				const { alertId, subscribed, alertResponse, alertCategory } =
					alert;

				const stringAlertId = JSON.stringify(alertId);

				return (
					<SingleAlert
						key={alertId}
						initialValue={subscribed}
						alertId={alertId}
						stringAlertId={stringAlertId}
						alertResponse={alertResponse}
						updateAlertSettings={updateAlertSettings}
						alertCategory={alertCategory}
					/>
				);
			});
			return currentAlertSettings;
		}
		return [];
	};

	if (isLoading) return <Loader size={50} />;

	if (isSuccess) {
		const alertComponent = createAlertComponents();

		return (
			<Grid container spacing={2} sx={{ marginBottom: 8 }}>
				<Grid item xs={12} md={8}>
					{isAlertSettingsError && <Alert variant="error" />}
					{isAlertSettingsSuccess && <Alert variant="success" />}

					<Typography variant="h3Gascogne" component="h3">
						General
					</Typography>
				</Grid>
				<Grid item xs={12} md={8} sx={{ marginBottom: 4 }}>
					{alertComponent.slice(0, 3)}
				</Grid>
				<Grid item xs={12} md={8}>
					<Typography variant="h3Gascogne" component="h3">
						Mortgages
					</Typography>
				</Grid>
				<Grid item xs={12} md={8} sx={{ marginBottom: 4 }}>
					{alertComponent.slice(3, 5)}
				</Grid>
				<Grid item xs={12} md={8}>
					<Typography variant="h3Gascogne" component="h3">
						Credit Card
					</Typography>
				</Grid>
				<Grid item xs={12} md={8} sx={{ marginBottom: 4 }}>
					{alertComponent.slice(5, 6)}
				</Grid>
				<Grid item xs={12} md={8}>
					<Typography variant="h3Gascogne" component="h3">
						HELOC
					</Typography>
				</Grid>
				<Grid item xs={12} md={8}>
					{alertComponent.slice(6)}
				</Grid>
			</Grid>
		);
	}
}

export default AlertsTab;
