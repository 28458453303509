import { dataIsValid } from 'shared/utils';
import sentenceCapitalize from 'shared/utils/formatting/sentenceCapitalize';

// This is used in Exportable Report
const formatAddressTitleCase = (address) => {
	try {
		const isAddressLine1Valid = dataIsValid(address?.addressLine1);
		const isAddressLine2Valid = dataIsValid(address?.addressLine2);

		if (isAddressLine1Valid && isAddressLine2Valid) {
			const { addressLine1, addressLine2 } = address;
			return `${
				addressLine1 === '' ? '' : sentenceCapitalize(addressLine1)
			} ${addressLine2 === '' ? '' : sentenceCapitalize(addressLine2)}`;
		}

		if (isAddressLine1Valid) {
			const { addressLine1 } = address;
			return addressLine1 === '' ? '' : sentenceCapitalize(addressLine1);
		}

		if (isAddressLine2Valid) {
			const { addressLine2 } = address;
			return addressLine2 === '' ? '' : sentenceCapitalize(addressLine2);
		}

		return '';
	} catch (e) {
		// eslint-disable-next-line no-console
		console.error(e);

		return '';
	}
};

export default formatAddressTitleCase;
