import { Skeleton } from '@mui/material';
import { useState } from 'react';

// Our Components
import OnboardingTasksTable from 'components/Tasks/OnboardingTasksTable';

// Our Hooks
import useGetWealthboxContactList from 'hooks/wealthbox/useGetWealthboxContactList';

function WealthboxOnboardingTable({
	disableOverlay,
	handleSelected,
	KEY,
	optionalParams
}) {
	const [page, setPage] = useState(0);
	const { isLoading, isSuccess, data, isFetching } =
		useGetWealthboxContactList(KEY, page, optionalParams);

	const smartPageHandler = (newPage) => {
		if (data) {
			const { lastPageOnServer } = data;

			const isValidPage = newPage >= 0 && newPage <= lastPageOnServer;
			if (isValidPage) {
				setPage(newPage);
			}
		}
	};

	if (isLoading)
		return (
			<Skeleton
				sx={{ width: '100%', height: 400 }}
				variant="rectangular"
			/>
		);

	if (isSuccess) {
		const { contactList, totalCount, pageSize } = data;

		const hasNoContacts = totalCount < 1;

		return (
			<OnboardingTasksTable
				disableOverlay={disableOverlay}
				isLoading={isFetching}
				page={page}
				setPage={smartPageHandler}
				pageSize={pageSize}
				contactList={contactList}
				handleSelected={handleSelected}
				withOnboardNowOverlay={disableOverlay ?? hasNoContacts}
				totalCount={totalCount}
			/>
		);
	}
}

export default WealthboxOnboardingTable;
