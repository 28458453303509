import { Helmet } from 'react-helmet-async';
import ContactUsForm from 'components/ContactUs/ContactUsForm';

export default function ContactUsPage() {
	return (
		<>
			<Helmet>
				<title>Contact Us</title>
			</Helmet>

			<ContactUsForm />
		</>
	);
}
