import { Box, IconButton, Typography } from '@mui/material';
import PropTypes from 'prop-types';

// Our common Props
import CommonProps from 'shared/prop-types';

function CardHeading({
	onClick,
	title,
	Icon,
	sx,
	buttonSx,
	ariaLabel,
	children
}) {
	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'row',
				height: 'fit-content',
				alignItems: 'center',
				marginBottom: 0.5,
				minHeight: 40,
				...sx
			}}
		>
			<Typography variant="subtitle1">{title}</Typography>

			{Icon && (
				<IconButton
					aria-label={ariaLabel}
					onClick={onClick}
					sx={{ color: 'black', ...buttonSx }}
				>
					<Icon sx={{ fontSize: '1.2rem' }} />
				</IconButton>
			)}
			{children}
		</Box>
	);
}

CardHeading.propTypes = {
	Icon: PropTypes.elementType,
	onClick: PropTypes.func,
	title: PropTypes.string.isRequired,
	sx: CommonProps.sx,
	buttonSx: CommonProps.sx
};

CardHeading.defaultProps = {
	Icon: null,
	onClick: () => {},
	sx: {},
	buttonSx: {}
};

export default CardHeading;
