import { Box, Typography } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import PropTypes from 'prop-types';
// Our Common Prop types
import CommonProps from 'shared/prop-types';

// Constants
const defaultTableStyles = {
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
	borderBottom: '0.25px black solid',
	padding: 2
};

function AnalyticsPresentationRow({
	currentRowData,
	makeLastBold,
	sx,
	firstColumnSX
}) {
	const [label, value1, value2] = currentRowData;

	return (
		<Box key={uuidv4()} sx={{ ...defaultTableStyles, ...sx }}>
			<Box sx={{ ...firstColumnSX }}>
				<Typography variant="body2">
					{makeLastBold ? <strong>{label}</strong> : label}
				</Typography>
			</Box>
			<Box
				sx={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'flex-start',
					minWidth: 86
				}}
			>
				<Typography variant="body2">
					{makeLastBold ? <strong>{value1}</strong> : value1}
				</Typography>
			</Box>
			<Box sx={{ width: 150 }}>
				<Typography variant="body2">
					{makeLastBold ? <strong>{value2}</strong> : value2}
				</Typography>
			</Box>
		</Box>
	);
}

AnalyticsPresentationRow.propTypes = {
	makeLastBold: PropTypes.bool,
	sx: CommonProps.sx,
	firstColumnSX: CommonProps.sx
};

AnalyticsPresentationRow.defaultProps = {
	makeLastBold: false,
	sx: {},
	firstColumnSX: { width: 110 }
};

export default AnalyticsPresentationRow;
