import { Typography, Box, Paper } from '@mui/material';
import { useTheme } from '@emotion/react';

// our components
import DebtToleranceMessage from 'components/Debt-Tolerance/DebtToleranceMessage';
import LoadingSkeleton from 'components/Frame/LoadingSkelton';
import SoraToolTip from 'components/Tooltip/index';

// our hooks
import useGetDts from 'hooks/dts/useGetDts';

// Our Constants
import { DTS_COMPLETED } from 'shared/constants';

// Local Constant
const NA = 'N/A';

/**
 *
 * @param {number} dtsScore
 * @returns {string} color
 */
const getDtsScoreColor = (dtsScore) => {
	if (dtsScore === NA) return 'na';
	const flooredScore = Math.floor(dtsScore);

	if (flooredScore <= 20) return 'veryLow';
	if (flooredScore <= 40) return 'low';
	if (flooredScore <= 60) return 'medium';
	if (flooredScore <= 80) return 'high';

	return 'veryHigh';
};

function DebtToleranceCard({ isClient, clientData }) {
	const SoraTheme = useTheme();

	const { primary, dtsColors } = SoraTheme.palette;

	const { isLoading, isSuccess, data: dtsData } = useGetDts();

	if (isLoading)
		return (
			<LoadingSkeleton
				sx={{
					width: '100%',
					height: '100px',
					marginTop: 4,
					marginBottom: 4
				}}
				variant="rounded"
			/>
		);

	if (isSuccess) {
		const { dtsStatus } = dtsData;

		const dtsScore = dtsStatus === DTS_COMPLETED ? dtsData.dtsScore : NA;

		const dtsBackgroundColor = dtsColors[getDtsScoreColor(dtsScore)];

		const isMiddle = dtsScore >= 41 && dtsScore <= 60;

		const dtsTextColor =
			dtsScore === NA || isMiddle ? primary.black : primary.white;

		return (
			<Box>
				<Box
					sx={{
						height: '100%',
						display: 'flex',
						alignItems: 'center'
					}}
				>
					<Typography
						variant="h2Gascogne"
						sx={{
							color: primary.indigo
						}}
					>
						Debt Tolerance Score
					</Typography>
					<SoraToolTip
						text="The debt tolerance score is a tool used to understand a client's preferences and tolerance for debt, which enables us to provide you with better liability solutions and recommendations based on this"
						toolTipSx={{
							marginLeft: 1,
							marginBottom: 0.5
						}}
						placement="bottom"
					/>
				</Box>
				<Paper
					elevation={3}
					sx={{
						padding: 2,
						marginTop: 2,
						marginBottom: 2,
						borderRadius: 4,
						display: 'flex',
						justifyContent: 'flex-start',
						alignItems: 'center'
					}}
				>
					<Box
						sx={{
							width: 80,
							height: 48,
							backgroundColor: dtsBackgroundColor,
							borderRadius: 4,
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center'
						}}
					>
						<Typography
							variant="h6"
							sx={{ color: dtsTextColor, fontSize: '1.5rem' }}
						>
							{dtsScore}
						</Typography>
					</Box>
					<Box
						sx={{
							width: '90%',
							marginLeft: 2,
							display: 'flex',
							alignItems: 'center'
						}}
					>
						<DebtToleranceMessage
							dtsData={dtsData}
							isClient={isClient}
							clientData={clientData}
						/>
					</Box>
				</Paper>
			</Box>
		);
	}
}

export default DebtToleranceCard;
