import { Box } from '@mui/material';
import { TextButton } from 'components/Button/Button';

import { useTheme } from '@emotion/react';

function PreviewButton({ isRejectedFile, isEmeddableFile, setFileToPreview }) {
	const SoraTheme = useTheme();

	const { primary } = SoraTheme.palette;

	return (
		<Box sx={{ width: 70 }}>
			{!isRejectedFile && isEmeddableFile && (
				<TextButton
					sx={{ color: primary.soraBlue, paddingTop: 1 }}
					onClick={() => {
						setFileToPreview();
					}}
				>
					Preview
				</TextButton>
			)}
		</Box>
	);
}

export default PreviewButton;
