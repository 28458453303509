import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { AppBar, Box, IconButton, Toolbar } from '@mui/material';

// Icons
import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';

// Our Components
import OpenMobileNav from 'components/Frame/OpenMobileNav';

// formerly MenuAppBar
export default function MobileNavBarToggle({ progress, userType }) {
	const { pathname } = useLocation();
	const [isMenuOpen, setIsMenuOpen] = useState(false);

	const toggleMenuOpen = () => setIsMenuOpen(!isMenuOpen);

	// Codsworth
	useEffect(() => {
		setIsMenuOpen(false);
	}, [pathname]);

	return (
		<AppBar position="static" sx={{ width: '100vw', bgcolor: 'white' }}>
			<Toolbar
				disableGutters
				sx={{
					justifyContent: 'space-between',
					alignItems: 'center',
					paddingLeft: 2,
					paddingRight: 2,
					height: '100%'
				}}
			>
				<Box
					component="img"
					src="https://prod-public-asset.s3.amazonaws.com/SoraLogos/SoraLogoBlack.svg"
					sx={{ height: 25 }}
				/>

				<IconButton
					size="large"
					edge="start"
					color="inherit"
					aria-label="menu"
					onClick={() => toggleMenuOpen()}
				>
					{!isMenuOpen ? (
						<MenuIcon sx={{ color: 'black' }} />
					) : (
						<CloseIcon sx={{ color: 'black' }} />
					)}
				</IconButton>
			</Toolbar>

			<OpenMobileNav
				isMenuOpen={isMenuOpen}
				toggleMenuOpen={toggleMenuOpen}
				progress={progress}
				userType={userType}
			/>
		</AppBar>
	);
}
