import { Typography } from '@mui/material';

// Our Components
import Dropdown from 'components/Dropdown/Dropdown';

// Our Constants
import { AUTO, HELOC, MORTGAGE, PERSONAL, STUDENT } from 'shared/constants';

// Our Utils
import { dataIsValid } from 'shared/utils';

// Local Constants
const MAX_DROPDOWN_WIDTH = 350;
const NO_RELEVANT_LOANS = [
	'Construction Loan',
	'Commercial Loan',
	'Farm Loan',
	'Lot Loan',
	'Securities Based Line',
	'Other'
];

function aggregateLenderData(objectsArray) {
	const result = {};

	objectsArray.forEach((obj) => {
		const { lender, outstandingBalance } = obj;

		if (!result[lender]) {
			// If lender is not already in the result, add it
			result[lender] = { ...obj };
		} else {
			// If lender is already in the result, sum the outstandingBalance
			result[lender].outstandingBalance += outstandingBalance;
		}
	});

	// Convert the result object back to an array
	return Object.values(result);
}

// helper fns
const getSelectedClientLoans = (selectedLoanType, clientWalletOverviewData) => {
	if (NO_RELEVANT_LOANS.includes(selectedLoanType)) return [];
	if (!dataIsValid(selectedLoanType)) return [];

	if (selectedLoanType.includes('New')) return [];

	if (selectedLoanType.includes('Automotive')) {
		// Automotive Loans
		return clientWalletOverviewData?.liability?.liabilities.filter(
			({ tradeLineType }) => tradeLineType === AUTO
		);
	}

	const isMortgageLoan =
		selectedLoanType.includes('Mortgage') ||
		selectedLoanType === 'Cash Out Refinance' ||
		selectedLoanType === HELOC;

	if (isMortgageLoan) {
		return clientWalletOverviewData?.liability?.liabilities.filter(
			({ tradeLineType }) => tradeLineType === MORTGAGE
		);
	}

	const isPersonalLoan = selectedLoanType.includes('Personal');

	if (isPersonalLoan) {
		return clientWalletOverviewData?.liability?.liabilities.filter(
			({ tradeLineType }) => tradeLineType === PERSONAL
		);
	}

	const isStudentLoan = selectedLoanType.includes('Student');

	if (isStudentLoan) {
		return clientWalletOverviewData?.liability?.liabilities.filter(
			({ tradeLineType }) => tradeLineType === STUDENT
		);
	}

	return [];
};

function PriceALoanRelevantLoans({
	selectedLoanType,
	clientWalletOverviewData,
	selectedClientLoan,
	setSelectedClientLoan
}) {
	const selectedClientLoans = getSelectedClientLoans(
		selectedLoanType,
		clientWalletOverviewData
	);

	const hasNoRelevantLoans =
		!selectedClientLoans || selectedClientLoans.length < 1;

	if (hasNoRelevantLoans) return null;

	const selectedClientLoanLenders = hasNoRelevantLoans
		? 'Client has no relevant loans'
		: selectedClientLoans.map((singleLoan) => singleLoan.lender);

	const handleSelectedClientLoan = (selectedLender) => {
		const aggregatedLoanData = aggregateLenderData(selectedClientLoans);

		const selectedLoan = aggregatedLoanData.find(
			(singleLoan) => singleLoan.lender === selectedLender
		);

		setSelectedClientLoan(selectedLoan);
	};

	return (
		<>
			<Typography variant="subtitle2" sx={{ marginRight: 4 }}>
				Relevant Loans:
			</Typography>

			{selectedLoanType === '' && (
				<Typography variant="body1">
					Please select a client and loan type.
				</Typography>
			)}

			{selectedLoanType && hasNoRelevantLoans && (
				<Typography variant="body1">
					Client has no relevant loans
				</Typography>
			)}

			{selectedLoanType && !hasNoRelevantLoans && (
				<Dropdown
					sx={{ flexGrow: 2, maxWidth: MAX_DROPDOWN_WIDTH }}
					onChange={handleSelectedClientLoan}
					items={selectedClientLoanLenders}
					selected={selectedClientLoan?.lender ?? ''}
				/>
			)}
		</>
	);
}

export default PriceALoanRelevantLoans;
