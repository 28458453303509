/* eslint-disable no-unreachable */
import { useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Grid, Typography } from '@mui/material';
import { useQueryClient } from 'react-query';
import { useTheme } from '@emotion/react';
import { isMobilePhone, isDate } from 'validator';

// Our components
import Input from 'components/Input/TextInput';
import LiablitiesProcessing from 'components/Client/Onboarding/Methodfi/LiabilitiesProcessing';
import Loader from 'components/Loader';
import PhoneNumberInput from 'components/Input/PhoneNumberInput';
import { PrimaryButton, TextButton } from 'components/Button/Button';
import SSNTextInput from 'components/Input/SSNTextInput';
import StandardDatePicker from 'components/DatePicker/StandardDatePicker';
import WelcomeModal from 'components/Client/Onboarding/WelcomeModal';

// Our Query Keys
import {
	EMPLOYEE_USER_DATA,
	USER_DATA,
	USERPROFILEDATA,
	FROM_SIGNUP
} from 'shared/query-keys';

// Our utils
import { isSubmissionReady } from 'shared/utils';
import convertDateToDatePickerFormat from 'shared/utils/formatting/convertDateToDatePickerFormat';
import convertDateToEndpointFormat from 'shared/utils/formatting/convertDateToEndpointFormat';

// Our Routes
import { LIABILITY_ADDITIONAL_ROUTE } from 'routes';

// Constants
import { TEXT } from 'components/Input/Types';
import PhoneNumberWhitelist from 'shared/phone-number-whitelist.json';

// Our hooks
import useMutateAddProfileData from 'hooks/useMutateAddProfileData';
import MethodfiConnect from 'components/Client/Onboarding/Methodfi/MethodfiConnect';
import capitalizeUserProfile from 'shared/utils/formatting/capitalizeUserProfile';

function getPrePopulatedUserData(queryClient) {
	const existingEmployeeUserData =
		queryClient.getQueryData(EMPLOYEE_USER_DATA);

	// if Employee data is available use it
	if (existingEmployeeUserData !== undefined) return existingEmployeeUserData;

	// if we got to this block the current user is not an employee
	// if we have their exisiting USER_DATA it means they are a client returning to the page (i.e pressed the back button after Methodfi for enter manually)
	const existingUserData = queryClient.getQueryData(USER_DATA);

	if (existingUserData !== undefined) return existingUserData;

	// if we got to this block it means the user is a CLIENT and they're visiting for the first time. Previously we returned undefined now its null.
	return null;
}

function AdditionalDetailsForm() {
	const SoraTheme = useTheme();
	const queryClient = useQueryClient();
	const navigate = useNavigate();

	// API Calls
	const updateProfileData = useMutateAddProfileData();
	const { isLoading } = updateProfileData;

	// PrePopulate check.
	const existingUserData = getPrePopulatedUserData(queryClient);

	const initialFirstName = existingUserData?.firstName ?? '';
	const initialLastName = existingUserData?.lastName ?? '';
	const initialPhoneNumber = existingUserData?.phoneNumber ?? '';
	const initialDob =
		convertDateToDatePickerFormat(existingUserData?.dob) ?? null;
	const isFromSignup = queryClient.getQueryData(FROM_SIGNUP);

	// State related to form data.
	const [firstName, setFirstName] = useState(initialFirstName);
	const [lastName, setLastName] = useState(initialLastName);
	const [phoneNumber, setPhoneNumber] = useState(initialPhoneNumber);
	const [dob, setDob] = useState(initialDob);
	const [ssn, setSSN] = useState('');

	// Methodfi Related State
	const [beginMethodTokenRequest, setBeginMethodTokenRequest] =
		useState(false);
	const [successfulMethodfiConnection, setSuccessfulMethodfiConnection] =
		useState(false);

	// Modal Related State
	const [isModalOpen, setIsModalOpen] = useState(isFromSignup ?? false); // if fromSignUp exists use it. Otherwise do not open the modal

	const userDetails = { dob, firstName, lastName, phoneNumber, ssn };

	MethodfiConnect(
		userDetails,
		beginMethodTokenRequest,
		setBeginMethodTokenRequest,
		setSuccessfulMethodfiConnection
	);

	const requiredFormData = [firstName, lastName];

	const isValidDate = useMemo(() => {
		const isStringDate = typeof dob === 'string';
		const isDateType = dob instanceof Date;
		if (isStringDate && dob !== '') {
			const isDateCheckWithConversion = isDate(new Date(dob));
			return isDateCheckWithConversion;
		}

		if (isDateType) {
			return isDate(dob);
		}
		// if dob is null this returns false;
		return false;
	}, [dob]);

	const isValidPhoneNumber = useMemo(() => {
		if (PhoneNumberWhitelist.includes(phoneNumber)) {
			return true;
		}
		return isMobilePhone(phoneNumber, 'en-US');
	}, [phoneNumber]);

	const isFormReady = useMemo(
		() =>
			isSubmissionReady(requiredFormData) &&
			phoneNumber !== '' &&
			isValidPhoneNumber &&
			isValidDate,
		[...requiredFormData, phoneNumber, dob] // phone number and dob have other criteria other than not just being empty.
	);

	const handleDobChange = (value) => {
		const isDateInvalid = value === null;
		if (isDateInvalid) {
			// ensures date is a string.
			setDob('');
			return;
		}
		setDob(value);
	};

	// Handle Additional Details form submit,
	const submitUserInformation = (shouldLaunchMethodfi = true) => {
		// Gather and store the data entered into form and associated companyId given from backend if found
		const cleanedPhoneNumber =
			phoneNumber && phoneNumber.replaceAll('-', '');

		const dobYearMonthDayFormat = convertDateToEndpointFormat(dob);

		const userData = capitalizeUserProfile({
			firstName,
			lastName,
			phoneNumber: cleanedPhoneNumber,
			dob: dobYearMonthDayFormat
		});

		// Save all this data to react query
		queryClient.setQueryData(USER_DATA, userData);

		// Call mutation to update user's data
		updateProfileData.mutate(userData, {
			onSuccess: () => {
				// this ensures that if this page was revisted that data is FRESH.
				queryClient.invalidateQueries(USERPROFILEDATA);
				setBeginMethodTokenRequest(shouldLaunchMethodfi);
				if (!shouldLaunchMethodfi) navigate(LIABILITY_ADDITIONAL_ROUTE);
			}
		});
	};

	if (successfulMethodfiConnection) return <LiablitiesProcessing />;

	return (
		<Box sx={{ marginLeft: 2, marginRight: 2, marginTop: 4 }}>
			<Typography
				data-test="heading"
				variant="h1Gascogne"
				component="h1"
				gutterBottom
			>
				Please provide your contact information
			</Typography>

			<Typography
				variant="body1"
				gutterBottom
				sx={{ marginTop: 2, marginBottom: 4 }}
			>
				We’ll use this information to securely gather your financial
				data
			</Typography>

			<Grid component="form" noValidate container spacing={2}>
				<Grid item xs={6}>
					{/* First Name */}
					<Input
						id="firstName"
						label="First Name"
						type={TEXT}
						onChange={setFirstName}
						value={firstName}
						subLabel="Enter your legal name"
						sx={{
							width: '100%'
						}}
						inputProps={{
							tabIndex: '1',
							'data-test': 'firstName'
						}}
					/>
				</Grid>
				<Grid item xs={6}>
					{/* Last Name */}
					<Input
						id="lastName"
						label="Last Name"
						type={TEXT}
						onChange={setLastName}
						value={lastName}
						subLabel="Enter your legal name"
						sx={{
							width: '100%'
						}}
						inputProps={{
							tabIndex: '2',
							'data-test': 'lastName'
						}}
					/>
				</Grid>

				<Grid item xs={6}>
					{/* Phone Number */}
					<PhoneNumberInput
						phoneNumber={phoneNumber}
						onChange={setPhoneNumber}
						isValidPhoneNumber={isValidPhoneNumber}
						inputProps={{
							tabIndex: '3',
							'data-test': 'phoneNumber'
						}}
					/>
				</Grid>
				<Grid item xs={6}>
					<StandardDatePicker
						id="dob"
						label="Date of Birth"
						helperText="Enter your birth date"
						onChange={handleDobChange}
						value={dob}
						error={dob === null || !isValidDate}
						errorText="Date of birth needs to be a valid date"
						inputProps={{
							tabIndex: '4',
							'data-test': 'dob'
						}}
					/>
				</Grid>
				<Grid item xs={6}>
					{/* Optional SSN */}
					<SSNTextInput
						ssnValue={ssn}
						onChange={setSSN}
						// eslint-disable-next-line jsx-a11y/tabindex-no-positive
						tabIndex="5"
					/>
				</Grid>
				<Grid item xs={12}>
					{!isLoading && (
						<>
							<PrimaryButton
								data-test="submit"
								onClick={submitUserInformation}
								sx={{
									marginTop: 4
								}}
								disabled={!isFormReady}
							>
								Next
							</PrimaryButton>

							<Typography variant="body1" marginTop={2}>
								- or -
							</Typography>

							<TextButton
								sx={{
									color: SoraTheme.palette.primary
										.mediumEmphasis,
									marginTop: 2
								}}
								onClick={() => {
									submitUserInformation(false);
								}}
								disabled={!isFormReady}
							>
								Enter Loans Manually
							</TextButton>
						</>
					)}

					{isLoading && <Loader />}
				</Grid>
			</Grid>
			<WelcomeModal
				isModalOpen={isModalOpen}
				setIsModalOpen={setIsModalOpen}
			/>
		</Box>
	);
}

export default AdditionalDetailsForm;
