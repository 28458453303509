import { Chip } from '@mui/material';
import { useMemo } from 'react';

// Our Utils
import { dollarFormatter } from 'shared/utils';

const getComparisonIcon = (currentComparisonQuery) => {
	const listOfPossibleComparisons = {
		'Greater than': '>',
		'Less than': '<',
		Exactly: '=',
		Between: '[ ]'
	};

	return listOfPossibleComparisons[currentComparisonQuery];
};

function getChipLabel(
	currentComparisonQuery,
	currentFilterBy,
	currentQueryValue,
	currentUpperBound
) {
	const comparisonSymbol = getComparisonIcon(currentComparisonQuery);

	if (currentFilterBy === 'Interest Rate') {
		if (currentComparisonQuery === 'Between')
			return `${currentFilterBy} [${currentQueryValue}%, ${currentUpperBound}%]`;

		return `${currentFilterBy} ${comparisonSymbol} ${currentQueryValue}%`;
	}

	const formattedCurrentQueryValue =
		dollarFormatter.format(currentQueryValue);

	const formattedUpperBoundValue = dollarFormatter.format(currentUpperBound);

	if (currentComparisonQuery === 'Between')
		return `${currentFilterBy} [${formattedCurrentQueryValue}, ${formattedUpperBoundValue}]`;

	return `${currentFilterBy} ${comparisonSymbol} ${formattedCurrentQueryValue}`;
}

function QueryEngineFilterChips({
	filterTags,
	filterTagsAvailable,
	removeFilterTag,
	setFilterTagsAvailable
}) {
	const renderFilterTags = useMemo(
		() =>
			filterTags.map(
				({
					betweenQueryUpperBound: currentUpperBound,
					comparisonQuery: currentComparisonQuery,
					filterBy: currentFilterBy,
					currentQueryValue
				}) => {
					const chipLabel = getChipLabel(
						currentComparisonQuery,
						currentFilterBy,
						currentQueryValue,
						currentUpperBound
					);

					return (
						<li key={currentFilterBy}>
							<Chip
								sx={{ marginBottom: 2 }}
								label={chipLabel}
								onDelete={() => {
									removeFilterTag(currentFilterBy);
									setFilterTagsAvailable(
										filterTagsAvailable.concat(
											currentFilterBy
										)
									);
								}}
							/>
						</li>
					);
				}
			),
		[filterTags, filterTagsAvailable]
	);

	return renderFilterTags;
}

export default QueryEngineFilterChips;
