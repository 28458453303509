export const STANDARD_REQUEST_STEPS = [
	'Pending information',
	'Looking for offers',
	'Select loan offer',
	'Under Review',
	'In Underwriting',
	'Completed'
];

export const REJECTED_REQUEST_STEPS = [
	'Initial request',
	'Additional Information',
	'Under Review',
	'In Underwriting',
	'Rejected'
];
