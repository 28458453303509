import {
	Autocomplete,
	Box,
	Checkbox,
	Chip,
	Divider,
	Fab,
	Grid,
	Paper,
	Skeleton,
	TextField,
	Typography
} from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { useEffect, useState, useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';

import AddIcon from '@mui/icons-material/Add';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

// Our Components
import AutocompleteTextinput from 'components/Input/AutocompleteTextinput';
import CurrencyTextInput from 'components/Input/CurrencyTextInput';
import Dropdown from 'components/Dropdown/Dropdown';
import Loader from 'components/Loader/index';
import QueryEngineFilterChips from 'components/Chips/QueryEngineFilterChip';
import QueryEngineTable from 'components/DataTable/QueryEngineTable';
import TextInput from 'components/Input/TextInput';

// Our Hooks
import useGetClients from 'hooks/useGetClients';
import useMutateGenerateReport from 'hooks/genereateReport/useMutateGenerateReport';

// Our Constants
import {
	ALL,
	AUTO,
	MORTGAGE,
	STUDENT,
	PERSONAL,
	HELOC
} from 'shared/constants';

// Input Types
import { PERCENT } from 'components/Input/Types';

// Our Utils
import normalizeLoanType from 'shared/utils/clientOnboarding/normalizeLoanType';
import { isSubmissionReady } from 'shared/utils';
import { PrimaryButton } from 'components/Button/Button';

const SUPPORTED_LOAN_TYPES = [
	ALL,
	AUTO,
	MORTGAGE,
	STUDENT,
	PERSONAL,
	HELOC
].map((loanType) => normalizeLoanType(loanType));

const SUPPORTED_QUERY_FIELDS = [
	'Interest Rate',
	'Untapped Savings',
	'Upside Potential',
	'Outstanding Balance',
	'Monthly Payment',
	'Equity Available'
];

const DEFAULT_DROPDOWN_LENGTH = SUPPORTED_QUERY_FIELDS.length;

const getFieldName = (currentFieldName) => {
	const separatedFieldName = currentFieldName.split(' ');
	const firstWord = separatedFieldName[0].toLowerCase();
	const restOfWords = separatedFieldName
		.slice(1)
		.map(
			(currentWord) => currentWord[0].toUpperCase() + currentWord.slice(1)
		);

	return firstWord + restOfWords.join('');
};

function ClientReports() {
	const generateReportApiCall = useMutateGenerateReport();

	// Query Data
	const [clientNameTyped, setClientNameTyped] = useState('');
	const [liabilityCategories, setLiabilityCategories] = useState('');
	const [filterBy, setFilterBy] = useState('');
	const [comparisonQuery, setComparisonQuery] = useState('');
	const [betweenQueryUpperBound, setBetweenQueryUpperBound] = useState('');
	const [currentQueryValue, setCurrentQueryValue] = useState('');

	// Tag Data
	const [listOfClientsForQuery, setListOfClientsForQuery] = useState([]);
	const [filterTags, setFilterTags] = useState([]);
	const [filterTagsAvailable, setFilterTagsAvailable] = useState(
		SUPPORTED_QUERY_FIELDS
	);

	const [queryResults, setQueryResults] = useState([]);

	// Filter Client List
	const [filterClientList, setFilterClientList] = useState('');

	// Api Request to get list of clients
	const { isLoading, isSuccess, data } = useGetClients(filterClientList);
	const { isLoading: isQueryLoading, isSuccess: isQuerySuccess } =
		generateReportApiCall;

	const handleFilterByChange = (incomingValue) => {
		// If filter by changed reset the other options
		setComparisonQuery('');
		setCurrentQueryValue('');
		setBetweenQueryUpperBound('');

		// set filterBy on the new value;
		setFilterBy(incomingValue);
	};

	const clearInputFields = () => handleFilterByChange('');

	const isGenerateReportReady =
		listOfClientsForQuery.length > 0 &&
		liabilityCategories !== '' &&
		filterTags.length > 0;

	const isFabReady =
		isSubmissionReady([
			liabilityCategories,
			filterBy,
			comparisonQuery,
			currentQueryValue
		]) && filterTags.length < 4;

	const handleFabClick = () => {
		const currentQueryOption = {
			filterBy,
			comparisonQuery,
			currentQueryValue,
			betweenQueryUpperBound
		};

		setFilterTags(filterTags.concat(currentQueryOption));
		clearInputFields();
	};

	const handleGenerateReport = () => {
		const clientIds = listOfClientsForQuery.map(({ clientId }) => clientId);
		const tradelineType = liabilityCategories.toUpperCase();

		const queryFieldtags = filterTags.map((currentFilterBy) => {
			const {
				betweenQueryUpperBound: currentUpperBound,
				filterBy: currentFieldName,
				comparisonQuery: currentComparisonQuery
			} = currentFilterBy;

			const fieldName = getFieldName(currentFieldName);

			if (currentComparisonQuery === 'Exactly') {
				return {
					lessThan: currentFilterBy.currentQueryValue,
					greaterThan: currentFilterBy.currentQueryValue,
					fieldName
				};
			}

			if (currentComparisonQuery === 'Greater than')
				return {
					greaterThan: +currentFilterBy.currentQueryValue,
					lessThan: Number.MAX_VALUE,
					fieldName
				};
			if (currentComparisonQuery === 'Less than')
				return {
					greaterThan: -Number.MAX_VALUE,
					lessThan: +currentFilterBy.currentQueryValue,
					fieldName
				};

			//
			return {
				fieldName,
				lessThan: +currentUpperBound,
				greaterThan: +currentFilterBy.currentQueryValue
			};
		});

		const reportQuery = {
			clients: clientIds,
			fields: queryFieldtags,
			tradelineType
		};

		// if the first item in clientId arr is an object with clientId of -1
		// it means the user selected ALL clients
		// and thus the BE spec is to omit `clients` from request payload

		if (clientIds[0] === -1) {
			delete reportQuery.clients;
		}

		generateReportApiCall.mutate(reportQuery, {
			onSuccess: ({ data: queryData }) => setQueryResults(queryData.data)
		});
	};

	const removeFilterTag = (filterTagClickedOn) => {
		setFilterTags(
			filterTags.filter(
				({ filterBy: currentFilterTag }) =>
					currentFilterTag !== filterTagClickedOn
			)
		);
	};

	function renderTextInputs(currentlySelectedFilter, comparison, isDisabled) {
		if (comparison === 'Between') {
			if (currentlySelectedFilter === 'Interest Rate') {
				return (
					<Box sx={{ display: 'flex', gap: 2 }}>
						<TextInput
							disabled={isDisabled}
							type={PERCENT}
							label="Lower Bound"
							value={currentQueryValue}
							onChange={setCurrentQueryValue}
						/>
						<TextInput
							disabled={isDisabled}
							type={PERCENT}
							label="Upper Bound"
							value={betweenQueryUpperBound}
							onChange={setBetweenQueryUpperBound}
						/>
					</Box>
				);
			}

			return (
				<Box sx={{ display: 'flex', gap: 2 }}>
					<CurrencyTextInput
						disabled={isDisabled}
						value={currentQueryValue}
						onChange={setCurrentQueryValue}
						label="Lower Bound"
					/>
					<CurrencyTextInput
						disabled={isDisabled}
						value={betweenQueryUpperBound}
						onChange={setBetweenQueryUpperBound}
						label="Upper Bound"
					/>
				</Box>
			);
		}

		if (currentlySelectedFilter === 'Interest Rate')
			return (
				<TextInput
					disabled={isDisabled}
					type={PERCENT}
					label="Value"
					value={currentQueryValue}
					onChange={setCurrentQueryValue}
				/>
			);
		return (
			<CurrencyTextInput
				disabled={isDisabled}
				value={currentQueryValue}
				onChange={setCurrentQueryValue}
				label="Value"
			/>
		);
	}

	// Changes the options for the dropdown
	useEffect(() => {
		const isFilterTagsEmpty = filterTags.length < 1;

		if (
			isFilterTagsEmpty ||
			(isFilterTagsEmpty &&
				filterTagsAvailable.length !== DEFAULT_DROPDOWN_LENGTH)
		) {
			setFilterTagsAvailable(SUPPORTED_QUERY_FIELDS);
		} else {
			const currentListOfTagsSelected = new Set(
				filterTags.map(
					({ filterBy: currentFilterTag }) => currentFilterTag
				)
			);
			const filteredListForDropdown = SUPPORTED_QUERY_FIELDS.filter(
				(currentFilterTag) =>
					!currentListOfTagsSelected.has(currentFilterTag) &&
					SUPPORTED_QUERY_FIELDS.includes(currentFilterTag)
			);

			setFilterTagsAvailable(filteredListForDropdown);
		}
	}, [filterTags]);

	const shouldDisableQueryAdditions = filterTags.length > 2;

	const listOfFilteredClients = useMemo(() => {
		if (isSuccess) {
			const filteredClientList = data.map(({ fullName, clientId }) => ({
				fullName,
				clientId
			}));

			if (listOfClientsForQuery.length > 0) {
				const firstClient = listOfClientsForQuery[0];

				const { clientId: firstClientId } = firstClient;

				if (firstClientId !== -1) return filteredClientList;
			}
			return [{ fullName: 'All', clientId: -1 }].concat(
				filteredClientList
			);
		}

		return [];
	}, [data, listOfClientsForQuery]);

	if (isLoading) {
		return (
			<>
				<Helmet>
					<title>Reports</title>
				</Helmet>

				<Typography
					variant="h1Gascogne"
					component="h1"
					gutterBottom
					sx={{ marginBottom: 4 }}
				>
					Reports
				</Typography>

				<Grid container spacing={2}>
					<Grid item xs={4}>
						<Skeleton variant="rectangular" height={70} />
					</Grid>

					<Grid item xs={4}>
						<Skeleton variant="rectangular" height={70} />
					</Grid>

					<Grid item xs={4} />

					<Grid item xs={3}>
						<Skeleton variant="rectangular" height={70} />
					</Grid>

					<Grid item xs={3} sx={{ marginBottom: 10 }}>
						<Skeleton variant="rectangular" height={70} />
					</Grid>

					<Grid item xs={3}>
						<Skeleton variant="rectangular" height={70} />
					</Grid>

					<Grid item xs={3}>
						<Skeleton variant="circular" height={70} width={70} />
					</Grid>

					<Grid item xs={10} sx={{ marginBottom: 4 }}>
						<Skeleton variant="rectangular" height={300} />
					</Grid>
					<Grid item xs={10} sx={{ marginBottom: 4 }}>
						<Skeleton variant="rectangular" height={400} />
					</Grid>
				</Grid>
			</>
		);
	}

	if (isSuccess) {
		return (
			<>
				<Helmet>
					<title>Reports</title>
				</Helmet>

				<Typography
					variant="h1Gascogne"
					component="h1"
					gutterBottom
					sx={{ marginBottom: 4 }}
				>
					Reports
				</Typography>

				<Grid container spacing={2}>
					<Grid item xs={4}>
						<>
							<Typography
								variant="subtitle1"
								sx={{ marginBottom: 1 }}
							>
								Select Clients:
							</Typography>
							<Autocomplete
								autoComplete
								autoHighlight
								disableCloseOnSelect
								getOptionLabel={({ fullName }) => fullName}
								multiple
								options={listOfFilteredClients}
								inputValue={clientNameTyped}
								onInputChange={(e, newInputValue) =>
									setClientNameTyped(newInputValue)
								}
								onChange={(e, newValue) => {
									if (
										newValue[0] &&
										newValue[0].clientId === -1
									) {
										setFilterClientList(uuidv4());
									} else if (newValue.length < 1) {
										setFilterClientList('');
									}
									setListOfClientsForQuery(newValue);
								}}
								isOptionEqualToValue={(option, value) => {
									const { clientId } = option;

									const { clientId: selectedClientId } =
										value;

									return clientId === selectedClientId;
								}}
								renderInput={(params) => (
									<TextField
										{...params}
										type="text"
										label=""
										helperText="List of your clients"
										variant="outlined"
									/>
								)}
								// eslint-disable-next-line arrow-body-style
								renderOption={(props, option, { selected }) => (
									<li {...props} key={option.clientId}>
										<Checkbox
											icon={
												<CheckBoxOutlineBlankIcon fontSize="small" />
											}
											checkedIcon={
												<CheckBoxIcon fontSize="small" />
											}
											style={{ marginRight: 8 }}
											checked={selected}
										/>
										{option.fullName}
									</li>
								)}
								renderTags={() => null}
								value={listOfClientsForQuery}
							/>
						</>
					</Grid>

					<Grid item xs={4}>
						<AutocompleteTextinput
							autoComplete
							freeSolo={false}
							helperText="Liabilities"
							optionList={SUPPORTED_LOAN_TYPES}
							label="Select Liabilities:"
							handleChange={setLiabilityCategories}
							selected={liabilityCategories}
							withoutFilter={false}
						/>
					</Grid>

					<Grid item xs={4} />

					<Grid item xs={3}>
						<Dropdown
							disabled={shouldDisableQueryAdditions}
							items={filterTagsAvailable}
							label="Filter"
							onChange={handleFilterByChange}
							selected={filterBy}
						/>
					</Grid>

					<Grid item xs={3} sx={{ marginBottom: 10 }}>
						<Dropdown
							disabled={shouldDisableQueryAdditions}
							items={[
								'Greater than',
								'Less than',
								'Exactly',
								'Between'
							]}
							label="Comparison"
							onChange={setComparisonQuery}
							selected={comparisonQuery}
						/>
					</Grid>

					{/* no break */}
					<Grid item xs={3}>
						{renderTextInputs(
							filterBy,
							comparisonQuery,
							shouldDisableQueryAdditions
						)}
					</Grid>

					<Grid item xs={3}>
						<Box
							sx={{
								display: 'flex',
								alignItems: 'center',
								height: '65%'
							}}
						>
							<Fab
								color="primary"
								aria-label="add"
								disabled={!isFabReady}
								onClick={handleFabClick}
							>
								<AddIcon />
							</Fab>
						</Box>
					</Grid>

					{/* TODO increase elevation on Paper */}
					<Grid
						container
						item
						component={Paper}
						xs={11}
						sx={{
							justifyContent: 'space-between',
							minHeight: 300,
							listStyle: 'none',
							textAlign: 'center',
							marginLeft: 2
						}}
					>
						<Grid item xs={5}>
							<Typography
								variant="subtitle1"
								sx={{ marginBottom: 1 }}
							>
								Clients:
							</Typography>
							<Box
								elevation={10}
								component="ul"
								sx={{
									display: 'flex',
									justifyContent: 'center',
									boxSizing: 'content-box',
									flexWrap: 'wrap',
									listStyle: 'none',
									gap: 2,
									padding: 1,
									margin: 0,
									minHeight: '80%',
									width: '100%'
								}}
							>
								{listOfClientsForQuery.map(
									({ fullName, clientId }) => {
										const chipLabel =
											fullName === 'All'
												? 'All'
												: `${fullName} - ${clientId}`;

										const isAllSelected = clientId === -1;

										return (
											<li key={clientId}>
												<Chip
													label={chipLabel}
													onDelete={() => {
														if (isAllSelected) {
															setFilterClientList(
																''
															);
															setListOfClientsForQuery(
																[]
															);
														} else {
															const filteredList =
																listOfClientsForQuery.filter(
																	({
																		clientId:
																			currentClientId
																	}) =>
																		currentClientId !==
																		clientId
																);

															setListOfClientsForQuery(
																filteredList
															);
														}
													}}
												/>
											</li>
										);
									}
								)}
							</Box>
						</Grid>

						<Grid item xs={2} sx={{ height: '100%' }}>
							<Box
								sx={{
									width: '100%',
									height: '100%',
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center'
								}}
							>
								<Divider
									orientation="vertical"
									sx={{ height: '100%' }}
								>
									<Typography>
										{liabilityCategories ||
											'Select a liability'}
									</Typography>
								</Divider>
							</Box>
						</Grid>

						<Grid item xs={5} sx={{ alignText: 'center' }}>
							<Typography variant="subtitle1" marginBottom={2}>
								Criteria (you can add up to 3):
							</Typography>
							<div
								sx={{
									display: 'flex',
									boxSizing: 'content-box'
								}}
							>
								<QueryEngineFilterChips
									filterTags={filterTags}
									filterTagsAvailable={filterTagsAvailable}
									removeFilterTag={removeFilterTag}
									setFilterTagsAvailable={
										setFilterTagsAvailable
									}
								/>
							</div>
						</Grid>
					</Grid>

					<Grid item xs={12} sx={{ marginBottom: 1 }}>
						{isQueryLoading && <Loader size={40} />}

						{!isQueryLoading && (
							<PrimaryButton
								onClick={() => handleGenerateReport()}
								disabled={!isGenerateReportReady}
							>
								Generate Report
							</PrimaryButton>
						)}
					</Grid>
					<Grid item xs={11}>
						<QueryEngineTable
							queryResults={queryResults}
							isLoading={isQueryLoading}
							isSuccess={isQuerySuccess}
						/>
					</Grid>
				</Grid>
			</>
		);
	}
}

export default ClientReports;
