import { useEffect } from 'react';

// our utils
import { dataIsValid } from 'shared/utils';

function usePrepopulateState(stateValues, dependencies) {
	useEffect(() => {
		try {
			const safeStateValues = stateValues.filter(
				({ value }) => dataIsValid(value) && value !== 'undefined'
			);
			safeStateValues.forEach(({ setState, value }) => {
				setState(value);
			});
		} catch (error) {
			console.error(error);
		}
	}, dependencies);
}

export default usePrepopulateState;
