import { useMethod } from 'react-method-elements/dist/index.ts';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import { useEffect } from 'react';

// Our Hooks
import useMutateGetMethodfiToken from 'hooks/methodfi/useMutateGetMethodfiToken';
import useMutateGetMethodfiLiabilities from 'hooks/methodfi/useMutateGetMethodfiLiabilities';

// Our Query Keys
import { ERROR_MESSAGE_DATA, METHODFI_ENTITY_ID } from 'shared/query-keys';

// Our Routes
import { CLIENT_ONBOARDING_UNABLE_TO_VERIFY_ROUTE } from 'routes';

// Our Utils
import convertDateStringToMethodfiFormat from 'shared/utils/formatting/convertDateStringToMethodfiFormat';

// Constant that declares the Environment Method will work in
const METHODFI_ENVIRONMENT = process.env.REACT_APP_METHODFI_ENV;

function MethodfiConnect(
	userDetails,
	beginMethodTokenRequest,
	setBeginMethodTokenRequest,
	setAccountConnectionSuccess
) {
	const navigate = useNavigate();
	const queryClient = useQueryClient();
	const getMethodfiToken = useMutateGetMethodfiToken();
	const getMethodfiLiabilities = useMutateGetMethodfiLiabilities();

	const method = useMethod({
		env: METHODFI_ENVIRONMENT, // (dev / sandbox / production)
		onSuccess: () => {
			// This Success call is fired After security questions are answered OR is bypassed via PII auth.
			const entityId = queryClient.getQueryData(METHODFI_ENTITY_ID);
			getMethodfiLiabilities.mutate(entityId, {
				onError: () => {
					navigate(CLIENT_ONBOARDING_UNABLE_TO_VERIFY_ROUTE);
				}
			});
			setAccountConnectionSuccess(true);
			method.close();
		},
		onError: ({ sub_type: subType }) => {
			if (subType === 'ENTITY_AUTH_SESSION_LOCKED') {
				queryClient.setQueryData(ERROR_MESSAGE_DATA, [
					'Your account is temporarily locked. Please try again later.',
					'Contact support at contact@sorafinance.com if you continue to experience issues.'
				]);
				navigate(CLIENT_ONBOARDING_UNABLE_TO_VERIFY_ROUTE);
			}
			method.close();
		},
		onExit: () => {
			setBeginMethodTokenRequest(false);
		}
	});

	// Treat this effect as an event listener
	// this event listener is listening to beginMethodToken Request
	// if that value changes it will run the function
	// if the value changes and its TRUE it will fire the api call.
	useEffect(() => {
		if (beginMethodTokenRequest) {
			// if this is true it means we want to begin the request for the token.
			const { dob, firstName, lastName, phoneNumber, ssn } = userDetails;

			let methodfiAcceptedDate = dob;

			if (typeof dob !== 'string') {
				const parsedDob = dob.toLocaleDateString('en-US', {
					year: 'numeric',
					day: '2-digit',
					month: '2-digit'
				});

				const splitDate = parsedDob.split('/');

				const year = splitDate[2];
				const day = splitDate[1];
				const month = splitDate[0];

				methodfiAcceptedDate = `${year}-${month}-${day}`;
			} else {
				methodfiAcceptedDate = convertDateStringToMethodfiFormat(dob);
			}

			const cleanedPhoneNumber =
				phoneNumber && phoneNumber.replaceAll('-', '');

			const userDetailsSentToMFI = {
				first_name: firstName,
				last_name: lastName,
				phone: cleanedPhoneNumber,
				dob: methodfiAcceptedDate
			};

			const hasSSNAndNotEmpty = ssn && ssn.length > 0;

			if (hasSSNAndNotEmpty)
				userDetailsSentToMFI.ssn = ssn.replaceAll('-', '');

			getMethodfiToken.mutate(userDetailsSentToMFI, {
				onSuccess: (tokenData) => {
					if (
						tokenData?.message ===
						"User's profile not found in MethodFI"
					) {
						navigate(CLIENT_ONBOARDING_UNABLE_TO_VERIFY_ROUTE);
					}
					const { elementsId, entityId } = tokenData;
					queryClient.setQueryData(METHODFI_ENTITY_ID, entityId);
					method.open(elementsId);
				},
				onError: () => {
					navigate(CLIENT_ONBOARDING_UNABLE_TO_VERIFY_ROUTE);
				}
			});
		}
	}, [beginMethodTokenRequest]); // this gets triggered when beginMethodTokenRequest changes
}

export default MethodfiConnect;
