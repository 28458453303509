import { Box, Link as ExternalLink, Grid, Typography } from '@mui/material';
import { useTheme } from '@emotion/react';

// Our Components
import LoadingSkeleton from 'components/Frame/LoadingSkelton';
import Modal from 'components/Modal/Modal';
import { TertiaryButton } from 'components/Button/Button';

// Our Utils
import copyToClipboard from 'shared/utils/events/copyToClipBoard';

const MODAL_MESSAGE = `As part of the services I deliver to you as your financial advisor,

I can help manage and optimize your loans to save money.

I'd like to better understand your preferences and tolerance for debt to know the best way to support you in growing your net worth. 

I've built an initial profile for you with my partner, Sora Finance. 

Click the following link which will take you to that profile and to The Debt Tolerance Assessment, which will ask you to complete a short 3-5 minutes interview.`;

function DebtToleranceModal({ isModalOpen, closeModal, isLoading, uniqueUrl }) {
	const SoraTheme = useTheme();
	const { primary } = SoraTheme.palette;

	return (
		<Modal
			title="Here's an email template to send to your client"
			subtitle="Copy this text paste it in your email client and send it to your client"
			isOpen={isModalOpen}
			handleClose={closeModal}
		>
			<Grid container>
				<Grid item xs={12}>
					<Box>
						<Box
							sx={{
								border: `1px solid ${primary.lightGrey}`,
								borderRadius: 2,
								padding: 4
							}}
						>
							<Typography
								variant="body2"
								sx={{
									color: primary.grey,
									lineHeight: 2
								}}
							>
								{MODAL_MESSAGE}
							</Typography>

							<Typography variant="body2">
								{!isLoading && (
									<ExternalLink
										href={uniqueUrl}
										sx={{ cursor: 'pointer' }}
									>
										Click here to complete the assessment
									</ExternalLink>
								)}

								{isLoading && (
									<LoadingSkeleton
										sx={{ width: '80%', height: 30 }}
									/>
								)}
							</Typography>
						</Box>

						<TertiaryButton
							sx={{ marginTop: 4, marginRight: 4 }}
							onClick={() => {
								copyToClipboard(
									`${MODAL_MESSAGE} \n\n${uniqueUrl}`
								);
								closeModal();
							}}
						>
							Copy text
						</TertiaryButton>
					</Box>
				</Grid>
			</Grid>
		</Modal>
	);
}

export default DebtToleranceModal;
