import { Tooltip } from '@mui/material';

function RequiredFieldsTooltip({ children }) {
	return (
		<Tooltip title="Please complete all required fields *">
			<span>{children}</span>
		</Tooltip>
	);
}

export default RequiredFieldsTooltip;
