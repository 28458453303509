/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import { useLocation, useParams, generatePath } from 'react-router-dom';
import { Tooltip } from '@mui/material';
import { useMemo, useState, forwardRef } from 'react';
import PropTypes from 'prop-types';

// Our Components
import Loader from 'components/Loader';
import { PrimaryButton } from 'components/Button/Button';

// Our Hooks
import useGetAllUserLiabilities from 'hooks/clientOnboarding/useGetAllUserLiabilities';
import useGetSupportedLiabilities from 'hooks/clientOnboarding/useGetSupportedLiabilities';
import useWasFromWallet from 'hooks/utilityHook/useWasFromWallet';

// Our Routes
import {
	ADVISOR_BASE_NEW_LOAN_SELECTION_ROUTE,
	ADVISOR_DYNAMIC_WALLET_ROUTE,
	CLIENT_NEW_LOAN_SELECTION_ROUTE,
	CLIENT_SORAWALLET_ROUTE
} from 'routes';

// Our Utils
import getNextLiabilityRoute from 'shared/utils/clientOnboarding/getNextLiabilityRoute';

const ForwardedButton = forwardRef((props, ref) => (
	<PrimaryButton ref={ref} {...props} />
));

const getNonOnboardingNextRoute = (
	isFromWallet,
	isAdvisorAddNewRoute,
	isClientAddNewRoute,
	clientId
) => {
	if (isFromWallet) {
		if (isAdvisorAddNewRoute) {
			// Advisors need to route back to their clients wallet so lets do that.
			const clientWalletRoute = generatePath(
				ADVISOR_DYNAMIC_WALLET_ROUTE,
				{
					clientId
				}
			);
			return clientWalletRoute;
		}

		// Clients can simply go to their own wallet and thus do not need to reference their own id.
		if (isClientAddNewRoute) return CLIENT_SORAWALLET_ROUTE;
	}

	return -1;
};

function LiabilityDynamicButton({
	disabled,
	isMutationLoading,
	onClick,
	tooltipText
}) {
	const isFromWallet = useWasFromWallet();

	const [isHovered, setIsHovered] = useState(false);

	const location = useLocation();

	const { pathname } = location;

	const { tradeLineId: currentTradeLineId, clientId } = useParams();

	const isOnboardingRoute = pathname.includes('onboarding');

	const isAdvisorAddNewRoute = pathname.includes(
		ADVISOR_BASE_NEW_LOAN_SELECTION_ROUTE
	);

	const isClientAddNewRoute = pathname.includes(
		CLIENT_NEW_LOAN_SELECTION_ROUTE
	);

	const { isLoading, isSuccess, data } =
		useGetAllUserLiabilities(isOnboardingRoute);

	const filteredLiabilityList = useGetSupportedLiabilities(data, isSuccess);

	const userTradeLines = useMemo(
		() =>
			filteredLiabilityList.map(({ tradelineId, tradeLineType }) => {
				const formattedTradeLineType = tradeLineType?.toLowerCase();
				return {
					tradelineId,
					tradeLineType: formattedTradeLineType
				};
			}),
		[filteredLiabilityList]
	);

	const handleOnClick = (e, route) => {
		e.preventDefault();
		onClick(route);
	};

	// IMPORTANT order here matters!
	if (isLoading || isMutationLoading) {
		return <Loader boxSX={{ alignItems: 'center' }} />;
	}

	if (isSuccess && isOnboardingRoute) {
		const nextRoute = getNextLiabilityRoute(
			pathname,
			currentTradeLineId,
			userTradeLines
		);

		return (
			<Tooltip title={tooltipText}>
				<span>
					<ForwardedButton
						disabled={disabled}
						data-test="next"
						onClick={(e) => handleOnClick(e, nextRoute)}
					>
						Next
					</ForwardedButton>
				</span>
			</Tooltip>
		);
	}

	// we need to wrap it in the tooltip now.
	const nextRoute = getNonOnboardingNextRoute(
		isFromWallet,
		isAdvisorAddNewRoute,
		isClientAddNewRoute,
		clientId
	);

	return (
		<Tooltip
			title={tooltipText}
			open={isHovered && disabled}
			onClose={() => setIsHovered(false)}
			disableHoverListener={!disabled}
		>
			<span
				onMouseOver={() => setIsHovered(true)}
				onMouseOut={() => setIsHovered(false)}
			>
				<ForwardedButton
					disabled={disabled}
					data-test="next"
					onClick={(e) => handleOnClick(e, nextRoute)}
					onMouseOver={() => setIsHovered(true)}
				>
					Save
				</ForwardedButton>
			</span>
		</Tooltip>
	);
}

LiabilityDynamicButton.propTypes = {
	disabled: PropTypes.bool,
	onClick: PropTypes.func.isRequired,
	isMutationLoading: PropTypes.bool,
	tooltipText: PropTypes.string
};

LiabilityDynamicButton.defaultProps = {
	disabled: false,
	isMutationLoading: false,
	tooltipText: 'Please complete all required fields'
};

export default LiabilityDynamicButton;
