import { useCallback } from 'react';
import PropTypes from 'prop-types';

// Our Components
import TextInput from 'components/Input/TextInput';

// Input types
import { YEAR } from 'components/Input/Types';

// Common Props
import CommonPropTypes from 'shared/prop-types';

function YearInput({ disabled, value, onChange, sx }) {
	const handleYearChange = useCallback((incomingValue) => {
		if (incomingValue) {
			const number = Number(incomingValue);
			if (
				isNaN(number) ||
				!Number.isInteger(number) ||
				number <= 0 ||
				number > 10000
			) {
				return;
			}
		}
		onChange(incomingValue.trim());
	}, []);

	return (
		<TextInput
			disabled={disabled}
			sx={sx}
			type={YEAR}
			inputSx={{ height: 40 }}
			value={value}
			onChange={handleYearChange}
		/>
	);
}

YearInput.propTypes = {
	sx: CommonPropTypes.sx,
	disabled: PropTypes.bool
};

YearInput.defaultProps = {
	sx: {},
	disabled: false
};

export default YearInput;
