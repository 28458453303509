// Our Components
import Dropdown from 'components/Dropdown/Dropdown';

function MarginalDollarOverflowDropdown({
	overflowAssetCategory,
	setOverflowAssetCategory
}) {
	const { selected, overflowAssetCategoryOptions } = overflowAssetCategory;

	return (
		<Dropdown
			label="Overflow asset category:"
			labelVariant="body2"
			items={overflowAssetCategoryOptions}
			onChange={setOverflowAssetCategory}
			selected={selected}
		/>
	);
}

export default MarginalDollarOverflowDropdown;
