import {
	GridToolbarContainer,
	GridToolbarColumnsButton,
	GridToolbarFilterButton,
	GridToolbarExport
} from '@mui/x-data-grid';

function DataTableToolBar() {
	return (
		<GridToolbarContainer
			sx={{
				display: 'flex',
				justifyContent: 'flex-end',
				marginBottom: 2
			}}
		>
			<GridToolbarColumnsButton />
			<GridToolbarFilterButton />
			<GridToolbarExport />
		</GridToolbarContainer>
	);
}

export default DataTableToolBar;
