import { Box, Typography } from '@mui/material';
import { useMutation, useQueryClient } from 'react-query';
import { useTheme } from '@emotion/react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import emailjs from '@emailjs/browser';

// Our Components
import CardItem from 'components/SoraWallet/Cards/CardItem';
import Loader from 'components/Loader';
import { TertiaryButton } from 'components/Button/Button';

// Our Assets
import DollarIcon from 'assets/icons/wallet_icons/DollarIcon.svg';

// Utils
import { dollarFormatter, roundToDecimal } from 'shared/utils';
import calculateEquityAvailable from 'shared/utils/calculations/calculateEquityAvailable';

// Our Routes
import { LOAN_INQUIRY_ROUTE } from 'routes';

// query keys
import {
	CLIENT_DATA,
	ERROR_MESSAGE_DATA,
	SUCCESS_MESSAGE_DATA
} from 'shared/query-keys';

// EmailJs variables
const EMAIL_SERVICE_ID = process.env.REACT_APP_EMAILJS_SERVICE_ID;
const EMAIL_TEMPLATE_ID = process.env.REACT_APP_EMAILJS_NEW_LOAN_TEMPLATE_ID;
const EMAIL_PUBLIC_KEY = process.env.REACT_APP_EMAILJS_PUBLIC_KEY;

function EquityAvailableCard({ currentLoanDetails, isClient }) {
	const navigate = useNavigate();
	const SoraTheme = useTheme();
	const { primary } = SoraTheme.palette;

	const queryClient = useQueryClient();

	const totalOutstandingBalance =
		currentLoanDetails?.totalOutstandingBalance ?? 0;

	const equityAvailable = calculateEquityAvailable(
		currentLoanDetails?.home,
		totalOutstandingBalance
	);

	const formattedEquityAvailable = dollarFormatter.format(
		equityAvailable < 0 ? 0 : equityAvailable
	);

	// Trigger email send
	const sendNewLoanInquiry = useMutation(
		async () => {
			// used for email
			const clientData = queryClient.getQueryData(CLIENT_DATA);

			const clientEmail = clientData?.email ?? '';

			const clientFirstName = clientData?.firstName ?? '';

			const clientLastName = clientData?.lastName ?? '';

			const formattedTotalOutstandingBalance = dollarFormatter.format(
				totalOutstandingBalance ?? 0
			);

			const costOfDebt = dollarFormatter.format(
				currentLoanDetails?.costOfDebt ?? 0
			);

			const monthlyPayment = dollarFormatter.format(
				currentLoanDetails?.monthlyPayment ?? 0
			);
			const remainingMonths = currentLoanDetails?.remainingMonth ?? 0;

			const YearsRemaining =
				remainingMonths < 12
					? '< 1'
					: roundToDecimal(remainingMonths / 12, 1);

			const interestRate = roundToDecimal(
				currentLoanDetails?.interestRate,
				1
			);
			const CashoutPotential = dollarFormatter.format(
				currentLoanDetails?.cashOutPotential
			);

			const homeId = currentLoanDetails?.home?.id;

			const addressLine1 = currentLoanDetails?.home?.addressLine1 ?? '';
			const addressLine2 = currentLoanDetails?.home?.addressLine2 ?? '';

			const homeAddress = addressLine1 + addressLine2;
			const emailSubject = `HELOC Requested | ${clientFirstName} ${clientLastName}`;

			let emailJsParams = {
				name: `${clientFirstName} ${clientLastName}`,
				subject: emailSubject
			};

			const message = `
			${clientFirstName} ${clientLastName} is interested in taking out a HELOC loan for the following liability.
			Email: ${clientEmail}
			------
			Home ID: ${homeId}
			Home Address: ${homeAddress}
			Loan Interest Rate: ${interestRate}%
			Loan Monthly Payment: ${monthlyPayment}
			Loan Balance Outstanding: ${formattedTotalOutstandingBalance}
			Current Cost of Debt: ${costOfDebt}
			Remaining Term of loan in years: ${YearsRemaining}
			Cashout Potential: ${CashoutPotential}
		`;

			emailJsParams = {
				...emailJsParams,
				clientEmail,
				message
			};

			await emailjs.send(
				EMAIL_SERVICE_ID,
				EMAIL_TEMPLATE_ID,
				emailJsParams,
				EMAIL_PUBLIC_KEY
			);
		},
		{
			onSettled: () => {
				// Set success message
				queryClient.setQueryData(
					SUCCESS_MESSAGE_DATA,
					'Your financial advisor will be in touch with you soon.'
				);
			}
		},
		{
			onError: () => {
				// Set error message
				queryClient.setQueryData(
					ERROR_MESSAGE_DATA,
					'There was an error making your HELOC loan request.'
				);
			}
		}
	);

	// Handlers for mutation
	const { isLoading } = sendNewLoanInquiry;

	return (
		<CardItem
			withoutButton
			sx={{
				display: 'flex',
				flexDirection: 'row',
				justifyContent: 'space-between',
				paddingBottom: 1,
				background: 'linear-gradient(180deg, white 0%, #F5F8FA 100%)',
				boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.20)'
			}}
		>
			<Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
				<Typography variant="subtitle1">
					Home Equity Available
				</Typography>
				<Typography variant="h4" sx={{ color: primary.indigo }}>
					{formattedEquityAvailable}
				</Typography>

				{isLoading && (
					<Loader loaderSX={{ margin: '25px auto 0 auto' }} />
				)}

				{!isLoading && !isClient && (
					<TertiaryButton
						sx={{ marginTop: 2, marginBottom: 2 }}
						onClick={() => navigate(LOAN_INQUIRY_ROUTE)}
					>
						Request HELOC
					</TertiaryButton>
				)}
			</Box>

			<Box
				component="img"
				src={DollarIcon}
				sx={{
					display: { xs: 'none', md: 'block' },
					position: 'absolute',
					right: '1rem',
					bottom: 0,
					width: '6rem !important',
					height: '6rem !important',
					marginBottom: '0.375rem !important'
				}}
			/>
		</CardItem>
	);
}

EquityAvailableCard.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	currentLoanDetails: PropTypes.object.isRequired
};

export default EquityAvailableCard;
