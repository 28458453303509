import { useCallback } from 'react';
import PropTypes from 'prop-types';

// Our Components
import CurrencyTextInput from 'components/Input/CurrencyTextInput';
// Our PropTypes
import CommonPropTypes from 'shared/prop-types';

// TODO @Daniel create Prop Type support for currency and reuse here.
function MonthlyPaymentInput({
	label,
	monthlyPayment,
	setMonthlyPayment,
	outstandingBalance,
	sx
}) {
	const handleMonthlyPaymentChange = useCallback(
		(value) => {
			const isValueEmpty = value === '';
			if (isValueEmpty) {
				setMonthlyPayment('');
				return;
			}
			const startsWithZero =
				value && value.length > 1 && value[0] === '0';
			if (startsWithZero) {
				const isOnlyZeroes = value
					.split('')
					.every((char) => char === '0');
				if (isOnlyZeroes) return;
			}

			const number = +value;
			const isLessThanBalanceOustanding = number < +outstandingBalance;
			if (isLessThanBalanceOustanding && number >= 0) {
				setMonthlyPayment(value);
			}
		},
		[outstandingBalance]
	);

	return (
		<CurrencyTextInput
			label={label}
			error={outstandingBalance === ''}
			helperText="Total outstanding amount must be filled"
			value={monthlyPayment}
			onChange={handleMonthlyPaymentChange}
			dependingValues={[outstandingBalance]}
			sx={sx}
		/>
	);
}

MonthlyPaymentInput.propTypes = {
	label: PropTypes.string,
	outstandingBalance: PropTypes.string.isRequired,
	setMonthlyPayment: PropTypes.func.isRequired,
	sx: CommonPropTypes.sx
};

MonthlyPaymentInput.defaultProps = {
	label: 'Monthly Payment',
	sx: {}
};

export default MonthlyPaymentInput;
