import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import styled from '@emotion/styled';

const UnSortedColumnIcon = styled(ArrowDropDownIcon)`
	opacity: 0.6 !important;
	color: #000000 !important;
`;

function DataTableSortingArrow({ sortingOrder, ...props }) {
	return <UnSortedColumnIcon {...props} />;
}

export default DataTableSortingArrow;
