const DEFAULT_STATE_DETAILED_ASSETS_TABLE = [
	{
		assetCategory: 'Brokerage',
		assetType: 'Stocks',
		presentValue: '',
		firstPeriodAnnualGrowth: '6',
		durationOfGrowth: 'Unlimited',
		years: ''
	},
	{
		assetType: 'Bonds',
		presentValue: '',
		firstPeriodAnnualGrowth: '6',
		durationOfGrowth: 'Unlimited',
		years: ''
	},
	{
		assetCategory: 'Non-Brokerage',
		assetType: 'Stocks',
		presentValue: '',
		firstPeriodAnnualGrowth: '6',
		durationOfGrowth: 'Unlimited',
		years: ''
	},
	{
		assetType: 'Bonds',
		presentValue: '',
		firstPeriodAnnualGrowth: '6',
		durationOfGrowth: 'Unlimited',
		years: ''
	},
	{
		assetCategory: 'Other',
		assetType: 'Other',
		presentValue: '',
		firstPeriodAnnualGrowth: '',
		durationOfGrowth: 'Unlimited',
		years: ''
	}
];

export default DEFAULT_STATE_DETAILED_ASSETS_TABLE;
