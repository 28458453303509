// constants
import { LIABILITY_CONFIDENCE_LEVEL_MAPPING } from 'shared/constants';

const UNKNOWN_DATA_SOURCE = 'unknown';
const RATE_MANUALLY_UPDATED = 'rateManuallyUpdated';
const PARSED_LIABILITY_CONFIDENCE = JSON.parse(
	LIABILITY_CONFIDENCE_LEVEL_MAPPING
);

const getLiabilityConfidence = (interestRateSource, rateManuallyUpdated) => {
	if (rateManuallyUpdated)
		return PARSED_LIABILITY_CONFIDENCE[RATE_MANUALLY_UPDATED];

	// we are only checking interest rate source now
	if (interestRateSource in PARSED_LIABILITY_CONFIDENCE)
		return PARSED_LIABILITY_CONFIDENCE[interestRateSource];

	// This is the default which the env is currently pointing to Low
	return PARSED_LIABILITY_CONFIDENCE[UNKNOWN_DATA_SOURCE];
};

export default getLiabilityConfidence;
