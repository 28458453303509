import {
	Box,
	FormLabel,
	FormControl,
	FormControlLabel,
	Grid,
	RadioGroup,
	Typography
} from '@mui/material';
import { useTheme } from '@emotion/react';
import { useNavigate } from 'react-router-dom';
import { useState, useMemo, useEffect } from 'react';
import { useQueryClient } from 'react-query';
import numbro from 'numbro';

// Our Components
import DropDown from 'components/Dropdown/Dropdown';
import Loader from 'components/Loader';
import GetFullAddressPartialForm from 'components/Forms/addressRelated/GetFullAddressPartialForm';
import SquareRadioButton from 'components/RadioGroup/SquareRadioButton';
import Input from 'components/Input/TextInput';
import CurrencyTextInput from 'components/Input/CurrencyTextInput';
import { PrimaryButton } from 'components/Button/Button';
import { NUMBER } from 'components/Input/Types';

// Our Routes
import { NEW_LOAN_OFFER_ROUTE } from 'routes';

// our Hooks
import useMutateNewHome from 'hooks/useMutateNewHome';

// Our Utils
import {
	isFormValueReady,
	isSubmissionReady,
	roundToDecimal
} from 'shared/utils';

// Our Query Keys
import { USERPROFILEDATA } from 'shared/query-keys';

// Our Local Constants
const dropDownTenureTypes = [
	'10-Year Fixed Rate',
	'15-Year Fixed Rate',
	'20-Year Fixed Rate',
	'30-Year Fixed Rate',
	'40-Year Fixed Rate',
	'3-Year Arm Rate',
	'5-Year Arm Rate',
	'7-Year Arm Rate',
	'10-Year Arm Rate'
];

function HomeLoanQuestionnaire() {
	const SoraTheme = useTheme();
	const queryClient = useQueryClient();
	const navigate = useNavigate();
	const addHome = useMutateNewHome();
	const { primary } = SoraTheme.palette;

	const { isLoading } = addHome;

	const [mortgageType, setMortgageType] = useState('30-Year Fixed Rate');
	const [homePrice, setHomePrice] = useState(0);
	const [downPaymentPercent, setDownPaymentPercent] = useState(20);
	const [downPayment, setDownPayment] = useState(0);
	const [downPaymentLabel, setDownPaymentLabel] = useState(
		'Estimated Down Payment'
	);
	const [ownershipType, setOwnershipType] = useState('');
	const [dwellingType, setDwellingType] = useState('');
	const [priorityType, setPriorityType] = useState('');
	const [multiHomeCount, setMultiHomeCount] = useState('');

	// Home Address State
	const [addressLine1, setAddressLine1] = useState('');
	const [addressLine2, setAddressLine2] = useState('');
	const [city, setCity] = useState('');
	const [state, setState] = useState('');
	const [zipCode, setZipCode] = useState('');

	const isMultiHomeProperty = dwellingType === 'MULTIPLE_UNIT';
	const formData = [
		downPayment,
		dwellingType,
		homePrice,
		mortgageType,
		ownershipType,
		priorityType
	];
	const isFormReady = useMemo(
		() =>
			isMultiHomeProperty
				? isSubmissionReady([...formData, multiHomeCount])
				: isSubmissionReady(formData),
		[...formData, multiHomeCount]
	);
	const handlePriorityChange = (e) => {
		setPriorityType(e.target.value);
	};

	const handleDwellingTypeChange = (e) => {
		// if dwellingType changes then we know that we may have moved away or to Multi home.
		// In this case the input field should be empty and not retain state.
		if (multiHomeCount !== '') {
			setMultiHomeCount('');
		}

		setDwellingType(e.target.value);
	};

	const handleOwnershipTypeChange = (e) => {
		setOwnershipType(e.target.value);
	};

	useEffect(() => {
		// Change homePrice from string to number
		const homePriceNumber = Number.parseFloat(homePrice, 10);
		// Used to display down payment dollar amount for user
		const calculatedDownPaymentAmount = roundToDecimal(
			(downPaymentPercent / 100) * homePriceNumber
		);
		// Using Numbro to un-format this string and return the number equivalent, then setting the downPayment
		// in state to the number version of this amount
		const calculatedDownPaymentAmountNumber = numbro.unformat(
			calculatedDownPaymentAmount
		);
		setDownPayment(calculatedDownPaymentAmountNumber);
		setDownPaymentLabel(
			calculatedDownPaymentAmountNumber === undefined
				? 'Estimated Down Payment'
				: `Estimated Down Payment ($${calculatedDownPaymentAmount})`
		);
	}, [downPaymentPercent, homePrice]);

	const handleSubmission = () => {
		if (isFormReady) {
			const profileData = queryClient.getQueryData(USERPROFILEDATA);
			const clientId = profileData?.id;
			// Ensures that mortgage Type matches the expected payloadKey
			const splitMortgageType = mortgageType.split(' ');
			const tenureType = `${splitMortgageType[1].toUpperCase()}_${splitMortgageType[0]
				.toUpperCase()
				.replace('-', '_')}`;
			const homeData = {
				clientId,
				addressLine1,
				city,
				downPayment: +downPayment,
				dwellingType,
				homePrice: +homePrice,
				ownershipType,
				priorityType,
				state,
				tenureType,
				zipCode,
				noOfUnits: multiHomeCount
			};

			// Make sure optional data isn't sent over
			if (addressLine2 && addressLine2 !== '') {
				homeData.addressLine2 = addressLine2;
			}

			addHome.mutate(homeData, {
				onSuccess: () => {
					navigate(NEW_LOAN_OFFER_ROUTE, {
						state: {
							clientData: {
								firstName: profileData?.firstName,
								lastName: profileData?.lastName,
								clientId: profileData?.clientId
							}
						}
					});
				}
			});
		}
	};

	return (
		<Box sx={{ marginLeft: 2, marginRight: 2 }}>
			<Grid item xs={12}>
				<Typography
					variant="h1Gascogne"
					component="h1"
					gutterBottom
					sx={{ marginTop: 4 }}
				>
					Give us information about your new home
				</Typography>
			</Grid>
			<Grid container item spacing={2}>
				<Grid item xs={12} md={6}>
					{/* Mortgage Type */}
					<DropDown
						items={dropDownTenureTypes}
						sx={{
							width: '100%',
							marginTop: 4
						}}
						selected={mortgageType}
						type="visible"
						onChange={setMortgageType}
						label="Mortgage Type"
					/>
				</Grid>
				<Grid item xs={12} md={6}>
					<CurrencyTextInput
						label="Estimated Home Price"
						helperText="Price cannot be empty"
						onChange={setHomePrice}
						value={homePrice}
						error={!isFormValueReady(homePrice)}
						sx={{
							marginTop: 4,
							marginBottom: 2,
							width: '100%'
						}}
					/>
				</Grid>

				<Grid item xs={12} md={6} sx={{ marginBottom: 4 }}>
					<Input
						type="percent"
						label={downPaymentLabel}
						helperText="Down payment cannot be empty"
						onChange={setDownPaymentPercent}
						value={downPaymentPercent}
						error={!isFormValueReady(downPaymentPercent)}
					/>
				</Grid>

				<GetFullAddressPartialForm
					addressLine1={addressLine1}
					addressLine2={addressLine2}
					city={city}
					state={state}
					zipCode={zipCode}
					setAddressLine1={setAddressLine1}
					setAddressLine2={setAddressLine2}
					setCity={setCity}
					setState={setState}
					setZipCode={setZipCode}
				/>
			</Grid>

			<Grid item xs={12} sx={{ marginTop: 4 }}>
				{/* Primary home radio buttons */}
				<FormControl>
					<FormLabel id="primary-home-radio-buttons-group-label">
						<Typography
							variant="h5"
							gutterBottom
							color={primary.soraBlue}
						>
							Is this your primary home?
						</Typography>
						<Typography
							variant="body2"
							gutterBottom
							color={primary.black}
						>
							*Required
						</Typography>
					</FormLabel>
					<RadioGroup
						aria-labelledby="Priorities radiogroup for loan"
						value={ownershipType}
						onChange={handleOwnershipTypeChange}
						name="radio-buttons-group"
					>
						<FormControlLabel
							value="Primary Residence"
							control={
								<SquareRadioButton
									value="PRIMARY"
									label="Primary Residence"
								/>
							}
							label="Primary Residence"
						/>
						<FormControlLabel
							value="Secondary Residence"
							control={
								<SquareRadioButton
									value="SECONDARY"
									label="Secondary Residence"
								/>
							}
							label="Secondary Residence"
						/>
						<FormControlLabel
							value="Investment Property"
							control={
								<SquareRadioButton
									value="INVESTMENT"
									label="Investment Property"
								/>
							}
							label="Investment Property"
						/>
					</RadioGroup>
				</FormControl>
			</Grid>
			<Grid item xs={12} sx={{ marginTop: 4 }}>
				{/* Property Type */}
				<FormControl>
					<FormLabel id="property-type-radio-buttons-group-label">
						<Typography
							variant="h5"
							gutterBottom
							color={primary.soraBlue}
						>
							What type of property is this?
						</Typography>
						<Typography
							variant="body2"
							gutterBottom
							color={primary.black}
						>
							*Required
						</Typography>
					</FormLabel>
					<RadioGroup
						aria-labelledby="Priorities radiogroup for loan"
						value={dwellingType}
						onChange={handleDwellingTypeChange}
						name="radio-buttons-group"
					>
						<FormControlLabel
							value="Single Family"
							control={
								<SquareRadioButton
									value="SINGLE_FAMILY"
									label="Single Family"
								/>
							}
							label="Single Family"
						/>
						<FormControlLabel
							value="Townhouse or Condo"
							control={
								<SquareRadioButton
									value="TOWNHOUSE_OR_CONDO"
									label="Townhouse or Condo"
								/>
							}
							label="Townhouse or Condo"
						/>
						<FormControlLabel
							value="Multiple Unit Property"
							control={
								<SquareRadioButton
									value="MULTIPLE_UNIT"
									label="Multiple Unit Property"
								/>
							}
							label="Multiple Unit Property"
						/>
						{isMultiHomeProperty && (
							<Input
								type={NUMBER}
								placeholder="How many units?"
								onChange={setMultiHomeCount}
								value={multiHomeCount}
								required
								withVisibleToggle
							/>
						)}
					</RadioGroup>
				</FormControl>
			</Grid>
			<Grid item xs={12} sx={{ marginTop: 4 }}>
				{/* Priorities Loan */}
				<FormControl>
					<FormLabel id="priorities-loan-radio-buttons-group-label">
						<Typography
							variant="h5"
							gutterBottom
							color={primary.soraBlue}
						>
							What is your priority for this loan?
						</Typography>
						<Typography
							variant="body2"
							gutterBottom
							color={primary.black}
						>
							*Required
						</Typography>
					</FormLabel>
					<RadioGroup
						aria-labelledby="Priorities radiogroup for loan"
						value={priorityType}
						onChange={handlePriorityChange}
						name="radio-buttons-group"
					>
						<FormControlLabel
							value="Get the lowest monthly payment"
							control={
								<SquareRadioButton
									value="LOW_EMI"
									label="Get the lowest monthly payment"
								/>
							}
							label="Get the lowest monthly payment"
						/>
						<FormControlLabel
							value="Pay off my loan quickly"
							control={
								<SquareRadioButton
									value="EARLY_PAYOFF"
									label="Pay off my loan quickly"
								/>
							}
							label="Pay off my loan quickly"
						/>
						<FormControlLabel
							value="Get the lowest total cost of debt"
							control={
								<SquareRadioButton
									value="LOW_COD"
									label="Get the lowest total cost of debt"
								/>
							}
							label="Get the lowest total cost of debt"
						/>
					</RadioGroup>
				</FormControl>
			</Grid>

			{!isLoading && (
				<PrimaryButton
					onClick={() => handleSubmission()}
					sx={{ marginTop: 4 }}
					disabled={!isFormReady}
				>
					Next
				</PrimaryButton>
			)}
			{isLoading && <Loader />}
		</Box>
	);
}

export default HomeLoanQuestionnaire;
