// Our axios Instance
import axiosInstance from 'services/API/API';

import { useMutation } from 'react-query';
import { checkForCompany } from 'shared/api-urls';

function useMutateCheckDomain() {
	return useMutation(({ companyWebsite }) =>
		axiosInstance
			.get(`${checkForCompany}${companyWebsite}`, {
				withCredentials: true
			})
			.then((res) => res.data.data)
	);
}

export default useMutateCheckDomain;
