import PropTypes from 'prop-types';

// Our Components
import Dropdown from 'components/Dropdown/Dropdown';

// Our PropTypes
import CommonPropTypes from 'shared/prop-types';

// Our Consants
import { DEFAULT_TASK_ACTION_OPTIONS } from 'shared/constants';

function ActionsDropdown({
	page,
	actionOptions,
	sx,
	clientData,
	disabled,
	dropDownLabelSX,
	handleSelected
}) {
	return (
		<Dropdown
			disabled={disabled}
			items={actionOptions}
			labelSx={dropDownLabelSX}
			selected=""
			onChange={(value) => {
				handleSelected(value, clientData, page);
			}}
			placeholder="Select Action"
			sx={{
				height: '60%',
				width: 160,
				...sx
			}}
			selectSX={{
				height: '100%',
				width: 160
			}}
			menuProps={{
				PaperProps: {
					sx: {
						width: 160
					}
				}
			}}
		/>
	);
}

ActionsDropdown.propTypes = {
	sx: CommonPropTypes.sx,
	dropDownLabelSX: CommonPropTypes.sx,
	actionOptions: PropTypes.arrayOf(PropTypes.string)
};

ActionsDropdown.defaultProps = {
	sx: {},
	dropDownLabelSX: {},
	actionOptions: DEFAULT_TASK_ACTION_OPTIONS
};

export default ActionsDropdown;
