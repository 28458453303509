import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import { useMemo } from 'react';

// Our Components
import Dropdown from 'components/Dropdown/Dropdown';

// Our PropTypes
import CommonPropTypes from 'shared/prop-types';

// CONSTANTS
import { FINANCIAL_GOAL_OPTIONS } from 'shared/constants';

function FinancialGoalDropdown({
	dropDownSX,
	dropDownLabelSX,
	financialGoal,
	gridColumns,
	setFinancialGoal,
	sx,
	withoutCashout
}) {
	const financialGoalOptions = useMemo(() => {
		if (withoutCashout) return FINANCIAL_GOAL_OPTIONS.slice(0, 3);
		return FINANCIAL_GOAL_OPTIONS;
	}, [withoutCashout]);
	return (
		<Grid item xs={gridColumns} sx={{ marginBottom: 4, ...sx }}>
			<Dropdown
				items={financialGoalOptions}
				label="Financial Goal"
				labelSx={dropDownLabelSX}
				onChange={setFinancialGoal}
				selected={financialGoal}
				sx={dropDownSX}
				variant="outlined"
			/>
		</Grid>
	);
}

FinancialGoalDropdown.propTypes = {
	dropDownSX: CommonPropTypes.sx,
	dropDownLabelSX: CommonPropTypes.sx,
	financialGoal: PropTypes.string.isRequired,
	gridColumns: PropTypes.number,
	setFinancialGoal: PropTypes.func.isRequired,
	sx: CommonPropTypes.sx,
	withoutCashout: PropTypes.bool
};

FinancialGoalDropdown.defaultProps = {
	dropDownLabelSX: {},
	dropDownSX: {},
	gridColumns: 5,
	sx: {},
	withoutCashout: false
};

export default FinancialGoalDropdown;
