import { useMutation, useQueryClient } from 'react-query';
import {
	useNavigate,
	useLocation,
	useParams,
	generatePath
} from 'react-router-dom';

// Our axios instance
import axiosInstance from 'services/API/API';

// Our Endpoints
import { userAssets } from 'shared/api-urls';

// Our Query keys
import {
	CLIENT_ONBOARDING_ASSETS,
	ERROR_MESSAGE_DATA,
	WALLET_OVERVIEW_DATA
} from 'shared/query-keys';

// Our Routes
import { ADVISOR_WALLET_ROUTE, CLIENT_SORAWALLET_ROUTE } from 'routes';

// Hooks
import useMutateUpdateOnboardingStatus from 'hooks/clientOnboarding/mutations/useMutateUpdateOnboardingStatus';
import useWasFromWallet from 'hooks/utilityHook/useWasFromWallet';

import { ASSETS_UPDATED } from 'shared/constants';

function useMutateSetAssetValue() {
	const navigate = useNavigate();
	const queryClient = useQueryClient();
	const isFromWallet = useWasFromWallet();
	const updateOnboardingStatus = useMutateUpdateOnboardingStatus();

	const location = useLocation();

	const { state } = location;
	const params = useParams();

	const { clientId } = params;

	return useMutation(
		async ({ apiCallType, totalAssets, assetIdToUpdate }) => {
			// the api requires the following payload, even though we only have one field on the front end "totalAssets"
			const payload = {
				providerId: 0,
				externalId: null,
				accountId: null,
				assetAmount: totalAssets,
				assetType: null,
				currency: 'USD',
				dataSource: 'Manual'
			};

			if (clientId || state?.clientId) {
				payload.clientId = clientId;
			}

			if (apiCallType === 'post' || assetIdToUpdate === undefined) {
				const response = await axiosInstance.post(userAssets, payload);

				// Updates Onboarding Status
				await updateOnboardingStatus.mutate(ASSETS_UPDATED);
				return response.data.data;
			}

			const URL = `${userAssets}/${assetIdToUpdate}`;

			const response = await axiosInstance.patch(URL, payload);
			const userAssetsData = response.data.data;

			// variable here is not needed but the AWAIT is.
			// stored in a variable for contextual purposes.
			if (!isFromWallet) {
				// Updates Onboarding Status
				await updateOnboardingStatus.mutate(ASSETS_UPDATED);
			} else {
				await queryClient.invalidateQueries(WALLET_OVERVIEW_DATA, {
					refetchInactive: true
				});
			}

			return userAssetsData;
		},
		{
			onError: () => {
				queryClient.setQueryData(
					ERROR_MESSAGE_DATA,
					'We had an issue updating your asset information.'
				);
			},
			onSuccess: () => {
				queryClient.invalidateQueries(CLIENT_ONBOARDING_ASSETS);
				if (isFromWallet) {
					if (clientId) {
						navigate(
							generatePath(`${ADVISOR_WALLET_ROUTE}:clientId`, {
								clientId
							}),
							{ replace: true }
						);
					} else {
						navigate(CLIENT_SORAWALLET_ROUTE, { replace: true });
					}
				}
			}
		}
	);
}

export default useMutateSetAssetValue;
