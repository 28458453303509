import { Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { useTheme } from '@emotion/react';

// Our Routes
import { MESSAGES_ROUTE } from 'routes';

function SettingsTab() {
	const SoraTheme = useTheme();
	return (
		<>
			<Typography
				variant="h6"
				sx={{
					color: SoraTheme.palette.primary.soraBlue,
					marginBottom: 2
				}}
			>
				Your Plan
			</Typography>

			<Typography
				variant="body"
				sx={{
					marginBottom: 2
				}}
			>
				If you have questions about your monthly plan, please reach out
				to Sora via our <Link to={MESSAGES_ROUTE}>contact us form</Link>{' '}
				or via email at contact@sorafinance.com
			</Typography>
		</>
	);
}

export default SettingsTab;
