import { useQueryClient, useMutation } from 'react-query';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';

// Our Hooks
import useMutateUnmaskUser from 'hooks/useMutateRemoveUserMask';

// Our Query Keys
import { ERROR_MESSAGE_DATA, FIREBASEUSERCREDENTIALS } from 'shared/query-keys';

const useMutateLoginUser = () => {
	const unmaskUser = useMutateUnmaskUser();
	const auth = getAuth();
	const queryClient = useQueryClient();
	// useMutation is for create/update/delete
	// to GET an existing user we need to useQuery- why? this will automatically cache the result
	// useMutation hook which requires a promise.
	// pass object with email and password
	// use fireBase signInWithEmailAndPassword
	return useMutation(
		async ({ email, password }) => {
			try {
				await unmaskUser.mutate({});
			} catch (e) {
				console.error(e);
			}

			const userCredential = await signInWithEmailAndPassword(
				auth,
				email,
				password
			);

			return userCredential.user.accessToken;
		},
		{
			onSuccess: (data) => {
				// if this mutation call is successful store the response to querycache
				queryClient.setQueryData(FIREBASEUSERCREDENTIALS, data);
			},
			onError: (error) => {
				queryClient.setQueryData(ERROR_MESSAGE_DATA, error.message);
			}
		}
	);
};

export default useMutateLoginUser;
