import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { getAuth, signInWithPopup, GoogleAuthProvider } from 'firebase/auth';

// Our Hooks
import useMutateUnmaskUser from 'hooks/useMutateRemoveUserMask';

// Our Query Keys
import { FIREBASEUSERCREDENTIALS } from 'shared/query-keys';

const useSignInWithPopup = (isEnabled) => {
	const unmaskUser = useMutateUnmaskUser();
	const auth = getAuth();

	const provider = new GoogleAuthProvider();
	provider.setCustomParameters({
		prompt: 'select_account'
	});

	const navigate = useNavigate();
	return useQuery(
		FIREBASEUSERCREDENTIALS,
		async () => {
			try {
				await unmaskUser.mutate({});
			} catch (e) {
				console.error(e);
			}

			const userCredential = await signInWithPopup(auth, provider);

			return userCredential.user.accessToken;
		},
		{
			enabled: isEnabled,
			onSuccess: () => {
				navigate('/routing-user');
			}
		}
	);
};

export default useSignInWithPopup;
