import { Grid, Typography } from '@mui/material';
import { useMemo, useEffect } from 'react';
import {
	AreaChart,
	Area,
	CartesianGrid,
	Legend,
	Label,
	ResponsiveContainer,
	Tooltip,
	XAxis,
	YAxis
} from 'recharts';
import PropTypes from 'prop-types';
import { useTheme } from '@emotion/react';

// Our Components
import AnalyticsPresentationTable from 'components/Table/AnalyticsPresentationTable';
import AnalyticsPresentationRow from 'components/Table/AnalyticsPrsentationRow';

// Our Utils
import { dollarFormatter } from 'shared/utils';
import createFormattedRowData from 'shared/utils/analytics/createFormattedRowData';

function InterestSavingsTab({
	analyticsSavingData,
	analyticsAmortizationData
}) {
	const SoraTheme = useTheme();
	const { analytics } = SoraTheme.palette;

	const { currentLoan, bestOffer, interestSavings } = analyticsSavingData;

	useEffect(() => {
		// scroll to 	id="update-data-button"
		const updateDataButton = document.getElementById('update-data-button');
		if (updateDataButton) {
			updateDataButton.scrollIntoView({ behavior: 'smooth' });
		}
	}, [analyticsSavingData]);

	const safeAmortizationGraphData = useMemo(() => {
		if (analyticsAmortizationData) {
			const { amortization: bestOfferAmortization } =
				analyticsAmortizationData.bestOffer;
			const { amortization: currentLoanAmortization } =
				analyticsAmortizationData.currentLoan;

			// set of unique months
			const allMonths = new Set([
				...currentLoanAmortization.map((data) => data.month),
				...bestOfferAmortization.map((data) => data.month)
			]);

			// map for interest values by month
			const currentLoanMap = new Map(
				currentLoanAmortization.map((data) => [
					data.month,
					data.totalInterest
				])
			);
			const bestOfferMap = new Map(
				bestOfferAmortization.map((data) => [
					data.month,
					data.totalInterest
				])
			);

			// last available interest values to make the graph stroke flat for values not available
			const lastCurrentLoanInterest =
				currentLoanAmortization.length > 0
					? currentLoanAmortization[
							currentLoanAmortization.length - 1
					  ].totalInterest
					: NaN;
			const lastBestOfferInterest =
				bestOfferAmortization.length > 0
					? bestOfferAmortization[bestOfferAmortization.length - 1]
							.totalInterest
					: NaN;

			// result array, if the interest value is not present it will take the last available value of that amortization
			const result = Array.from(allMonths).map((month) => ({
				month,
				'Current Loan':
					currentLoanMap.get(month) ?? lastCurrentLoanInterest,
				'Best Offer': bestOfferMap.get(month) ?? lastBestOfferInterest
			}));

			return result;
		}

		return [];
	}, [analyticsAmortizationData]);

	const savingsInvestmentPotentialTableRows = useMemo(
		() =>
			[
				createFormattedRowData(
					'Total Payment',
					currentLoan?.totalPayment,
					bestOffer?.totalPayment
				),
				createFormattedRowData(
					'Principal',
					currentLoan?.originalLoanAmount,
					bestOffer?.originalLoanAmount
				),
				createFormattedRowData(
					'Total Interest Payments',
					currentLoan?.totalCostOfDebt,
					bestOffer?.totalCostOfDebt
				),
				[
					'Interest Savings',
					dollarFormatter.format(interestSavings ?? 0),
					''
				]
			].map((rowData, i) => {
				const isLast = i === 3;
				const borderBottom = isLast ? 'none' : '0.25px black solid';

				return (
					<AnalyticsPresentationRow
						currentRowData={rowData}
						sx={{ borderBottom }}
						firstColumnSX={{ width: 150 }}
					/>
				);
			}),
		[analyticsSavingData]
	);

	return (
		<>
			<Typography
				id="update-data-button"
				variant="h2Gascogne"
				sx={{
					display: 'block',
					marginBottom: 4
				}}
			>
				Cumulative Interest Paid
			</Typography>

			<Grid container spacing={2}>
				<Grid item xs={12} xl={6}>
					{/* Graph here */}
					<ResponsiveContainer width="90%" height={500}>
						<AreaChart
							data={safeAmortizationGraphData}
							stackOffset="none"
						>
							<CartesianGrid strokeDasharray="1 2" />
							<XAxis dataKey="month">
								<Label position="left" offset={10}>
									Months
								</Label>
							</XAxis>
							<YAxis
								width={120}
								tickFormatter={(yAxisTickValue) => {
									const formattedValue =
										dollarFormatter.format(yAxisTickValue);
									return formattedValue;
								}}
							/>
							<Tooltip
								formatter={(value) =>
									dollarFormatter.format(value)
								}
							/>
							<Legend />
							<Area
								type="natural"
								dataKey="Current Loan"
								fill={analytics.interestSavingsCurrentLoan}
								stroke={analytics.interestSavingsCurrentLoan}
								strokeWidth={5}
							/>
							<Area
								type="natural"
								dataKey="Best Offer"
								fill={analytics.interestSavingsbestoffer}
								stroke={analytics.interestSavingsbestoffer}
								strokeWidth={5}
							/>
						</AreaChart>
					</ResponsiveContainer>
				</Grid>

				<Grid
					container
					item
					xs={12}
					xl={6}
					sx={{ marginBottom: 4, maxHeight: 312 }}
				>
					{currentLoan && bestOffer && (
						<AnalyticsPresentationTable
							tableData={savingsInvestmentPotentialTableRows}
							firstColumnSX={{ width: 150 }}
						/>
					)}
				</Grid>
			</Grid>
		</>
	);
}

InterestSavingsTab.propTypes = {
	analyticsSavingData: PropTypes.shape({
		bestOffer: PropTypes.shape({
			originalLoanAmount: PropTypes.number,
			totalCostOfDebt: PropTypes.number,
			totalPayment: PropTypes.number
		}),
		currentLoan: PropTypes.shape({
			originalLoanAmount: PropTypes.number,
			totalCostOfDebt: PropTypes.number,
			totalPayment: PropTypes.number
		}),
		interestSavings: PropTypes.number
	})
};

InterestSavingsTab.defaultProps = {
	analyticsSavingData: {
		currentLoan: {},
		bestOffer: {}
	}
};

export default InterestSavingsTab;
