import { useState } from 'react';
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Typography
} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

function TasksAccordion({ defaultExpandState, taskHeading, children }) {
	const [expanded, setExpanded] = useState(defaultExpandState ?? false);

	const handleChange = () => setExpanded(!expanded);

	return (
		<Accordion elevation={0} expanded={expanded} onChange={handleChange}>
			<AccordionSummary
				sx={{
					display: 'flex',
					width: 'fit-content',
					'& .MuiAccordionSummary-content': {
						order: 1
					},
					'& .MuiAccordionSummary-expandIconWrapper': {
						order: 2
					},
					paddingLeft: 0
				}}
				expandIcon={<ArrowDropDownIcon sx={{ color: 'black' }} />}
				aria-controls="panel1a-content"
				id="panel1a-header"
			>
				<Typography variant="subtitle1">{taskHeading}</Typography>
			</AccordionSummary>
			<AccordionDetails sx={{ padding: 0 }}>
				{children ?? (
					<Typography>
						Lorem ipsum dolor sit amet, consectetur adipiscing elit.
						Suspendisse malesuada lacus ex, sit amet blandit leo
						lobortis eget.
					</Typography>
				)}
			</AccordionDetails>
		</Accordion>
	);
}

export default TasksAccordion;
