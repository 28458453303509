import { useMutation, useQueryClient } from 'react-query';

// Our Axios
import axiosInstance from 'services/API/API';

// Our Endpoints
import { refreshWealthboxClientEndpoint } from 'shared/api-urls';

// Our Hooks
import useGetProfile from 'hooks/useGetProfile';

// Our Query keys
import {
	ERROR_MESSAGE_DATA,
	SUCCESS_MESSAGE_DATA,
	WEALTH_BOX_CLIENTS,
	WEALTH_BOX_DONE_CLIENTS
} from 'shared/query-keys';

function useMutateRefreshWealthboxClientList() {
	const queryClient = useQueryClient();

	const { data } = useGetProfile();

	const { id: advisorId } = data;

	return useMutation(
		async () => {
			const response = await axiosInstance.patch(
				`${refreshWealthboxClientEndpoint}${advisorId}`,
				{}
			);

			return response.data;
		},
		{
			onSuccess: async () => {
				queryClient.setQueryData(
					SUCCESS_MESSAGE_DATA,
					'Client list has been refreshed'
				);

				// Update list of uncompleted clients
				await queryClient.invalidateQueries(WEALTH_BOX_CLIENTS);
				await queryClient.invalidateQueries(WEALTH_BOX_DONE_CLIENTS);
			},
			onError: () => {
				queryClient.setQueryData(
					ERROR_MESSAGE_DATA,
					'Whoops, something went wrong refreshing the data'
				);
			}
		}
	);
}

export default useMutateRefreshWealthboxClientList;
