// Our axios Instance
import axiosInstance from 'services/API/API';

import { useMutation, useQueryClient } from 'react-query';
import { faContactClient } from 'shared/api-urls';
import { SUCCESS_MESSAGE_DATA, ERROR_MESSAGE_DATA } from 'shared/query-keys';

function useMutateContactClient() {
	const queryClient = useQueryClient();
	return useMutation(
		({ clientId, inviteId, messageText }) => {
			const requestPayload = clientId
				? { clientId, message: messageText }
				: { inviteId, message: messageText };
			return axiosInstance
				.post(faContactClient, requestPayload, {
					withCredentials: true
				})
				.then((res) => res.data);
		},
		{
			onError: (error) =>
				queryClient.setQueryData(ERROR_MESSAGE_DATA, error.message),
			onSuccess: () =>
				queryClient.setQueryData(
					SUCCESS_MESSAGE_DATA,
					'Client successfully contacted.'
				)
		}
	);
}

export default useMutateContactClient;
