import { useMutation, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';

// Our Axios
import axiosInstance from 'services/API/API';

// Our Endpoints
import { dataRefreshUrl } from 'shared/api-urls';

// Our QUery keys
import { ERROR_MESSAGE_DATA, SUCCESS_MESSAGE_DATA } from 'shared/query-keys';

function useMutateRefreshSingleClient() {
	const { clientId } = useParams();
	const queryClient = useQueryClient();

	return useMutation(
		async () => {
			const response = await axiosInstance.patch(
				`${dataRefreshUrl}${clientId ? `/${clientId}` : ''}`
			);

			const recentTimeStamp = response?.data?.meta?.timestamp;

			return recentTimeStamp;
		},
		{
			onSuccess: () => {
				queryClient.setQueryData(
					SUCCESS_MESSAGE_DATA,
					'Data has been refreshed'
				);
			},
			onError: () => {
				queryClient.setQueryData(
					ERROR_MESSAGE_DATA,
					'Whoops, something went wrong refreshing the data'
				);
			}
		}
	);
}

export default useMutateRefreshSingleClient;
