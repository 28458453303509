import PropTypes from 'prop-types';
import { useState } from 'react';
import { Box, ClickAwayListener, Tooltip, Typography } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import CloseIcon from '@mui/icons-material/Close';

// Our Prop Types
import CommonPropTypes from 'shared/prop-types';

function SoraToolTip({ placement, text, variant, sx, toolTipSx }) {
	// This state is suppose to allow us to hover and also keep the tooltip open if its clicked.
	// dual behavior. Hovering -> displays it
	// clicking -> keeps it open. The ClickAwayListener SHOULD close the tooltip if the user clicks outside of the tooltip.
	// eslint-disable-next-line no-unused-vars
	const [isOpen, setIsOpen] = useState(false);

	const isToggleVariant = variant === 'toggle';

	const isSimpleText = typeof text === 'string';

	return (
		<ClickAwayListener onClickAway={() => setIsOpen(false)}>
			<Tooltip
				title={
					<Box
						sx={{
							bgcolor: '#FFFFFF',
							color: 'black',
							display: 'flex',
							gap: 2,
							padding: 4,
							borderRadius: '20px',
							filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
							...sx
						}}
					>
						{isSimpleText && <Typography>{text}</Typography>}
						{!isSimpleText && text}

						{isToggleVariant && (
							<CloseIcon
								onClick={() => {
									setIsOpen(false);
								}}
								sx={{
									cursor: 'pointer'
								}}
							/>
						)}
					</Box>
				}
				placement={placement || 'top'}
				disableHoverListener={variant === 'toggle'}
				// open={isOpen}
				// onClick={() => setIsOpen(!isOpen)}
				componentsProps={{
					tooltip: {
						sx: {
							bgcolor: 'transparent',
							maxWidth: 600
						}
					}
				}}
			>
				<InfoIcon sx={{ cursor: 'pointer', ...toolTipSx }} />
			</Tooltip>
		</ClickAwayListener>
	);
}

SoraToolTip.propTypes = {
	placement: PropTypes.oneOf([
		'top',
		'top-start',
		'top-end',
		'right-start',
		'right',
		'right-end',
		'bottom-start',
		'bottom',
		'bottom-end',
		'left-start',
		'left',
		'left-end'
	]),
	text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
	variant: PropTypes.oneOf(['hover', 'toggle']),
	sx: CommonPropTypes.sx,
	toolTipSx: CommonPropTypes.sx
};

SoraToolTip.defaultProps = {
	placement: 'top',
	variant: 'hover',
	sx: {},
	toolTipSx: {}
};

export default SoraToolTip;
