import { useState } from 'react';
import { Box, TextField, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

// Our Components
import { PrimaryButton } from 'components/Button/Button';
import Input from 'components/Input/TextInput';

// Types
import { TEXT } from 'components/Input/Types';

function ContactUserForm({ clientData, handleContactSubmission, subjectLine }) {
	const SoraTheme = useTheme();

	// Give option to override subjectLine.
	const defaultSubjectText = subjectLine || 'Completing your Sora Onboarding';
	// eslint-disable-next-line no-unused-vars
	const [subjectText, setSubjectText] = useState(defaultSubjectText);
	const [messageText, setMessageText] = useState('');
	const { clientId, invitedName, inviteId, name } = clientData;
	return (
		<Box
			component="form"
			noValidate
			autoComplete="off"
			sx={{
				display: 'flex',
				flexDirection: 'column',
				gap: 2
			}}
		>
			{/* name is not currenlt editable */}
			<Input
				id="name"
				label="Name"
				type={TEXT}
				name="name"
				value={invitedName || name || ''}
				endAdornment="hidden"
				sx={{
					width: '100%'
				}}
			/>
			{/* name is not currently editable */}
			<Input
				id="subject"
				label="Subject"
				type={TEXT}
				name="subject"
				value={subjectText}
				endAdornment="hidden"
				sx={{
					width: '100%'
				}}
			/>
			<Box>
				<Typography
					variant="subtitle1"
					gutterBottom
					component="div"
					sx={{
						marginBottom: 1
					}}
				>
					Message
				</Typography>
				<TextField
					aria-label="Message"
					minRows={10}
					maxRows={20}
					multiline
					onChange={(e) => setMessageText(e.target.value)}
					value={messageText}
					style={{
						width: '100%',
						fontFamily: SoraTheme.typography.fontFamily,
						fontWeight: SoraTheme.typography.body1.fontWeight,
						fontSize: SoraTheme.typography.body1.fontSize,
						lineHeight: SoraTheme.typography.body1.lineHeight,
						letterSpacing: SoraTheme.typography.body1.letterSpacing,
						marginBottom: 15
					}}
					sx={{
						width: '100%'
					}}
				/>
				<PrimaryButton
					disabled={messageText.length < 1}
					onClick={() =>
						handleContactSubmission({
							clientId,
							inviteId,
							messageText
						})
					}
				>
					Send
				</PrimaryButton>
			</Box>
		</Box>
	);
}

export default ContactUserForm;
