import { List } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import { useTheme } from '@emotion/react';
import { useMemo, useState } from 'react';
import PropTypes from 'prop-types';

// Icons
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';

// Our Components
import NavItem from 'components/NavMenu/NavBarComponents/NavItem';
import FreemiumModal from 'components/Modal/fremium/FreemiumModal';

// Hooks
import useGetAllUnreadMessagesForCurrentUser from 'hooks/talkjs/queries/useGetAllUnreadMessagesForCurrentUser';
import useGetProfile from 'hooks/useGetProfile';

// Our Selectors
import selectFremiumProfileStatus from 'selectors/selectFremiumProfileStatus';

// Routes
import { MESSAGES_ADMIN_ROUTE } from 'routes/index';

// constants
import { ADMIN } from 'shared/constants';
import ADVISOR_NAV_OPTIONS from 'shared/constants/navigation/advisorNavOptions';

// Local Constants
const ADMIN_NAV_OPTIONS = [
	{
		icon: <ChatBubbleOutlineOutlinedIcon />,
		title: 'Messages',
		route: MESSAGES_ADMIN_ROUTE
	}
];

function NavMenuAdvisor({ variant }) {
	const SoraTheme = useTheme();
	const { primary } = SoraTheme.palette;

	const [isFreemiumModalOpen, setIsFreemiumModalOpen] = useState(false);
	const { isSuccess, data: unreadMessageCount } =
		useGetAllUnreadMessagesForCurrentUser();

	const { data: profileData } = useGetProfile();

	const isAdmin = profileData?.role === ADMIN;

	const navOptions = isAdmin ? ADMIN_NAV_OPTIONS : ADVISOR_NAV_OPTIONS;
	const isDesktop = variant === 'desktop';
	const color = isDesktop ? primary.white : primary.indigo;

	const fremiumProfileStatus = selectFremiumProfileStatus(profileData);

	const shouldShowFreemiumModal =
		!fremiumProfileStatus.premium && fremiumProfileStatus.clientCount >= 5;

	const handleIntegrationClick = () => {
		setIsFreemiumModalOpen(true);
	};

	const memoizedNavOptions = useMemo(
		() =>
			navOptions.map(({ title, route, icon }) => {
				const isMessages = title === 'Messages';
				const isIntegration = title === 'Integrations';

				return (
					<NavItem
						key={uuidv4()}
						title={title}
						route={route}
						icon={icon}
						isMessages={isMessages}
						isSuccess={isSuccess}
						unreadMessageCount={unreadMessageCount}
						variant={variant}
						color={color}
						onClick={
							isIntegration && shouldShowFreemiumModal
								? handleIntegrationClick
								: null
						}
					/>
				);
			}),

		[navOptions, variant, color, isSuccess, unreadMessageCount]
	);

	return (
		<>
			{shouldShowFreemiumModal && (
				<FreemiumModal
					isOpen={isFreemiumModalOpen}
					setIsOpen={() => setIsFreemiumModalOpen(false)}
				/>
			)}
			<List>{memoizedNavOptions}</List>
		</>
	);
}

NavItem.propTypes = {
	variant: PropTypes.oneOf(['desktop', 'mobile'])
};

NavItem.defaultProps = {
	variant: 'desktop'
};

export default NavMenuAdvisor;
