import { useQuery, useQueryClient } from 'react-query';

// Our axios Instance
import axiosInstance from 'services/API/API';

// Sora API endpoint
import { alertSettingsUrl } from 'shared/api-urls';

// React Query Keys
import { USER_ALERT_SETTINGS, ERROR_MESSAGE_DATA } from 'shared/query-keys';

function useGetAlertSettings() {
	const queryClient = useQueryClient();
	return useQuery(
		USER_ALERT_SETTINGS,
		async () => {
			const res = await axiosInstance.get(alertSettingsUrl);
			const userAlertSettings = res.data.data;
			return userAlertSettings;
		},
		{
			refetchOnWindowFocus: false,
			onError: (error) => {
				queryClient.setQueryData(ERROR_MESSAGE_DATA, error.message);
			}
		}
	);
}

export default useGetAlertSettings;
