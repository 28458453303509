import { Grid } from '@mui/material';
import { Outlet } from 'react-router-dom';
import PropTypes from 'prop-types';

// Our Components
import Footer from 'components/Footer/Footer';
import FreemiumBanner from 'components/Banner/FremiumBanner';
import MobileNavigationBarToggle from 'components/Frame/MobileNavBarToggle';
import DesktopLeftNav from 'components/Frame/DesktopLeftNav';

// Our Hooks
import useGetProfile from 'hooks/useGetProfile';

// Our Selectors
import selectFremiumProfileStatus from 'selectors/selectFremiumProfileStatus';

// Our Common Prop types
import CommonProps from 'shared/prop-types';

function ContainerFrameWithNav({ sx, outletSX, disableFooter, checkBanner }) {
	// This component is where the switch between mobile and desktop versions occur
	const OutletGridColumnAmount = disableFooter ? 12 : 11;

	const { data: fremiumProfileStatus } = useGetProfile(
		selectFremiumProfileStatus
	);

	const shouldShowFreemiumBanner =
		checkBanner && !fremiumProfileStatus.premium;
	return (
		<Grid container>
			<Grid
				item
				sx={{
					display: { xs: 'block', lg: 'none' },
					maxHeight: 60
				}}
			>
				<MobileNavigationBarToggle />
			</Grid>

			{shouldShowFreemiumBanner && (
				<Grid item xs={12}>
					<FreemiumBanner />
				</Grid>
			)}

			<Grid
				item
				xs={0}
				lg={2}
				sx={{
					display: { xs: 'none', lg: 'block' },
					height: '100dvh'
				}}
			>
				<DesktopLeftNav />
			</Grid>

			<Grid
				container
				item
				direction="column"
				xs={12}
				lg={10}
				sx={{
					height: '100dvh',
					overflow: 'hidden',
					justifyContent: 'space-between',
					...sx
				}}
			>
				<Grid
					container
					item
					xs={OutletGridColumnAmount}
					sx={{
						paddingTop: 6,
						paddingLeft: 8,
						paddingRight: 8,
						overflow: 'auto',
						alignContent: 'flex-start',
						...outletSX
					}}
				>
					<Outlet />
				</Grid>
				<Footer />
			</Grid>
		</Grid>
	);
}

ContainerFrameWithNav.propTypes = {
	sx: CommonProps.sx,
	outletSX: CommonProps.sx,
	disableFooter: PropTypes.bool
};

ContainerFrameWithNav.defaultProps = {
	sx: {},
	outletSX: {},
	disableFooter: false
};

export default ContainerFrameWithNav;
